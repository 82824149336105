import React from 'react';

import { UserActions } from 'src/stores/UserStore';
import { getSiteIdFromPath } from 'src/helpers/url';

import { Input, Button, Flex, Field, Label, Control } from 'src/components';

export default function LoginPage() {
  const [login, setLogin] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState(null);

  const onSubmit = React.useCallback(() => {
    if (!login || !password) return;

    const hostPrefix = window.location.hostname.split('_')[0];
    const siteId = getSiteIdFromPath();
    setError(null);

    UserActions.login({ login, password, hostPrefix }, siteId, (error) =>
      setError(error)
    );
  }, [login, password]);

  const loginDisabled = !login || !password;

  return (
    <div className="login-page">
      <div className="form-wrapper">
        <h1 className="title">Accès PUF</h1>
        <h2 className="subtitle">
          Vous n'êtes pas connecté, merci d'entrer vos identifiants
        </h2>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="box"
        >
          <Field>
            <Label>Email</Label>

            <Control>
              <Input
                value={login}
                id="email"
                label="Login"
                type="text"
                onChange={setLogin}
                placeholder="Email"
              />
            </Control>
          </Field>

          <Field>
            <Label>Mot de passe</Label>

            <Control>
              <Input
                id="password"
                label="Mot de passe"
                type="password"
                value={password}
                onChange={setPassword}
                placeholder="Mot de passe"
              />
            </Control>
          </Field>

          {error && <p className="has-text-danger">{error.message}</p>}

          <Flex style={{ justifyContent: 'flex-end' }}>
            <Button
              id="submit-button"
              type="submit"
              color="success"
              disabled={loginDisabled}
            >
              Se connecter
            </Button>
          </Flex>
        </form>
      </div>
    </div>
  );
}
