import { create } from 'zustand';

const useUIStore = create((set, get) => ({
  codeModalState: null,
  openCodeModal: (resource, onChange) =>
    set({ codeModalState: { resource, onChange } }),
  closeCodeModal: () => set({ codeModalState: null }),
}));

export default useUIStore;
