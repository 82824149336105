// @flow

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { MegadraftIcons, insertDataBlock } from 'megadraft';

import 'react-dropzone-uploader/dist/styles.css';

import { withContext } from 'src/utils/HOC';
import { APIContext } from 'src/pages/contexts';

import Modal from 'src/components/Modal';
import Dropzone from 'src/components/form/Dropzone';

//component that displays the button of the plugins and executes the block
class ImageButton extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
    className: PropTypes.string,
    title: PropTypes.string,
  };
  state = {
    open: false,
    files: [],
    src: '',
  };

  //handle accepted files
  onDrop = (imagePath) => {
    if (!imagePath) return;

    const data = { src: imagePath, type: 'image' };
    this.props.onChange(insertDataBlock(this.props.editorState, data));
    this.setState({ open: false });
  };

  _closeModal = () => {
    this.setState({ open: false });
    this.setState({ files: [] });
  };
  _openModal = () => {
    this.setState({ open: true });
  };

  // UI of the component
  render() {
    return (
      <div>
        <button
          className={this.props.className}
          type="button"
          onClick={this._openModal}
          title={this.props.title}
        >
          <MegadraftIcons.ImageIcon className="sidemenu__button__icon" />
        </button>
        <Modal
          modal={true}
          open={this.state.open}
          buttonLabel="Fermer"
          overlayClassName={css(styles.overlay)}
          className={css(styles.content)}
          onClose={this._closeModal}
        >
          <Dropzone
            onChange={this.onDrop}
            multiple={false}
            maxFiles={1}
            accept="image/*"
            type="image"
          />
        </Modal>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  button: {
    marginLeft: '2px',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'black',
    zIndex: '2',
  },
  content: {
    position: 'absolute',
    top: '-250px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  okButton: {
    borderRadius: '10px',
    background: 'white',
    color: '#02b875',
    borderColor: '#02b875',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderWidth: '1px',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  xButton: {
    position: 'absolute',
    top: 260,
    right: 10,
    cursor: 'pointer',
    fontSize: '30px',
  },
});

export default (withContext(
  APIContext,
  ImageButton
): React.ComponentType<OwnProps>);
