// @flow
import * as React from 'react';

import TopNavbar from './TopNavbar';
import Sidebar from './Sidebar';
import MainContainer from './MainContainer';
import SectionsContextProvider from './SectionsContextProvider';

type Props = {|
  children: React.Node,
  withSidebar: boolean,
|};

export default function AppLayout({
  children,
  withSidebar,
}: Props): React.Node {
  return (
    <SectionsContextProvider>
      <div className="app">
        <TopNavbar />

        <MainContainer>
          {withSidebar && (
            <div className="app-navigation">
              <Sidebar />
            </div>
          )}

          <div className="app-content">{children}</div>
        </MainContainer>
      </div>
    </SectionsContextProvider>
  );
}
