import extendComponent from '../definitions/components/extenders';
import {
  HTML_CLASSNAME_OPTION_SCHEMA,
  HTML_ID_OPTION_SCHEMAS,
} from '../definitions/components/extenders/styleExtender';
import { CONDITIONS_SCHEMA } from '../definitions/components/extenders/conditionsExtender';

const BaseRowSchema = {
  _id: 'LayoutRow',
  label: 'Row du Layout',
  type: 'object',
  propertiesGroups: ['Général', 'Style'],
  isNotComponent: true,
  propertiesList: [
    {
      _cls: 'ObjectPropertySchema',
      key: 'noPaddingTop',
      label: "Retirer l'espace au dessus",
      type: 'bool',
      group: 'Style',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'noPaddingBottom',
      label: "Retirer l'espace au dessous",
      type: 'bool',
      group: 'Style',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'maxWidth',
      label: 'Largeur maximale (en px)',
      type: 'int',
      group: 'Style',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'loadImagesSync',
      label: 'Charger les images immédiatement',
      type: 'bool',
      group: 'Général',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'wrapperClassName',
      label: 'Classe du wrapper de la row',
      type: 'string',
      group: 'Style',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'withSeparator',
      label: "Séparateur avec la ligne d'après",
      type: 'bool',
      group: 'Style',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'isFullWidth',
      label: 'Pleine largeur',
      type: 'bool',
      group: 'Style',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'breakpointViewport',
      label: 'Viewport en dessous duquel les colonnes "break"',
      type: 'enum',
      default: 'tablet',
      group: 'Général',
      values: [
        {
          label: 'desktop',
          value: 'desktop',
        },
        {
          label: 'tablet (par défaut)',
          value: 'tablet',
        },
        {
          label: 'Jamais (ne "break" jamais)',
          value: 'mobile',
        },
      ],
    },
    CONDITIONS_SCHEMA,
  ],
};

const BaseColumnSchema = {
  _id: 'LayoutColumn',
  label: 'Colonne du Layout',
  type: 'object',
  isNotComponent: true,
  propertiesGroups: ['Général', 'Style'],
  propertiesList: [
    {
      _cls: 'ObjectPropertySchema',
      key: 'size',
      label: 'Taille (entre 1 et 12)',
      type: 'int',
      min: 1,
      max: 12,
      group: 'Général',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'offset',
      label: 'Décalage, en nombre de colonne (entre 1 et 12)',
      type: 'int',
      min: 0,
      max: 12,
      group: 'Général',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'loadImagesSync',
      label: 'Charger les images immédiatement',
      type: 'bool',
      group: 'Général',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'options',
      label: 'Options',
      type: 'object',
      group: 'Général',
      propertiesList: [
        HTML_CLASSNAME_OPTION_SCHEMA,
        {
          _cls: 'ObjectPropertySchema',
          key: 'align',
          label: 'Alignement du contenu',
          type: 'enum',
          values: [
            {
              value: 'left',
              label: 'A gauche (par défaut)',
            },
            {
              value: 'center',
              label: 'Au milieu',
            },
            {
              value: 'right',
              label: 'A droite',
            },
          ],
        },
        {
          _cls: 'ObjectPropertySchema',
          key: 'orderAfterBreak',
          label: "Ordre d'affichage une fois que les colonnes ont break",
          type: 'int',
          min: 0,
          group: 'Style',
        },
        HTML_ID_OPTION_SCHEMAS,
        CONDITIONS_SCHEMA,
      ],
    },
  ],
};

const BaseGroupSchema = {
  _id: 'LayoutGroup',
  label: 'Groupe du Layout',
  type: 'object',
  propertiesGroups: ['Général', 'Style'],
  isNotComponent: true,
  hasMarginsOption: true,
  propertiesList: [CONDITIONS_SCHEMA],
};

export const RowSchema = extendComponent(BaseRowSchema, {
  propertyListKey: 'propertiesList',
});
export const ColumnSchema = extendComponent(BaseColumnSchema, {
  propertyListKey: 'propertiesList',
});
export const GroupSchema = extendComponent(BaseGroupSchema, {
  propertyListKey: 'propertiesList',
});
