import * as React from 'react';

import { getCustomPropertyRenderer } from './customPropertyRenderers';
import { getGenericPropertyRenderer } from './genericPropertyRenderers';

import BasePropertyEditor from 'src/pages/components/resource/PropertyEditor';
import { ErrorBoundary, Button, Icon } from 'src/components';

export default function GenericPropertyRenderer({
  value,
  propertySchema,
  resource,
  resourceSchema,
  connector,
}) {
  const { key, columnOptions = {} } = propertySchema;
  const { _id: resourceSchemaId } = resourceSchema;
  const [editing, setEditing] = React.useState(false);

  const PropertyRenderer =
    getCustomPropertyRenderer(resourceSchemaId, key) ||
    getGenericPropertyRenderer(propertySchema);

  if (!PropertyRenderer) return 'No renderer';

  const { editable } = columnOptions;

  if (editing && connector) {
    return (
      <PropertyEditor
        resource={resource}
        propertySchema={propertySchema}
        connector={connector}
        value={value}
        onStopEditing={() => setEditing(false)}
      />
    );
  }

  return (
    <ErrorBoundary>
      <PropertyRenderer
        value={value}
        propertySchema={propertySchema}
        resource={resource}
        resourceSchema={resourceSchema}
      />

      {editable && connector && (
        <Button
          isGhost
          onClick={() => setEditing(true)}
          additionalClassName="edit-button"
        >
          <Icon name="edit" />
        </Button>
      )}
    </ErrorBoundary>
  );
}

function PropertyEditor({
  resource,
  value: initialValue,
  connector,
  propertySchema,
  onStopEditing,
}) {
  const { key } = propertySchema;
  const [value, setValue] = React.useState(initialValue);
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);

  const onSave = React.useCallback(async () => {
    setSubmitting(true);

    try {
      await connector.save({ [key]: value });
      onStopEditing();
    } catch (e) {
      setError(e.message);
    } finally {
      setSubmitting(false);
    }
  }, [value, connector, setSubmitting]);

  return (
    <div className="property-editor-wrapper">
      <BasePropertyEditor
        value={value}
        resource={resource}
        propertySchema={propertySchema}
        onChange={(_propertySchema, newValue) => {
          setValue(newValue);
        }}
        disabled={submitting}
        fieldOptions={{
          noLabel: true,
        }}
      />
      <div className="actions">
        <Button
          isGhost
          onClick={onSave}
          disabled={submitting || value === initialValue}
        >
          <Icon name="check" />
        </Button>
        <Button isGhost onClick={onStopEditing} disabled={submitting}>
          <Icon name="xmark" />
        </Button>
      </div>

      {error && <p className="help is-danger">{JSON.stringify(error)}</p>}
    </div>
  );
}
