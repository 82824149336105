// @flow
import * as React from 'react';

import type { Resource, ResourceSchema } from 'src/types/models';
import type { Query } from 'src/types/routing';
import type { ActionProps, CustomItemAction } from 'src/types/models/schema';

import useResourcePaginatedData from './useResourcePaginatedData';
import { useGlobal } from 'src/hooks';
import { ArchivedContext } from './contexts';

import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import Switch from 'src/components/form/Switch';
import Dropdown from 'src/components/dropdown/Dropdown';
import DropdownMenuItem from 'src/components/dropdown/MenuItem';
import Paginator from '../../Paginator';
import Table from './Table';

type Props = {|
  getItemCustomActions?: (Resource) => Array<CustomItemAction>,
  openInANewTab?: boolean,
  query?: Query,
  onSelect?: (Resource) => void,
  selectedIds?: Array<string>,
  countPerPage?: number,
  canDelete?: boolean,
  canExport?: boolean,
|};

export default function ResourceTable({
  openInANewTab,
  getItemCustomActions,
  query,
  onSelect,
  selectedIds,
  countPerPage: initialCountPerPage,
  canDelete,
  canExport,
}: Props): React.Node {
  const [archived, setArchived] = React.useState(false);
  const { user } = useGlobal();

  const {
    items,
    sortBy,
    filterBy,
    setFilterBy,
    setSortBy,
    loading,
    error,
    search,
    setSearch,
    page,
    setPage,
    countPerPage,
    setCountPerPage,
    count,
    refetch,
    exportUrl,
  } = useResourcePaginatedData({
    archived,
    query,
    countPerPage: initialCountPerPage,
  });

  return (
    <div className="resource-table">
      <Paginator
        autoFocus
        search={search}
        setSearch={setSearch}
        page={page}
        setPage={setPage}
        countPerPage={countPerPage}
        setCountPerPage={setCountPerPage}
        loading={loading}
        count={count}
        error={error}
        additionalActions={
          <>
            {user.isDeveloper && (
              <Switch
                label="Archivés"
                value={archived}
                onChange={setArchived}
                size="small"
                style={{ marginBottom: 0 }}
              />
            )}
            {canExport && (
              <Dropdown
                trigger={(toggleDropdown) => (
                  <Button onClick={toggleDropdown} size="small">
                    <Icon name="ellipsis-v" />
                  </Button>
                )}
              >
                <DropdownMenuItem title="Exporter au format CSV">
                  <a
                    href={exportUrl}
                    download="data.csv"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="download" /> Exporter au format CSV
                  </a>
                </DropdownMenuItem>
              </Dropdown>
            )}
          </>
        }
      >
        <ArchivedContext.Provider value={archived}>
          <Table
            items={items}
            sortBy={sortBy}
            filterBy={filterBy}
            setSortBy={setSortBy}
            setFilterBy={setFilterBy}
            onSelect={onSelect}
            selectedIds={selectedIds}
            getItemCustomActions={getItemCustomActions}
            openInANewTab={!!openInANewTab}
            refetch={refetch}
            canDelete={!!canDelete}
          />
        </ArchivedContext.Provider>
      </Paginator>
    </div>
  );
}
