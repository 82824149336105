// @flow

import * as React from 'react';

import type { ResourceSchema } from 'src/types/models';

import { assert } from 'src/helpers/invariant';
import RESOURCE_TYPES from '../constants/resources';
import SchemaContext from 'src/pages/contexts/SchemaContext';

import { useGlobal } from 'src/hooks';

import { Route, Redirect, Switch, type ContextRouter } from 'react-router-dom';
import ResourceCollection from './Collection';
import ResourceShow from './Show';
import ResourceNew from './New';

type Props = {|
  schema: ResourceSchema,
  scopeQuery?: Object,
  ...ContextRouter,
|};

export default function ResourceRouter({
  schema,
  match,
  scopeQuery = {},
}: Props): React.Node {
  const filters = schema._id ? RESOURCE_TYPES[schema._id].filters : undefined;
  const schemaContextValue = {
    schema,
    path: match.path,
  };

  if (schema.isSingleton) {
    return (
      <SchemaContext.Provider value={schemaContextValue}>
        <div className={`resource-router resource-type-${schema._id}`}>
          <Switch>
            <Route
              sensitive
              exact
              path={`${match.path}/new`}
              key={`${match.path}-new`}
              render={(routeProps) => <ResourceNew {...routeProps} />}
            />

            <Route
              sensitive
              path={`${match.path}/:id`}
              key={`${match.path}-show`}
              render={(routeProps) => {
                const id = assert(
                  routeProps.match.params.id,
                  'Id should be defined in match.params'
                );

                return <ResourceShow {...routeProps} id={id} />;
              }}
            />

            <Route>
              <Redirect to={`${match.path}/${schema.singletonId}`} />
            </Route>
          </Switch>
        </div>
      </SchemaContext.Provider>
    );
  }

  return (
    <SchemaContext.Provider value={schemaContextValue}>
      <div className={`resource-router resource-type-${schema._id}`}>
        <Switch>
          <Route
            sensitive
            path={`${match.path}/collection`}
            key={`${match.path}/collection`}
            render={(routeProps) => (
              <ResourceCollection {...routeProps} query={scopeQuery} />
            )}
          />

          {!!filters &&
            filters.map((filter) => (
              <Route
                sensitive
                path={`${match.path}/${filter.pathname}/collection`}
                key={`${match.path}?filter=${filter.pathname}`}
                exact
                render={(routeProps) => (
                  <ResourceCollection {...routeProps} query={filter.query} />
                )}
              />
            ))}

          <Route
            sensitive
            exact
            path={`${match.path}/new`}
            key={`${match.path}-new`}
            render={(routeProps) => <ResourceNew {...routeProps} />}
          />

          <Route
            sensitive
            path={`${match.path}/:id`}
            key={`${match.path}-show`}
            render={(routeProps) => {
              const id = assert(
                routeProps.match.params.id,
                'Id should be defined in match.params'
              );

              return <ResourceShow {...routeProps} id={id} />;
            }}
          />

          <Route>
            <Redirect to={`${match.path}/collection`} />
          </Route>
        </Switch>
      </div>
    </SchemaContext.Provider>
  );
}
