// @flow
import type { ResourceSchema } from 'src/types';

import SECTIONS from 'src/pages/constants/sections';
import { toPath, pluralizeResourceType } from 'src/helpers/string';

export const getSiteIdFromPath = (): string =>
  window.location.pathname.split('/')[1];

type GetSchemaPathOption = {
  isDraft: boolean,
  customDataPath: string,
};
export const getSchemaFetchPath = (
  siteId: string,
  schema: Object,
  options?: GetSchemaPathOption
): string => {
  const { customDataPath } = options || {
    customDataPath: '',
  };
  let dataPath = 'data';

  if (customDataPath) dataPath = customDataPath;

  const url = `${
    schema.storedInInstanceDatabase ? siteId : 'global'
  }/${dataPath}/${schema.customApiPath || schema._id}`;

  return url;
};

export const getSchemaExportPath = (
  siteId: string,
  schema: Object,
  options?: GetSchemaPathOption
): string => {
  const baseSchemaUrl = getSchemaFetchPath(siteId, schema, options);
  return `${baseSchemaUrl}/export`;
};

export const getResourcePath = (
  siteId: string,
  schema: ResourceSchema,
  id?: string
): string => {
  const { section, _id: type } = schema;
  const toTab = schema.recordsAreEditableInAdmin ? 'edit' : '';
  let resourcePath = `${pluralizeResourceType(type)}`;

  resourcePath =
    section && SECTIONS[section]
      ? `/${siteId}${SECTIONS[section].pathname}/${resourcePath}`
      : `/${siteId}/${resourcePath}`;

  resourcePath = id
    ? `${toPath(resourcePath)}/${id}/${toTab}`
    : toPath(resourcePath);

  return resourcePath;
};

export const getResourceSchemaPath = (
  siteId: string,
  schema: ResourceSchema
): string => `/${siteId}/admin/schemas/${schema._id}`;

function removeAccents(str: string): string {
  return str
    ? str.normalize
      ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      : str.replace(/\W+/g, '')
    : '';
}

export function urlize(str: string): string {
  return str
    ? removeAccents(str.toLowerCase()) // then remove all non-alphanum characters:
        .replace(/^\W+|\W+$/g, '') // trim begin/end
        .replace(/\W+/g, '-') // replace others with "-"
    : '';
}
