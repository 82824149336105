// @flow

import type { ResourceType } from 'src/types/models';
import type { Filter } from 'src/types/routing';

export type ResourceDescription = {|
  singular: ResourceType,
  plural: string,
  pluralLabel?: string,
  filters?: Array<Filter>,
  alwaysShowFilters?: boolean,
|};

const RESOURCE_TYPES: { [key: ResourceType]: ResourceDescription } = {
  AdminUser: {
    singular: 'AdminUser',
    plural: 'AdminUsers',
    pluralLabel: 'Administrateurs',
  },
  Answer: {
    singular: 'Answer',
    plural: 'Answers',
    pluralLabel: 'Réponses',
  },
  Author: {
    singular: 'Author',
    plural: 'Authors',
    pluralLabel: 'Auteurs / intervenants',
    icon: 'user-shakespeare',
  },
  Blog: {
    singular: 'Blog',
    plural: 'Blogs',
    pluralLabel: 'Blogs',
    icon: 'square-pen',
  },
  BlogArticle: {
    singular: 'BlogArticle',
    plural: 'BlogArticles',
    pluralLabel: 'Articles de blog',
    icon: 'pencil-mechanical',
  },
  BlogAuthor: {
    singular: 'BlogAuthor',
    plural: 'BlogAuthors',
    pluralLabel: 'Auteurs de blog',
    icon: 'user-shakespeare',
  },
  BlogCategory: {
    singular: 'BlogCategory',
    plural: 'BlogCategories',
    pluralLabel: 'Catégories des blogs',
    icon: 'tags',
  },
  BlogTag: {
    singular: 'BlogTag',
    plural: 'BlogTags',
    pluralLabel: 'Tags des articles',
    icon: 'tags',
  },
  Bookmark: {
    singular: 'Bookmark',
    plural: 'Bookmarks',
    pluralLabel: 'Liens externes',
  },
  Cart: {
    singular: 'Cart',
    plural: 'Carts',
    pluralLabel: 'Paniers',
    icon: 'cart-shopping',
  },
  SocialInteractionConfiguration: {
    singular: 'SocialInteractionConfiguration',
    plural: 'SocialInteractionConfigurations',
    pluralLabel: 'Configurations des intéractions sociales',
  },
  ComplexComponent: {
    singular: 'ComplexComponent',
    plural: 'ComplexComponents',
    pluralLabel: 'Composants personnalisés',
    icon: 'block-brick',
    filters: [
      {
        label: 'Composants standards',
        pathname: 'standard',
        query: {
          filterBy: {
            objectType: { $eq: [] },
          },
        },
      },
      {
        label: 'Composants pour contenu',
        pathname: 'for-content',
        query: {
          filterBy: {
            objectType: { $exists: true, $type: 'array', $ne: [] },
          },
        },
      },
    ],
  },
  ComponentDataRequester: {
    singular: 'ComponentDataRequester',
    plural: 'ComponentDataRequesters',
    pluralLabel: 'Contenus du composant',
  },
  ContentReport: {
    singular: 'ContentReport',
    plural: 'ContentReports',
    pluralLabel: 'Signalements utilisateur',
    icon: 'skull-crossbones',
  },
  ContentRequester: {
    singular: 'ContentRequester',
    plural: 'ContentRequesters',
    pluralLabel: 'Listes de contenus',
  },
  ContentSchedule: {
    singular: 'ContentSchedule',
    plural: 'ContentSchedules',
    pluralLabel: 'Programmations de contenu',
  },
  Category: {
    singular: 'Category',
    plural: 'Categories',
    pluralLabel: 'Catégories',
    icon: 'tags',
  },
  CrowdFundingCampaign: {
    singular: 'CrowdFundingCampaign',
    plural: 'CrowdFundingCampaigns',
    pluralLabel: 'Campagnes de dons',
    icon: 'hand-holding-dollar',
  },
  CrowdFundingCampaignDonation: {
    singular: 'CrowdFundingCampaignDonation',
    plural: 'CrowdFundingCampaignDonations',
    pluralLabel: 'Dons de campagne',
    icon: 'hand-holding-dollar',
  },
  DiffusionList: {
    singular: 'DiffusionList',
    plural: 'DiffusionLists',
    pluralLabel: 'Contenus à afficher',
  },
  EmailTemplate: {
    singular: 'EmailTemplate',
    plural: 'EmailTemplates',
    pluralLabel: 'Modèles de mail',
    icon: 'envelopes-bulk',
  },
  Export: {
    singular: 'Export',
    plural: 'Exports',
    pluralLabel: 'Exports',
  },
  Font: {
    singular: 'Font',
    plural: 'Fonts',
    pluralLabel: 'Polices de caractères',
  },
  Footer: {
    singular: 'Footer',
    plural: 'Footers',
    pluralLabel: 'Pied de page du site (footer)',
  },
  FrontendRedirection: {
    singular: 'FrontendRedirection',
    plural: 'FrontendRedirections',
    pluralLabel: 'Redirections',
  },
  Gift: {
    singular: 'Gift',
    plural: 'Gifts',
    pluralLabel: 'Cadeaux',
    icon: 'gift',
  },
  Header: {
    singular: 'Header',
    plural: 'Headers',
    pluralLabel: 'Numéro de la revue',
  },
  ImageConfiguration: {
    singular: 'ImageConfiguration',
    plural: 'ImageConfigurations',
    pluralLabel: 'Configuration des images',
    icon: 'camera',
  },
  Link: {
    singular: 'Link',
    plural: 'Links',
    pluralLabel: 'Liens',
  },
  MagazineArticle: {
    singular: 'MagazineArticle',
    plural: 'MagazineArticles',
    pluralLabel: 'Articles de revue',
    icon: 'user-shakespeare',
  },
  MagellanOptions: {
    singular: 'MagellanOptions',
    plural: 'MagellanOptions',
    pluralLabel: 'Options Magellan',
    icon: 'newspaper',
  },
  MagazineIssue: {
    singular: 'MagazineIssue',
    plural: 'MagazineIssues',
    pluralLabel: 'Numéros de la revue',
    icon: 'newspaper',
  },
  Marker: {
    singular: 'Marker',
    plural: 'Marker',
    pluralLabel: 'Marqueurs',
  },
  Menu: {
    singular: 'Menu',
    plural: 'Menus',
    pluralLabel: 'Menus',
  },
  MenuItem: {
    singular: 'MenuItem',
    plural: 'MenuItems',
    pluralLabel: 'Eléments du menu',
  },
  Namespace: {
    singular: 'Namespace',
    plural: 'Namespaces',
    pluralLabel: 'Espaces de nommage',
    icon: 'folder-tree',
  },
  NewsBrief: {
    singular: 'NewsBrief',
    plural: 'NewsBriefs',
    pluralLabel: 'Nouvelles en bref',
    icon: 'megaphone',
  },
  NewspaperConfiguration: {
    singular: 'NewspaperConfiguration',
    plural: 'NewspaperConfigurations',
    pluralLabel: 'Configuration presse',
  },
  OrganizationConfiguration: {
    singular: 'OrganizationConfiguration',
    plural: 'OrganizationConfigurations',
    pluralLabel: "Configurations de l'entreprise",
  },
  Page: {
    singular: 'Page',
    plural: 'Pages',
    pluralLabel: 'Pages',
    icon: 'pager',
    filters: [
      {
        label: 'Page standards',
        pathname: 'standard',
        query: {
          filterBy: {
            objectType: { $eq: [] },
          },
        },
      },
      {
        label: 'Page contenu',
        pathname: 'for-content',
        query: {
          filterBy: {
            objectType: { $exists: true, $type: 'array', $ne: [] },
          },
        },
      },
    ],
  },
  PaywallConfiguration: {
    singular: 'PaywallConfiguration',
    plural: 'PaywallConfigurations',
    pluralLabel: 'Configuration du paywall',
  },
  PopUp: {
    singular: 'PopUp',
    plural: 'PopUps',
    icon: 'square',
  },
  Question: {
    singular: 'Question',
    plural: 'Questions',
    pluralLabel: 'Questions',
  },
  Quizz: {
    singular: 'Quizz',
    plural: 'Quizzes',
    pluralLabel: 'Quizzes',
  },
  Rights: {
    singular: 'Rights',
    plural: 'Rights',
    pluralLabel: "Droits d'accès",
    icon: 'shield',
  },
  RSSConfiguration: {
    singular: 'RSSConfiguration',
    plural: 'RSSConfigurations',
    pluralLabel: 'Configurations RSS',
  },
  Series: {
    singular: 'Series',
    plural: 'Series',
    pluralLabel: 'Séries',
    icon: 'folders',
  },
  ShopItem: {
    singular: 'ShopItem',
    plural: 'ShopItems',
    pluralLabel: 'Produits de boutique',
  },
  schema: {
    singular: 'schema',
    plural: 'schemas',
    pluralLabel: 'Schemas',
  },

  Site: {
    singular: 'Site',
    plural: 'Sites',
    pluralLabel: 'Sites',
  },
  SiteOptions: {
    singular: 'SiteOptions',
    plural: 'SiteOptions',
    pluralLabel: 'Options du site',
    icon: 'globe',
  },
  SitePaymentsConfig: {
    singular: 'SitePaymentsConfig',
    plural: 'SitePaymentsConfigs',
    pluralLabel: 'Configurations des paiements',
  },
  SiteStaticPageConfiguration: {
    singular: 'SiteStaticPageConfiguration',
    plural: 'SiteStaticPageConfigurations',
    pluralLabel: 'Config pages statiques',
    icon: 'file-circle-info',
  },
  Snapshot: {
    singular: 'Snapshot',
    plural: 'Snapshots',
    pluralLabel: 'Snapshots',
  },
  Styles: {
    singular: 'Styles',
    plural: 'Styles',
    pluralLabel: 'Couleurs et style du site',
    icon: 'paintbrush',
  },
  Subscription: {
    singular: 'Subscription',
    plural: 'Subscriptions',
    pluralLabel: 'Abonnements',
    icon: 'badge-dollar',
    filters: [
      {
        label: 'Actifs',
        pathname: 'active',
        query: {
          filterBy: {
            status: 'active',
          },
        },
      },
      {
        label: 'Incomplets',
        description:
          "L'utilisateur n'est pas allé au bout du tunnel de paiement, son abonnement ne s'est donc pas activé suite au paiement",
        pathname: 'incomplete',
        query: {
          filterBy: {
            status: 'incomplete',
          },
        },
      },
    ],
  },
  SubscriptionFormula: {
    singular: 'SubscriptionFormula',
    plural: 'SubscriptionFormulas',
    pluralLabel: "Formules d'abonnement",
    icon: 'money-check-dollar-pen',
  },
  Survey: {
    singular: 'Survey',
    plural: 'Surveys',
    pluralLabel: 'Sondages',
  },
  Tag: {
    singular: 'Tag',
    plural: 'Tags',
    pluralLabel: 'Tags',
    icon: 'tags',
  },
  TextContent: {
    singular: 'TextContent',
    plural: 'TextContents',
    pluralLabel: 'Contenus texte',
    icon: 'newspaper',
  },
  Tournament: {
    singular: 'Tournament',
    plural: 'Tournaments',
    pluralLabel: 'Compétitions',
  },
  TournamentParticipant: {
    singular: 'TournamentParticipant',
    plural: 'TournamentParticipants',
    pluralLabel: 'Participants au compétition',
  },
  User: {
    singular: 'User',
    plural: 'Users',
    pluralLabel: 'Utilisateurs',
    icon: 'user',
  },
  UserComment: {
    singular: 'UserComment',
    plural: 'UserComments',
    pluralLabel: 'Commentaires',
    icon: 'comments',
  },
  UserContribution: {
    singular: 'UserContribution',
    plural: 'UserContributions',
    pluralLabel: 'Contributions utilisateur',
    icon: 'share-from-square',
  },
  Video: {
    singular: 'Video',
    plural: 'Videos',
    pluralLabel: 'Fichiers vidéo',
    icon: 'file-video',
  },
  VideoContent: {
    singular: 'VideoContent',
    plural: 'VideoContents',
    pluralLabel: 'Contenus vidéo',
    icon: 'tv',
  },
  VideoServer: {
    singular: 'VideoServer',
    plural: 'VideoServers',
    pluralLabel: 'Serveurs de streaming vidéo',
  },
  WorkLabel: {
    singular: 'WorkLabel',
    plural: 'WorkLabels',
    pluralLabel: 'Labels de travail',
  },
};

export default RESOURCE_TYPES;
