const NewsletterInput = {
  _id: 'NewsletterInput',
  frontComponent: 'NewsletterInput',
  label: "Input d'abonnement à la newsletter",
  icon: 'envelope',
  optionsSchema: [],
  forPage: true,
};

export default NewsletterInput;
