// @flow
import * as React from 'react';

import { Tooltip, Tag, Icon } from 'src/components';

export default function MarkerTag({ marker, style }) {
  return (
    <Tooltip
      trigger={['hover', 'click']}
      placement="bottom"
      overlay={
        <span>
          {marker.label} : {marker.description}
        </span>
      }
    >
      <a href="#">
        <Tag
          color={marker.type}
          style={style}
          additionalClassName={`marker marker-${marker.value}`}
        >
          {marker.icon && (
            <Icon name={marker.icon} style={{ marginRight: 5 }} />
          )}
          {marker.valueLabel}
        </Tag>
      </a>
    </Tooltip>
  );
}

export function AddedMarkerTag({ marker }) {
  const formattedMarker = {
    ...marker,
    id: marker._id,
    valueLabel: marker.label,
  };

  return (
    <MarkerTag
      marker={formattedMarker}
      style={{
        backgroundColor: marker.backgroundColor,
        color: marker.color,
      }}
    />
  );
}
