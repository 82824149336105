import * as React from 'react';
import classnames from 'classnames';

import { APIContext } from 'src/pages/contexts';

import { Modal, Icon } from 'src/components';

export default function ImageRenderer({
  mode,
  value: image,
  propertySchema,
  resource,
  resourceSchema,
}) {
  const { acceptedRatio } = propertySchema;
  const { getImageUrl } = React.useContext(APIContext);

  const [imageModalOpened, setImageModalOpened] = React.useState(false);

  return (
    <div className="image-renderer">
      <figure
        onClick={() => {
          setImageModalOpened(true);
        }}
        className={classnames(
          'image',
          acceptedRatio ? `is-${acceptedRatio.split(':').join('by')}` : ''
        )}
      >
        <img
          src={getImageUrl(image?.file)}
          alt={resource.label}
          loading="lazy"
        />
        {image && (
          <div className="image-overlay">
            <div className="image-overlay-content">
              <Icon name="hand-pointer" size="2x" />
              <p>Cliquer pour agrandir</p>
            </div>
          </div>
        )}
      </figure>

      {imageModalOpened && (
        <Modal open onClose={() => setImageModalOpened(false)} size="full">
          <img
            src={getImageUrl(image?.file, { originalSize: true })}
            alt={resource.label}
            style={{ maxHeight: '80vh', maxWidth: '95vw' }}
            loading="lazy"
          />
        </Modal>
      )}
    </div>
  );
}
