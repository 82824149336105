// @flow
import * as React from 'react';
import _ from 'lodash';

import type { Trailer } from '../../../types/components/stats/trailer';

type Props = {|
  pl_trailer: Trailer,
  pl_full: Trailer,
  trailer: Trailer,
  full: Trailer,
  className?: string,
|};

export default function EntityStatsView(props: Props): React.Node {
  const metrics = ['playCount', 'viewCount'];
  const dimensions = ['trailer', 'full'];

  return (
    <table className={'EntityStatsView-table ' + (props.className || '')}>
      <thead>
        <tr>
          <th></th>
          <th colSpan="2" className="trailer">
            Extrait
          </th>
          <th colSpan="2" className="full">
            Complet
          </th>
        </tr>
        <tr>
          <th></th>
          <th className="trailer play">Lectures</th>
          <th className="trailer view">Vues ({'>20%'})</th>
          <th className="full play">Lectures</th>
          <th className="full view">Vues ({'>20%'})</th>
        </tr>
      </thead>
      <tbody>
        <tr className="playlist">
          <th>Playlist</th>
          {_.flatten(
            dimensions.map((k) =>
              metrics.map((m) => (
                <td key={k + '-' + m} className={'value ' + k + ' ' + m}>
                  {props['pl_' + k] && props['pl_' + k][m]}
                </td>
              ))
            )
          )}
        </tr>
        <tr className="page">
          <th>Page contenu</th>
          {_.flatten(
            dimensions.map((k) =>
              metrics.map((m) => (
                <td key={k + '-' + m} className={'value ' + k + ' ' + m}>
                  {props[k] && props[k][m]}
                </td>
              ))
            )
          )}
        </tr>
        <tr className="total">
          <th>TOTAL</th>
          {_.flatten(
            dimensions.map((k) =>
              metrics.map((m) => (
                <td key={k + '-' + m} className={'value ' + k + ' ' + m}>
                  {((props[k] && props[k][m]) || 0) +
                    ((props['pl_' + k] && props['pl_' + k][m]) || 0)}
                </td>
              ))
            )
          )}
        </tr>
      </tbody>
    </table>
  );
}
