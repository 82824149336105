import { useDataStore } from 'src/zustand';

export default function ResourceFromDataStore({
  resourceType,
  resourceId,
  render,
}) {
  const getResource = useDataStore((state) => state.get);

  const resource = getResource(resourceType, resourceId);

  if (!resource) return null;

  return render(resource);
}
