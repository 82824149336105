// @flow
import * as React from 'react';
import HmacSha1 from 'crypto-js/hmac-sha1';
import Base64 from 'crypto-js/enc-base64';

import { useGlobal } from 'src/hooks';
import { getSiteIdFromPath } from 'src/helpers/url';
import SECTIONS from 'src/pages/constants/sections';
import { toPath } from 'src/helpers/string';
import { APIContext, SiteContext } from './contexts';
import API from 'src/helpers/api/API';
import { UserActions } from 'src/stores/UserStore';

import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import AppLayout from './AppLayout';
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import SectionRouter from './SectionRouter';
import NotificationCenter from './NotificationCenter';
import JSONCodeModal from './components/JSONCodeModal';

export default function AppRouter(): React.Node {
  const global = useGlobal();
  const { schemaLoaded, siteId, user, userLoaded, site = {} } = global;
  const location = useLocation();
  const history = useHistory();

  const siteIdInPath = getSiteIdFromPath();
  const [loaded, setLoaded] = React.useState(false);

  const [apiContextState, setApiContextState] = React.useState({
    apiUrl: '',
    apiImageBaseUrl: '',
    apiImageUploadUrl: '',
    getImageUrl: () => '',
    getStaticFileUrl: () => '',
    apiUserContributionShowUrl: '',
    getUserContributionFileUrl: () => '',
    siteId: '',
  });

  React.useEffect(() => {
    if (!!siteId && siteIdInPath !== siteId) {
      history.push(
        toPath(
          `/${siteId}/${location.pathname.split('/').slice(2).join('/')}${
            location.search
          }`
        )
      );
    }
  }, [siteIdInPath, siteId]);

  React.useEffect(() => {
    window.logGlobal = () => console.log(global);
  }, [global]);

  React.useEffect(() => {
    if (userLoaded && siteId && schemaLoaded) setLoaded(true);
    if (!userLoaded || !siteId || !schemaLoaded) setLoaded(false);
  }, [userLoaded, siteId, schemaLoaded]);

  React.useEffect(() => {
    const API_URL = import.meta.env.VITE_APP_API_URL || '';
    const useThumbor = site?.site?.featureFlag_thumbor;

    const getImageUrl = (path, options = {}) => {
      if (!path) return '';

      if (!useThumbor) return `${API_URL}/admin/${siteId}/images/show${path}`;

      const { originalSize = false } = options;

      const originalURL = `${siteId}/${path[0] == '/' ? path.substr(1) : path}`;

      const base64OriginalURL = encodeURI(originalURL.toString());

      const optionsString = `${
        originalSize ? '' : '100x0'
      }/${base64OriginalURL}`;

      const signature = Base64.stringify(
        HmacSha1(optionsString, import.meta.env.VITE_APP_THUMBOR_SECURITY_KEY)
      )
        .replaceAll('+', '-')
        .replaceAll('/', '_');

      const url = `${
        import.meta.env.VITE_APP_THUMBOR_SERVER_URL
      }/${signature}/${optionsString}`;

      return url;
    };

    setApiContextState({
      apiUrl: `${API_URL}/admin/${siteId}`,
      apiImageBaseUrl: `${API_URL}/admin/${siteId}/images`,
      apiImageUploadUrl: `${API_URL}/admin/${siteId}/images/upload`,
      apiStaticFileUploadUrl: `${API_URL}/admin/${siteId}/static-files/upload`,
      getImageUrl,
      getStaticFileUrl: (path) =>
        `${API_URL}/admin/${siteId}/static-files/show${path}`,
      apiUserContributionShowUrl: `${API_URL}/admin/${siteId}/user-contributions/show`,
      getUserContributionFileUrl: (contributionId) =>
        contributionId
          ? `${API_URL}/admin/${siteId}/user-contributions/${contributionId}/file`
          : '',
      siteId,
    });
  }, [siteId, site?.site?.featureFlag_thumbor, setApiContextState]);

  React.useEffect(() => {
    if (!user) return;

    const subscriberId = API.subscribe((error, response) => {
      if (response?.status === 401) UserActions.setUser(null);
    });

    return () => API.unsubscribe(subscriberId);
  }, [user]);

  if (userLoaded && !user) return <LoginPage />;
  if (!loaded || !userLoaded) return null;

  const displayHomePage = [
    toPath(`/${siteId}`),
    toPath(`/${siteId}/`),
  ].includes(location.pathname);

  return (
    <APIContext.Provider value={apiContextState}>
      <SiteContext.Provider value={{ siteId, site }}>
        <AppLayout withSidebar={!displayHomePage}>
          <Switch>
            <Route exact path={toPath(`/${siteId}`)} component={HomePage} />

            {Object.keys(SECTIONS).map((sectionId, i) => {
              const section = SECTIONS[sectionId];

              return (
                <Route
                  key={sectionId}
                  sensitive
                  path={toPath(`/${siteId}${section.pathname}`)}
                  render={(routerProps) => (
                    <SectionRouter id={sectionId} {...routerProps} />
                  )}
                />
              );
            })}

            <Route>
              <Redirect to={toPath(`/${siteId}`)} />
            </Route>
          </Switch>

          <NotificationCenter />
          <JSONCodeModal />
        </AppLayout>
      </SiteContext.Provider>
    </APIContext.Provider>
  );
}
