import moment from 'moment';
import React from 'react';

import { Control, Field, IntegerInput, Label } from 'src/components';

// const PERIODS = ['year', 'month', 'day', 'hour', 'minute', 'second'];
const PERIODS = ['year', 'month', 'day'];

export default function DurationEditor({
  propertySchema,
  value: propertyValue,
  onChange,
  disabled,
}) {
  const { authorizedPeriods = [] } = propertySchema;
  const duration = moment.duration(propertyValue);

  const displayedPeriods =
    authorizedPeriods.length > 0 ? authorizedPeriods : PERIODS;

  const [intermediateValue, setIntermediateValue] = React.useState({
    years: duration.years(),
    months: duration.months(),
    days: duration.days(),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  });

  const makeOnFieldChange = React.useCallback(
    (fieldName) => (fieldValue) =>
      setIntermediateValue((previousValue) => ({
        ...previousValue,
        [fieldName]: fieldValue,
      })),
    []
  );

  React.useEffect(() => {
    const newDuration = moment.duration(intermediateValue);

    onChange(newDuration);
  }, [intermediateValue]);

  return (
    <div className="duration-editor">
      {displayedPeriods.includes('year') && (
        <Field>
          <Label>Année</Label>
          <Control>
            <IntegerInput
              value={intermediateValue.years}
              onChange={makeOnFieldChange('years')}
              min={0}
            />
          </Control>
        </Field>
      )}

      {displayedPeriods.includes('month') && (
        <Field>
          <Label>Mois</Label>
          <Control>
            <IntegerInput
              value={intermediateValue.months}
              onChange={makeOnFieldChange('months')}
              min={0}
              max={11}
            />
          </Control>
        </Field>
      )}

      {displayedPeriods.includes('day') && (
        <Field>
          <Label>Jour</Label>
          <Control>
            <IntegerInput
              value={intermediateValue.days}
              onChange={makeOnFieldChange('days')}
              min={0}
              max={30}
            />
          </Control>
        </Field>
      )}

      {/* {displayedPeriods.includes('hour') && (
        <Field>
          <Label>Heure</Label>
          <Control>
            <IntegerInput
              value={intermediateValue.hours}
              onChange={makeOnFieldChange('hours')}
              min={0}
              max={23}
            />
          </Control>
        </Field>
      )}

      {displayedPeriods.includes('minute') && (
        <Field>
          <Label>Minute</Label>
          <Control>
            <IntegerInput
              value={intermediateValue.minutes}
              onChange={makeOnFieldChange('minutes')}
              min={0}
              max={59}
            />
          </Control>
        </Field>
      )}

      {displayedPeriods.includes('seconds') && (
        <Field>
          <Label>Seconde</Label>
          <Control>
            <IntegerInput
              value={intermediateValue.seconds}
              onChange={makeOnFieldChange('seconds')}
              min={0}
              max={59}
            />
          </Control>
        </Field>
      )} */}
    </div>
  );
}
