import { HTML_ELEMENT_OPTION_SCHEMAS } from '../../extenders/markupExtender';

const EntitySeriesEpisodes = {
  _id: 'EntitySeriesEpisodes',
  label: 'Contenus de la série',
  icon: 'link',
  frontComponent: 'EntitySeriesEpisodes',
  forEntity: true,
  optionsSchema: [
    { ...HTML_ELEMENT_OPTION_SCHEMAS, key: 'LabelElement' },
    {
      key: 'label',
      label: 'Titre de la liste',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Affichage',
    },
    {
      key: 'mode',
      label: "Mode d'affichage",
      type: 'enum',
      dataSource: {
        resource: 'ComplexComponent',
        valuePath: 'mode',
        labelPath: 'label',
        query: {
          limit: 0,
          adminFilter: { objectType: { $exists: true, $not: { $size: 0 } } },
        },
      },
      group: 'Affichage',
      _id: '341547b2-e840-407f-91f4-d622f0ddc740',
      required: true,
      default: 'list',
    },
    {
      key: 'showDots',
      label: 'Afficher les puces pour accéder aux images',
      type: 'bool',
      default: false,
      group: 'Affichage',
    },
    {
      key: 'showArrows',
      label: 'Afficher les flèches',
      type: 'bool',
      default: true,
      group: 'Affichage',
    },

    {
      key: 'showCount',
      label: 'Nombre de contenus à afficher',
      type: 'int',
      group: 'Affichage',
    },
    {
      key: 'speed',
      label: 'Vitesse de slide',
      type: 'enum',
      default: 300,
      values: [
        { label: 'lent', value: 1000 },
        { label: 'moyen', value: 500 },
        { label: 'rapide', value: 300 },
        { label: 'très rapide', value: 150 },
      ],
      group: 'Affichage',
    },
  ],
};

export default EntitySeriesEpisodes;
