// @flow
import * as React from 'react';

import type { Instance } from 'src/types/models';

export type ModalContextType = {
  depth: number,
};

export const ModalContext = (React.createContext<ModalContextType>({
  depth: 0,
}): React.Context<ModalContextType>);

export type SelectContextType = {
  portal: boolean,
};

export const SelectContext = (React.createContext<SelectContextType>({
  portal: false,
}): React.Context<SelectContextType>);

export type APIContextType = {|
  apiUrl: string,
  apiImageBaseUrl: string,
  getImageUrl: (imagePath: string) => string,
  getStaticFileUrl: (filePath: string) => string,
  apiImageUploadUrl: string,
  siteId: string,
  apiUserContributionShowUrl: string,
  getUserContributionFileUrl: (contributionId: string) => string,
|};

export const APIContext = (React.createContext<APIContextType>({
  apiUrl: '',
  apiImageBaseUrl: '',
  apiImageUploadUrl: '',
  getImageUrl: () => '',
  getStaticFileUrl: () => '',
  siteId: '',
  apiUserContributionShowUrl: '',
  getUserContributionFileUrl: () => '',
}): React.Context<APIContextType>);

export type SiteContextType = {|
  siteId: string,
  site: ?Instance,
|};

export const SiteContext = (React.createContext<SiteContextType>({
  siteId: '',
  site: null,
}): React.Context<SiteContextType>);

export type SectionsContextType = any;

export const SectionsContext = (React.createContext<SectionsContextType>({
  routes: {},
  countByCollection: {},
}): React.Context<SectionsContextType>);
