const EntityPaywallTag = {
  _id: 'EntityPaywallTag',
  label: 'Tag du paywall (abonné)',
  icon: 'dollar-sign',
  frontComponent: 'EntityPaywallTag',
  forEntity: true,
  optionsSchema: [
    {
      key: 'freeContent',
      label: 'Tag à afficher pour les contenus gratuits',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Affichage',
    },
    {
      key: 'freeTooltip',
      label: 'Texte à afficher au survol pour les contenus gratuits',
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'datawallContent',
      label: 'Tag à afficher pour les contenus réservés aux inscrits',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Affichage',
    },
    {
      key: 'datawallTooltip',
      label:
        'Texte à afficher au survol pour les contenus réservés aux inscrits',
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'paywallContent',
      label: 'Tag à afficher pour les contenus pour les contenus payants',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Affichage',
    },
    {
      key: 'paywallTooltip',
      label:
        'Texte à afficher au survol pour les contenus pour les contenus payants',
      type: 'string',
      group: 'Affichage',
    },
  ],
};

export default EntityPaywallTag;
