import classNames from 'classnames';
import React from 'react';

import { Tag } from 'src/components';

export default function NamespaceChainRenderer({
  mode,
  value: namespace,
  propertySchema,
  resource,
  resourceSchema,
}) {
  const { chain } = resource;

  if (!chain) return null;

  return (
    <nav className="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
      <ul>
        {chain.map((chain, i) => (
          <li
            key={chain.path}
            className={i === chain.length - 1 ? classNames('is-active') : ''}
          >
            <Tag color="dark">{chain.path}</Tag>
          </li>
        ))}
      </ul>
    </nav>
  );
}
