// @flow
import * as React from 'react';
import { pick, pickBy } from 'lodash';

import type { ResourcePropertySchema, ResourceRef } from 'src/types';
import type { OnPropertyValueChange } from 'src/types/components/resource/propertyEditor';

import { getResourcePath } from 'src/helpers/url';
import { useGlobal } from 'src/hooks';
import { useDataStore } from 'src/zustand';

import { Button, Icon, Card, CardHeader, Flex } from 'src/components';
import LabelRenderer from 'src/pages/components/resource/PropertyRenderer/new/LabelRenderer';

import ResourceSelectorInModal from './ResourceSelectorInModal';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: ?ResourceRef,
  disabled?: boolean,
|};

export default function ObjectRefEditor({
  value: resourceRef,
  propertySchema,
  onChange,
  disabled,
}: Props): React.Node {
  const { siteId, globalSchema } = useGlobal();
  const { objectType, schemaSelector, creatable, deletable } = propertySchema;
  const [modalOpen, setModalOpen] = React.useState(false);
  const getResource = useDataStore((state) => state.get);

  let objectTypes = [];

  if (objectType) {
    objectTypes = (
      Array.isArray(propertySchema.objectType)
        ? propertySchema.objectType
        : [propertySchema.objectType]
    ).filter((type) => !!type);
  } else if (schemaSelector) {
    objectTypes = Object.keys(pickBy(globalSchema, schemaSelector));
  } else {
    return null;
  }

  //$FlowIgnore
  const resourceSchemas = pick(globalSchema, objectTypes);
  const resource = resourceRef
    ? getResource(resourceRef._cls, resourceRef._id)
    : null;
  const schema = resourceRef ? resourceSchemas[resourceRef._cls] : null;

  return (
    <>
      <Flex additionalClassName="object-ref-editor">
        {resource && schema && (
          <Card
            style={{ marginRight: 10 }}
            additionalClassName="object-ref-card"
          >
            <CardHeader
              title={
                <LabelRenderer
                  additionalClassName="component-label"
                  resource={resource || resourceRef}
                  schema={schema}
                />
              }
              actions={[
                {
                  icon: 'eye',
                  to: getResourcePath(siteId, schema, resource._id),
                  target: '_blank',
                },
              ]}
            />
          </Card>
        )}

        {!disabled && (
          <>
            <Button
              onClick={() => setModalOpen(true)}
              style={{ marginRight: 10 }}
              size="small"
            >
              <Icon name={!!resourceRef ? 'edit' : 'plus'} />
            </Button>

            {!!resourceRef && (
              <Button
                onClick={() => onChange(null)}
                style={{ marginRight: 10 }}
                color="danger"
                size="small"
              >
                <Icon name="times" />
              </Button>
            )}
          </>
        )}
      </Flex>

      {modalOpen && (
        <ResourceSelectorInModal
          value={resourceRef ? [resourceRef] : []}
          onChange={(resource) => {
            onChange(resource);
          }}
          // $FlowIgnore
          resourceSchemas={Object.values(resourceSchemas)}
          creatable={creatable}
          deletable={deletable}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
}
