import * as React from 'react';
import classnames from 'classnames';

import { useGlobal } from 'src/hooks';

import DynImg from 'src/utils/DynImg';
import {
  Tag,
  QuestionMarkTooltip,
  RichTextContent,
  Icon,
  Text,
} from 'src/components';

export default function PropertyRenderer({ schema, value }) {
  const { user } = useGlobal();
  const { key, label, description, required } = schema;

  const { type } = schema;

  let renderer = null;

  switch (type) {
    case 'string':
    case 'int':
    case 'enum':
      renderer = value || <EmptyValueRenderer required={required} />;
      break;

    case 'richtext':
      if (!value?.html) return null;

      renderer = <RichTextContent richtext={value} truncateLength={100} />;
      break;

    case 'image':
      renderer = <DynImg src={value?.file} h={60} />;
      break;
    case 'file':
    case 'rawImage':
      renderer = <DynImg src={value} h={60} />;
      break;

    case 'DiffusionList':
      renderer = <DiffusionListRenderer value={value} schema={schema} />;
      break;

    default:
      renderer = null;
  }

  if (!renderer) return null;

  return (
    <div
      className={classnames('property-renderer', {
        'has-empty-value': !value,
      })}
    >
      <div
        style={{ justifyContent: 'space-between' }}
        className="property-label"
      >
        <div style={{ display: 'inline-block' }}>
          {label}
          {required ? ' *' : ''}

          {user.isDeveloper && key && (
            <Tag color="info" light style={{ marginLeft: 5 }}>
              {key}
            </Tag>
          )}

          {description && <QuestionMarkTooltip content={description} />}
        </div>
      </div>

      <div className="property-value">{renderer}</div>
    </div>
  );
}

function DiffusionListRenderer({ value, schema }) {
  const { required } = schema;

  if (!value || value.length === 0)
    return <EmptyValueRenderer required={required} />;

  return (
    <ul className="diffusion-list-renderer">
      {value.map((item) => {
        if (item._cls === 'ContentSchedule') {
          return (
            <li key={JSON.stringify(item)}>
              Article seul : {item.content?.label || 'A remplir'}
            </li>
          );
        }

        if (item._cls === 'ContentRequester') {
          return (
            <li key={JSON.stringify(item)}>
              Liste de {item.type} - {item.limit} affichés, triés par{' '}
              {item.sortBy} / {item.sortOrder}
            </li>
          );
        }

        return null;
      })}
    </ul>
  );
}

function EmptyValueRenderer({ required }) {
  return (
    <Text color="danger">
      {required && (
        <Icon name="triangle-exclamation" style={{ marginRight: 5 }} />
      )}
      (vide)
    </Text>
  );
}
