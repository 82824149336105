// @flow

import * as React from 'react';

import { Modal } from 'src/components';
import ResourceSelector from './ResourceSelector';

export default function ResourceSelectorInModal({
  onChange,
  value = [],
  resourceSchemas,
  multi,
  title,
  creatable,
  deletable,
  onClose,
  query,
}: Props): React.Node {
  const [creating, setCreating] = React.useState(false);

  const [intermediateValue, setIntermediateValue] = React.useState(value);

  const applyChanges = React.useCallback(() => {
    if (value === intermediateValue) return;

    onChange(intermediateValue);
  }, [value, intermediateValue]);

  return (
    <Modal
      open
      onClose={(key) => {
        if (key === 'ok') applyChanges();

        onClose();
      }}
      title="Sélection de ressources"
      size="full"
      freezeActions={creating}
    >
      <ResourceSelector
        value={intermediateValue}
        onChange={setIntermediateValue}
        resourceSchemas={Object.values(resourceSchemas)}
        creatable={creatable}
        onCreating={setCreating}
        deletable={deletable}
        multi={multi}
        query={query}
      />
    </Modal>
  );
}
