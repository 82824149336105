// @flow
import * as React from 'react';

import { useGlobal } from 'src/hooks';
import { APIContext } from 'src/pages/contexts';

export default function InstanceLogo(): React.Node {
  const { siteConfig } = useGlobal();
  const { getImageUrl } = React.useContext(APIContext);

  if (!siteConfig.options?.favicon?.file) return null;

  return (
    <img
      alt="instance"
      src={getImageUrl(siteConfig.options.favicon.file)}
      width="28"
      height="28"
    />
  );
}
