// @flow
import * as React from 'react';

import {
  Pagination,
  LoadingOverlay,
  Input,
  Field,
  Control,
  Flex,
} from 'src/components';

type Props = {|
  search?: string,
  setSearch?: (string) => void,
  page: number,
  setPage: (number) => void,
  countPerPage: number,
  setCountPerPage: (number) => void,
  loading: boolean,
  count: number,
  error: null | string,
  children: React.Node,
  additionalActions?: React.Node,
  autoFocus?: boolean,
|};

export default function Paginator({
  search,
  setSearch,
  page,
  setPage,
  countPerPage,
  setCountPerPage,
  loading,
  error,
  count,
  children,
  additionalActions,
  autoFocus,
}: Props): React.Node {
  return (
    <div className="paginator" style={{ position: 'relative' }}>
      {error && <span className="error">{error}</span>}

      {(setSearch || additionalActions) && (
        <Flex style={{ justifyContent: 'space-between', alignItems: 'start' }}>
          {setSearch && (
            <Field>
              <Control style={{ width: 300 }}>
                <Input
                  placeholder="Effectuez une recherche"
                  value={search}
                  onChange={(newSearch) => setSearch(newSearch || '')}
                  autoFocus={autoFocus}
                />
              </Control>
            </Field>
          )}

          {additionalActions && (
            <Flex
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              {additionalActions}
            </Flex>
          )}
        </Flex>
      )}

      <Pagination
        onChange={setPage}
        currentPage={page}
        countPerPage={countPerPage}
        totalCount={count}
        size="small"
      />

      {children}

      <Pagination
        onChange={setPage}
        currentPage={page}
        countPerPage={countPerPage}
        totalCount={count}
        size="small"
      />

      <LoadingOverlay show={loading} color="grey" />
    </div>
  );
}
