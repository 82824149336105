import RCTooltip from 'rc-tooltip';
import React from 'react';

export default function Tooltip({
  children,
  placement,
  trigger,
  visible,
  overlay,
}) {
  if (typeof visible !== 'undefined') {
    return (
      <RCTooltip
        placement={placement || 'top'}
        trigger={trigger}
        overlay={overlay}
        visible={visible}
      >
        {children}
      </RCTooltip>
    );
  }

  return (
    <RCTooltip
      placement={placement || 'top'}
      trigger={trigger}
      overlay={overlay}
    >
      {children}
    </RCTooltip>
  );
}
