const Image = {
  _id: 'simpleImage',
  frontComponent: 'Image',
  label: 'Image',
  icon: 'image',
  displayImages: true,
  optionsSchema: [
    {
      key: 'image',
      label: 'Image',
      type: 'rawImage',
      required: true,
      preview: true,
      group: 'Général',
    }, // TODO formats
    {
      key: 'label',
      label: 'Titre (alt)',
      type: 'string',
      required: true,
      preview: true,
      secondaryPreview: true,
      group: 'Général',
    },
    {
      key: 'tooltip',
      label: 'Tooltip',
      type: 'string',
      required: false,
      preview: false,
      secondaryPreview: false,
      group: 'Général',
    },
    {
      key: 'caption',
      label: 'Légende',
      type: 'string',
      required: false,
      preview: true,
      secondaryPreview: true,
      group: 'Général',
    },
    {
      key: 'credit',
      label: 'Légende',
      type: 'string',
      required: false,
      preview: true,
      secondaryPreview: true,
      group: 'Général',
    },
    {
      key: 'priority',
      label: 'Prioritaire',
      type: 'bool',
      group: 'Général',
    },
    {
      key: 'to',
      label: 'Lien',
      type: 'Link',
      group: 'Général',
    },
    {
      key: 'ratioWH',
      label: 'Ratio',
      type: 'enum',
      values: [
        { label: '16/9', value: 'ratio-16-9' },
        { label: '4/3', value: 'ratio-4-3' },
        { label: 'carré', value: 'ratio-1-1' },
        { label: 'portrait', value: 'ratio-3-4' },
        { label: 'cinemascope', value: 'ratio-2_35-1' },
        { label: 'bandeau', value: 'ratio-5-1' },
      ],
      required: true,
      preview: true,
      secondaryPreview: true,
      group: 'Général',
    },

    {
      key: 'customRatioWidth',
      label: 'Ratio en largeur',
      type: 'float',
      group: 'Général',
    },

    {
      key: 'customRatioHeight',
      label: 'Ratio en hauteur',
      type: 'float',
      group: 'Général',
    },
  ],
  forPage: true,
};

export default Image;
