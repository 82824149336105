const EntityEdiisCartLink = {
  _id: 'EntityEdiisCartLink',
  label: 'Lien vers panier Ediis',
  icon: 'cart-shopping',
  frontComponent: 'EntityEdiisCartLink',
  hasBulmaButtonOption: true,
  hasColorOption: true,
  hasBulmaSizeOption: true,
  optionsSchema: [
    {
      key: 'label',
      label: 'Libellé',
      required: true,
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Général',
    },
  ],

  forEntity: true,
};

export default EntityEdiisCartLink;
