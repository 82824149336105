// @flow
import { atom, type RecoilState } from 'recoil';
import * as React from 'react';

type HeaderHeightState = number;

export const headerHeightState = (atom<HeaderHeightState>({
  key: 'HeaderHeight',
  default: 110,
}): RecoilState<HeaderHeightState>);

type Notification = {|
  text: React.Node,
  type: 'success' | 'warning' | 'danger' | 'info',
  id: string,
  time?: number, // in ms
|};
type NotificationCenterState = Array<Notification>;

export const notificationCenterState = (atom<NotificationCenterState>({
  key: 'notificationCenterState',
  default: [],
}): RecoilState<NotificationCenterState>);

export const pushNotification =
  (notification: Notification): any =>
  (previousNotificationCenterState: NotificationCenterState) =>
    [...previousNotificationCenterState, notification];

export const forceNotification =
  (notification: Notification): any =>
  (previousNotificationCenterState: NotificationCenterState) =>
    [notification];
