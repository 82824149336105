import React from 'react';

const withContext = (Context, Component) => (props) => {
  return (
    <Context.Consumer>
      {(context) => <Component {...context} {...props} />}
    </Context.Consumer>
  );
};

export default withContext;
