import _ from 'lodash';
import React from 'react';

import { Tooltipped } from 'src/utils/Tooltipped';
import { DISPLAY_USER_TYPE_VALUES } from 'src/pages/components/resource/PropertyEditor/LayoutEditor/definitions/displayConditionValues';

import Icon from 'src/components/Icon';

const VISITOR_TYPE_ICONS = {
  anonymous: <Icon name="user-slash" />,
  registered: <Icon name="user" />,
  freemium: <Icon name="user" color="green" />,
  premium: <Icon name="user-tie" color="orange" />,
  not_premium: <Icon name="user-slash" color="orange" />,
};

const VISITOR_TYPE_LABELS = {
  anonymous: 'Non connecté',
  freemium: 'Freemium',
  premium: 'Premium',
  registered: 'Connecté',
  not_premium: 'Non-premium',
};

const DEVICE_TYPE_ICONS = {
  phone: <Icon name="mobile-alt" />,
  desktop: <Icon name="desktop" />,
};

const DEVICE_TYPE_LABELS = {
  phone: 'Mobile',
  desktop: 'Desktop',
};

export default function DisplayConditionRenderer({ condition, mode }) {
  if (!condition) return '';

  if (mode === 'layout') {
    return (
      <span className="display-condition">
        {VISITOR_TYPE_ICONS[condition.visitorType] && (
          <Tooltipped
            tooltip={
              _.find(DISPLAY_USER_TYPE_VALUES, { value: condition.visitorType })
                .label
            }
          >
            {VISITOR_TYPE_ICONS[condition.visitorType]}
          </Tooltipped>
        )}

        {condition.visitorType &&
          condition.deviceType &&
          condition.deviceType !== 'all' &&
          '+'}

        {DEVICE_TYPE_ICONS[condition.deviceType] && (
          <Tooltipped tooltip={DEVICE_TYPE_LABELS[condition.deviceType]}>
            {DEVICE_TYPE_ICONS[condition.deviceType]}
          </Tooltipped>
        )}
      </span>
    );
  }

  return (
    <span className="display-condition">
      {VISITOR_TYPE_ICONS[condition.visitorType]}{' '}
      {VISITOR_TYPE_LABELS[condition.visitorType]}
      {DEVICE_TYPE_ICONS[condition.deviceType]}{' '}
      {DEVICE_TYPE_LABELS[condition.deviceType]}
    </span>
  );
}
