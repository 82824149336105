// @flow
import _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';

import type { Column as ColumnType, ColumnSize, Handlers } from '../../types';

import withStore from 'src/utils/HOC/withStore';
import { makeSpacingClass } from 'src/helpers/style';
import { ColumnSchema } from '../schemas';
import SchemaContext from 'src/pages/contexts/SchemaContext';
import { ResourceFormContext } from 'src/pages/components/resource/ResourceEditor/FormEditor/contexts';

import { Droppable, Icon, Button, Flex, Modal, Text } from 'src/components';
import ResourceFormEditor from 'src/pages/components/resource/ResourceEditor/FormEditor';
import GroupManager from './GroupManager';

type Props = {|
  column: ColumnType,
  path: string,
  actualSize: ColumnSize,
  handlers: Handlers,
  index: number,
  columnsCount: number,
  allSiteKeys?: any, // instance store props
|};

type State = {|
  dragOver: boolean,
  canDrop: boolean,
  optionsModalOpened: boolean,
  dirty: boolean,
  editedColumn: ColumnType,
|};

class LayoutColumn extends React.Component<Props, State> {
  state: State = {
    dragOver: false,
    canDrop: false,
    optionsModalOpened: false,
    dirty: false,
    editedColumn: _.cloneDeep(_.omit(this.props.column, 'contents')),
  };

  onDrop(data: any, e: any) {
    e.stopPropagation();
    const { path, handlers } = this.props;

    if (data.component) {
      handlers.onAddComponent(path, data.component);
    } else if (data.tomove) {
      if (e.ctrlKey) {
        handlers.onAddComponent(path, data.tomove.component);
      } else {
        handlers.onMoveComponent(data.tomove.path, path, data.tomove.component);
      }
    } else if (data.divider) {
      handlers.onDivideCol(path, data.divider);
    }
  }

  canDrop(data: any): any {
    const { actualSize, path } = this.props;

    const canDrop =
      data.component ||
      (data.tomove &&
        !_.isEqual(_.toPath(data.tomove.path).slice(0, -2), _.toPath(path))) ||
      (data.divider && data.divider.minSize <= actualSize);

    return !!canDrop;
  }

  onDragEnter(e: any, canDrop: boolean) {
    this.setState({ dragOver: true, canDrop });
  }

  onDragLeave(e: any, canDrop: boolean) {
    this.setState({ dragOver: false, canDrop: true });
  }

  render(): React.Node {
    const { column, path, actualSize, handlers, columnsCount } = this.props;
    const { contents, flex: columnFlex, groups = [], name } = column;

    const { dragOver, canDrop, optionsModalOpened, dirty, editedColumn } =
      this.state;

    let flex = columnFlex;

    // Convert old values:
    if (!!flex && /col-\w+/.test(flex)) {
      flex = null;
    } else if (flex && flex !== 'row' && flex !== 'text') {
      flex = 'row';
    }

    //-- select flex order menu
    let flexOrderValues = null;
    if (columnsCount > 1) {
      flexOrderValues = [
        {
          value: null,
          label: 'R',
          description: <i> - non spécifié (de gauche à droite)</i>,
        },
      ];

      for (let i = 1; i <= columnsCount; i++) {
        flexOrderValues.push({
          value: i,
          label: (
            <span className="LayoutTemplateItem-flex-order-number">{i}</span>
          ),
        });
      }
    }

    if (!contents) {
      return <div style={{ width: '100%' }}>empty col (should not happen)</div>;
    }

    const hasDeeperRow = contents.find((content) => !!content.cols);

    if (!hasDeeperRow) {
      return (
        <Droppable
          types={['component', 'divider', 'tomove']}
          onDrop={this.onDrop.bind(this)}
          canDrop={this.canDrop.bind(this)}
          onDragEnter={this.onDragEnter.bind(this)}
          onDragLeave={this.onDragLeave.bind(this)}
          style={{ width: '100%' }}
          className={classnames({
            over: dragOver,
            'prevent-dropping': dragOver && !canDrop,
          })}
        >
          <div className="layout-column">
            <Flex
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              additionalClassName={makeSpacingClass([['margin', 2, 'bottom']])}
            >
              <Text element="h4" isTitle size={6} style={{ marginBottom: 5 }}>
                Colonne {name ? <strong>: {name}</strong> : ''}
              </Text>

              <div>
                <Button
                  onClick={() =>
                    this.setState({
                      optionsModalOpened: true,
                    })
                  }
                  size="small"
                  style={{ marginRight: 10 }}
                >
                  <Icon name="fas fa-pen fa-sm" />
                </Button>

                {dirty && (
                  <Button
                    color="success"
                    size="small"
                    onClick={() => {
                      handlers.onChangeCol(path, {
                        ...column,
                        ...editedColumn,
                      });
                      this.setState({ dirty: false });
                    }}
                    style={{ marginRight: 5 }}
                  >
                    <Icon name="check" size="small" />
                  </Button>
                )}

                {!contents.length && ( // empty col
                  <Button
                    title="Supprimer le bloc"
                    onClick={() => handlers.onRemoveCol(path)}
                    size="small"
                    color="danger"
                  >
                    <Icon name="times" />
                  </Button>
                )}
              </div>
            </Flex>

            <GroupManager
              contents={contents}
              groups={groups}
              columnPath={path}
              actualSize={actualSize}
              handlers={handlers}
              column={column}
            />

            {optionsModalOpened && (
              <OptionsModal
                column={editedColumn}
                onChange={(newColumn) =>
                  this.setState({ editedColumn: newColumn, dirty: true })
                }
                onClose={() => this.setState({ optionsModalOpened: false })}
              />
            )}
          </div>
        </Droppable>
      );
    }

    return (
      <div style={{ width: '100%' }} className="layout-column">
        {/* we need at least flex order: */}

        <Flex
          style={{ justifyContent: 'space-between' }}
          additionalClassName={makeSpacingClass([['margin', 4, 'bottom']])}
        >
          <Text element="h4" isTitle size={6} style={{ marginBottom: 5 }}>
            Colonne {name ? <strong>: {name}</strong> : ''}
          </Text>

          <div>
            <Button
              onClick={() =>
                this.setState({
                  optionsModalOpened: true,
                })
              }
              size="small"
              style={{ marginRight: 10 }}
            >
              <Icon name="fas fa-pen fa-sm" />
            </Button>

            {dirty && (
              <Button
                color="success"
                size="small"
                onClick={() => {
                  handlers.onChangeCol(path, {
                    ...column,
                    ...editedColumn,
                  });
                  this.setState({ dirty: false });
                }}
                style={{ marginRight: 5 }}
              >
                <Icon name="check" size="small" />
              </Button>
            )}
          </div>
        </Flex>

        <GroupManager
          contents={contents}
          groups={groups}
          columnPath={path}
          actualSize={actualSize}
          handlers={handlers}
          column={column}
        />

        {optionsModalOpened && (
          <OptionsModal
            column={editedColumn}
            onChange={(newColumn) =>
              this.setState({ editedColumn: newColumn, dirty: true })
            }
            onClose={() => this.setState({ optionsModalOpened: false })}
          />
        )}
      </div>
    );
  }
}

type OptionsModalProps = {|
  onClose: () => void,
  column: ColumnType,
  onChange: ($Shape<ColumnType>) => void,
|};

function OptionsModal({ column, onChange, onClose }: OptionsModalProps) {
  const [editedColumn, setEditedColumn] = React.useState<ColumnType>(
    _.cloneDeep(column)
  );

  return (
    <Modal
      open
      onClose={(buttonName) => {
        if (buttonName === 'ok') onChange(editedColumn);
        onClose();
      }}
      title="Options d'affichage de la ligne"
      modalClassName="is-small"
      size="half"
    >
      <SchemaContext.Provider
        // $FlowIgnore
        value={{
          schema: ColumnSchema,
        }}
      >
        <ResourceFormContext.Provider value={{ enableRouterTabs: false }}>
          <ResourceFormEditor
            resource={editedColumn}
            onChange={(newValue) =>
              setEditedColumn({ ...editedColumn, ...newValue })
            }
          />
        </ResourceFormContext.Provider>
      </SchemaContext.Provider>
    </Modal>
  );
}

export default (withStore(
  'InstanceStore',
  LayoutColumn
): React.ComponentType<Props>);
