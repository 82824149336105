import * as React from 'react';

import { APIContext } from 'src/pages/contexts';
import mediaTypePropertySchema from 'src/schema/PropertySchema/types/Media';

import RawImageEditor from './RawImageEditor';
import EnumEditor from './EnumEditor';
import ObjectRefEditor from './ObjectRefEditor';
import { Dropzone } from 'src/components';

export default function MediaEditor({ value: mediaValue, onChange, disabled }) {
  const [mediaType, setMediaType] = React.useState(mediaValue?.mediaType);
  const [value, setValue] = React.useState(mediaValue?.value);
  const { getStaticFileUrl } = React.useContext(APIContext);

  React.useEffect(() => {
    onChange({ mediaType, value });
  }, [mediaType, value]);

  const updateMediaType = React.useCallback(
    (newMediaType) => {
      if (newMediaType !== mediaType) {
        setValue(null);
      }
      setMediaType(newMediaType);
    },
    [setMediaType, setValue]
  );

  let mediaEditor = null;
  switch (mediaType) {
    case 'image':
      mediaEditor = (
        <RawImageEditor value={value} onChange={setValue} disabled={disabled} />
      );
      break;

    case 'video':
      // case 'audio':
      mediaEditor = (
        <ObjectRefEditor
          propertySchema={{
            label: 'Vidéo',
            type: 'object_ref',
            key: 'video',
            objectType: 'Video',
          }}
          value={value}
          onChange={setValue}
          disabled={disabled}
        />
      );
      break;

    case 'pdf':
      mediaEditor = (
        <>
          <Dropzone
            accept=".pdf"
            onChange={setValue}
            multiple={false}
            maxFiles={1}
            disabled={disabled}
            value={value}
            showPreview={false}
            type="static"
          />
          {value && (
            <object
              data={getStaticFileUrl(value)}
              type="application/pdf"
              width="100%"
              height="500px"
            >
              <p>
                <a href={getStaticFileUrl(value)}>Lien vers le PDF</a>
              </p>
            </object>
          )}
        </>
      );
      break;

    default:
      mediaEditor = null;
  }

  return (
    <div className="media-editor">
      <div style={{ marginBottom: 10 }}>
        <EnumEditor
          value={mediaType}
          propertySchema={
            mediaTypePropertySchema.find((prop) => prop.key === 'objectType')
              .propertiesList[0]
          }
          onChange={updateMediaType}
          disabled={disabled}
        />
      </div>
      {mediaEditor}
    </div>
  );
}
