// @flow
import * as Sentry from '@sentry/react';
import * as React from 'react';
import { cloneDeep, isInteger } from 'lodash';

import type { Row as RowType, Handlers, RowOptions } from '../types';

import SchemaContext from 'src/pages/contexts/SchemaContext';
import { RowSchema } from './schemas';
import useUIStore from 'src/zustand/UIStore';
import { ResourceFormContext } from 'src/pages/components/resource/ResourceEditor/FormEditor/contexts';

import { Icon, Button, Tile, Modal, Text } from 'src/components';
import ColumnComponent from './Column';
import ResourceFormEditor from 'src/pages/components/resource/ResourceEditor/FormEditor';

type Props = {|
  row: RowType,
  path: string,
  index: number,
  handlers: Handlers,
  rowCount: number,
  actualSize?: number,
  isDeep?: boolean,
|};

// Need to get components from context
export default function LayoutRow({
  row,
  index,
  rowCount,
  path,
  handlers,
  actualSize,
  isDeep,
}: Props): React.Node {
  const openCodeModal = useUIStore((state) => state.openCodeModal);
  const [modalOpen, openModal] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);
  const [editedRow, setEditedRow] = React.useState({
    options: cloneDeep(row.options),
  });
  const { name } = row.options || {};

  const saveChanges = React.useCallback(() => {
    if (!dirty) return false;
    // $FlowIgnore
    handlers.onEditRowOptions(path, editedRow.options);
  }, [dirty, editedRow]);

  const { cols: columns } = row;
  let columnComponents;

  if (!row || !columns) return null;

  try {
    columnComponents = columns.map((column, i) => (
      <React.Fragment key={i}>
        {isInteger(column.offset) && column.offset > 0 && (
          <Tile
            // $FlowIgnore
            size={column.offset}
            isParent
            isVertical
            additionalClassName="column-wrapper"
          />
        )}

        <Tile
          // $FlowIgnore
          size={column.size}
          isParent
          isVertical
          additionalClassName="column-wrapper"
        >
          <ColumnComponent
            column={column}
            path={`${path}.cols[${i}]`}
            actualSize={((actualSize || 1) * column.size) / 12}
            handlers={handlers}
            index={i}
            columnsCount={columns.length}
          />
        </Tile>
      </React.Fragment>
    ));
  } catch (e) {
    Sentry.captureException(e);
    return <div className="error">Error in layout ! {e.toString()}</div>;
  }

  const actions = (
    <Tile
      additionalClassName="actions"
      style={{
        justifyContent: 'space-between',
        marginBottom: 5,
        alignItems: 'center',
      }}
    >
      <Text isTitle element="h4" size={6} style={{ marginBottom: 5 }}>
        Ligne {name ? <strong>: {name}</strong> : ''}
      </Text>

      <div>
        <Button
          onClick={() => openModal(true)}
          size="small"
          style={{ marginRight: 10 }}
        >
          <Icon name="pen" size="small" />
        </Button>

        <Button
          color="info"
          isLight
          size="small"
          onClick={() => openCodeModal(row)}
          style={{ marginRight: 5 }}
        >
          <Icon name="code" size="small" />
        </Button>

        {dirty && (
          <Button
            color="success"
            size="small"
            onClick={() => {
              saveChanges();
              setDirty(false);
            }}
            style={{ marginRight: 5 }}
          >
            <Icon name="check" size="small" />
          </Button>
        )}

        {index > 0 && (
          <Button
            onClick={() => handlers.onContentUp(path)}
            size="small"
            style={{ marginRight: 5 }}
          >
            <Icon name="angle-up" size="small" />
          </Button>
        )}

        {index < rowCount - 1 && (
          <Button
            onClick={() => handlers.onContentDown(path)}
            size="small"
            style={{ marginRight: 5 }}
          >
            <Icon name="angle-down" size="small" />
          </Button>
        )}

        <Button
          onClick={() => handlers.onRemoveContent(path)}
          size="small"
          style={{ marginRight: 5 }}
          color="danger"
        >
          <Icon name="times" size="small" />
        </Button>
      </div>
    </Tile>
  );

  return (
    <div className="layout-editor-row">
      {!actualSize && actions}

      <Tile>{columnComponents}</Tile>

      {modalOpen && (
        <OptionsModal
          row={row}
          onChange={(rowShape) => {
            setDirty(true);
            setEditedRow({ ...editedRow, ...rowShape });
          }}
          onClose={() => openModal(false)}
        />
      )}
    </div>
  );
}

type OptionsModalProps = {|
  onClose: () => void,
  row: RowType,
  onChange: ($Shape<RowType>) => void,
|};

function OptionsModal({ row, onChange, onClose }: OptionsModalProps) {
  const { options } = row;
  const [editedOptions, setEditedOptions] = React.useState<$Shape<RowOptions>>({
    ...options,
  });

  return (
    <Modal
      open
      onClose={(buttonName) => {
        buttonName === 'ok'
          ? onChange({ options: editedOptions })
          : setEditedOptions({ ...options });
        onClose();
      }}
      title="Options d'affichage de la ligne"
      modalClassName="is-small"
      size="half"
    >
      <SchemaContext.Provider
        // $FlowIgnore
        value={{
          schema: RowSchema,
        }}
      >
        <ResourceFormContext.Provider value={{ enableRouterTabs: false }}>
          <ResourceFormEditor
            resource={editedOptions}
            onChange={(newValue) =>
              setEditedOptions({ ...editedOptions, ...newValue })
            }
          />
        </ResourceFormContext.Provider>
      </SchemaContext.Provider>
    </Modal>
  );
}
