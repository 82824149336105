// @flow
import * as React from 'react';

import type { ResourcePropertySchema } from 'src/types';
import type { UserStoreProps } from 'src/types/stores';

import invariant from 'src/helpers/invariant';
// import { debugReactMemo } from 'src/helpers/debug';
import useStore from 'src/hooks/useStore';

import { Dropzone } from 'src/components';
import EnumEditor from './EnumEditor';
import ObjectEditor from './ObjectEditor';
import LayoutEditor from './LayoutEditor';
import ObjectRefEditor from './ObjectRefEditor';
import ColorEditor from './ColorEditor';
import ListEditor from './ListEditor';
import SlugEditor from './SlugEditor';
import StringEditor from './StringEditor';
import NumberEditor from './NumberEditor';
import DateEditor from './DateEditor';
import TimestampEditor from './TimestampEditor';
import BooleanEditor from './BooleanEditor';
import PropertyField from './PropertyField';
import RichTextEditor from './RichTextEditor';
import ImageEditor from './ImageEditor';
import RawImageEditor from './RawImageEditor';
import MediaEditor from './MediaEditor';
import JsonEditor from './JsonEditor';
import DurationEditor from './DurationEditor';
import ConditionEditor from './ConditionEditor';

type PropertyFieldOptions = {|
  noLabel?: boolean,
|};

export type Props = {|
  propertySchema: ResourcePropertySchema,
  value: any,
  onChange: (
    propertySchema: ResourcePropertySchema,
    newPropertyValue: any,
    references: any
  ) => void,
  disabled?: boolean,
  isResourcePersisted?: boolean,
  fieldOptions?: PropertyFieldOptions,
|};

function PropertyEditor({
  propertySchema,
  onChange: onChangeProp,
  value: propertyValue,
  disabled: disabledProp,
  isResourcePersisted,
  fieldOptions = {},
}: Props): React.Node {
  const { key, readOnly, createOnly, type, objectType } = propertySchema;
  const { user } = useStore<UserStoreProps>('UserStore');

  const onChange = React.useCallback<OnPropertyValueChange, any>(
    (newPropertyValue, references) =>
      onChangeProp(propertySchema, newPropertyValue, references),
    [onChangeProp]
  );

  const disabled =
    disabledProp || readOnly || (isResourcePersisted && createOnly);

  const propertyType = propertySchema.propertyType || type;

  try {
    let editor;
    switch (propertyType) {
      case 'string':
        editor = (
          <StringEditor
            value={propertyValue}
            propertySchema={propertySchema}
            onChange={onChange}
            disabled={disabled}
          />
        );

        break;

      case 'int': // eslint-disable-line  no-fallthrough
      case 'float':
        editor = (
          <NumberEditor
            value={propertyValue}
            propertySchema={propertySchema}
            onChange={onChange}
            disabled={disabled}
          />
        );
        break;

      case 'bool':
        return (
          <BooleanEditor
            value={propertyValue}
            propertySchema={propertySchema}
            onChange={onChange}
            disabled={disabled}
          />
        );

      case 'timestamp':
        editor = (
          <TimestampEditor
            value={propertyValue}
            propertySchema={propertySchema}
            onChange={onChange}
            disabled={disabled}
          />
        );
        break;

      case 'date':
        editor = (
          <DateEditor
            value={propertyValue}
            propertySchema={propertySchema}
            onChange={onChange}
            disabled={disabled}
          />
        );
        break;

      case 'duration':
        editor = (
          <DurationEditor
            value={propertyValue}
            propertySchema={propertySchema}
            onChange={onChange}
            disabled={disabled}
          />
        );
        break;

      case 'enum':
        editor = (
          <EnumEditor
            value={propertyValue}
            propertySchema={propertySchema}
            onChange={onChange}
            disabled={disabled}
          />
        );
        break;

      case 'richtext':
        editor = (
          <RichTextEditor
            value={propertyValue}
            propertySchema={propertySchema}
            onChange={onChange}
            disabled={disabled}
          />
        );
        break;

      case 'file':
        editor = (
          <Dropzone
            value={propertyValue}
            onChange={onChange}
            disabled={disabled}
            user={user}
            type="static"
          />
        );
        break;

      case 'color':
        editor = (
          <ColorEditor
            value={propertyValue}
            onChange={onChange}
            propertySchema={propertySchema}
            disabled={disabled}
          />
        );
        break;

      case 'layout':
        editor = (
          <LayoutEditor
            value={propertyValue}
            onChange={onChange}
            disabled={disabled}
          />
        );
        break;

      case 'list':
        editor = (
          <ListEditor
            propertySchema={propertySchema}
            value={propertyValue}
            onChange={onChange}
            disabled={disabled}
          />
        );

        break;

      case 'json':
        editor = (
          <JsonEditor
            propertySchema={propertySchema}
            value={propertyValue}
            onChange={onChange}
            disabled={disabled}
          />
        );
        break;

      case 'object':
        editor = (
          <ObjectEditor
            propertySchema={propertySchema}
            value={propertyValue}
            onChange={onChange}
            disabled={disabled}
          />
        );
        break;

      case 'object_ref':
        editor = (
          <ObjectRefEditor
            propertySchema={propertySchema}
            value={propertyValue}
            onChange={(newValue) => onChange(newValue, newValue)}
            disabled={disabled}
          />
        );
        break;

      case 'object_id':
      case 'mongooseRef':
        invariant(
          !!objectType,
          `Property ${key}: object type should be defined when property is of type object_id`
        );

        editor = (
          <ObjectRefEditor
            propertySchema={propertySchema}
            value={
              !!propertyValue
                ? {
                    _id: propertyValue,
                    _isRef: true,
                    _cls: Array.isArray(objectType)
                      ? objectType[0]
                      : objectType,
                  }
                : null
            }
            onChange={(newValue) =>
              onChange(newValue ? newValue._id : null, newValue)
            }
            disabled={disabled}
          />
        );
        break;

      case 'slug':
        editor = (
          <SlugEditor
            value={propertyValue || ''}
            onChange={onChange}
            disabled={disabled}
          />
        );
        break;

      case 'image':
        editor = (
          <ImageEditor
            propertySchema={propertySchema}
            value={propertyValue}
            onChange={onChange}
            disabled={disabled}
          />
        );
        break;

      case 'rawImage':
        editor = (
          <RawImageEditor
            value={propertyValue}
            onChange={onChange}
            disabled={disabled}
          />
        );
        break;

      case 'media':
        editor = (
          <MediaEditor
            value={propertyValue}
            onChange={onChange}
            disabled={disabled}
          />
        );
        break;

      case 'display-condition':
        editor = (
          <ConditionEditor
            value={propertyValue}
            onChange={onChange}
            disabled={disabled}
            propertySchema={propertySchema}
          />
        );
        break;

      default:
        editor = (
          <p>{`No input for property with type ${propertySchema.type}`}</p>
        );
    }

    return (
      <PropertyField
        propertySchema={propertySchema}
        disabled={disabled}
        options={fieldOptions}
        value={propertyValue}
      >
        {editor}
      </PropertyField>
    );
  } catch (e) {
    return 'ERROR: ' + e;
  }
}

export default (React.memo(
  PropertyEditor
  // debugReactMemo((props) => `PropertyEditor:${props.propertySchema.key}`) // To debug prop change
): React.ComponentType<Props>);
