import { BULMA_BUTTON_STYLE_OPTION_SCHEMA } from 'src/pages/components/resource/PropertyEditor/LayoutEditor/definitions/components/extenders/bulmaExtender';
import { COLOR_OPTION_SCHEMA } from 'src/pages/components/resource/PropertyEditor/LayoutEditor/definitions/components/extenders/styleExtender';

const UserAccountButtons = {
  _id: 'userButtonsCustom',
  frontComponent: 'UserButtons',
  label: 'Boutons compte utilisateur',
  icon: 'user',
  hasBulmaButtonOption: true,
  hasColorOption: true,
  hasBulmaSizeOption: true,
  hasIconOption: true,
  optionsSchema: [
    {
      key: 'buttons',
      label: 'Boutons',
      type: 'list',
      group: 'Général',
      itemSchema: {
        _id: 'UserButtonOptions',
        type: 'object',
        labelPath: 'buttonType',
        propertiesList: [
          {
            key: 'buttonType',
            label: 'Fonction',
            type: 'enum',
            group: 'Général',
            values: [
              {
                value: 'userAccount',
                label: 'Compte utilisateur + connexion / déconnexion',
              },
              { value: 'gift', label: 'Offrir' },
              { value: 'cart', label: 'Panier' },
              { value: 'donation', label: 'Don' },
              { value: 'subscribe', label: "S'abonner" },
              { value: 'search', label: 'Recherche' },
            ],
            required: true,
          },
          {
            key: 'labelNodes',
            label: 'Libellé',
            type: 'list',
            group: 'Général',
            itemType: ['react-node'],
            default: [],
          },
          BULMA_BUTTON_STYLE_OPTION_SCHEMA,
          COLOR_OPTION_SCHEMA,
          {
            key: 'alwaysDisplayed',
            label: 'Toujours affiché',
            type: 'bool',
            group: "Condition d'affichage",
            required: false,
            default: false,
          },
        ],
      },
      default: 'Se connecter',
    },
  ],
  forPage: true,
};

export default UserAccountButtons;
