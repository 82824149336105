import * as React from 'react';

import { APIContext } from 'src/pages/contexts';

export default function UserFileRenderer({ value: filePath, resource }) {
  const { getUserContributionFileUrl } = React.useContext(APIContext);

  const fileUrl = getUserContributionFileUrl(resource._id);

  return (
    <a href={fileUrl} target="_blank" rel="noopener noreferrer">
      {filePath}
    </a>
  );
}
