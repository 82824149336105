// @flow
import * as React from 'react';

import type { ResourcePropertySchema, Resource } from 'src/types';
import type { OnPropertyChange } from 'src/types/components/resource/propertyEditor/formEditor';

import getCustomPropertyEditor from '../../../PropertyEditor/customEditors';
import GenericPropertyEditor from '../../../PropertyEditor';
import PropertyField from '../../../PropertyEditor/PropertyField';

type Props = {|
  propertySchema: ResourcePropertySchema,
  resource: Resource,
  onPropertyChange: OnPropertyChange,
  disabled?: boolean,
|};

export default function PropertyEditor({
  resource,
  propertySchema,
  onPropertyChange,
  disabled,
}: Props): React.Node {
  const CustomPropertyEditor = getCustomPropertyEditor(
    resource,
    propertySchema
  );

  if (CustomPropertyEditor)
    return (
      <PropertyField propertySchema={propertySchema} disabled={disabled}>
        <CustomPropertyEditor
          key={propertySchema.key}
          propertySchema={propertySchema}
          value={resource[propertySchema.key]}
          onChange={onPropertyChange}
          disabled={disabled}
        />
      </PropertyField>
    );

  return (
    <GenericPropertyEditor
      key={propertySchema.key}
      propertySchema={propertySchema}
      value={resource[propertySchema.key]}
      onChange={onPropertyChange}
      disabled={disabled}
      isResourcePersisted={!!resource.createdAt}
    />
  );
}
