const EntityMedias = {
  _id: 'medias',
  label: 'Medias',
  icon: 'photo-film-music',
  frontComponent: 'EntityMedias',
  optionsSchema: [],
  forEntity: true,
};

export default EntityMedias;
