import * as React from 'react';

import { ResourceStableFieldsContext } from 'src/pages/contexts/ResourceContext';
import { interpolate } from 'src/helpers/string';

export default function useResourceInterpolatedQuery(query) {
  const resourceStableFields = React.useContext(ResourceStableFieldsContext);

  if (!query) return {};

  return JSON.parse(interpolate(JSON.stringify(query), resourceStableFields));
}
