const EntityBookmarkButton = {
  _id: 'EntityBookmarkButton',
  label: 'Bouton Marque-page',
  frontComponent: 'EntityBookmarkButton',
  icon: 'bookmark',
  hasBulmaButtonOption: true,
  hasColorOption: true,
  optionsSchema: [
    {
      key: 'bookmarkLabel',
      label: 'Texte affiché pour le bouton "Mettre en favori"',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Affichage',
    },
    {
      key: 'unbookmarkLabel',
      label: 'Texte affiché pour le bouton "Mettre en favori"',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Affichage',
    },
  ],
  forEntity: true,
};

export default EntityBookmarkButton;
