export const CONDITIONS_SCHEMA = [
  {
    key: 'displayConditions',
    label: "Conditions d'apparition",
    type: 'list',
    itemSchema: {
      type: 'display-condition',
    },
    group: "Conditions d'apparition",
  },
  {
    key: 'hideConditions',
    label: 'Conditions bloquantes',
    type: 'list',
    itemSchema: {
      type: 'display-condition',
    },
    group: "Conditions d'apparition",
  },
];

const extendComponent = (component, options = {}) => {
  const { propertyListKey = 'optionsSchema' } = options;

  if (component.isNotComponent) return component;

  return {
    ...component,
    [propertyListKey]: [
      ...(component[propertyListKey] || []),
      ...CONDITIONS_SCHEMA,
    ],
  };
};

export default extendComponent;
