const EntityDate = {
  _id: 'publishTs',
  label: 'Date de publication',
  icon: 'clock',
  frontComponent: 'EntityDate',
  forEntity: true,
  hasHTMLMarkup: true,
  optionsSchema: [
    {
      key: 'prefix',
      label: 'Libellé après les auteurs',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Affichage',
    },
    {
      key: 'format',
      label: 'Format',
      type: 'enum',
      default: 'DD/MM/YYYY',
      values: [
        {
          value: 'DD/MM/YYYY',
          label: 'Compact DD/MM/YYYY (ex: "18/05/2022")',
        },
        {
          value: 'LL',
          label: 'Développé LL (ex: "18 Mai 2022")',
        },
        {
          value: 'LLLL',
          label: 'Très développé LLLL (ex: "vendredi 22 avril 2022 17:30")',
        },
        {
          value: 'from-now',
          label: "Depuis aujourd'hui (ex: il y a une semaine)",
        },
      ],
      group: 'Affichage',
    },
    {
      key: 'customFormat',
      label: 'Format custom',
      description:
        "Vous pouvez spécifier n'importe quel format valie de la librairie momentjs https://momentjs.com/docs/#/displaying/",
      type: 'string',
      preview: true,
      group: 'Affichage',
    },
  ],
};

export default EntityDate;
