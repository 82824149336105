// @flow
import * as React from 'react';
import { sortBy, findIndex } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import type { Column, Content, ColumnGroup, Handlers } from '../../../types';

import invariant from 'src/helpers/invariant';

import { Tile } from 'src/components';
import Group from './Group';
import ColumnContent from './ColumnContent';

const NEW_GROUP_COLOR = '#9F9994';
const RANDOM_COLOR_PALETTE = [
  '#B8B42D',
  '#697A21',
  '#B4BB85',
  '#FFFCE8',
  '#3E363F',
  '#8E3B3D',
  '#DD403A',
];

const NEW_GROUP = {
  selectionRange: [],
  options: {
    cssClass: '',
    styleProperties: [],
  },
  title: '',
};

type Props = {|
  column: Column,
  contents: Array<Content>,
  columnPath: string,
  groups: Array<ColumnGroup>,
  actualSize: number,
  handlers: Handlers,
|};

export default function GroupManager({
  contents = [],
  columnPath,
  column,
  groups = [],
  actualSize,
  handlers,
}: Props): React.Node {
  const [newGroup, setNewGroup] = React.useState<ColumnGroup>({
    ...NEW_GROUP,
    uuid: uuidv4(),
  });
  const { selectionRange } = newGroup;

  const resetNewGroup = React.useCallback(() => {
    setNewGroup({ ...NEW_GROUP, uuid: uuidv4() });
  }, [setNewGroup]);

  const addGroup = React.useCallback(
    (group: ColumnGroup) => {
      handlers.onChangeCol(columnPath, {
        ...column,
        groups: [...groups, group],
      });
      resetNewGroup();
    },
    [groups, column, resetNewGroup]
  );

  const deleteGroup = React.useCallback(
    (groupToDelete: ColumnGroup) => {
      handlers.onChangeCol(columnPath, {
        ...column,
        groups: groups.filter((group) => group.uuid !== groupToDelete.uuid),
      });
    },
    [column, groups]
  );

  const editGroup = React.useCallback(
    (editedGroup: ColumnGroup) => {
      const newGroups = [...groups];
      const index = newGroups.findIndex(
        (group) => (group.uuid = editedGroup.uuid)
      );
      newGroups[index] = editedGroup;
      handlers.onChangeCol(columnPath, { ...column, groups: newGroups });
    },
    [groups, column]
  );

  const selectContent = React.useCallback(
    (contentIndex) => {
      let newRange;

      if (selectionRange.length > 2)
        throw new Error(
          'Selection range should never have length superior to 2'
        );

      if (selectionRange.includes(contentIndex)) newRange = [];
      else if (selectionRange.length === 0)
        newRange = [contentIndex, contentIndex];
      else if (selectionRange.length === 1)
        newRange =
          contentIndex < selectionRange[0]
            ? [contentIndex, selectionRange[0]]
            : [selectionRange[0], contentIndex];
      else if (selectionRange.length === 2) {
        const [pos1, pos2] = selectionRange;
        if (contentIndex < pos1) {
          newRange = [contentIndex, pos2];
        } else {
          newRange = [pos1, contentIndex];
        }
      } else {
        throw new Error('Selection range in an undetermined state');
      }

      setNewGroup({
        ...newGroup,
        selectionRange: newRange,
      });
    },
    [selectionRange]
  );

  const allGroups = [...groups, newGroup];
  const orderedGroups = sortBy(allGroups, ({ selectionRange }) => {
    return selectionRange[0];
  });

  const isContentInGroup = (group: ColumnGroup, contentIndex: number) => {
    const { selectionRange } = group;
    return (
      selectionRange[0] <= contentIndex && contentIndex <= selectionRange[1]
    );
  };

  const isContentInAGroup = React.useCallback(
    (contentIndex) => {
      return allGroups.some((group) => isContentInGroup(group, contentIndex));
    },
    [allGroups]
  );

  const renderedComponents: any = contents
    .map((content, contentIndex) => {
      const contentIsInGroup = isContentInAGroup(contentIndex);

      if (!contentIsInGroup) {
        const contentPath = `${columnPath}.contents[${contentIndex}]`;

        return (
          <Tile isChild key={contentIndex}>
            <ColumnContent
              content={content}
              path={contentPath}
              actualSize={actualSize}
              handlers={handlers}
              index={contentIndex}
              rowCount={contents.length}
              onSelect={() => selectContent(contentIndex)}
              isSelected={selectionRange.includes(contentIndex)}
            />
          </Tile>
        );
      }

      const group = orderedGroups.find((group) =>
        isContentInGroup(group, contentIndex)
      );

      invariant(
        !!group,
        'Content is in a group since isContentInAGroup is true'
      );

      const isFirstOfGroup = group.selectionRange[0] === contentIndex;

      if (!isFirstOfGroup) return null;

      const groupIndex = findIndex(groups, (gr) => gr.uuid === group.uuid);

      const groupIsNew = group.uuid === newGroup.uuid;

      return (
        <Group
          key={contentIndex}
          baseContentIndex={contentIndex}
          contents={contents.filter((content, contentIndex) =>
            isContentInGroup(group, contentIndex)
          )}
          group={group}
          columnPath={columnPath}
          actualSize={actualSize}
          handlers={handlers}
          rowCount={contents.length}
          color={
            groupIsNew ? NEW_GROUP_COLOR : RANDOM_COLOR_PALETTE[groupIndex]
          }
          isNew={groupIsNew}
          title={groupIsNew ? 'Nouveau groupe' : group.title}
          onSave={(editedGroup: ColumnGroup) =>
            groupIsNew ? addGroup(editedGroup) : editGroup(editedGroup)
          }
          onDelete={groupIsNew ? resetNewGroup : () => deleteGroup(group)}
        />
      );
    })
    .filter((component) => !!component);

  return <>{renderedComponents}</>;
}
