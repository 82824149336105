const Iframe = {
  _id: 'Iframe',
  frontComponent: 'Iframe',
  label: 'Iframe',
  icon: 'page',
  forPage: true,
  forEntity: false,
  optionsSchema: [
    {
      key: 'title',
      label: 'titre',
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'width',
      label: 'largeur',
      description: "Largueur de l'Iframe",
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'height',
      label: 'hauteur',
      description: "Hauteur de l'Iframe",
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'src',
      label: 'src',
      description: "url de l'iframe",
      type: 'string',
      group: 'Affichage',
    },
  ],
};

export default Iframe;
