// @flow
import * as React from 'react';

import type { CustomActionProps } from 'src/types/components/pages/resourceRouter';

import { useGlobal, useResourceContext, useNotification } from 'src/hooks';
import API from 'src/helpers/api/API';

import { Icon, QuestionMarkTooltip } from 'src/components';

function TestEmailAction({ render }: CustomActionProps): React.Node {
  const { user: admin } = useGlobal();
  const {
    resource: { _id: emailTemplateId },
    apiPath,
  } = useResourceContext();
  const [sending, setSending] = React.useState(false);
  const { force: forceNotification } = useNotification();

  const sendTest = React.useCallback(async () => {
    setSending(true);
    try {
      await API.get(`${apiPath}/${emailTemplateId}/test`);
      forceNotification({
        type: 'success',
        text: `L'email de test a été envoyé à votre adresse ${admin.login}`,
      });
    } finally {
      setSending(false);
    }
  }, [emailTemplateId, forceNotification]);

  return render({
    onClick: sendTest,
    disabled: sending,
    children: (
      <span>
        <Icon name="envelope" style={{ marginRight: 5 }} />
        Envoi d'un email de test{sending ? ' ...' : ''}
        <QuestionMarkTooltip content="En cliquant sur ce lien, vous recevrez un email de test sur votre adresse mail d'admin" />
      </span>
    ),
  });
}

export default TestEmailAction;
