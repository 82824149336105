import React from 'react';
import { MegadraftIcons, DraftJS, createTypeStrategy } from 'megadraft';

import CustomIcons from 'src/images/Icons/Icons';

import Icon from 'src/components/Icon';

//configured entity inputs in toolbar // used in HTML export only
const entityInputStyles = {
  HEADER: (data) => {
    return {
      element: `h${data.header}`,
    };
  },
  LINK: (data) => {
    return {
      element: 'a',
      attributes: {
        href: data.url,
        target: '_blank',
      },
    };
  },
  INTERNAL_PAGE_LINK: (data) => {
    return {
      element: 'a',
      attributes: {
        'data-page-id': data.pageId,
        alt: data.name,
        target: '_blank',
        //href: data.url
      },
    };
  },
};

// Using entityDecorators overrides default decorators so we break standard Links elements... so we re-provide it:
export const entityDecorators = new DraftJS.CompositeDecorator([
  {
    strategy: createTypeStrategy('INTERNAL_PAGE_LINK'),
    component: ({ entityKey, children, contentState }) => {
      const { pageId } = contentState.getEntity(entityKey).getData();
      return (
        <span className="editor__link" title={'lien vers la page ' + pageId}>
          <span className="internal-link-hint">{/*pageId*/}</span>
          {children}
        </span>
      );
    },
  },
  {
    strategy: createTypeStrategy('LINK'),
    component: ({ entityKey, children, contentState }) => {
      const { url } = contentState.getEntity(entityKey).getData();
      return (
        <a className="editor__link" title={url}>
          {children}
        </a>
      );
    },
  },
]);

// Toolbar Actions
export const actions = [
  {
    type: 'inline',
    label: 'B',
    style: 'BOLD',
    icon: MegadraftIcons.BoldIcon,
  },
  {
    type: 'inline',
    label: 'I',
    style: 'ITALIC',
    icon: MegadraftIcons.ItalicIcon,
  },

  {
    type: 'entity',
    label: 'Link',
    style: 'link',
    entity: 'LINK',
    icon: MegadraftIcons.LinkIcon,
  },
  {
    type: 'entity',
    label: 'Link',
    style: 'link',
    entity: 'INTERNAL_PAGE_LINK',
    icon: () => <Icon name="file-alt" style={{ width: 20, height: 20 }} />,
  },
  {
    type: 'separator',
  },
  {
    type: 'block',
    label: 'UL',
    style: 'unordered-list-item',
    icon: MegadraftIcons.ULIcon,
  },

  {
    type: 'block',
    label: 'OL',
    style: 'ordered-list-item',
    icon: MegadraftIcons.OLIcon,
  },
  {
    type: 'block',
    label: 'QT',
    style: 'blockquote',
    icon: MegadraftIcons.BlockQuoteIcon,
  },
  {
    type: 'separator',
  },
  {
    type: 'block',
    label: 'H1',
    style: 'header-one',
    icon: CustomIcons.H1,
  },

  {
    type: 'block',
    label: 'H2',
    style: 'header-two',
    icon: CustomIcons.H2,
  },
  {
    type: 'block',
    label: 'H3',
    style: 'header-three',
    icon: CustomIcons.H3,
  },
  {
    type: 'block',
    label: 'H4',
    style: 'header-four',
    icon: CustomIcons.H4,
  },
  {
    type: 'block',
    label: 'H5',
    style: 'header-five',
    icon: CustomIcons.H5,
  },
  {
    type: 'block',
    label: 'small',
    style: 'header-six',
    icon: () => (
      <h3
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style={{
          position: 'relative',
          bottom: 5,
        }}
      >
        Sm
      </h3>
    ),
  },
  {
    type: 'separator',
  },
  {
    type: 'block',
    label: 'left',
    style: 'left',
    icon: CustomIcons.LeftIcon,
  },
  {
    type: 'block',
    label: 'center',
    style: 'center',
    icon: CustomIcons.CenterIcon,
  },
  {
    type: 'block',
    label: 'right',
    style: 'right',
    icon: CustomIcons.RightIcon,
  },
];

const specialCharactersConvert = (str) => {
  str = str.toString();
  str = str.replace(/&/g, '&amp;');
  str = str.replace(/>/g, '&gt;');
  str = str.replace(/</g, '&lt;');
  str = str.replace(/"/g, '&quot;');
  str = str.replace(/'/g, '&#039;');
  return str;
};

//Helper that converts editor state to HTML
export const draftToHtmlOptions = {
  blockRenderers: {
    atomic: (block) => {
      let escapedData = {};
      //this object contains the data of the uploaded image sotred as a binary array , example : 0 [ "src" : "/upload/X.jpg"]
      block.getData()._root.entries.forEach((entry) => {
        escapedData[entry[0]] = specialCharactersConvert(entry[1]);
      });

      // returning div wrapper and processed image
      return `<figure
                class="align-${escapedData.align || 'center'}"
                ${
                  Number.parseInt(escapedData.display)
                    ? `style="width: ${escapedData.display}%"`
                    : ''
                }
              >
                <picture>
                  <img 
                    data-url="${escapedData.src}"
                    ${
                      escapedData.caption ? `alt="${escapedData.caption}"` : ''
                    }                  
                  >
                </picture>  
                <figcaption class="image-legend">
                  ${
                    escapedData.caption
                      ? `<span class="image-caption">${escapedData.caption}</span>`
                      : ''
                  }
                  ${
                    escapedData.rightsHolder
                      ? `<span class="image-credit">${escapedData.rightsHolder}</span>`
                      : ''
                  }
                </figcaption>              
               
            </figure>`;
    },
  },
  entityStyleFn(entity) {
    const entityType = entity.getType();
    const data = entity.getData();
    if (
      entityType in entityInputStyles &&
      typeof entityInputStyles[entityType] === 'function'
    ) {
      return entityInputStyles[entityType](data);
    }

    return {};
  },
};

// Helper that converts from HTML to editor state ( might be usefull)
export const draftFromHtmlOptions = {
  // @TODO : Refactor to fetch each customBlockFn from the plugin dynamically
  customBlockFn(element) {
    if (
      element.tagName === 'DIV' &&
      element.className.includes('img-wrapper')
    ) {
      let img = element.children.item(0);
      if (img) {
        return {
          type: 'atomic',
          data: {
            blockType: 'image',
            type: 'image',
            id: img.getAttribute('data-target-content-id'),
            caption: img.getAttribute('alt'),
            rightsHolder: img.getAttribute('data-author'),
            display: img.getAttribute('data-display'),
            align: img.getAttribute('data-align'),
            src: img.getAttribute('src'),
          },
        };
      }
    }
    return;
  },
};
