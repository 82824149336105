// @flow
import * as React from 'react';

import type { CustomActionProps } from 'src/types/components/pages/resourceRouter';

import { useGlobal, useResourceContext } from 'src/hooks';

import { QuestionMarkTooltip, Icon } from 'src/components';

function UserLoginAsAction({ render }: CustomActionProps): React.Node {
  const { user: admin, site } = useGlobal();
  const { resource: user } = useResourceContext();

  const generateLoginUrl = React.useCallback(() => {
    const password = window.prompt('Entrer votre mot de passe admin');
    const loginParams = {
      // $FlowIgnore
      login: user.login,
      password: `admin:${admin.login}:${password}`,
    };
    const loginUrl = site.getFrontUrl({
      queryParams: loginParams,
      path: '/login',
    });

    window.open(loginUrl, '_blank').focus();
  });

  return render({
    onClick: generateLoginUrl,
    children: (
      <span>
        <Icon name="user" style={{ marginRight: 5 }} />
        Login as
        <QuestionMarkTooltip content="En cliquant sur ce lien, votre mot de passe admin vous sera demandé puis vous serez redirigé sur le site, connecté en tant que l'utilisateur" />
      </span>
    ),
  });
}

export default UserLoginAsAction;
