// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Query, Filter } from 'src/types/routing';

import { NavLink } from 'react-router-dom';
import { QuestionMarkTooltip } from 'src/components';

type Props = {|
  pathname: string,
  children: React.Node,
  title: string,
  query?: Query,
  filters?: Array<Filter>,
  showFilters?: boolean,
  additionalClassName?: string,
|};

function SidebarItem({
  pathname,
  query,
  children,
  title,
  filters,
  showFilters,
  additionalClassName,
}: Props): React.Node {
  const hasSubItems = !!filters && filters.length > 0;

  return (
    <li
      className={classnames('sidebar-item', additionalClassName, {
        'has-subitems': hasSubItems,
      })}
    >
      <NavLink
        activeClassName="is-active"
        to={pathname}
        title={title}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {children}
      </NavLink>

      {hasSubItems && (
        <SubSidebarItems
          filters={filters || []}
          title={title}
          alwaysShow={!!showFilters}
          pathname={pathname}
        />
      )}
    </li>
  );
}

export default SidebarItem;

type SubSidebarItemsProps = {|
  pathname: string,
  filters: Array<Filter>,
  alwaysShow: boolean,
  title: string,
  additionalClassName?: string,
|};

function SubSidebarItems({
  filters,
  alwaysShow,
  pathname,
  title,
  additionalClassName,
}: SubSidebarItemsProps): React.Node {
  return (
    <ul
      className={classnames('sidebar-subitems', additionalClassName, {
        'always-show': alwaysShow,
      })}
    >
      {filters.map((filter, i) => {
        return (
          <SidebarItem
            pathname={`${pathname}/${filter.pathname}/collection`}
            key={filter.label}
            title={title}
          >
            <span>{filter.label}</span>
            {filter.description && (
              <QuestionMarkTooltip content={filter.description} />
            )}
          </SidebarItem>
        );
      })}
    </ul>
  );
}
