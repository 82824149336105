// @flow
import * as React from 'react';

import type { Resource } from 'src/types';
import type { Style } from 'src/components/types';

import { useGlobal } from 'src/hooks';
import { getResourcePath } from 'src/helpers/url';

import { Card, CardHeader } from 'src/components';
import LabelRenderer from 'src/pages/components/resource/PropertyRenderer/new/LabelRenderer';

type Props = {|
  resource: Resource,
  onAdd?: () => *,
  onRemove?: () => *,
  style?: Style,
  disabled?: boolean,
|};

export default function ListPropertyEditorItem({
  resource,
  onAdd,
  onRemove,
  style,
  disabled,
}: Props): React.Node {
  const resourceType = resource._cls;
  const { globalSchema, siteId } = useGlobal();

  const actions = [
    {
      icon: 'eye',
      to: getResourcePath(siteId, globalSchema[resource._cls], resource._id),
      target: '_blank',
    },
  ];

  if (onAdd) {
    actions.push({
      icon: 'plus',
      onClick: onAdd,
      disabled,
    });
  } else if (onRemove) {
    actions.push({
      icon: 'times',
      onClick: onRemove,
      disabled,
    });
  }

  return (
    <Card style={style}>
      <CardHeader
        title={
          <LabelRenderer
            additionalClassName="component-label"
            resource={resource}
            schema={globalSchema[resourceType]}
          />
        }
        actions={actions}
      />
    </Card>
  );
}
