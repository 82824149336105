// @flow
import type { Tab } from 'src/types/components/pages/resourceRouter';

import ViewStatisticsTab from './ViewStatisticsTab';

const videoSteamsCustomTabs: Array<Tab> = [
  {
    to: 'statistics',
    icon: 'chart-line',
    label: 'Statistiques de vues',
    content: ViewStatisticsTab,
  },
];

export default videoSteamsCustomTabs;
