import * as React from 'react';
import moment from 'moment';
import { useEffectOnce } from 'usehooks-ts';

import API from 'src/helpers/api/API';
import { useResourceContext } from 'src/hooks';
import { useGlobal } from 'src/hooks';
import { buildQueryString } from 'src/helpers/misc';

import {
  Columns,
  Column,
  Text,
  DefaultPlaceholder,
  Button,
} from 'src/components';
import RankingTable from './RankingTable';
import TimeSeries from './TimeSeries';

export default function TournamentStatsPage() {
  const { siteId } = useGlobal();
  const { resource: tournament } = useResourceContext();

  const [stats, setStats] = React.useState(null);

  useEffectOnce(() => {
    const fetchStats = async () => {
      const fetchedStats = await API.get(
        `${siteId}/statistics/tournaments/${tournament._id}`
      );

      setStats(fetchedStats);
    };

    fetchStats();
  });

  if (!stats) return null;

  const period = 'day';
  const startDate = moment.unix(tournament.startTs).subtract(1, 'week');
  const endDate = moment.min([
    moment().add(1, 'day'),
    moment.unix(tournament.endTs),
  ]);

  const {
    betsTimeSeries,
    createdParticipantCountTimeSeries,
    top20Rankings,
    participantCountTimeSeries,
  } = stats;

  return (
    <div className="tournament-statistics">
      <Columns>
        <Column>
          <Text size={4} isTitle>
            Classement
          </Text>

          <div style={{ marginTop: 10 }}>
            {top20Rankings.length > 0 ? (
              <RankingTable top20Rankings={top20Rankings} />
            ) : (
              <DefaultPlaceholder
                title="Le classement n'est pas encore disponible"
                iconName="search"
                description="Le tournoi n'ayant pas encore débuté"
              />
            )}
          </div>
        </Column>
        <Column>
          <Text size={4} isTitle>
            Evolution du nombre de paris
          </Text>
          <TimeSeries
            timeSeries={{ bets: betsTimeSeries }}
            tournament={tournament}
            startDate={startDate}
            endDate={endDate}
            period={period}
            title="Evolution du nombre de paris"
          />
        </Column>
      </Columns>
      <Columns>
        <Column>
          <Text size={4} isTitle>
            Evolution du nombre de participants
          </Text>
          <TimeSeries
            timeSeries={{ participants: participantCountTimeSeries }}
            tournament={tournament}
            startDate={startDate}
            endDate={endDate}
            period={period}
            title="Evolution du nombre de participants"
          />
        </Column>
        <Column>
          <Text size={4} isTitle>
            Evolution du nombre de participants créés
          </Text>
          <TimeSeries
            timeSeries={{
              createdParticipants: createdParticipantCountTimeSeries,
            }}
            tournament={tournament}
            startDate={startDate}
            endDate={endDate}
            period={period}
            title="Evolution du nombre de participants créés"
          />
        </Column>
      </Columns>

      <div className="download-buttons">
        <Text element="h3" size={4} isTitle>
          Télécharger la liste des participants
        </Text>
        <Button
          isText
          style={{ marginRight: 10 }}
          target="_blank"
          rel="noopener noreferrer"
          href={`${
            import.meta.env.VITE_APP_API_URL
          }/admin/${siteId}/tournaments/${tournament._id}/participants-csv`}
        >
          Tous
        </Button>
        <Button
          isText
          target="_blank"
          rel="noopener noreferrer"
          href={`${
            import.meta.env.VITE_APP_API_URL
          }/admin/${siteId}/tournaments/${
            tournament._id
          }/participants-csv?${buildQueryString({
            createdOnly: true,
          })}`}
        >
          Créés pour le tournoi
        </Button>
      </div>
    </div>
  );
}
