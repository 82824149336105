// @flow
import type { SectionName, HardcodedSectionRoute } from 'src/types/routing';

// Statistics tab
import ChartsStatsPage from './sectionSpecificPages/data/ChartsStatsPage';

// Encoding tab
import VideoEncodingPage from './sectionSpecificPages/encoding/VideoEncodingPage';

// Site tab
import ImageTool from './sectionSpecificPages/admin/ImageTool';

// SuperAdmin tab
import ComponentFinder from './sectionSpecificPages/superAdmin/ComponentFinder';

const superAdminRoutes: Array<HardcodedSectionRoute> = [
  {
    pathname: 'component-finder',
    component: ComponentFinder,
    label: 'Recherche de composants',
  },
];

const videoEncodingRoutes: Array<HardcodedSectionRoute> = [
  {
    pathname: 'encoding',
    component: VideoEncodingPage,
    query: {
      filterBy: { encodingStatus: { $in: ['encoding', 'pending'] } },
      refresh: true,
    },
    label: "En cours d'encodage",
  },
  {
    pathname: 'error',
    component: VideoEncodingPage,
    query: { filterBy: { encodingStatus: 'error' } },
    label: 'En erreur',
  },
  {
    pathname: 'all',
    component: VideoEncodingPage,
    label: 'Toutes',
  },
];

const toolsRoutes = [
  {
    pathname: 'images',
    component: ImageTool,
    label: 'Images',
  },
];

const dataRoutes: Array<HardcodedSectionRoute> = [
  {
    pathname: 'charts',
    component: ChartsStatsPage,
    label: 'Graphiques',
    exact: false,
  },
];

const sectionHardcodedRoutes: {
  [key: SectionName]: Array<HardcodedSectionRoute>,
} = {
  site: [],
  contents: [],
  encoding: videoEncodingRoutes,
  data: dataRoutes,
  users: [],
  payments: [],
  tools: toolsRoutes,
  superadmin: superAdminRoutes,
};

export default sectionHardcodedRoutes;
