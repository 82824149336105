const Countdown = {
  _id: 'SubscriptionsGoalProgress',
  frontComponent: 'SubscriptionsGoalProgress',
  label: 'Compte à rebours Call-To-Action',
  icon: 'bullseye',
  forPage: true,
  forEntity: false,
  optionsSchema: [
    {
      key: 'title',
      label: 'Titre',
      type: 'string',
      required: true,
      group: 'Général',
    },
    {
      key: 'goal',
      label: "Nombre d'abonnements à atteindre",
      type: 'int',
      required: true,
      group: 'Général',
    },
    {
      key: 'additionalFakeCount',
      label: "Faux nombre d'abonnements à ajouter au total",
      type: 'int',
      required: false,
      group: 'Affichage',
    },
    {
      key: 'progressMultiplier',
      label:
        "Multiplicateur pour la bar de progrès. Multipliera le nombre réel d'abonnement pour calculer le pourcentage de complétion",
      type: 'int',
      required: false,
      group: 'Affichage',
    },
    {
      key: 'endTs',
      label: 'Deadline',
      type: 'timestamp',
      required: true,
      group: 'Général',
    },
  ],
};

export default Countdown;
