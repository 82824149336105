import * as React from 'react';

import { useGlobal } from 'src/hooks';
import { NavLink } from 'react-router-dom';
import { getResourcePath } from 'src/helpers/url';
import { useDataStore } from 'src/zustand';

import LabelRenderer from '../LabelRenderer';
import { Tag, Icon } from 'src/components';

export default function ObjectIdRenderer({
  value,
  propertySchema,
  resource,
  resourceSchema,
}) {
  const { globalSchema, siteId } = useGlobal();
  const [objectType] = Array.isArray(propertySchema.objectType)
    ? propertySchema.objectType
    : [propertySchema.objectType];
  const getResource = useDataStore((state) => state.get);

  if (!value) return null;

  const refSchema = globalSchema[objectType];
  const reference = getResource(objectType, value);

  if (!objectType) return null;

  if (!reference || !refSchema)
    return (
      <Tag>
        {objectType} - {value}
      </Tag>
    );

  return (
    <NavLink
      to={`${getResourcePath(siteId, refSchema)}/${reference._id}`}
      target="_blank"
      style={{ display: 'flex' }}
    >
      <LabelRenderer resource={reference} schema={refSchema} />

      <Icon
        size="sm"
        name="external-link-alt"
        style={{ verticalAlign: 'text-bottom', marginLeft: 5 }}
      />
    </NavLink>
  );
}
