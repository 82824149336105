import * as React from 'react';
import ReactJson from 'react-json-view';
import useFuse from 'use-fuse';
import { flatten, pick, omit } from 'lodash';
import useDebouncedEffect from 'use-debounced-effect';

import { Input, Field, Control } from 'src/components';

// see: https://fusejs.io/
const fuseOptions = {
  keys: ['backofficeId', 'frontendId', 'sites', '_pages'],
  threshold: 0.1,
  minMatchCharLength: 3,
};

export default function ComponentUsage({ components }) {
  const [search, setSearch] = React.useState('');
  const [fuseSearch, setFuseSearch] = React.useState('');

  useDebouncedEffect(() => setFuseSearch(search), 500, [search]);

  const formattedComponents = React.useMemo(
    () =>
      Object.keys(components).map((componentId) => {
        const component = components[componentId];

        const sites = Object.keys(component.sites);

        const filteredPages = pick(component.sitePages, sites);
        const _pages = flatten(
          Object.values(components[componentId].sitePages)
        );

        return {
          id: componentId,
          backofficeId: componentId,
          frontendId: component.frontendId,
          sites,
          _pages,
          pages: filteredPages,
        };
      }),
    [components]
  );

  const fusedComponents = useFuse(formattedComponents, fuseSearch, fuseOptions);
  const displayedComponents = fusedComponents
    .slice(0, 4)
    .map((component) => omit(component, '_pages'));

  return (
    <div className="finder">
      <Field>
        <Control style={{ maxWidth: 300 }}>
          <Input
            value={search}
            onChange={setSearch}
            placeholder="ID composant, site ID ou page ID"
          />
        </Control>
      </Field>

      {search.length > 2 ? (
        <ReactJson src={displayedComponents} />
      ) : (
        <div className="placeholder">
          Entrer au moins 3 caractères pour afficher les composants
        </div>
      )}
    </div>
  );
}
