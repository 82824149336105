import IDSchema from './PropertySchema/specificProperties/ID';
import TypePropertySchema from './PropertySchema/specificProperties/Type';

const SchemaSchema = {
  _id: 'schema',
  label: "Schéma de l'objet",
  type: 'object',
  section: 'admin',
  recordsAreEditableInAdmin: true,
  storedInInstanceDatabase: false,
  propertiesList: [
    {
      ...IDSchema,
      key: '_id',
      label: 'Classe/type',
      description: 'Nom du type associé à ce schéma',
      column: true,
      sortable: true,
    },
    {
      key: 'storedInInstanceDatabase',
      label: "Les data sont stockées dans la base spécifique de l'instance",
      type: 'bool',
      required: true,
      default: true,
      column: false,
      condition: { f$: 'adminUser?.isDeveloper' },
    },
    {
      key: 'hasAssociatedDBCollection',
      label: 'Le schéma possède une collection associée en base de données',
      type: 'bool',
      required: true,
      default: true,
      column: false,
      condition: { f$: 'adminUser?.isDeveloper' },
    },
    {
      key: 'isSingleton',
      label: "Il n'existe qu'un élément de ce type par instance",
      type: 'bool',
      required: true,
      default: false,
      column: false,
      condition: { f$: 'adminUser && adminUser.isDeveloper' },
    },
    {
      key: 'singletonId',
      label: "ID de l'élément",
      type: 'string',
      required: true,
      default: false,
      column: false,
      condition: { f$: 'adminUser && adminUser.isDeveloper' },
    },
    {
      key: 'customApiPath',
      type: 'string',
      label: 'Custom API path',
      column: false,
      condition: { f$: 'adminUser && adminUser.isDeveloper' },
    },
    { key: 'label', type: 'string', label: 'Libellé', column: true },
    TypePropertySchema,
    {
      key: 'description',
      type: 'richtext',
      label: 'Description',
      column: false,
    },
    {
      key: 'section',
      label: "Section de l'admin",
      description: "Si vide, n'apparaîtra pas dans l'admin",
      type: 'enum',
      required: false,
      column: true,
      values: [
        { value: 'site', label: 'Configuration du site' },
        { value: 'contents', label: 'Contenus' },
        { value: 'blogs', label: 'Blogs' },
        { value: 'products', label: 'Produits' },
        { value: 'data', label: 'Data' },
        { value: 'users', label: 'Utilisateurs' },
        { value: 'payments', label: 'Paiements' },
        { value: 'admin', label: 'Admin' },
        { value: 'superadmin', label: 'Super Admin' },
        {
          value: 'noneButDisplayable',
          label: "Aucune mais affichable dans l'admin",
        },
      ],
    },
    {
      key: 'showForInstances',
      label: 'Afficher uniquement pour les sites suivants',
      type: 'enum',
      multiple: true,
      required: false,
      dataSource: {
        isGlobal: true,
        resource: 'Site',
        valuePath: '_id',
        labelPath: '_id',
      },
    },
    {
      key: 'isPlugin',
      label: 'Plugin ajoutable aux modèles',
      type: 'bool',
      required: false,
      column: true,
    },
    {
      key: 'plugins',
      type: 'enum',
      multiple: true,
      label: 'Liste des plugins à appliquer',
      values: [
        {
          value: 'ContentPlugin',
          label: 'Plugin contenu',
        },
        {
          value: 'HistoryPlugin',
          label:
            "Plugin historique (enregistrement de l'historique des changements)",
        },
        {
          value: 'SEOPlugin',
          label: 'Plugin SEO',
        },
        {
          value: 'VersionPlugin',
          label:
            "Plugin de versionning (enregistrement d'une capture d'une ressource)",
        },
      ],
      column: true,
      default: [],
    },
    {
      key: 'isProduct',
      label: 'Produit achetable dans le panier',
      type: 'bool',
      required: false,
      column: true,
    },
    {
      key: 'propertiesGroups',
      type: 'list',
      label: 'Groupe de propriétés',
      description:
        "Les propriétés qui auront été assignées à un groupe appraîtront groupées dans l'éditeur",
      itemSchema: {
        type: 'string',
      },
      toggleable: true,
    },
    {
      key: 'propertiesList',
      label: 'Properties List',
      type: 'list',
      unique: 'key',
      itemSchema: {
        type: 'ObjectPropertySchema',
      },
    },
    {
      key: 'recordsAreEditableInAdmin',
      label: 'Editable en admin',
      type: 'bool',
      default: true,
    },
  ],
};

export default SchemaSchema;
