// @flow
import * as React from 'react';
import Reflux from 'reflux';

import SchemaStore from 'src/stores/SchemaStore';
import UserStore from 'src/stores/UserStore';
import InstanceStore from 'src/stores/InstanceStore';

const stores = {
  SchemaStore,
  UserStore,
  InstanceStore,
};

type StoreName = 'SchemaStore' | 'UserStore' | 'InstanceStore';

// storeNameList is a list of store name like ['SchemaStore', 'UserStore'];
export default function withStore<SP, OP>(
  storeNames: StoreName | Array<StoreName>,
  Component: React.ComponentType<{| ...OP, ...SP |}>
): React.ComponentType<OP> {
  class WithStoreComponent extends Reflux.Component {
    stores = (Array.isArray(storeNames) ? storeNames : [storeNames]).map(
      (name) => stores[name]
    );

    render() {
      return <Component {...this.state} {...this.props} />;
    }
  }

  return (WithStoreComponent: React.ComponentType<OP>);
}
