// @flow
import * as React from 'react';
import useDebouncedEffect from 'use-debounced-effect';
import moment from 'moment';

import API from 'src/helpers/api/API';
import { useGlobal } from 'src/hooks';
import { useResourceContext } from 'src/hooks';

import { Toolbar, Chart, Notification } from 'src/components';
import { PERIOD, PERIOD_LABELS } from 'src/components/stats/constants';

const USAGE_TYPE = {
  VIEWS: 'VIEWS',
};

const USAGE_TYPE_LABELS = {
  VIEWS: 'Nombre de vues',
};

export default function ViewStatisticsTab(): React.Node {
  const { resource: content } = useResourceContext();

  const { siteId } = useGlobal();

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [startDate, setStartDate] = React.useState(
    moment().subtract(1, 'month').toDate()
  );
  const [endDate, setEndDate] = React.useState(
    moment().add(1, 'week').toDate()
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState({
    value: PERIOD.WEEK,
    label: PERIOD_LABELS[PERIOD.WEEK],
  });
  const [datasets, setDatasets] = React.useState({});

  const fetchDataset = React.useCallback(async () => {
    setLoading(true);
    try {
      const fetchedDatasets = await API.get(
        `${siteId}/statistics/content-views`,
        {
          period: selectedPeriod.value,
          startDate: moment(startDate),
          endDate: moment(endDate),
          contentType: content._cls,
          contentId: content._id,
        }
      );

      setDatasets({
        'Nombre de vues': fetchedDatasets,
      });
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  });

  React.useEffect(() => {
    fetchDataset();
  }, []);

  useDebouncedEffect(
    () => {
      fetchDataset();
    },
    1000,
    [selectedPeriod.value, startDate, endDate]
  );

  if (!content?._id || !content?.views)
    return (
      <Notification color="warning">
        Pas encore de statistiques pour ce contenu
      </Notification>
    );

  return (
    <div className="view-statistics-tab">
      <p style={{ marginBottom: '1rem' }}>
        Nombre total de vues : <strong>{content.views}</strong>
      </p>

      <div className="view-chart">
        <Toolbar
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          usageTypeLabels={USAGE_TYPE_LABELS}
          usageType={USAGE_TYPE}
          setEndDate={setEndDate}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
        />

        {error && <p className="has-text-error">{JSON.stringify(error)}</p>}

        <Chart
          startDate={startDate}
          endDate={endDate}
          period={selectedPeriod.value}
          datasets={datasets}
          loading={loading}
          title="Statistique de vues"
          periodTitle={PERIOD_LABELS[selectedPeriod.value]}
        />
      </div>

      <Notification color="info" style={{ margin: '1rem 0' }}>
        <p style={{ marginBottom: '0.5rem' }}>
          <strong>Une vue est comptabilisée quand : </strong>
        </p>
        <ul className="bullet-list">
          <li>
            <strong>Contenu textuel</strong> : l'utilisateur est sur le contenu
            depuis plus de 15 secondes et atteint 50% de l'article (doit avoir
            les droits d'accès au contenu)
          </li>
          <li>
            <strong>Contenu vidéo</strong> : l'utilisateur a atteint 50% de la
            vidéo (doit avoir les droits d'accès au contenu)
          </li>
        </ul>
      </Notification>
    </div>
  );
}
