const ProductCartButton = {
  _id: 'ProductCartButton',
  frontComponent: 'ProductCartButton',
  label: "Bouton panier d'un produit",
  icon: 'euro-sign',
  optionsSchema: [
    {
      key: 'product',
      label: 'Produit',
      type: 'object_ref',
      objectType: ['SubscriptionFormula'],
      group: 'Général',
    },
    {
      key: 'addLabel',
      label: 'Texte',
      type: 'string',
      group: 'Général',
    },
  ],
  forPage: true,
  ofEntity: false,
  hasBulmaButtonOption: true,
  hasColorOption: true,
  hasBulmaSizeOption: true,
};

export default ProductCartButton;
