const EntityLikeButton = {
  _id: 'EntityLikeButton',
  label: 'Bouton Like',
  frontComponent: 'EntityLikeButton',
  icon: 'heart',
  optionsSchema: [
    {
      key: 'likeLabel',
      label: 'Texte affiché pour le bouton "Like"',
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'unlikeLabel',
      label: 'Texte affiché pour le bouton "Unlike"',
      type: 'string',
      group: 'Affichage',
    },
  ],
  forEntity: true,
};

export default EntityLikeButton;
