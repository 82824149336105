// @flow
import * as React from 'react';

import type { ResourcePropertySchema } from 'src/types';
import type { OnPropertyValueChange } from 'src/types/components/resource/propertyEditor';

import { useGlobal } from 'src/hooks';

import ResourceListPropertyEditor from './ResourceListEditor';
import ObjectListPropertyEditor from './ObjectListEditor';
import PropertiesListEditor from '../../ResourceEditor/FormEditor/SchemaEditor/PropertiesListEditor';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: Array<any>,
  disabled?: boolean,
|};

export default function ListPropertyEditor({
  propertySchema,
  onChange,
  value,
  disabled,
}: Props): React.Node {
  const { globalSchema } = useGlobal();
  const { itemSchema } = propertySchema;
  const itemType = Array.isArray(propertySchema.itemType)
    ? propertySchema.itemType[0]
    : propertySchema.itemType;
  const itemTypeStoredInDB =
    itemType && !!globalSchema[itemType]?.hasAssociatedDBCollection;

  if (
    itemTypeStoredInDB ||
    ['object_ref', 'object_id'].includes(itemSchema?.type)
  )
    return (
      <ResourceListPropertyEditor
        propertySchema={propertySchema}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    );

  if (itemSchema?.type === 'ObjectPropertySchema') {
    return (
      <PropertiesListEditor
        propertySchema={propertySchema}
        value={value}
        onChange={(schema, newValue) => onChange(newValue)}
        disabled={disabled}
      />
    );
  }

  return (
    <ObjectListPropertyEditor
      propertySchema={propertySchema}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
}
