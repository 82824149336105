// @flow
import * as React from 'react';
import classnames from 'classnames';

import { useOnKeyDown } from 'src/hooks';
import { ModalContext, SelectContext } from 'src/pages/contexts';

import Button from './Button';
import GlobalModalElement from './GlobalModalElement';

type ButtonText = string;

type Props = {|
  buttons?: { [buttonKey: string]: ButtonText },
  onClose: (buttonText?: string) => any,
  modalClassName?: string,
  open: boolean,
  contentClassName?: string,
  children: React.Node,
  title?: string,
  size?: 'full' | 'two-third' | 'half',
  freezeActions?: boolean,
  hideButtons?: boolean,
|};

export default function Modal({
  buttons: buttonsFromProps,
  onClose,
  modalClassName: modalClassNameFromProps,
  open,
  contentClassName: contentClassNameFromProps,
  children,
  title,
  size = 'two-third',
  freezeActions,
  hideButtons,
  okLabel = 'Ok',
  cancelLabel = 'Annuler',
  maxWidth,
}: Props): React.Node {
  const modalContext = React.useContext(ModalContext);
  const modalClassName = classnames(
    'modal',
    'is-active',
    `depth-${modalContext.depth}`,
    modalClassNameFromProps,
    size
  );
  const closeModal = React.useCallback(
    (key) => open && !freezeActions && onClose && onClose(key),
    [onClose, freezeActions]
  );

  useOnKeyDown(
    {
      onEscapeKeyDown: () => {
        closeModal('cancel');
      },
    },
    [closeModal]
  );

  if (!open) return null;

  return (
    <GlobalModalElement>
      <div className={modalClassName}>
        <div className="modal-background" />

        <div className="modal-card" style={{ maxWidth }}>
          {title && (
            <header className="modal-card-head">
              <p className="modal-card-title">{title}</p>
              {onClose && (
                <button
                  className="delete"
                  aria-label="close"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    closeModal('cancel');
                  }}
                ></button>
              )}
            </header>
          )}
          <section className="modal-card-body">
            <div>
              <ModalContext.Provider
                value={{
                  depth: modalContext.depth + 1,
                }}
              >
                <SelectContext.Provider
                  value={{
                    portal: true,
                  }}
                >
                  {children}
                </SelectContext.Provider>
              </ModalContext.Provider>
            </div>
          </section>

          {!hideButtons && (
            <footer className="modal-card-foot">
              <Button
                onClick={() => closeModal('cancel')}
                disabled={freezeActions}
              >
                {cancelLabel}
              </Button>
              <Button onClick={() => closeModal('ok')} disabled={freezeActions}>
                {okLabel}
              </Button>
            </footer>
          )}
        </div>
      </div>
    </GlobalModalElement>
  );
}
