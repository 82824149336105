// @flow

import * as React from 'react';
import moment from 'moment';

import type { CustomActionProps } from 'src/types/components/pages/resourceRouter';

import { useGlobal, useResourceContext } from 'src/hooks';
import API from 'src/helpers/api/API';

import {
  Icon,
  Modal,
  Field,
  Label,
  Control,
  DatePicker,
  Button,
  Flex,
  Notification,
  CopyButton,
} from 'src/components';

export default function GenerateFreeLinkAction({
  render,
}: CustomActionProps): React.Node {
  const { site } = useGlobal();
  const { resource: content } = useResourceContext();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [expirationDate, setExpirationDate] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);
  const [link, setLink] = React.useState(null);
  const [error, setError] = React.useState(null);

  const generateLink = React.useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!expirationDate) return;

      setSubmitting(true);
      setError(null);

      try {
        const generatedLink = await API.post(
          `${site._id}/contents/${content._cls}/${content._id}/generateFreeLink`,
          {
            expirationDate,
          }
        );

        setLink(generatedLink);
      } catch (e) {
        console.error(e);
        if (e?.message) setError(e.message);
      } finally {
        setSubmitting(false);
      }
    },
    [expirationDate, site, content]
  );

  const closeModal = React.useCallback(
    (e) => {
      if (!modalOpen) return;

      if (e.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      }

      setModalOpen(false);
    },
    [modalOpen, setModalOpen]
  );

  const openModal = React.useCallback(
    (e) => {
      if (modalOpen) return;

      if (e.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
      }

      setModalOpen(true);
    },
    [modalOpen, setModalOpen]
  );

  return render({
    onClick: openModal,
    children: (
      <span>
        <Icon name="gift" style={{ marginRight: 5 }} />
        Générer un lien de gratuité
        <Modal
          modal
          open={modalOpen}
          onClose={closeModal}
          title="Générer un lien de gratuité"
          modalClassName="is-small"
          hideButtons
          maxWidth={600}
        >
          {!content.published && (
            <Notification color="warning" size="small">
              <p>
                <Icon name="warning" style={{ marginRight: 5 }} size="small" />
                Ce contenu n'étant pas publié, vous ne pourrez pas tester le
                lien généré sur le site.
              </p>
            </Notification>
          )}

          <p style={{ marginBottom: '1rem' }}>
            Une fois que vous aurez généré le lien, vous pourrez le partager à
            des personnes afin qu'elles puissent accéder au contenu sans être
            bloqué par le datawall ou paywall.
          </p>

          <Field style={{ marginRight: 10 }}>
            <Label>Date d'expiration du lien :</Label>
            <Control>
              <DatePicker
                minDate={moment().toDate()}
                maxDate={moment().add(3, 'month').toDate()}
                value={expirationDate}
                onChange={setExpirationDate}
                withPortal
              />
            </Control>
          </Field>

          {error && <p className="has-text-danger">{error}</p>}

          {link && (
            <Notification
              color="success"
              light
              style={{ position: 'relative' }}
            >
              <p>
                Un lien donnant accès au contenu jusqu'au{' '}
                <strong>{moment(expirationDate).format('DD-MM-YYYY')}</strong> a
                été généré. Conservez et partagez le pour donner accès
                gratuitement au contenu.
              </p>
              <p
                style={{
                  marginTop: 10,
                  position: 'relative',
                  paddingBottom: 30,
                }}
              >
                <strong>Lien complet :</strong>
                <br />
                <a href={link.url} target="_blank" rel="noreferrer">
                  {link.url}{' '}
                </a>
                <span style={{ position: 'absolute', bottom: 10, right: 10 }}>
                  <Button
                    href={link.url}
                    size="small"
                    isGhost
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="arrow-up-right-from-square" />
                  </Button>

                  <CopyButton
                    textToCopy={link.url}
                    style={{ marginLeft: 10 }}
                  />
                </span>
              </p>
            </Notification>
          )}

          <Flex
            style={{
              justifyContent: 'flex-end',
              gap: 10,
              alignItems: 'center',
            }}
          >
            <Button onClick={closeModal} type="button">
              Fermer
            </Button>

            {link ? (
              <span className="has-text-success" style={{ marginLeft: 10 }}>
                Code généré !
              </span>
            ) : (
              <Button
                disabled={!expirationDate || link || submitting}
                type="submit"
                color="success"
                onClick={generateLink}
              >
                Générer le lien
              </Button>
            )}
          </Flex>
        </Modal>
      </span>
    ),
  });
}
