import * as React from 'react';
import { pick } from 'lodash';

import { Flex, Button, Text, Tag, Icon } from 'src/components';
import PropertyRenderer from './PropertyRenderer';

export default function ComponentPreview({ component, alerts }) {
  const { options = {}, optionsSchema = [] } = component;

  const [secondaryFieldsToggled, setSecondaryFieldsToggled] =
    React.useState(false);

  const optionsSchemaToPreview = optionsSchema.filter(
    (schema) => schema.preview
  );

  if (optionsSchemaToPreview.length === 0) return null;

  const primaryPreviewOptions = optionsSchemaToPreview.filter(
    (schema) => !schema.secondaryPreview
  );
  const primaryFieldsAlerts = pick(
    alerts,
    primaryPreviewOptions.map((schema) => schema.key)
  );
  const secondaryPreviewOptions = optionsSchemaToPreview.filter(
    (schema) => schema.secondaryPreview
  );
  const secondaryFieldsAlerts = pick(
    alerts,
    secondaryPreviewOptions.map((schema) => schema.key)
  );

  return (
    <div className="component-preview">
      <div className="primary-fields">
        <Text isTitle size={7}>
          Champs principaux ({primaryPreviewOptions.length})
          {Object.keys(primaryFieldsAlerts).length > 0 && (
            <Tag color="danger" size="small" additionalClassName="alerts-count">
              {Object.keys(primaryFieldsAlerts).length}{' '}
              <Icon name="triangle-exclamation" size="small" />
            </Tag>
          )}
        </Text>

        <ul className="bullet-list">
          {primaryPreviewOptions.map((propertySchema) => {
            const { key } = propertySchema;

            return (
              <li key={key}>
                <PropertyRenderer
                  schema={propertySchema}
                  value={options[key]}
                />
              </li>
            );
          })}
        </ul>
      </div>

      {secondaryPreviewOptions.length > 0 && (
        <div className="secondary-fields">
          <Flex verticalAlign style={{ justifyContent: 'space-between' }}>
            <Text isTitle size={7} style={{ marginBottom: 0 }}>
              Champs secondaires ({secondaryPreviewOptions.length})
              {Object.keys(secondaryFieldsAlerts).length > 0 && (
                <Tag
                  color="danger"
                  size="small"
                  additionalClassName="alerts-count"
                >
                  {Object.keys(secondaryFieldsAlerts).length}{' '}
                  <Icon name="triangle-exclamation" size="small" />
                </Tag>
              )}
            </Text>

            <Button
              size="small"
              onClick={() => setSecondaryFieldsToggled(!secondaryFieldsToggled)}
              isText
            >
              {secondaryFieldsToggled ? 'Replier' : 'Déplier'}
            </Button>
          </Flex>

          {secondaryFieldsToggled && (
            <ul className="bullet-list">
              {secondaryPreviewOptions.map((propertySchema) => {
                const { key } = propertySchema;

                return (
                  <li key={key}>
                    <PropertyRenderer
                      schema={propertySchema}
                      value={options[key]}
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}
