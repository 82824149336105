import * as React from 'react';

import Icon from './Icon';

export default function ReactNode({ node }) {
  if (!node) return null;

  switch (node.type) {
    case 'icon': {
      const { icon } = node;
      if (!icon) return null;
      return (
        <Icon
          name={icon.name}
          color={icon.color?.type}
          size={icon.size}
          weight={icon.weight}
          type={icon.type}
        />
      );
    }

    case 'text':
      return <span>{node.text}</span>;

    case 'break':
      return <br />;

    default:
      return null;
  }
}
