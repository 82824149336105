// @flow
import * as React from 'react';

import type { ResourcePropertySchema } from 'src/types';
import type { OnPropertyValueChange } from 'src/types/components/resource/propertyEditor';

import IntegerEditor from './IntegerEditor';
import FloatEditor from './FloatEditor';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: number,
  disabled?: boolean,
|};

export default function NumberEditor(props: Props): React.Node {
  const { propertySchema } = props;
  const { type } = propertySchema;

  switch (type) {
    case 'int':
      return <IntegerEditor {...props} />;
    case 'float':
      return <FloatEditor {...props} />;
    default:
      throw new Error(`NumberEditor doesnt know how to render type ${type}`);
  }
}
