import React from 'react';

import fr_FR from 'antd/es/locale/fr_FR';
import {
  AntdConfig,
  AntdWidgets,
  Query,
  Utils as QbUtils,
} from '@react-awesome-query-builder/antd';

import '@react-awesome-query-builder/antd/css/styles.css';

import { useEffectOnce } from 'usehooks-ts';
import { useGlobal } from 'src/hooks';

import Result from './Result';
import Builder from './Builder';
import { Notification } from 'src/components';

const InitialConfig = AntdConfig;
const { FieldCascader, FieldDropdown } = AntdWidgets;

export default function RawConditionBuilder({
  configSchema,
  value,
  onChange: onChangeProp,
}) {
  const { user } = useGlobal();
  const [config, setConfig] = React.useState({
    ...InitialConfig,
    settings: {
      ...InitialConfig.settings,
      locale: {
        moment: 'fr',
        antd: fr_FR,
      },
    },
    renderField: (props) => <FieldCascader {...props} />,
    renderOperator: (props) => <FieldDropdown {...props} />,
    renderFunc: (props) => <FieldDropdown {...props} />,
    ...configSchema,
  });
  const [tree, setTree] = React.useState();
  const [errors, setErrors] = React.useState([]);
  const [treeLoaded, setTreeLoaded] = React.useState(false);

  useEffectOnce(() => {
    if (value) {
      setTree(
        QbUtils.checkTree(QbUtils.loadFromJsonLogic(value, config), config)
      );
    }
    setTreeLoaded(true);
  });

  const onChange = React.useCallback(
    (immutableTree, config) => {
      // Tip: for better performance you can apply `throttle` - see `examples/demo`

      setTree(immutableTree);
      setConfig(config);

      const jsonLogicObject = QbUtils.jsonLogicFormat(immutableTree, config);
      const { errors, logic } = jsonLogicObject;

      setErrors(errors);

      onChangeProp(logic);
      // `jsonTree` can be saved to backend, and later loaded to `queryValue`
    },
    [onChangeProp]
  );

  if (!treeLoaded) return null;

  return (
    <div>
      <Query
        {...config}
        value={tree}
        onChange={onChange}
        renderBuilder={Builder}
      />

      {errors.length > 0 &&
        errors.map((error) => (
          <Notification key={error} color="danger">
            {error}
          </Notification>
        ))}

      {user?.isDeveloper && <Result tree={tree} config={config} />}
    </div>
  );
}
