// @flow
import type { SectionName, SectionOrder } from 'src/types/routing';

const SECTION_ITEMS_ORDER: { [sectionName: SectionName]: Array<string> } = {
  site: [
    'site-options',
    'site-static-page-configurations',
    'pages',
    'complex-components',
    'styles',
    'user-profile-templates',
    'external-services',
    'content-types',
    'countries',
    'email-templates',
    'encoding-profiles',
    'pop-ups',
    'question-categories',
    'right-owners',
  ],
  contents: [
    'authors',
    'text-contents',
    'video-contents',
    'magazine-articles',
    'magazine-issues',
    'news',
    'tags',
    'categories',
    'quizzes',
    'series',
    'surveys',
    'answers',
    'questions',
    'videos',
  ],
  blogs: ['blogs', 'blog-authors', 'blog-articles'],

  encoding: ['all', 'encoding', 'error'],
  data: ['views', 'sales'],
  users: [
    'users',
    'aboweb-action-logs',
    'newsletters-subscribers',
    'user-bug-reports',
    'user-comments',
    'user-contributions',
    'user-quizz-answers',
    'user-watcheds',
  ],
  payments: [
    'subscription-formulas',
    'subscriptions',
    'crowd-funding-campaigns',
    'crowd-funding-campaign-donations',
    'rights',
  ],
  admin: ['magellan-options', 'paywall-configuration', 'rss-configuration'],
  tools: ['images'],
  superadmin: [
    'sites',
    'admin-users',
    'video-servers',
    'tools',
    'schemas',
    'schema-preview',
    'site-hierarchy',
    'compare-data',
    'component-finder',
  ],
};

const buildSectionItemOrder = (sectionName: SectionName): SectionOrder => {
  const order = {};

  SECTION_ITEMS_ORDER[sectionName].forEach((itemPathname, index) => {
    order[itemPathname] = index;
  });

  return order;
};

export default buildSectionItemOrder;
