// @flow
import * as React from 'react';

import type { ResourceType } from 'src/types/models';

export type CustomAction = {|
  id: string,
  content: React.ComponentType<{}>,
|};

export default function getResourceTypeMainActions(
  resourceType: ResourceType
): Array<CustomAction> {
  switch (resourceType) {
    default:
      return [];
  }
}
