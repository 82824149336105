// @flow

import * as React from 'react';

import LabelRenderer from 'src/pages/components/resource/PropertyRenderer/new/LabelRenderer';
import { Text, Icon, Flex, Tag } from 'src/components';

export default function LayoutComponentTitle({ component }) {
  const {
    label,
    icon,
    optionsSchema = [],
    options = {},
    _cls: componentClass,
    labelPath,
  } = component;

  const { name, mode } = options || {};

  const modeKeySchema = optionsSchema.find((option) => option.key === 'mode');

  return (
    <Flex
      style={{
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Text
        element="h5"
        size={6}
        additionalClassName="layout-component-label"
        style={{ display: 'flex' }}
      >
        {icon && <Icon name={icon} style={{ marginRight: 5 }} />}
        <span style={{ marginRight: 10 }}>{label} :</span>

        <strong>
          {name ? (
            <span>{name}</span>
          ) : (
            <LabelRenderer
              additionalClassName="component-label"
              resource={options}
              schema={{
                type: 'object',
                propertiesList: optionsSchema,
                labelPath,
              }}
              noWarning
            />
          )}
        </strong>
      </Text>

      {modeKeySchema && (
        <ComponentMode mode={mode} modeSchema={modeKeySchema} />
      )}

      {componentClass === 'ComplexComponent' && (
        <Tag color="link">Composant personalisé</Tag>
      )}
    </Flex>
  );
}

function ComponentMode({ modeSchema, mode }): React.Node {
  if (!mode)
    return (
      <span className="LayoutComponent-mode">
        <Tag color="danger">
          <Icon name="triangle-exclamation" style={{ marginRight: 5 }} />
          Mode non défini
        </Tag>
      </span>
    );

  return (
    <span className="LayoutComponent-mode">
      <Tag color="primary">
        <span style={{ marginRight: 5 }}>Mode</span> <strong>{mode}</strong>
      </Tag>
    </span>
  );
}
