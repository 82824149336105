import * as React from 'react';
import { map } from 'lodash';

// import type { Resource, ResourceSchema, ResourceRef } from 'src/types';

import { useGlobal } from 'src/hooks';
import invariant from 'src/helpers/invariant';
import SchemaContext from 'src/pages/contexts/SchemaContext';
import { getResourcePath } from 'src/helpers/url';
import { makeRef } from 'src/helpers/models/schema';

import { Flex, Select, Text, EmptyState, Button, Icon } from 'src/components';
import Item from './Item';
import ResourceTable from 'src/pages/components/resource/ResourceTable';
import CreateResourceForm from './CreateResourceForm';

// type Props = {|
//   onChange: (Array<Resource>, reference: Resource) => any,
//   value: Array<ResourceRef>,
//   multi?: boolean,
//   resourceSchemas: Array<ResourceSchema>,
//   title?: string,
//   creatable?: boolean,
//   deletable?: boolean,
//   onCreating?: (boolean) => any,
// |};

function ResourceSelector({
  onChange,
  value = [],
  resourceSchemas,
  multi,
  title,
  creatable,
  deletable,
  onCreating,
  query,
}) {
  const { siteId } = useGlobal();
  const [selectedSchema, setSelectedSchema] = React.useState(
    resourceSchemas.length === 1 ? resourceSchemas[0] : null
  );
  const [creating, setCreating] = React.useState(false);

  const [selectedResources, setSelectedResources] = React.useState(value || []);
  const schemaContext = React.useMemo(
    () =>
      selectedSchema
        ? {
            schema: selectedSchema,
            path: getResourcePath(siteId, selectedSchema),
          }
        : null,
    [siteId, selectedSchema]
  );

  const onSelect = React.useCallback(
    (selectedResource) => {
      setSelectedResources((prevSelectedResources) => {
        if (!multi)
          return selectedResource?._id === prevSelectedResources[0]?._id
            ? []
            : [makeRef(selectedResource, selectedSchema)];

        const selectedIds = Array.isArray(prevSelectedResources)
          ? prevSelectedResources.map((resource) => resource._id)
          : [];

        if (selectedIds.includes(selectedResource._id)) {
          return prevSelectedResources.filter(
            (resource) => resource._id !== selectedResource._id
          );
        } else {
          return [
            ...prevSelectedResources,
            makeRef(selectedResource, selectedSchema),
          ];
        }
      });
    },
    [selectedSchema]
  ); // les dépendances sont vides, donc onSelect ne changera pas à chaque rendu

  React.useEffect(() => {
    if (!multi) return onChange(selectedResources[0] || null);

    return onChange(selectedResources);
  }, [multi, onChange, selectedResources]);

  invariant(
    resourceSchemas.length > 0,
    'resourceSchemas cannot be empty in ResourceSelector'
  );

  if (creating) {
    return (
      <div className="resource-selector">
        <div
          className="creation-form-wrapper"
          style={{ marginBottom: '0.1rem ' }}
        >
          <Flex style={{ justifyContent: 'flex-end' }}>
            <Button
              isGhost
              onClick={() => {
                setCreating(false);
                onCreating && onCreating(false);
              }}
            >
              Annuler
            </Button>
          </Flex>

          {creating && selectedSchema && (
            <CreateResourceForm
              schema={selectedSchema}
              onCancel={() => {
                setCreating(false);
                onCreating && onCreating(false);
              }}
              onAfterCreate={(createdResource) => {
                onSelect(createdResource);
                setCreating(false);
                onCreating && onCreating(false);
              }}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="resource-selector">
      <div
        className="selected-resources"
        style={{ marginTop: 20, marginBottom: 40 }}
      >
        <Text element="h4" size={5} style={{ marginBottom: 5 }}>
          Ressources sélectionnées
        </Text>

        {selectedResources.length > 0 ? (
          <Flex verticalAlign>
            {selectedResources.map((resource) => {
              return (
                <Item
                  key={resource._id}
                  // $FlowIgnore
                  resource={resource}
                  style={{ marginRight: 10 }}
                  // $FlowIgnore
                  onRemove={() => onSelect(resource)}
                />
              );
            })}
          </Flex>
        ) : (
          <EmptyState
            title="Aucune resource sélectionnée pour l'instant"
            style={{ padding: '10px 20px' }}
            size="normal"
          />
        )}
      </div>

      {resourceSchemas.length > 1 && (
        <Flex
          style={{
            justifyContent: 'flex-end',
            marginBottom: 20,
          }}
        >
          <Select
            placeholder="Type de ressource à afficher"
            options={resourceSchemas.map((resourceSchema) => ({
              label: resourceSchema.label,
              value: resourceSchema._id,
              schema: resourceSchema,
            }))}
            value={
              selectedSchema
                ? {
                    label: selectedSchema.label,
                    id: selectedSchema._id,
                    schema: selectedSchema,
                  }
                : null
            }
            onChange={(option) => setSelectedSchema(option?.schema)}
            menuPortalTarget={document.getElementById('select-portal')}
            styles={{
              container: (provided) => ({ ...provided, width: 300 }),
            }}
          />
        </Flex>
      )}

      {creatable && (
        <div
          className="creation-form-wrapper"
          style={{ marginBottom: '0.1rem ' }}
        >
          <Flex style={{ justifyContent: 'flex-end' }}>
            <Button
              disabled={!selectedSchema}
              isGhost
              onClick={() => {
                setCreating(true);
                onCreating && onCreating(true);
              }}
            >
              <Icon name="plus" style={{ marginRight: 5 }} /> Créer un{' '}
              {selectedSchema?.label}
            </Button>
          </Flex>
        </div>
      )}

      {!creating && (
        <>
          {!!selectedSchema ? (
            <SchemaContext.Provider value={schemaContext}>
              <div className="resource-table-wrapper">
                <ResourceTable
                  openInANewTab
                  onSelect={onSelect}
                  selectedIds={map(selectedResources, '_id')}
                  canExport={false}
                  canDelete={deletable}
                  query={query}
                />
              </div>
            </SchemaContext.Provider>
          ) : (
            <EmptyState
              title="Sélectionner un type de ressource"
              icon="hand-pointer"
              style={{ padding: '80px 20px' }}
            />
          )}
        </>
      )}
    </div>
  );
}

export default React.memo(ResourceSelector);
