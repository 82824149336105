// @flow
import * as React from 'react';
import { sortBy } from 'lodash';

import type { GlobalSchema } from 'src/types/models';
import type { Section, Filter } from 'src/types/routing';

import {
  toPath,
  pluralizeResourceType,
  pluralizeResourceLabel,
} from 'src/helpers/string';
import RESOURCE_TYPES from 'src/pages/constants/resources';
import useGetResourceCollectionCount, {
  type CountByCollection,
} from 'src/hooks/resources/useGetResourceCollectionCount';
import SECTIONS from 'src/pages/constants/sections';
import { useGlobal } from 'src/hooks';
import { SectionsContext } from 'src/pages/contexts';

import { getSectionSchemas } from 'src/helpers/models/schema';
import sectionHardcodedRoutes from 'src/pages/SectionRouter/hardcodedRoutes';

export type RouteItem = {|
  pathname: string,
  label: string,
  exact?: boolean,
  count?: number,
  query?: Object,
  filters?: Array<Filter>,
  alwaysShowFilters?: boolean,
  schemaId: string,
|};

type BuildSchemaRouteItemsOptions = {|
  globalSchema: GlobalSchema,
  siteId: string,
  countByCollection: CountByCollection,
|};

export const buildSchemaRouteItems = (
  section: Section,
  { globalSchema, siteId, countByCollection }: BuildSchemaRouteItemsOptions
): Array<RouteItem> => {
  const sectionSchemas = getSectionSchemas(section.id, {
    siteId,
    globalSchema,
  });

  return sectionSchemas.map((schema) => {
    const { label, _id: type } = schema;
    const count = !schema.isSingleton
      ? countByCollection[schema._id]
      : undefined;

    return {
      pathname: `${toPath(pluralizeResourceType(type))}`,
      label: !!label
        ? pluralizeResourceLabel(type, label)
        : pluralizeResourceType(type),
      exact: true,
      filters: RESOURCE_TYPES[schema._id]?.filters || [],
      count,
      alwaysShowFilters: RESOURCE_TYPES[schema._id]?.alwaysShowFilters,
      schemaId: schema._id,
      icon: RESOURCE_TYPES[schema._id]?.icon,
    };
  });
};

export const getSectionRoutes = (
  section: Section,
  { globalSchema, siteId, countByCollection }: any
): Array<RouteItem> => {
  const hardcodedRoutes = ((sectionHardcodedRoutes[section.id] ||
    []: any): Array<RouteItem>);

  const displayedSchemaRoutes = buildSchemaRouteItems(section, {
    globalSchema,
    siteId,
    countByCollection,
  });
  const routes = sortBy(
    [...hardcodedRoutes, ...displayedSchemaRoutes],
    (route) => section.order[route.pathname]
  );

  return routes;
};

const getAllSectionsRoutes = ({
  globalSchema,
  siteId,
  countByCollection,
}: any) => {
  const routesPerSection = {};

  for (const sectionId of Object.keys(SECTIONS)) {
    const section = SECTIONS[sectionId];
    routesPerSection[sectionId] = getSectionRoutes(section, {
      globalSchema,
      siteId,
      countByCollection,
    });
  }

  return routesPerSection;
};

type Props = {| children: React.Node |};

export default function SectionsContextProvider({
  children,
}: Props): React.Node {
  const { globalSchema, siteId } = useGlobal();

  const routesPerSection = React.useRef({});
  const countByCollection = useGetResourceCollectionCount();

  if (!routesPerSection.current) return null;
  if (Object.keys(globalSchema).length === 0) return null;

  if (Object.keys(routesPerSection.current).length === 0) {
    routesPerSection.current = getAllSectionsRoutes({
      globalSchema,
      siteId,
      countByCollection: {},
    });
  }

  return (
    <SectionsContext.Provider
      value={{
        routes: routesPerSection.current,
        countByCollection,
      }}
    >
      {children}
    </SectionsContext.Provider>
  );
}
