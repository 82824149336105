export { default as CartCheckout } from './CartCheckout';
export { default as ContributionUploader } from './ContributionUploader';
export { default as NewsletterInput } from './NewsletterInput';
export { default as ProductCard } from './ProductCard';
export { default as SearchButton } from './SearchButton';
export { default as UserAccountButtons } from './UserAccountButtons';
export { default as SubscriptionsGoalProgress } from './SubscriptionsGoalProgress';
export { default as ProductCartButton } from './ProductCartButton';

export const LoginForm = {
  _id: 'LoginForm',
  frontComponent: 'LoginForm',
  label: 'Formulaire de connexion',
  icon: 'user',
  optionsSchema: [],
  forPage: true,
};

export const RegisterForm = {
  _id: 'RegisterForm',
  frontComponent: 'RegisterForm',
  label: "Formulaire d'inscription",
  icon: 'user',
  optionsSchema: [],
  forPage: true,
};

export const SessionForm = {
  _id: 'SessionForm',
  frontComponent: 'SessionForm',
  label: "Formulaire de connexion et d'inscription",
  icon: 'user',
  optionsSchema: [],
  forPage: true,
};
