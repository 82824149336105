import * as React from 'react';

import PropertyRenderer from './';
import getResourceCustomRenderer from './resourceCustomRenderers';

const isRef = (resource) =>
  Object.keys(resource).length <= 3 &&
  !resource.label &&
  resource._id &&
  resource._cls;

export default function LabelRenderer({ resource, schema, noWarning }) {
  const { labelPath } = schema;

  if (!resource) return null;
  if (isRef(resource))
    return (
      <span>
        {schema._id} - {resource._id}
      </span>
    );

  const CustomResourceRenderer = getResourceCustomRenderer(resource);

  if (CustomResourceRenderer)
    return <CustomResourceRenderer resource={resource} />;

  const labelProperties = labelPath
    ? schema.propertiesList
        .filter((propertySchema) => labelPath.includes(propertySchema.key))
        .sort((a, b) => labelPath.indexOf(a.key) - labelPath.indexOf(b.key))
    : [];

  const labelValues = labelProperties.map(
    (propertySchema) => resource[propertySchema.key]
  );

  if (!labelPath || !labelValues.some((value) => !!value)) {
    const labelSchema = schema.propertiesList.find(
      (prop) => prop.key === 'label'
    );

    if (!labelSchema || !resource.label)
      return noWarning ? null : (
        <span>
          {schema._id} - {resource._id}
        </span>
      );

    return (
      <div className="label-renderer">
        <PropertyRenderer
          value={resource[labelSchema.key]}
          propertySchema={labelSchema}
          resource={resource}
          resourceSchema={schema}
        />
      </div>
    );
  }

  if (labelPath) {
    const orderedProperties = schema.propertiesList
      .filter((propertySchema) => labelPath.includes(propertySchema.key))
      .sort((a, b) => labelPath.indexOf(a.key) - labelPath.indexOf(b.key));

    return (
      <div className="label-renderer">
        {orderedProperties.map((propertySchema, i) => {
          return (
            <React.Fragment key={propertySchema.key}>
              <PropertyRenderer
                value={resource[propertySchema.key]}
                propertySchema={propertySchema}
                resource={resource}
                resourceSchema={schema}
              />

              {i < orderedProperties.length - 1 && (
                <span className="seperator"> - </span>
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}
