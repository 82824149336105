const SearchForm = {
  _id: 'searchButton',
  frontComponent: 'SearchButton',
  label: 'Bouton recherche',
  icon: 'search',
  optionsSchema: [
    {
      key: 'label',
      label: 'Libellé bouton recherche',
      type: 'string',
      default: 'Rechercher',
      group: 'Général',
    },
    //{key: "placeholder", label: 'Texte dans le champ de recherche (si vide)', type: "string", default: "S'abonner"},
  ],
  forPage: true,
};

export default SearchForm;
