/**

 src = absolute/relative image url, ex.

 @see https://github.com/lencioni/SLIR#using

 w   Maximum width
 h   Maximum height
 c   Crop ratio
 b   Background fill color (hex)
 q   Quality
 p   Progressive

 other params are <img> tag attributes

 */

import React from 'react';

import { APIContext } from 'src/pages/contexts';

export default function DynImg({ className, src }) {
  const { getImageUrl } = React.useContext(APIContext);

  return <img alt="todo-alt" src={getImageUrl(src)} className={className} />;
}
