const OVERRIDE_CONFIGURATION_SCHEMA = [
  {
    _cls: 'ObjectPropertySchema',
    required: false,
    key: 'overrideImageConfig',
    label: 'Ecraser la configuration globale des images',
    type: 'bool',
    group: 'Configuration image',
  },
  {
    _cls: 'ObjectPropertySchema',
    label: 'Configuration image',
    key: 'imageConfiguration',
    type: 'object',
    condition: { f$: 'obj.overrideImageConfig' },
    group: 'Configuration image',
    propertiesList: [
      {
        _cls: 'ObjectPropertySchema',
        required: false,
        key: 'quality',
        label: "Qualité de l'image (0 - 100)",
        type: 'int',
        min: 1,
        max: 100,
      },
      {
        _cls: 'ObjectPropertySchema',
        required: false,
        key: 'maxSize',
        label: 'Poids maximal des images (en ko)',
        type: 'int',
        min: 1,
        default: 500,
      },
      {
        _cls: 'ObjectPropertySchema',
        required: true,
        key: 'smartDetectionEnabled',
        label:
          'Activer la reconnaissance automatique des éléments importants lors du recadrage (ex: visages, etc...)',
        type: 'bool',
        default: true,
      },
      {
        _cls: 'ObjectPropertySchema',
        required: true,
        key: 'watermarkEnabled',
        label: 'Utiliser des watermarks',
        type: 'bool',
        default: false,
      },
      {
        _cls: 'ObjectPropertySchema',
        required: false,
        key: 'watermarkConfig',
        label: 'Configuration du watermark',
        type: 'object',
        propertiesList: [
          {
            _cls: 'ObjectPropertySchema',
            required: true,
            key: 'watermarkUrl',
            label:
              'Lien vers le watermark (DEV : à remplacer directement par image)',
            type: 'string',
          },
          {
            _cls: 'ObjectPropertySchema',
            required: false,
            key: 'x',
            label: 'Position horizontale du watermark',
            type: 'string',
          },
          {
            _cls: 'ObjectPropertySchema',
            required: false,
            key: 'y',
            label: 'Position verticale du watermark',
            type: 'string',
          },
          {
            _cls: 'ObjectPropertySchema',
            required: false,
            key: 'alpha',
            label: 'Transparence du watermark',
            type: 'int',
            min: 0,
            max: 100,
          },
          {
            _cls: 'ObjectPropertySchema',
            required: false,
            key: 'w_ratio',
            label: 'Pourcentage maximale de la largeur prise par le watermark',
            type: 'int',
            min: 0,
            max: 100,
          },
          {
            _cls: 'ObjectPropertySchema',
            required: false,
            key: 'h_ratio',
            label: 'Pourcentage maximale de la longueur prise par le watermark',
            type: 'int',
            min: 0,
            max: 100,
          },
        ],
        condition: {
          f$: 'obj.watermarkEnabled',
        },
      },
    ],
  },
];

const extendComponent = (component, options = {}) => {
  const { propertyListKey = 'optionsSchema' } = options;

  if (!component.displayImages) return component;

  return {
    ...component,
    [propertyListKey]: [
      ...(component[propertyListKey] || []),
      ...OVERRIDE_CONFIGURATION_SCHEMA,
    ],
  };
};

export default extendComponent;
