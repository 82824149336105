const EntityPrice = {
  _id: 'entityPrice',
  frontComponent: 'EntityPrice',
  label: 'Prix',
  icon: 'euro-sign',
  hasHTMLMarkup: true,
  optionsSchema: [
    {
      key: 'label',
      label: 'Libellé avant le prix',
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'currencyType',
      label: 'Unité',
      type: 'enum',
      default: 'symbol',
      values: [
        { label: '€', value: 'symbol' },
        { label: 'EUR', value: 'code' },
        { label: 'euro', value: 'name' },
      ],
      group: 'Affichage',
    },
    {
      key: 'forceDigits',
      label: 'Toujours afficher les centimes (même si zéro)',
      type: 'bool',
      group: 'Affichage',
    },
  ],
  forEntity: true,
};

export default EntityPrice;
