// @flow
import * as React from 'react';
import classnames from 'classnames';
import { sortBy, findIndex } from 'lodash';

import type { ResourcePropertySchema, Resource } from 'src/types';
import type { OnPropertyChange } from 'src/types/components/resource/propertyEditor/formEditor';

import { Columns, Column } from 'src/components';
import PropertyEditor from './PropertyEditor';

type Props = {|
  label: string,
  propertySchemas: Array<ResourcePropertySchema>,
  resource: Resource,
  onPropertyChange: OnPropertyChange,
  disabled?: boolean,
  hasManyGroups: boolean,

  // propertyOrder can provide an array of string. The property keys listed in this array
  // will be followed by the group for the order of apparation of the properties
  // Property keys not listed will follow natural order
  propertyOrder: Array<string>,
|};

export default function PropertyGroup({
  label,
  propertySchemas,
  resource,
  onPropertyChange,
  disabled,
  hasManyGroups,
  propertyOrder,
}: Props): React.Node {
  const hasSidebar = propertySchemas.some(
    (propertySchema) => propertySchema.sideBar
  );

  if (hasSidebar) {
    return (
      <div
        className={classnames('property-group', {
          'has-many-groups': hasManyGroups,
        })}
      >
        <Columns>
          <Column size="two-thirds">
            <PropertyList
              propertySchemas={propertySchemas.filter(
                (propertySchema) => !propertySchema.sideBar
              )}
              resource={resource}
              onPropertyChange={onPropertyChange}
              disabled={disabled}
              propertyOrder={propertyOrder}
            />
          </Column>

          <Column size="one-third">
            <PropertyList
              propertySchemas={propertySchemas.filter(
                (propertySchema) => propertySchema.sideBar
              )}
              resource={resource}
              onPropertyChange={onPropertyChange}
              disabled={disabled}
              propertyOrder={propertyOrder}
            />
          </Column>
        </Columns>
      </div>
    );
  }

  return (
    <div
      className={classnames('property-group', {
        'has-many-groups': hasManyGroups,
      })}
    >
      <PropertyList
        propertySchemas={propertySchemas}
        resource={resource}
        onPropertyChange={onPropertyChange}
        disabled={disabled}
        propertyOrder={propertyOrder}
      />
    </div>
  );
}

type PropertyListProps = {|
  propertySchemas: Array<ResourcePropertySchema>,
  resource: Resource,
  onPropertyChange: OnPropertyChange,
  disabled?: boolean,
  propertyOrder: Array<string>,
|};

function PropertyList({
  propertySchemas,
  resource,
  disabled,
  propertyOrder = [],
  onPropertyChange,
}: PropertyListProps) {
  const orderedPropertySchemas =
    propertyOrder.length > 0
      ? sortBy(propertySchemas, (property) => {
          const orderedIndex = propertyOrder.indexOf(property.key);

          // The property is listed in the explicitely ordered properties
          if (orderedIndex >= 0) return orderedIndex;

          return (
            findIndex(propertySchemas, (p) => p.key === property.key) +
            propertyOrder.length
          );
        })
      : propertySchemas;

  return orderedPropertySchemas.map((propertySchema) => (
    <PropertyEditor
      resource={resource}
      propertySchema={propertySchema}
      onPropertyChange={onPropertyChange}
      disabled={disabled}
      key={propertySchema.key}
    />
  ));
}
