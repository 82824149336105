import * as React from 'react';

import { Icon } from 'src/components';

export default function StringRenderer({ value, propertySchema }) {
  if (propertySchema.isUrl) {
    return (
      <a
        href={value}
        target="_blank"
        rel="noopener noreferrer"
        className="string-renderer"
      >
        {value}
        <Icon name="external-link" type="regular" style={{ marginLeft: 5 }} />
      </a>
    );
  }

  return <span className="string-renderer">{value || null}</span>;
}
