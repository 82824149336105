// @flow

import React from 'react';

const defaultContextValue = {
  simple: [],
  complex: [],
};

export const ComponentsContext = React.createContext(defaultContextValue);
