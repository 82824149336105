// @flow
import * as React from 'react';
import classnames from 'classnames';

import { getFieldAlerts } from './helpers';

import ComponentTitle from './Title';
import Preview from './Preview';
import { Tag, Icon } from 'src/components';
import ComponentAlerts from './ComponentAlerts';

type Props = {|
  component: any,
|};

export default function LayoutComponentThumbnail({
  component,
}: Props): React.Node {
  const { optionsSchema = [] } = component;
  const options = component.options || {};
  const alerts = getFieldAlerts(options, optionsSchema);

  return (
    <div className={classnames('layout-component-summary')}>
      <ConditionTag options={options} />

      <ComponentTitle component={component} />

      <Preview component={component} alerts={alerts} />

      <ComponentAlerts alerts={alerts} optionsSchema={optionsSchema} />
    </div>
  );
}

function ConditionTag({ options }) {
  const { displayConditions = [], hideConditions = [] } = options;

  if (
    (!Array.isArray(displayConditions) || displayConditions.length === 0) &&
    (!Array.isArray(hideConditions) || hideConditions.length === 0)
  )
    return null;

  return (
    <Tag additionalClassName="display-conditions" color="warning">
      Conditions =>{' '}
      {displayConditions.length > 0 && (
        <span style={{ marginLeft: 5 }}>
          {displayConditions.length}
          <Icon name="eye" style={{ marginLeft: 1 }} />
        </span>
      )}
      {displayConditions.length > 0 && hideConditions.length > 0 && (
        <span style={{ margin: '0 5px' }}> et </span>
      )}
      {hideConditions.length > 0 && (
        <span>
          {hideConditions.length}
          <Icon name="eye-slash" style={{ marginLeft: 1 }} />
        </span>
      )}
    </Tag>
  );
}
