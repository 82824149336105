import Reflux from 'reflux';
import API from 'src/helpers/api/API';
import * as Sentry from '@sentry/react';
import { InstanceActions } from './InstanceStore';
import { SchemaActions } from './SchemaStore';
import { getSiteIdFromPath } from 'src/helpers/url';

export var UserActions = Reflux.createActions(['setUser', 'logout', 'login']);

// TODO use promises

export default class UserStore extends Reflux.Store {
  constructor(initialState = {}) {
    super();

    this.state = {
      user: null,
      userLoaded: false,
      ...initialState,
    };

    this.listenables = UserActions;

    API.get('session').then((user) => {
      UserActions.setUser(user);
      this.setState({ userLoaded: true });
      const instanceName = getSiteIdFromPath();
      InstanceActions.loadSiteList(user, instanceName);
    });
  }

  setUser(user) {
    console.debug('Set user', user);
    if (user) {
      Sentry.setUser({ email: user.email, id: user._id });
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
    this.setState({ user, userLoaded: true });
  }

  logout() {
    API.post('logout', null).then(() => {
      UserActions.setUser(null);
    });
  }

  login(loginInfo, instanceName, callback) {
    API.post('login', loginInfo)
      .then((user) => {
        UserActions.setUser(user);
        InstanceActions.loadSiteList(user, instanceName);
        SchemaActions.load(instanceName);
        callback && callback(null);
      })
      .catch(callback);
  }
}

UserStore.id = 'UserStore'; // https://github.com/reflux/refluxjs/tree/master/docs/stores#global-state
