const EntityEffects = {
  _id: 'EntityEffects',
  label: 'Effets',
  icon: 'book-reader',
  frontComponent: 'EntityEffects',
  forEntity: true,
  siteIds: ['factuel'],
  optionsSchema: [
    {
      key: 'mode',
      label: "Mode d'affichage",
      type: 'enum',
      values: [
        { value: 'thumbnail', label: 'Thumbnail' },
        { value: 'full', label: 'Complet' },
      ],
      required: true,
      default: 'list',
      group: 'Affichage',
    },
    {
      key: 'colCount',
      label: 'nombre de colonnes',
      type: 'int',
      required: true,
      group: 'Affichage',
    },
    {
      key: 'maxCount',
      label: "Nombre max d'effets affichés",
      type: 'int',
      required: true,
      group: 'Affichage',
    },
    {
      key: 'displayImages',
      label: 'Afficher les images',
      type: 'bool',
      required: true,
      default: true,
      group: 'Affichage',
    },
    {
      key: 'effect_layoutStacked',
      label: "Stacker le layout d'un effet",
      type: 'bool',
      required: true,
      default: false,
      group: 'Affichage',
    },
    {
      key: 'effect_posterRatioWH',
      label: "Ratio du poster de l'effet",
      type: 'enum',
      required: true,
      values: [
        { label: '16/9', value: 'ratio-16-9' },
        { label: '4/3', value: 'ratio-4-3' },
        { label: 'carré', value: 'ratio-1-1' },
        { label: 'portrait', value: 'ratio-3-4' },
        { label: 'cinemascope', value: 'ratio-2_35-1' },
        { label: 'bandeau', value: 'ratio-5-1' },
      ],
      preview: true,
      secondaryPreview: true,
      group: 'Affichage',
    },
  ],
};

export default EntityEffects;
