const EntityAuthors = {
  _id: 'contributors', // TODO rename this
  label: 'Auteurs',
  frontComponent: 'EntityAuthors',
  icon: 'users',
  hasHTMLMarkup: true,
  optionsSchema: [
    {
      key: 'prefix',
      label: 'Libellé après les auteurs',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Affichage',
    },
    {
      key: 'suffix',
      label: 'Libellé après les auteurs',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Affichage',
    },
    {
      key: 'linkToEntity',
      label: "Créer un lien vers la page de l'auteur",
      type: 'bool',
      default: true,
      group: 'Affichage',
    },
    {
      key: 'displayOccupation',
      label: "Afficher l'occupation",
      type: 'bool',
      default: false,
      group: 'Affichage',
    },
  ],
  forEntity: true,
};

export default EntityAuthors;
