// @flow
import { isPlainObject, isEqual, omitBy } from 'lodash';

type NameFn = (props: any) => string;

export const debugReactMemo =
  (nameFn: NameFn): function =>
  (prevProps: Object, nextProps: Object): boolean => {
    let memoize = true;

    const debugName = nameFn(nextProps);
    const changedKeys = [];

    for (let key in prevProps) {
      if (prevProps[key] !== nextProps[key]) {
        memoize = false;
        changedKeys.push(key);
      }
    }

    if (!memoize) {
      console.log(`React.memo debug -> ${debugName}`);

      for (const key of changedKeys) {
        console.log(`property ${key} has changed`);

        if (typeof prevProps[key] !== 'function')
          console.log(prevProps[key], nextProps[key]);

        if (isPlainObject(prevProps[key])) {
          const diff = getObjectDiff(prevProps[key], nextProps[key]);
          if (diff.length > 0) console.log('Object diff', diff);
        }
      }
    }

    /*
    When using this function you always need to return
    a Boolean. For now we'll say the props are NOT equal 
    which means the component should rerender.
  */
    return memoize;
  };

/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
export function getObjectDiff(obj1: Object, obj2: Object): Object {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }

    return result;
  }, Object.keys(obj2));

  return diff;
}

export function getSimpleObjectDiff(obj1: Object, obj2: Object): Object {
  return omitBy(obj2, function (v, k) {
    return isEqual(obj1[k], v);
  });
}
