// @flow
import * as React from 'react';

import type { ResourceType } from 'src/types/models';

import API from 'src/helpers/api/API';
import useGlobal from '../useGlobal';

export type CountByCollection = { [type: ResourceType]: number };

export default function useGetResourceCollectionCount(): CountByCollection {
  const { siteId } = useGlobal();
  const [countByCollection, setCountByCollection] =
    React.useState<CountByCollection>({});

  React.useEffect(() => {
    const loadCollections = async () => {
      const count: CountByCollection = await API.get(`${siteId}/collections`, {
        withCount: true,
      });
      setCountByCollection(count);
    };

    loadCollections();
  }, [siteId, setCountByCollection]);

  return countByCollection;
}
