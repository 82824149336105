// @flow

import * as React from 'react';
import striptags from 'striptags';

import type { ResourcePropertySchema, RichTextValue } from 'src/types';
import type { OnPropertyValueChange } from 'src/types/components/resource/propertyEditor';

import { FieldContext } from './contexts';
import StringCounter from './components/StringCounter';
import { Draft } from 'src/components';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: RichTextValue,
  disabled?: boolean,
|};

export default function RichTextEditor({
  value,
  onChange,
  disabled,
  propertySchema,
}: Props): React.Node {
  const {
    max: maxChar,
    disallowedTags = [],
    charactersCounter,
    expectedCharactersCount,
    expectedWordsCount,
  } = propertySchema;
  const { onFocus: onFieldFocus, onBlur: onFieldBlur } =
    React.useContext(FieldContext);

  return (
    <>
      <Draft
        value={value}
        onChange={onChange}
        disabled={disabled}
        disabledTags={disallowedTags.map((tag) => tag.toUpperCase())}
        maxChar={maxChar}
        expectedWordsCount={expectedWordsCount}
        charactersCounter={charactersCounter}
        expectedCharactersCount={expectedCharactersCount}
        onFocus={onFieldFocus}
        onBlur={onFieldBlur}
      />
      <StringCounter
        propertySchema={propertySchema}
        value={striptags(value?.html || '')}
      />
    </>
  );
}
