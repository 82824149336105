import * as React from 'react';

import { useImageSize, useGlobal } from 'src/hooks';
import { APIContext } from 'src/pages/contexts';
import { ImageEditorContext } from './ImageEditor';

import {
  Button,
  Modal,
  Flex,
  ImageFileUpload,
  Icon,
  InformationBubble,
  Field,
  Label,
  Input,
  Control,
  Text,
} from 'src/components';

export default function RawImageEditor({ value, onChange, disabled }) {
  const { user } = useGlobal();
  const { getImageUrl } = React.useContext(APIContext);
  const {
    acceptedRatio,
    maxSize = 1000,
    outputFormats,
  } = React.useContext(ImageEditorContext);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [typedValue, setTypedValue] = React.useState(value || '');
  const imageInfo = useImageSize(value);

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        modal={false}
        title=""
        size="full"
        modalClassName="raw-image-editor-modal"
        hideButtons
      >
        <ImageFileUpload
          value={value}
          onChange={(newValue) => {
            onChange(newValue);
            setModalOpen(false);
            setTypedValue(newValue);
          }}
          onCancel={() => setModalOpen(false)}
          acceptedRatio={acceptedRatio}
          maxSize={maxSize}
          outputFormats={outputFormats}
        />
      </Modal>

      {value && (
        <img src={getImageUrl(value, { originalSize: true })} alt="Le titre" />
      )}

      {user?.isDeveloper && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onChange(typedValue);
          }}
          style={{
            marginTop: 10,
            marginBottom: 10,
            padding: 15,
            background: '#edf6fc',
            borderRadius: 4,
          }}
        >
          <Field>
            <Label tooltip="Disponible pour les devs uniquement">
              Entrer un path d'image manuellement si nécessaire
            </Label>

            <Control style={{ display: 'flex' }}>
              <Input
                value={typedValue}
                onChange={setTypedValue}
                placeholder="Entrer un path d'image manuellement"
                style={{ marginRight: 10 }}
              />
              <Button type="submit">Appliquer</Button>
            </Control>
          </Field>
        </form>
      )}

      {imageInfo && (
        <InformationBubble style={{ marginBottom: 10 }}>
          <p>Information sur votre image :</p>
          <ul className="bullet-list">
            <li>
              Dimensions :{' '}
              <strong>
                {imageInfo.width} x {imageInfo.height}
              </strong>
            </li>
            <li>
              Ratio : <strong>{imageInfo.ratio}</strong>{' '}
              {acceptedRatio && imageInfo.ratio !== acceptedRatio && (
                <>
                  <br />
                  <Text color="danger">
                    Le ratio ne correspond pas au ratio attendu :{' '}
                    {acceptedRatio}
                  </Text>
                </>
              )}
            </li>
            <li>
              Poids : <strong>{imageInfo.fileSize / 1000}Ko</strong>
              {maxSize && imageInfo.fileSize / 1000 > maxSize && (
                <>
                  <br />
                  <Text color="danger">
                    La taille maximale est dépassée : {maxSize}Ko
                  </Text>
                </>
              )}
            </li>
          </ul>
        </InformationBubble>
      )}

      {!disabled && (
        <Flex style={{ justifyContent: 'flex-end' }}>
          {!!value && (
            <Button
              onClick={() => {
                onChange(null);
                setTypedValue('');
              }}
              style={{ marginRight: 10 }}
              color="danger"
            >
              <Icon name="times" />
            </Button>
          )}
          <Button onClick={() => setModalOpen(true)}>
            <Icon name={!!value ? 'edit' : 'plus'} />
          </Button>
        </Flex>
      )}
    </>
  );
}
