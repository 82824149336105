const SocialButtons = {
  _id: 'socialButtons',
  frontComponent: 'SocialButtons',
  label: 'Boutons sociaux',
  icon: 'thumbs-up',
  optionsSchema: [
    {
      key: 'twitterUrl',
      label: 'URL du Twitter',
      type: 'string',
      group: 'Général',
    },
    {
      key: 'facebookUrl',
      label: 'URL de la page Facebook',
      type: 'string',
      group: 'Général',
    },
    {
      key: 'youtubeUrl',
      label: 'URL de la chaîne Youtube',
      type: 'string',
      group: 'Général',
    },
    {
      key: 'instagramUrl',
      label: 'URL Instagram',
      type: 'string',
      group: 'Général',
    },
    {
      key: 'linkedinUrl',
      label: 'URL LinkedIn',
      type: 'string',
      group: 'Général',
    },
    {
      key: 'tiktokUrl',
      label: 'URL Tiktok',
      type: 'string',
      group: 'Général',
    },
    {
      key: 'displayRSS',
      label: 'Afficher le flux RSS (doit être configuré)',
      type: 'bool',
      group: 'Général',
    },
  ],
  forPage: true,
};

export default SocialButtons;
