import React from 'react';
import _ from 'lodash';

import ImageRenderer from '../ImageRenderer';
import { Flex, Notification, ReactNode } from 'src/components';
import ResourceReferencesRenderer from './ResourceReferencesRenderer';

export default function ListRenderer({
  value: list,
  propertySchema,
  resource,
  resourceSchema,
}) {
  const { itemSchema, itemType } = propertySchema;

  if (!list || list.length === 0)
    return <span className="has-text-grey-light">vide</span>;

  if (itemSchema && ['object_id', 'object_ref'].includes(itemSchema.type)) {
    return (
      <div className="list-renderer">
        <ResourceReferencesRenderer
          value={list}
          propertySchema={propertySchema}
          resource={resource}
          resourceSchema={resourceSchema}
        />
      </div>
    );
  }

  if (propertySchema.itemSchema?.type === 'image') {
    if (list.length === 0)
      return (
        <Notification color="warning" light>
          Pas de pré-visualisation
        </Notification>
      );

    return (
      <Flex style={{ gap: '1rem', minHeight: 200, minWidth: 300 }}>
        {_.filter(
          _.map(list, (image) => (
            <ImageRenderer
              value={image}
              propertySchema={propertySchema.itemSchema}
              resource={resource}
              resourceSchema={resourceSchema}
              key={image.file}
            />
          ))
        )}
      </Flex>
    );
  }

  if (Array.isArray(itemType) && itemType[0] === 'react-node') {
    return (
      <p>
        {list.map((item) => (
          <ReactNode key={item._id} node={item} />
        ))}
      </p>
    );
  }

  return (
    <div className="list-renderer">
      {_.filter(
        _.map(
          list,
          (item) => item && (item.label || item.text || item._id || '')
        )
      ).join(', ')}
    </div>
  );
}
