import * as React from 'react';

import { Tag } from 'src/components';

export default function ListRenderer({
  value,
  propertySchema,
  resource,
  resourceSchema,
}) {
  if (!value) return null;

  const list = Array.isArray(value) ? value : [value];

  return list
    .filter((item) => !!item)
    .map((item) => (
      <Tag
        key={JSON.stringify(item)}
        style={{ marginBottom: 5, marginRight: 5 }}
      >
        {typeof item === 'object' ? JSON.stringify(item) : item}
      </Tag>
    ));
}
