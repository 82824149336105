// @flow
import * as React from 'react';

import type { ResourcePropertySchema } from 'src/types';
import type { OnPropertyValueChange } from 'src/types/components/resource/propertyEditor';

import { FieldContext } from './contexts';

import { IntegerInput } from 'src/components';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: number,
  disabled?: boolean,
|};

export default function IntegerEditor({
  propertySchema,
  onChange,
  disabled,
  value,
}: Props): React.Node {
  const { min, max } = propertySchema;
  const { onFocus: onFieldFocus, onBlur: onFieldBlur } =
    React.useContext(FieldContext);

  return (
    <IntegerInput
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      disabled={disabled}
      onFocus={onFieldFocus}
      onBlur={onFieldBlur}
    />
  );
}
