import * as React from 'react';

import {
  generateEntitiesConfig,
  generateUserConfig,
  USER_SESSION_FIELDS_CONFIG,
  DEVICE_FIELDS_CONFIG,
  CONTEXT_FIELDS_CONFIG,
} from './helpers/config';

import { useGlobal, useResourceContext } from 'src/hooks';

import RawConditionBuilder from './RawConditionBuilder';
import EnumEditor from '../EnumEditor';
import StringEditor from '../StringEditor';
import PropertyField from '../PropertyField';

export default function ConditionEditor({
  value: valueProp,
  onChange: onChangeProp,
  propertySchema,
  disabled,
}) {
  const { globalSchema, propertySchemas } = useGlobal();
  const { displayCondition: DisplayConditionPropertySchema } = propertySchemas;
  const { resource } = useResourceContext();
  const objectType = resource.objectType
    ? Array.isArray(resource.objectType)
      ? resource.objectType
      : [resource.objectType]
    : [];
  const { label, type, conditions = {} } = valueProp;
  const getPropertySchema = React.useCallback(
    (property) =>
      DisplayConditionPropertySchema.propertiesList.find(
        (prop) => prop.key === property
      ),
    []
  );

  const handleChange = React.useCallback(
    (key, value) => {
      onChangeProp({
        ...valueProp,
        [key]: value,
      });
    },
    [valueProp, onChangeProp]
  );

  let config = {};

  switch (type) {
    case 'entity': {
      if (!globalSchema || !objectType) break;

      const schemas = objectType
        .map((type) => globalSchema[type])
        .filter((schema) => !!schema);

      if (schemas.length === 0) break;

      config = generateEntitiesConfig(schemas, globalSchema);
      break;
    }
    case 'user_session':
      config = USER_SESSION_FIELDS_CONFIG;
      break;
    case 'user':
      config = generateUserConfig(globalSchema.User, globalSchema);
      break;
    case 'device':
      config = DEVICE_FIELDS_CONFIG;
      break;
    case 'context':
      config = CONTEXT_FIELDS_CONFIG;
      break;

    default:
      break;
  }

  return (
    <div className="condition-editor">
      <PropertyField propertySchema={getPropertySchema('type')} value={type}>
        <EnumEditor
          value={type}
          propertySchema={getPropertySchema('type')}
          onChange={(newType) => handleChange('type', newType)}
          disabled={type || disabled || (conditions && conditions.length > 0)}
        />
      </PropertyField>

      <PropertyField propertySchema={getPropertySchema('label')} value={label}>
        <StringEditor
          value={label}
          propertySchema={DisplayConditionPropertySchema.propertiesList.find(
            (prop) => prop.key === 'label'
          )}
          onChange={(newLabel) => handleChange('label', newLabel)}
        />
      </PropertyField>

      {type && (
        <RawConditionBuilder
          configSchema={config}
          value={conditions}
          onChange={(newConditions) =>
            handleChange('conditions', newConditions)
          }
        />
      )}
    </div>
  );
}
