import * as React from 'react';

import { useGlobal } from 'src/hooks';

import { Text, Tag, Icon } from 'src/components';

export default function ComponentAlerts({ alerts, optionsSchema }) {
  const { user } = useGlobal();
  const [isToggled, toggle] = React.useState(false);

  if (Object.keys(alerts).length === 0) return null;

  return (
    <div className="component-alerts">
      <Text color="danger" element="p">
        <Icon name="triangle-exclamation" />
        <span>
          {Object.keys(alerts).length} erreurs dans la configuration du
          composant
        </span>
        <a
          onClick={() => toggle(!isToggled)}
          href="#"
          style={{ marginLeft: 10 }}
        >
          {isToggled ? 'Cacher' : 'Afficher'}
        </a>
      </Text>

      {isToggled && (
        <ul style={{ marginTop: 10 }} className="alerts-list">
          {Object.keys(alerts).map((propertyKey) => {
            const propertyAlerts = alerts[propertyKey];
            const schema = optionsSchema.find(
              (schema) => schema.key === propertyKey
            );

            return (
              <li className="field-alerts">
                <p>
                  <strong>{schema.label}</strong>
                  {user.isDeveloper && propertyKey && (
                    <Tag color="info" light style={{ marginLeft: 5 }}>
                      {propertyKey}
                    </Tag>
                  )}
                </p>
                <ul className="bullet-list">
                  {propertyAlerts.map((alertMessage) => (
                    <li>
                      <Text element="p">{alertMessage}</Text>
                    </li>
                  ))}
                </ul>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
