// @flow

// RTE using draftjs: https://draftjs.org/ and draft.js editor plugins https://github.com/draft-js-plugins/draft-js-plugins

import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { stateToHTML } from 'draft-js-export-html';
import request from 'superagent';
import {
  MegadraftEditor,
  editorStateFromRaw, // basically just does EditorState.createWithContent / .createEmpty
  editorStateToJSON, // basically just stringify editorState.getCurrentContent()
  insertDataBlock,
  DraftJS,
} from 'megadraft';

import { withContext } from 'src/utils/HOC';
import { APIContext } from 'src/pages/contexts';

import entityInputs from './inputs';
import ImagePlugin from './plugins/image/plugin';
import {
  actions,
  draftToHtmlOptions,
  entityDecorators,
} from 'src/utils/MegadraftUtils';
import { isGarbageFromDraftEditor } from 'src/helpers/misc';

import 'megadraft/dist/css/megadraft.css';

import ComponentBase from 'src/utils/ComponentBase';

import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import Textarea from 'src/components/form/Textarea';
import Modal from 'src/components/Modal';

class Draft extends ComponentBase {
  constructor(props) {
    super(props);

    this.state = {
      hasFocus: false,
      editorState: editorStateFromRaw(null, entityDecorators),
      pasteModalOpen: false,
      pastedHtml: '',
    };
  }

  static patchOldContentState(contentState) {
    const { blocks, entityMap } = contentState;
    let patchedCS = {
      ...contentState,
      blocks: [],
      entityMap: { ...contentState.entityMap },
    };

    _.forEach(blocks, (block) => {
      if (block && block.type === 'atomic') {
        // psion?
        _.forEach(block.entityRanges, (range) => {
          patchedCS.blocks.push({
            key: block.key + range.key,
            text: '',
            type: 'atomic',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {
              src: entityMap[range.key].data['data-url'],
              type: 'image',
              display: '50',
              align: 'center',
            },
          });
          delete patchedCS.entityMap[range.key];
        });
        //block = {...block, entityRanges: []};
      } else {
        patchedCS.blocks.push(block);
      }
    });

    return patchedCS;
  }

  didMount() {
    this.observeProp('value', (value, oldValue) => {
      if (value && _.isObject(value.contentState)) {
        // This is an "old" value from previous editor version.
        // TODO anis: import this
        try {
          console.debug(
            'value update - external change -> import old draft-js value',
            value.contentState
          );
          let patchedCS = Draft.patchOldContentState(value.contentState);
          console.debug('patched content state to import:', patchedCS);
          this.setState({
            editorState: editorStateFromRaw(patchedCS, entityDecorators),
          });
        } catch (e) {
          console.warn('import did not work', e);
        }
      } else if (value && value.editorStateJson) {
        // TODO optimise this test using a fingerprint / unique transaction ID...?
        if (
          value.editorStateJson !== editorStateToJSON(this.state.editorState)
        ) {
          // The value is different from the current editor state: value has been modified externally (load from DB?)
          // -> Set new editor state
          console.debug('value update - external change -> update editorState');
          this.setState({
            editorState: editorStateFromRaw(
              JSON.parse(value.editorStateJson),
              entityDecorators
            ),
          });
        } else {
          // The value is the same as the editor state: probably following a normal controlled component value update
          // -> Don't update editor state (we would lose caret position, toolbar status etc.)
          console.debug(
            'value update after onChange (normal controlled component flow) -> ignore'
          );
        }
      } else if (value && _.isString(value)) {
        // import old plain HTML value
        console.warn('Importing old HTML value into rich text', value);

        const blocksFromHTML = DraftJS.convertFromHTML(value);
        const oldContentState = DraftJS.ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        let oldContentStateRaw = DraftJS.convertToRaw(oldContentState);

        this.setState({
          editorState: editorStateFromRaw(oldContentStateRaw, entityDecorators),
        });
      } else {
        console.debug(
          'value update - external change with empty value -> reset editorState'
        );
        this.setState({
          editorState: editorStateFromRaw(null, entityDecorators),
        });
      }
    });
  }

  //----------------------- from Megadraft page
  insertImage = (url) => {
    var src = URL.createObjectURL(url);
    const data = {
      src: src,
      type: 'image',
    };
    return insertDataBlock(this.state.editorState, data);
  };

  //executes when an image is pasted
  handlePastedFiles = (files) => {
    const { apiImageUploadUrl } = this.props;
    const req = request.post(apiImageUploadUrl).withCredentials();

    files.forEach((file) => {
      req.attach('file', file);
    });
    // TODO handle multiple upload
    req.end((error, res) => {
      if (error) {
        console.error('upload error', error);
      } else {
        const data = { src: res.body.result, type: 'image' };
        this.onChangeEditorContent(
          insertDataBlock(this.state.editorState, data)
        );
      }
    });
  };

  shouldDisplayToolbar = (editorProps, toolbarState) => {
    // Workaround to fix focus issue between modal window and editor
    return (
      (toolbarState.editingEntity !== 'INTERNAL_PAGE_LINK' &&
        !this.state.editorState.getSelection().isCollapsed()) ||
      (toolbarState.show === true &&
        toolbarState.editingEntity === 'INTERNAL_PAGE_LINK')
    );
  };

  onPasteHTML = (newHtml) => {
    const { onChange } = this.props;

    const currentHtml = stateToHTML(
      this.state.editorState.getCurrentContent(),
      draftToHtmlOptions
    );

    if (!newHtml || newHtml === currentHtml) return;

    const blocksFromHTML = DraftJS.convertFromHTML(newHtml);
    const newContentState = DraftJS.ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    const newContentStateRaw = DraftJS.convertToRaw(newContentState);
    const newEditorState = editorStateFromRaw(
      newContentStateRaw,
      entityDecorators
    );
    const editorJsonStr = editorStateToJSON(newEditorState);

    this.setState({
      editorState: newEditorState,
    });

    onChange({
      editorStateJson: editorJsonStr,
      html: newHtml,
    });
  };

  // transforms contentState to HTML
  onChangeEditorContent = (newEditorState) => {
    const { onChange } = this.props;
    console.debug('---- on change editor content OR selection ----');

    const currentEditorState = this.state.editorState;

    this.setState({ editorState: newEditorState });

    const newEditorJsonStr = editorStateToJSON(newEditorState);

    // https://stackoverflow.com/questions/40911570/draftjs-triggers-content-change-on-focus

    const newCurrentContent = newEditorState.getCurrentContent();
    if (
      currentEditorState &&
      currentEditorState.getCurrentContent() === newCurrentContent &&
      editorStateToJSON(currentEditorState) === newEditorJsonStr
    ) {
      console.info(
        'onChangeEditorContent has been called, but there is no actual change'
      );
      return;
    }

    //transforms editor state to HTML
    let html;
    try {
      html = stateToHTML(newCurrentContent, draftToHtmlOptions);
    } catch (err) {
      html = stateToHTML(newCurrentContent);
    }

    html = !isGarbageFromDraftEditor(html) ? html : '';

    //console.debug("--------------------------------------------------------");
    console.debug(JSON.parse(newEditorJsonStr));
    console.debug('HTML: ', html);

    /**
     * This regular expression finds occurences of title tags wrapped in p tags
     */
    const regex = /<p><\s*h[0-9]>(.*?)<\s*\/\s*h[0-9]><\/p>/g;
    if (html) {
      const matchedString = html.match(regex);
      if (matchedString) {
        const newString = matchedString[0]
          .replace('<p>', '')
          .replace('</p>', '');
        html = html.replace(regex, newString);
      }
      this.setState({
        editorStateHtml: html,
      });
    }

    // TODO benchmark: is it better to store JSON or object?
    onChange &&
      onChange(
        {
          editorStateJson: newEditorJsonStr,
          html,
        },
        false
      );
  };

  render() {
    const {
      placeholder,
      disabled,
      disabledTags = [],
      onFocus,
      onBlur,
      apiImageUploadUrl,
    } = this.props;
    const { pastedHtml, pasteModalOpen } = this.state;

    const allowedActions = actions.filter(
      (action) => !disabledTags.includes(action.label)
    );

    return (
      <div
        onDrop={(e) => {
          e.preventDefault();
          e.persist();
          if (e.dataTransfer.files[0]) {
            const req = request.post(apiImageUploadUrl).withCredentials();
            req.attach('file', e.dataTransfer.files[0]);
            // TODO handle multiple upload
            req.end((error, res) => {
              if (error) {
                console.error('upload error', error);
              } else {
                const data = { src: res.body.result, type: 'image' };
                this.onChangeEditorContent(
                  insertDataBlock(this.state.editorState, data)
                );
              }
            });
          }
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
        }}
        className={classNames(
          'MegadraftContainer new',
          disabled ? 'disabled' : ''
        )}
      >
        <MegadraftEditor
          // http://megadraft.io/#/docs/customization
          handlePastedFiles={this.handlePastedFiles}
          shouldDisplayToolbarFn={this.shouldDisplayToolbar}
          editorState={this.state.editorState}
          onChange={this.onChangeEditorContent}
          movableBlocks={true}
          actions={allowedActions}
          plugins={[ImagePlugin /*, TextPlugin*/]}
          entityInputs={entityInputs}
          placeholder={placeholder || '(vide)'}
          resetStyleNewLine={true} // Editor will reset styles when a new paragraph is created.
          readOnly={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <Button
          additionalClassName="copy-paste-button"
          size="small"
          onClick={() => this.setState({ pasteModalOpen: true })}
        >
          <Icon name="paste" />
        </Button>
        <Modal
          modal
          open={pasteModalOpen}
          onClose={(actionType) => {
            if (actionType === 'cancel' || !pastedHtml) {
              this.setState({ pasteModalOpen: false, pastedHtml: '' });
              return;
            }

            this.onPasteHTML(pastedHtml);
            this.setState({ pasteModalOpen: false, pastedHtml: '' });
          }}
          title="Copier coller le HTML directement ci-dessous"
        >
          <Textarea
            value={pastedHtml}
            onChange={(newPastedHtml) =>
              this.setState({ pastedHtml: newPastedHtml })
            }
          />
        </Modal>
      </div>
    );
  }
}

export default (withContext(APIContext, Draft): React.ComponentType<OwnProps>);
