import * as React from 'react';

export default function DateRenderer({
  value: date,
  propertySchema,
  resource,
  resourceSchema,
}) {
  if (!date) return null;

  return <span title={date}>{new Date(date).toLocaleString('fr-FR')}</span>;
}
