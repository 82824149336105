const CartCheckout = {
  _id: 'cartCheckout',
  frontComponent: 'CartCheckout',
  label: 'Paiement du panier',
  icon: 'shopping-cart',
  optionsSchema: [
    {
      key: 'label',
      label: 'Titre par défaut',
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'giftLabel',
      label: "Titre lors d'un cadeau",
      type: 'string',
      group: 'Affichage',
    },
  ],
  forPage: true,
};

export default CartCheckout;
