// @flow
import * as React from 'react';

import type { ResourcePropertySchema, Resource } from 'src/types';
import type { OnPropertyValueChange } from 'src/types/components/resource/propertyEditor';

import { useGlobal, useResourceInterpolatedQuery } from 'src/hooks';
import invariant from 'src/helpers/invariant';
import { useDataStore } from 'src/zustand';

import ResourceSelectorInModal from '../../ResourceSelectorInModal';
import Button from 'src/components/Button';
import Item from './Item';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: Array<Resource>,
  disabled?: boolean,
|};

export default function ResourceListPropertyEditor({
  propertySchema,
  onChange: onChangeProp,
  value = [],
  disabled,
}: Props): React.Node {
  const { globalSchema } = useGlobal();
  const { itemSchema, creatable, deletable, filterQuery } = propertySchema;
  const interpolatedQuery = useResourceInterpolatedQuery(filterQuery);
  const getResource = useDataStore((state) => state.get);
  invariant(!!itemSchema, 'Item schema is always defined at this point');
  const { objectType, type } = itemSchema;
  invariant(!!objectType, 'Item schema should always bear an objectType');
  const subResourceTypes = Array.isArray(objectType)
    ? objectType
    : [objectType];

  const resourceSchemas = subResourceTypes.map((type) => globalSchema[type]);
  const [modalOpen, setModalOpen] = React.useState(false);

  invariant(
    type !== 'object_id' || subResourceTypes.length === 1,
    'Cannot have multiple resource types when selecting id'
  );

  const onChange = React.useCallback((newPropertyValue: any) => {
    if (type === 'object_id') {
      return onChangeProp(newPropertyValue.map((ref) => ref._id));
    }

    onChangeProp(newPropertyValue);
  }, []);

  const selectedResourceRefs =
    type === 'object_ref'
      ? value
      : value.map((id) => ({ _cls: subResourceTypes[0], _id: id }));

  const onRemove = React.useCallback(
    (resourceId) => {
      if (type === 'object_id') {
        return onChangeProp(value.filter((id) => id !== resourceId));
      }

      onChangeProp(value.filter((ref) => ref._id !== resourceId));
    },
    [value, onChangeProp]
  );

  const resources = selectedResourceRefs
    .map((resourceRef) =>
      getResource(resourceRef?._cls, resourceRef?._id, { log: true })
    )
    .filter((resource) => !!resource);

  return (
    <div className="resource-list-editor">
      <div className="items">
        {resources.map((resource) => {
          return (
            <Item
              key={resource._id}
              resource={resource}
              disabled={disabled}
              onRemove={() => onRemove(resource._id)}
            />
          );
        })}

        {modalOpen && (
          <ResourceSelectorInModal
            // $FlowIgnore
            value={selectedResourceRefs}
            onChange={onChange}
            multi
            resourceSchemas={resourceSchemas}
            creatable={creatable}
            deletable={deletable}
            onClose={() => setModalOpen(false)}
            query={interpolatedQuery}
          />
        )}
      </div>

      <div
        className="action"
        style={{ marginTop: resources.length > 0 ? 5 : 0 }}
      >
        <Button
          disabled={disabled}
          onClick={() => !disabled && setModalOpen(true)}
          size="small"
        >
          Editer la liste
        </Button>
      </div>
    </div>
  );
}
