import * as React from 'react';

import { Tag } from 'src/components';

export default function TagRenderer({ resource: tag }) {
  return (
    <Tag
      color="light"
      style={{
        backgroundColor: tag.color,
      }}
      key={tag._id}
      additionalClassName={`tag-renderer resource-type-${tag._cls}`}
    >
      {tag.label || tag.text}
    </Tag>
  );
}
