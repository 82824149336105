const ContentSlider = {
  _id: 'contentsSlider',
  label: 'Liste défilante',
  icon: 'angle-double-right',
  description: 'Liste de contenus ("slider") défilant horizontalement',
  frontComponent: 'ContentsSlider',
  isContentContainer: true,
  optionsSchema: [
    {
      key: 'showDots',
      label: 'Afficher les puces pour accéder aux images',
      type: 'bool',
      default: false,
      group: 'Affichage',
    },
    {
      key: 'showArrows',
      label: 'Afficher les flèches',
      type: 'bool',
      default: true,
      group: 'Affichage',
    },

    {
      key: 'showCount',
      label: 'Nombre de contenus à afficher',
      type: 'int',
      group: 'Affichage',
    },
    {
      key: 'speed',
      label: 'Vitesse de slide',
      type: 'enum',
      default: 300,
      values: [
        { label: 'lent', value: 1000 },
        { label: 'moyen', value: 500 },
        { label: 'rapide', value: 300 },
        { label: 'très rapide', value: 150 },
      ],
      group: 'Affichage',
    },
  ],
  forPage: true,
  hasEntities: true,
};

export default ContentSlider;
