// @flow
import * as React from 'react';

import type { ResourcePropertySchema } from 'src/types';
import type { OnPropertyValueChange } from 'src/types/components/resource/propertyEditor';

import { useDelayedUpdate } from 'src/hooks';
import { FieldContext } from './contexts';

import { Input, Textarea, Icon } from 'src/components';
import StringCounter from './components/StringCounter';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: string,
  disabled?: boolean,
  placeholder?: string,
|};

export default function StringEditor({
  propertySchema,
  onChange,
  disabled,
  value,
  placeholder,
}: Props): React.Node {
  const { multiline, isUrl } = propertySchema;
  const [debouncedValue, setDebouncedValue] = useDelayedUpdate(value, onChange);
  const { onFocus: onFieldFocus, onBlur: onFieldBlur } =
    React.useContext(FieldContext);

  if (disabled && isUrl)
    return (
      <a href={value} target="_blank" rel="noopener noreferrer">
        {value}
        <Icon name="external-link" type="regular" style={{ marginLeft: 5 }} />
      </a>
    );

  return (
    <>
      {multiline ? (
        <Textarea
          value={debouncedValue || ''}
          onChange={setDebouncedValue}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={onFieldFocus}
          onBlur={onFieldBlur}
        />
      ) : (
        <Input
          type="text"
          value={debouncedValue || ''}
          onChange={setDebouncedValue}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={onFieldFocus}
          onBlur={onFieldBlur}
        />
      )}

      <StringCounter propertySchema={propertySchema} value={debouncedValue} />
    </>
  );
}
