const Separator = {
  _id: 'Separator',
  label: 'Séparateur',
  icon: 'cut',
  frontComponent: 'Separator',
  optionsSchema: [
    {
      key: 'margin',
      label: 'Marge au dessus / en dessous',
      type: 'int',
      group: 'Affichage',
    },
    { key: 'width', label: 'Largeur (en %)', type: 'int', group: 'Affichage' },
  ],
  forPage: true,
};

export default Separator;
