// @flow
import { values, get, pick } from 'lodash';
import SchemaSchema from 'src/schema/SchemaSchema';

import type {
  GlobalSchema,
  ResourceSchema,
  ResourceRef,
  Resource,
} from 'src/types/models';
import type { SectionName } from 'src/types/routing';

import RESOURCE_TYPES from 'src/pages/constants/resources';
import invariant from '../invariant';

type GetSectionSchemasParams = {|
  siteId: string,
  globalSchema: GlobalSchema,
|};

export const getSectionSchemas = (
  sectionId: SectionName,
  { globalSchema, siteId }: GetSectionSchemasParams
): Array<ResourceSchema> => {
  const fullSchema = {
    ...globalSchema,
    Schema: SchemaSchema,
  };

  return values(fullSchema).filter((schema: ResourceSchema) => {
    const belongsToSection = schema.section === sectionId;
    const hiddenForCurrentInstance =
      schema.showForInstances && !schema.showForInstances.includes(siteId);

    return (
      belongsToSection &&
      !hiddenForCurrentInstance &&
      !!RESOURCE_TYPES[schema._id]
    );
  });
};

export function makeRef(
  resource: Resource,
  schema: ResourceSchema
): ResourceRef {
  invariant(
    resource._id && resource._cls,
    'Resource with id and cls should be provided when trying to create a ref'
  );

  let additionalFields = {};

  if (schema.refAdditionalFields && schema.refAdditionalFields.length > 0) {
    additionalFields = pick(resource, schema.refAdditionalFields);
  }

  return {
    _id: resource._id,
    _cls: resource._cls,
    _isRef: true,
    label: resource.label,
    ...additionalFields,
  };
}

export const getResourceLabel = (value: Resource, schema: any): string => {
  const { labelPath } = schema || {};
  const { label, _id } = value;

  const specificLabel = getSchemaSpecificLabel(value, schema);

  if (specificLabel) return specificLabel;

  let returnedLabel;

  if (label) return label;
  if (labelPath) {
    returnedLabel = (Array.isArray(labelPath) ? labelPath : [labelPath])
      .map((path) => get(value, path))
      .join(' - ');
  }

  return returnedLabel || _id;
};

export const getSchemaSpecificLabel = (
  value: Resource,
  schema: any
): ?string => {
  if (!schema._id) return null;

  switch (schema._id) {
    case 'User':
      return getUserLabel(value);
    default:
      return null;
  }
};

export const getUserLabel = (value: Resource): string => value.login;
