// @flow
import * as React from 'react';

import type { BulmaSize } from '../types';

import classnames from 'classnames';

import Icon from 'src/components/Icon';

type Type = 'danger' | 'success' | 'warning' | 'info';
type Goal = [boolean, string];
type Props = {|
  type: Type,
  goals?: Array<Goal>,
  children: React.Node,
  style?: Object,
  size?: BulmaSize,
  onHide?: () => any,
|};

export default function InformationBubble({
  type,
  goals,
  children,
  style,
  size,
  onHide,
  icon,
}: Props): React.Node {
  return (
    <div
      className={classnames(
        'information-bubble',
        !!type ? `is-${type}` : 'is-info',
        size && `is-${size}`
      )}
      style={style}
      size={size}
    >
      {onHide && (
        <button className="delete" aria-label="close" onClick={onHide} />
      )}
      <div className="icon-part">
        <Icon name={icon || 'lightbulb'} />
      </div>
      <div className="content-part">
        {children}
        {!!goals && goals.length > 0 && (
          <ul>
            {goals.map(([done, goal]) => (
              <li className={classnames({ done })} key={goal}>
                {done ? (
                  <Icon name="check" style={{ marginRight: 10 }} />
                ) : (
                  <Icon name="times" style={{ marginRight: 10 }} />
                )}
                <span>{goal}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
