// @flow
import * as React from 'react';
import moment from 'moment';

import type { CustomItemAction } from '..';
import type {
  Resource,
  ResourceSchema,
  ResourcePropertySchema,
} from 'src/types/models';

import { SiteContext } from 'src/pages/contexts';
import { useSchemaContext, useGlobal } from 'src/hooks';
import ResourceAPIConnector from 'src/helpers/api/ResourceAPIConnector';
import PropertyRenderer from 'src/pages/components/resource/PropertyRenderer/new';
import { ArchivedContext } from '../contexts';

import ResourceFromDataStore from 'src/pages/components/resource/ResourceFromDataStore';
import { NavLink } from 'react-router-dom';
import {
  Field,
  Button,
  Icon,
  Tr,
  Td,
  CheckBox,
  Flex,
  Tag,
} from 'src/components';
import MarkerTag, {
  AddedMarkerTag,
} from 'src/pages/components/resource/MarkerTag';

type Props = {|
  resource: Resource,
  getActions?: (ResourceSchema, Resource) => Array<CustomItemAction>,
  isSelected: boolean,
  onSelect?: (Resource) => any,
  openInANewTab: boolean,
  columnProperties: Array<ResourcePropertySchema>,
  canDelete: boolean,
  refetch: () => Promise<any>,
|};

function ResourceRow({
  resource,
  openInANewTab,
  getActions,
  columnProperties,
  isSelected,
  onSelect,
  canDelete,
  refetch,
}: Props): React.Node {
  const { schema, path } = useSchemaContext();
  const actions = getActions ? getActions(schema, resource) : [];
  const archived = React.useContext(ArchivedContext);
  const { siteId } = React.useContext(SiteContext);
  const resourceAPIConnector = React.useRef(
    new ResourceAPIConnector(siteId, schema, resource._id, {
      onSave: () => refetch(),
    })
  );
  const { autoMarkers = [], addedMarkers = [] } = resource;

  const actionsTD = archived ? (
    <Td>
      <RestoreItemButton schema={schema} id={resource._id} refetch={refetch} />
    </Td>
  ) : (
    <Td>
      <div
        style={{
          display: 'flex',
        }}
      >
        {schema.recordsAreEditableInAdmin ? (
          <NavLink
            title="Editer"
            to={`${path}/${resource._id}/edit`}
            style={{ marginRight: 5 }}
            target={openInANewTab ? '_blank' : undefined}
          >
            <Button size="small">
              <Icon name="edit" size="sm" />
            </Button>
          </NavLink>
        ) : (
          <NavLink
            title="Voir"
            to={`${path}/${resource._id}`}
            style={{ marginRight: 5 }}
            target={openInANewTab ? '_blank' : undefined}
          >
            <Button size="small">
              <Icon name="eye" size="sm" />
            </Button>
          </NavLink>
        )}

        {canDelete && schema.recordsAreEditableInAdmin && (
          <DeleteItemButton
            schema={schema}
            id={resource._id}
            refetch={refetch}
          />
        )}

        {actions.map((ItemCustomActionComponent, i) => (
          // $FlowIgnore
          <ItemCustomActionComponent
            key={i}
            schema={schema}
            // $FlowIgnore
            resource={resource}
            basePath={path}
            refetch={refetch}
            connector={resourceAPIConnector.current}
          />
        ))}
      </div>

      <Flex
        additionalClassName={`infos length-${
          addedMarkers.length + autoMarkers.length
        }`}
      >
        <Tag className="created-at">
          {moment(resource.createdAt).format('DD-MM-YYYY')}
        </Tag>

        {autoMarkers.map((marker) => (
          <MarkerTag marker={marker} key={marker.id} />
        ))}

        {addedMarkers.map((markerRef) => (
          <ResourceFromDataStore
            key={markerRef._id}
            resourceId={markerRef._id}
            resourceType={markerRef._cls}
            render={(marker) => <AddedMarkerTag marker={marker} />}
          />
        ))}
      </Flex>
    </Td>
  );

  return (
    <Tr key={resource._id} additionalClassName="resource-row">
      {!!onSelect && (
        <Td>
          <Field>
            <CheckBox
              isChecked={!!isSelected}
              onChange={() => onSelect(resource)}
            />
          </Field>
        </Td>
      )}

      {actionsTD}

      {schema &&
        columnProperties.map((propertySchema) => {
          return (
            <Td key={propertySchema.key} additionalClassName="property-cell">
              <ItemProperty
                schema={schema}
                propertySchema={propertySchema}
                resource={resource}
                connector={resourceAPIConnector.current}
              />
            </Td>
          );
        })}
    </Tr>
  );
}

export default (React.memo(ResourceRow): React.ComponentType<Props>);

type DeleteItemButtonProps = {|
  schema: ResourceSchema,
  id: string,
  refetch: () => Promise<any>,
|};

function DeleteItemButton({ schema, id, refetch }: DeleteItemButtonProps) {
  const { siteId } = useGlobal();
  const connector = new ResourceAPIConnector(siteId, schema, id);

  const deleteItem = React.useCallback(async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ?')) {
      await connector.destroy();
      await refetch();
    }
  }, [id]);

  return (
    <Button
      title="Supprimer"
      size="small"
      isLight
      color="danger"
      style={{ marginRight: 5 }}
      onClick={deleteItem}
    >
      <Icon name="trash" />
    </Button>
  );
}

function RestoreItemButton({ schema, id, refetch }: DeleteItemButtonProps) {
  const { siteId } = useGlobal();
  const archived = React.useContext(ArchivedContext);
  const connector = new ResourceAPIConnector(siteId, schema, id);

  const restoreItem = React.useCallback(async () => {
    if (window.confirm('Êtes-vous sûr de vouloir restaurer ?')) {
      await connector.restore({ archived });
      await refetch();
    }
  }, [id]);

  return (
    <Button
      title="Restaurer"
      size="small"
      isLight
      color="success"
      onClick={restoreItem}
    >
      <Icon name="trash-restore" />
    </Button>
  );
}

function ItemProperty({ propertySchema, schema, resource, connector }) {
  if (!propertySchema?.key) return null;

  const value = resource[propertySchema.key];

  return (
    <div className="property-renderer-wrapper">
      <PropertyRenderer
        value={value}
        propertySchema={propertySchema}
        resource={resource}
        resourceSchema={schema}
        connector={connector}
      />
    </div>
  );
}
