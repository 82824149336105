// @flow
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useGlobal, useResourceContext } from 'src/hooks';
import { getResourceSchemaPath } from 'src/helpers/url';

import getCustomActions from './resourceSpecific/actions';
import CopyResourceModal from 'src/pages/components/resource/CopyResourceModal';
import CreateSnapshotModal from 'src/pages/components/resource/CreateSnapshotModal';
import MarkerTag, {
  AddedMarkerTag,
} from 'src/pages/components/resource/MarkerTag';
import ResourceFromDataStore from 'src/pages/components/resource/ResourceFromDataStore';
import { Icon, Flex, Text, Button } from 'src/components';
import Dropdown from 'src/components/dropdown/Dropdown';
import DropdownMenuItem from 'src/components/dropdown/MenuItem';

type Props = {|
  navigationType?: 'routing' | 'state',
  children?: React.Node,
|};

export default function ResourceHeader({
  navigationType,
  children,
}: Props): React.Node {
  const { siteId, user } = useGlobal();
  const { resource, schema, path, dirty } = useResourceContext();
  const [isCopyModalOpened, toggleCopyModal] = React.useState(false);
  const [isCreateSnapshotModalOpened, toggleCreateSnapshotModal] =
    React.useState(false);
  const history = useHistory();
  const newResource = !resource._id;
  const label = resource.label || resource._id;
  const customActions = getCustomActions(schema._id, schema);

  return (
    <div className="resource-header">
      <div style={{ marginBottom: 30 }}>
        <Flex
          verticalAlign
          style={{ marginBottom: 10, justifyContent: 'space-between' }}
        >
          <Flex verticalAlign>
            {!schema.isSingleton && navigationType === 'routing' && (
              <Button
                isText
                id="back-button"
                style={{ marginRight: 10 }}
                onClick={() => {
                  const navigate =
                    !dirty ||
                    window.confirm(
                      'Êtes-vous sûr de vouloir quitter sans enregistrer vos modifications ?'
                    );

                  if (navigate) history.push(path);
                }}
              >
                <Icon name="angle-left" />
              </Button>
            )}

            <Text
              isTitle
              size={4}
              element="h1"
              style={{ marginBottom: 0, marginRight: 10 }}
            >
              <Text size={6} transformation="lowercase">
                {newResource && 'Creation de '}
                {schema.label}{' '}
              </Text>
              {label && (
                <Text weight="semibold" transformation="uppercase">
                  "{label}"
                </Text>
              )}
            </Text>
          </Flex>

          <Flex verticalAlign>
            <Dropdown
              trigger={(toggleDropdown) => (
                <Button onClick={toggleDropdown}>
                  <Icon name="ellipsis-v" />
                </Button>
              )}
            >
              {customActions.map((Action, index) => (
                <Action
                  key={index}
                  render={({ onClick, children, href, disabled }) => (
                    <DropdownMenuItem
                      onClick={onClick}
                      href={href}
                      disabled={disabled}
                    >
                      {children}
                    </DropdownMenuItem>
                  )}
                />
              ))}

              {customActions.length > 0 && <hr className="dropdown-divider" />}

              {(user.isDeveloper || user.superAdmin) && (
                <DropdownMenuItem
                  onClick={() => toggleCopyModal(true)}
                  title="Copier"
                >
                  <Text>
                    <Icon name="copy" /> Copier vers ...
                  </Text>
                </DropdownMenuItem>
              )}

              {user.isDeveloper && schema._id !== 'schema' && (
                <>
                  <DropdownMenuItem
                    to={getResourceSchemaPath(siteId, schema)}
                    title="Editer le modèle de donnée (schema) de l'objet"
                  >
                    <Text>
                      <Icon name="tools" /> Accéder au schéma
                    </Text>
                  </DropdownMenuItem>
                </>
              )}
              {schema.plugins?.includes('VersionPlugin') && (
                <DropdownMenuItem
                  onClick={() => toggleCreateSnapshotModal(true)}
                  title="Sauvegarder un snapshot de la ressource"
                >
                  <Text>
                    <Icon name="camera" /> Sauvegarder un snapshot de la
                    ressource
                  </Text>
                </DropdownMenuItem>
              )}
            </Dropdown>
          </Flex>
        </Flex>

        <div>
          <Text weight="bold">Création</Text> :{' '}
          {resource.createdAt
            ? moment(resource.createdAt).format('LLL')
            : '(manquant)'}
          {' - '}
          <Text weight="bold">Dernière MAJ : </Text>
          {resource.updatedAt
            ? moment(resource.updatedAt).format('LLL')
            : '(manquant)'}
        </div>

        {resource.autoMarkers && resource.autoMarkers.length > 0 && (
          <Flex className="auto-markers" style={{ gap: 10, marginTop: 10 }}>
            {resource.autoMarkers.map((marker) => (
              <MarkerTag marker={marker} key={marker.id} />
            ))}
          </Flex>
        )}

        {resource.addedMarkers && resource.addedMarkers.length > 0 && (
          <Flex className="added-markers" style={{ gap: 10, marginTop: 10 }}>
            {resource.addedMarkers.map((markerRef) => (
              <ResourceFromDataStore
                key={markerRef._id}
                resourceId={markerRef._id}
                resourceType={markerRef._cls}
                render={(marker) => <AddedMarkerTag marker={marker} />}
              />
            ))}
          </Flex>
        )}
      </div>

      {isCopyModalOpened && (
        <CopyResourceModal open onClose={() => toggleCopyModal(false)} />
      )}

      {schema.plugins?.includes('VersionPlugin') && (
        <CreateSnapshotModal
          open={isCreateSnapshotModalOpened}
          onClose={() => toggleCreateSnapshotModal(false)}
        />
      )}

      {children}
    </div>
  );
}
