const CommentList = {
  _id: 'EntityComments',
  icon: 'comments',
  label: 'Commentaires',
  frontComponent: 'EntityComments',
  forEntity: true,
  optionsSchema: [
    {
      key: 'limit',
      label: 'Nombre de commentaires à charger (20 par défaut)',
      type: 'int',
      default: 10,
      required: true,
      group: 'Général',
    },
  ],
};

export default CommentList;
