// @flow
import * as React from 'react';
import classnames from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';

import type { User } from 'src/types/models';

import { UserActions } from 'src/stores/UserStore';

import { Icon } from 'src/components';

type Props = {|
  user: User,
|};

export default function UserDropdown({ user }: Props): React.Node {
  const [opened, open] = React.useState(false);
  const ref = React.useRef(null);
  useOnClickOutside(ref, () => open(false));
  return (
    <div
      className={classnames('navbar-item', 'has-dropdown', {
        'is-active': opened,
      })}
      ref={ref}
    >
      <a className="navbar-link" onClick={() => open(!opened)}>
        <Icon name="user" />
      </a>

      <div className="navbar-dropdown is-right">
        <div className="navbar-item">{user.login}</div>

        <a href="#" className="navbar-item" onClick={UserActions.logout}>
          <span>
            <Icon name="sign-out-alt" />
            <span>Logout</span>
          </span>
        </a>
      </div>
    </div>
  );
}
