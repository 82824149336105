const EntityBody = {
  _id: 'body',
  label: 'Texte complet',
  icon: 'file-alt',
  frontComponent: 'EntityBody',
  optionsSchema: [],
  forEntity: true,
};

export default EntityBody;
