// @flow
import * as React from 'react';

import type { ResourceSchema, UserRight } from 'src/types/models';

import RESOURCE_TYPES from 'src/pages/constants/resources';

import videoCustomTabs from './video';
import tournamentCustomTabs from './tournament';
import contentCustomTabs from './contents';

export type Tab = {|
  to: string,
  icon: string,
  label: React.Node,
  content: React.ComponentType<{}>,
  right?: UserRight,
  exact?: boolean,
|};

export default function getResourceTypeCustomTabs(
  schema: ResourceSchema
): Array<Tab> {
  let customTabs = [];

  if (schema.isContent && schema.hasViewRecording) {
    customTabs = [...customTabs, ...contentCustomTabs];
  }

  switch (schema._id) {
    case RESOURCE_TYPES.Video.singular:
      customTabs = [...customTabs, ...videoCustomTabs];
      break;
    case RESOURCE_TYPES.Tournament.singular:
      customTabs = [...customTabs, ...tournamentCustomTabs];
      break;
    default:
      break;
  }

  return customTabs;
}
