import * as React from 'react';
import truncateHtml from 'truncate-html';

export default function RichTextRenderer({
  value,
  propertySchema,
  resource,
  resourceSchema,
}) {
  if (!value) return null;

  const html = 'html' in value ? value.html : value;
  if (!html || typeof html !== 'string') return null;

  return (
    <div
      className="quote-html"
      dangerouslySetInnerHTML={{
        __html: truncateHtml(html, 100) || '',
      }}
    />
  );
}
