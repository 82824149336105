import TypePropertySchema from '../specificProperties/Type';

const ObjectPropertySchema = [
  {
    key: 'itemSchema',
    label: "Schéma d'un item",
    type: 'object',
    group: 'Général',
    propertiesList: [
      TypePropertySchema,
      {
        key: 'propertiesList',
        label: 'Properties List',
        type: 'list',
        itemType: 'ObjectPropertySchema',
        group: 'Général',
      },
    ],
  },
];

export default ObjectPropertySchema;
