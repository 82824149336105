const Menu = {
  _id: 'menu',
  label: 'Menu/boutons',
  description: 'Menu de liens vers des pages',
  icon: 'bars',
  frontComponent: 'Menu',
  forPage: true,
  optionsSchema: [
    {
      key: 'mode',
      label: "Mode d'affichage",
      type: 'enum',
      values: [
        // TODO import from Front / ENTITY_COMPONENTS_MAP
        { value: 'header', label: 'Menu principal' },
        { value: 'secondary', label: 'Menu secondaire' },
        { value: 'footer', label: 'Pied de page' },
        { value: 'tabs', label: 'Onglets' },
        { value: 'button', label: 'Bouton classique' },
        { value: 'button_highlight', label: 'Bouton mis en évidence' },
      ],
      required: true, // unusual
      group: 'Général',
    },
    {
      key: 'title',
      label: 'Titre du menu',
      type: 'string',
      group: 'Général',
    },
    {
      key: 'menuItems',
      label: 'Items du menu',
      type: 'list',
      itemType: ['MenuItem', 'Menu'],
      group: 'Général',
    },
  ],
};

export default Menu;
