const CrowdFundingCampaign = {
  _id: 'CrowdFundingCampaign',
  frontComponent: 'CrowdFundingCampaign',
  label: 'Campagne de crowdfunding',
  icon: 'euro-sign',
  optionsSchema: [
    {
      key: 'campaign',
      label: 'Référence de la campagne de crowdfunding',
      type: 'object_ref',
      objectType: 'CrowdFundingCampaign',
      group: 'Général',
    },
    {
      key: 'title',
      label: 'Titre',
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'description',
      label: 'Description de la campagne',
      type: 'richtext',
      group: 'Affichage',
    },
    {
      key: 'callToAction',
      label: "Message d'appel à contribution",
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'displayProgress',
      label: 'Afficher les informations de progressions',
      type: 'bool',
      group: 'Affichage',
    },
    {
      key: 'displayTotal',
      label: 'Afficher le montant total',
      type: 'bool',
      group: 'Affichage',
    },
    {
      key: 'displayCount',
      label: 'Afficher le nombre de contributions',
      type: 'bool',
      group: 'Affichage',
    },
    {
      key: 'displayDeadline',
      label: 'Afficher la deadline',
      type: 'bool',
      group: 'Affichage',
    },
    {
      key: 'thanksText',
      label: 'Message de remerciement après participation',
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'fakeTotalToAdd',
      label: 'Faux montant à ajouter',
      type: 'int',
      group: 'Affichage',
    },
    {
      key: 'fakeCountToAdd',
      label: 'Faux nombre de contributions à ajouter',
      type: 'int',
      group: 'Affichage',
    },
  ],
  forPage: false,
  forEntity: true,
};

export default CrowdFundingCampaign;
