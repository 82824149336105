import extendComponent from './extenders';

import * as SITE_SPECIFIC from 'src/site-specific-module/components';
import * as CONTENT_CONTAINER from './CONTENT_CONTAINER';
import * as ENTITY_PROPERTY from './ENTITY_PROPERTY';
import * as ENTITY_SPECIFIC from './ENTITY_SPECIFIC';
import * as EXTERNAL_INTEGRATIONS from './EXTERNAL_INTEGRATIONS';
import * as SPECIAL from './SPECIAL';
import * as VISUAL_BASICS from './VISUAL_BASICS';

const components = [
  ...Object.values(SITE_SPECIFIC),
  ...Object.values(CONTENT_CONTAINER),
  ...Object.values(ENTITY_PROPERTY),
  ...Object.values(ENTITY_SPECIFIC),
  ...Object.values(EXTERNAL_INTEGRATIONS),
  ...Object.values(SPECIAL),
  ...Object.values(VISUAL_BASICS),
];

const extendedComponents = components.map(extendComponent);

export default extendedComponents;

// Certains composants ont été renommés, mais leur nom est resté le même en BDD
export const RENAME_COMPONENT_MAP = {
  UserButtonsCustom: 'UserButtons',
  EntityContentType: 'EntityMainContentType',
  MainMenu: 'Menu',
  SendAFile: 'ContributionUploader',
  Button: 'ActionButton',
  EntityMainTag: 'EntityTheme',
  EntityMainContentType: 'EntityTheme',
  SimpleImage: 'Image',
  SimpleVideo: 'Video',
  EntityDesc: 'EntityDescription',
  EntitySharingButtons: 'EntityShareButton',
  Comments: 'EntityComments',
  ContentVideo: 'EntityVideo',
  Title: 'Text',
  desc: 'EntityDescription',
};
