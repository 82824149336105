import * as React from 'react';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import { JsonEditor as JsonEditorReact } from 'jsoneditor-react';

// https://github.com/josdejong/jsoneditor/blob/master/docs/api.md#configuration-options

export default function JsonEditor({
  value: propertyValue,
  onChange,
  disabled,
}) {
  if (disabled)
    return (
      <div className="json-editor">
        <pre>{JSON.stringify(propertyValue, null, 2)}</pre>
      </div>
    );

  return (
    <div className="json-editor">
      <JsonEditorReact
        value={propertyValue}
        ace={ace}
        theme="ace/theme/github"
        allowedModes={['view', 'tree', 'form', 'code', 'text']}
        mode={'code'}
        onChange={onChange}
      />
    </div>
  );
}
