// @flow
import * as React from 'react';

import type { Tab } from 'src/types/components/pages/resourceRouter';

import { Icon } from 'src/components';
import MonitoringTab from './MonitoringTab';

const videoServerCustomTabs: Array<Tab> = [
  {
    to: 'monitoring',
    label: (
      <span>
        <Icon name="heartbeat" /> Monitoring
      </span>
    ),
    content: MonitoringTab,
  },
];

export default videoServerCustomTabs;
