// @flow

export function roundNumber(number: number, offset: number): number {
  return (
    Math.round(number * Math.pow(10, offset + 1)) / Math.pow(10, offset + 1)
  );
}

export type ConvertToNumberOptions = {|
  integerOnly?: boolean,
  min?: number,
  max?: number,
  decimals?: number,
|};

export function convertToNumber(
  value: void | null | string | number,
  options: ConvertToNumberOptions
): { result: ?number, valid: boolean } {
  const { integerOnly, min, max, decimals = 2 } = options;
  const parsedNumber = parseFloat(value);

  if (parsedNumber !== 0 && !parsedNumber)
    return { result: undefined, valid: true };

  if (isFinite(parsedNumber)) {
    // $FlowIgnore
    if (isFinite(min) && parsedNumber < min) {
      const result = integerOnly ? parseInt(min) : parseFloat(min);
      return { valid: true, result };
    }

    // $FlowIgnore
    if (isFinite(max) && parsedNumber > max) {
      const result = integerOnly ? parseInt(max) : parseFloat(max);
      return { valid: true, result };
    }

    if (integerOnly) {
      return { valid: true, result: Math.round(parsedNumber) };
    }

    if (!integerOnly) {
      const factor = Math.pow(10, decimals);
      const result = Math.round(parsedNumber * factor) / factor;
      return { valid: true, result };
    }
  }

  return { valid: false, result: undefined };
}

export const getClosestNumber = (
  number: number,
  targets: Array<number>
): number => {
  let closest = targets[0];

  for (const target of targets) {
    if (Math.abs(number - target) < Math.abs(number - closest))
      closest = target;
  }

  return closest;
};

export const isMoreOrLess = (
  real: number,
  target: number,
  percentage: number = 0.1
): boolean => (real * 100 - target * 100) / (target * 100) < 0.1;
