export const DISPLAY_USER_TYPE_VALUES = [
  {
    value: 'anonymous',
    label: 'Non connecté (anonyme)',
  },
  {
    value: 'freemium',
    label: "Freemium (utilisateur connecté, mais pas d'abonnement)",
  },
  {
    value: 'premium',
    label: 'Premium (utilisateur abonné payant)',
  },
  {
    value: 'registered',
    label: 'Connecté (premium / freemium)',
  },
  {
    value: 'not_premium',
    label: 'Non abonné (anonyme / freemium)',
  },
];
