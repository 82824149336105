// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { BulmaSize, Style } from './types';
import { bulmaSizeClassName } from 'src/helpers/style';

// When displaying the current page number, DELTA will control how many "ellipsis" pages are displayed on the left and right of the current page
// (if delta is 2 and current page is 10, displayed pages will be 8, 9, 10, 11 and 12)
const DELTA = 1;
type Props = {|
  currentPage: number,
  totalCount: number,
  countPerPage: number,
  onChange: (newPage: number) => any,

  disabled?: boolean,
  size?: BulmaSize,
  additionalClassName?: string,
  style?: Style,
  isRounded?: boolean,
|};
function Pagination({
  currentPage,
  totalCount,
  countPerPage,
  onChange,

  disabled,
  size,
  additionalClassName,
  style,
  isRounded,
}: Props): React.Node {
  const className = classnames(
    'pagination',
    bulmaSizeClassName(size),
    additionalClassName,
    {
      'is-rounded': isRounded,
    }
  );

  const firstPage = 0;
  const totalPageCount = Math.ceil(totalCount / countPerPage);
  const lastPage = totalPageCount - 1;

  const lastEllipsisPage = getLastEllipsisPage(currentPage, lastPage);
  const firstEllipsisPage = getFirstEllipsisPage(
    currentPage,
    lastEllipsisPage,
    firstPage
  );

  const hasPreviousPage = currentPage > 0;
  const hasNextPage = currentPage < totalPageCount - 1;

  return (
    <nav className={className} style={style}>
      <a
        className="pagination-previous"
        disabled={!hasPreviousPage}
        onClick={hasPreviousPage ? () => onChange(currentPage - 1) : undefined}
      >
        Précédent
      </a>
      <a
        className="pagination-next"
        disabled={!hasNextPage}
        onClick={hasNextPage ? () => onChange(currentPage + 1) : undefined}
      >
        Suivant
      </a>

      <span className="pagination-information">
        Affiche {Math.min(totalCount, countPerPage)} résultats sur {totalCount}
      </span>

      <ul className="pagination-list">
        {currentPage !== firstPage && firstEllipsisPage !== firstPage && (
          <>
            <li>
              <a
                className={classnames('pagination-link', {
                  'is-current': currentPage === firstEllipsisPage,
                })}
                onClick={() => onChange(firstPage)}
                disabled={disabled}
              >
                1
              </a>
            </li>

            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          </>
        )}
        {Array(lastEllipsisPage - firstEllipsisPage + 1)
          .fill(0)
          .map((_, pageNumber) => {
            return (
              <li key={pageNumber + firstEllipsisPage}>
                <a
                  className={classnames('pagination-link', {
                    'is-current':
                      currentPage === pageNumber + firstEllipsisPage,
                  })}
                  onClick={
                    currentPage === firstEllipsisPage + pageNumber
                      ? undefined
                      : () => onChange(firstEllipsisPage + pageNumber)
                  }
                  disabled={disabled}
                >
                  {pageNumber + firstEllipsisPage + 1}
                </a>
              </li>
            );
          })}
        {currentPage !== lastEllipsisPage && lastPage !== lastEllipsisPage && (
          <>
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
            <li>
              <a
                className="pagination-link"
                onClick={() => onChange(lastPage)}
                disabled={disabled}
              >
                {lastPage + 1}
              </a>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}

Pagination.defaultProps = {
  size: 'normal',
};

export default (React.memo(Pagination): React.ComponentType<Props>);

// Returns the first page listed between the ellipsis
const getFirstEllipsisPage = (currentPage, lastEllipsisPage, firstPage) => {
  if (currentPage === firstPage) {
    return currentPage;
  }

  const maxFirstEllipsisPage = lastEllipsisPage - DELTA * 2;
  const firstEllipsisPage = Math.min(currentPage - DELTA, maxFirstEllipsisPage);

  return firstEllipsisPage < firstPage ? firstPage : firstEllipsisPage;
};

// Returns the last page listed between the ellipsis
const getLastEllipsisPage = (currentPage, lastPage) => {
  if (currentPage === lastPage) {
    return lastPage;
  }

  const minLastEllipsisPage = DELTA * 2 + 1;
  const lastEllipsisPage = Math.max(currentPage + DELTA, minLastEllipsisPage);
  return lastEllipsisPage > lastPage ? lastPage : lastEllipsisPage;
};
