// @flow
import * as React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import type { ResourceType } from 'src/types/models';
import type { Filter } from 'src/types/routing';

import { useGlobal, useCurrentSection } from 'src/hooks';
import { toPath } from 'src/helpers/string';
import { SectionsContext } from 'src/pages/contexts';

import { Tag, Icon } from 'src/components';
import SidebarItem from './Item';

export type CountByCollection = { [type: ResourceType]: number };
export type RouteItem = {|
  pathname: string,
  label: string,
  exact?: boolean,
  count?: number,
  query?: Object,
  filters?: Array<Filter>,
  alwaysShowFilters?: boolean,
|};

export default function Sidebar(): React.Node {
  const currentSection = useCurrentSection();
  const sectionsContext = React.useContext(SectionsContext);
  const { siteId } = useGlobal();
  const location = useLocation();

  // If user landed to unknown section
  if (!currentSection) {
    return <Redirect to={`/${siteId}`} />;
  }

  const { routes: allSectionRoutes, countByCollection } = sectionsContext;
  const routes = allSectionRoutes[currentSection.id];

  // If user landed on the root path of the section, redirect to first item
  if (location.pathname === toPath(`/${siteId}${currentSection.pathname}`)) {
    const firstRoute = routes[0];
    return firstRoute ? (
      <Redirect
        to={toPath(
          `/${siteId}${currentSection.pathname}/${firstRoute.pathname}`
        )}
      />
    ) : null;
  }

  const { sidebarShortcuts: shortcuts } = currentSection;

  return (
    <nav className="menu">
      <ul className="menu-list">
        {shortcuts && shortcuts.length > 0 && (
          <>
            <p className="menu-label">Raccourcis</p>
            {shortcuts.map((shortcut) => (
              <SidebarItem
                pathname={toPath(
                  `/${siteId}${currentSection.pathname}/${shortcut.pathname}`
                )}
                title={shortcut.label}
                key={shortcut.label}
              >
                {shortcut.label}
              </SidebarItem>
            ))}
            <p className="menu-label">General</p>
          </>
        )}

        {routes.map((route, i) => {
          const count = countByCollection[route.schemaId];

          return (
            <SidebarItem
              pathname={toPath(
                `/${siteId}${currentSection.pathname}/${route.pathname}`
              )}
              query={route.query}
              title={route.label}
              key={route.label}
              filters={route.filters}
              showFilters={route.alwaysShowFilters}
            >
              <span>
                {route.icon && (
                  <Icon name={route.icon} style={{ marginRight: 5 }} />
                )}
                {route.label}
              </span>
              {(!!count || count === 0) && (
                <Tag style={{ marginLeft: 10 }} isRounded color="info" light>
                  {count}
                </Tag>
              )}
            </SidebarItem>
          );
        })}
      </ul>
    </nav>
  );
}
