// @flow

import * as React from 'react';
import { Helmet } from 'react-helmet';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import classnames from 'classnames';
import { kebabCase } from 'lodash';
import { SWRConfig } from 'swr';

import type { Environment } from 'src/types/environment';

import { ENVIRONMENT_COLOR_SET } from './constants/style';
import { assert } from './helpers/invariant';
import SWRFetcher from 'src/utils/SWRFetcher';
import { ModalContext, SelectContext } from 'src/pages/contexts';

import devIcon from './images/icon_puf_dev.png';
import stagingIcon from './images/icon_puf_staging.png';
import prodIcon from './images/icon_puf_prod.png';

import AppRouter from './pages';

const ICONS = {
  dev: devIcon,
  staging: stagingIcon,
  prod: prodIcon,
};

export default function App(): React.Node {
  const [sidebarToggled, setSidebarToggled] = React.useState(false);
  const env = ((import.meta.env.VITE_APP_PUF_ENV: any): Environment);

  React.useEffect(() => {
    const environmentColorSet = ENVIRONMENT_COLOR_SET[env];

    const documentElement = assert(
      document.documentElement,
      'Document always exists at this point'
    );

    Object.keys(environmentColorSet).map((CSSVariableName) =>
      documentElement.style.setProperty(
        `--environment-${kebabCase(CSSVariableName)}`,
        environmentColorSet[CSSVariableName]
      )
    );
  }, []);

  return (
    <SWRConfig
      value={{
        fetcher: SWRFetcher,
      }}
    >
      <BrowserRouter>
        <RecoilRoot>
          <ModalContext.Provider
            value={{
              depth: 0,
            }}
          >
            <SelectContext.Provider
              value={{
                portal: false,
              }}
            >
              <div
                className={classnames('backoffice', env, {
                  'sidebar-toggled': sidebarToggled,
                })}
              >
                <Helmet>
                  <title>PUF Admin {env}</title>
                  <link rel="icon" type="image/png" href={ICONS[env]} />
                </Helmet>

                <span onClick={setSidebarToggled} className="ToggleMenuButton">
                  <span>Menu</span>
                </span>

                <AppRouter />

                <div id="modal-portal" />
                <div id="dnd-portal" />
                <div id="select-portal" />
                <div id="datepicker-portal" />
              </div>
            </SelectContext.Provider>
          </ModalContext.Provider>
        </RecoilRoot>
      </BrowserRouter>
    </SWRConfig>
  );
}
