import * as React from 'react';
import classnames from 'classnames';

import { Tooltipped } from 'src/utils/Tooltipped';
import { APIContext } from 'src/pages/contexts';

export default function Avatar({ user }) {
  const { getImageUrl } = React.useContext(APIContext);

  if (!user) return null;

  const { avatar, label, avatarColor = '#000', login } = user;
  const userName = label || login;

  if (!userName) return null;

  const initialsSplit =
    userName.split(' ').length > 1 ? userName.split(' ') : userName.split('.');
  const initials = initialsSplit.map((str) => str[0]).join('');

  return (
    <Tooltipped tooltip={userName}>
      <div
        className={classnames('avatar', {
          'with-initials': !avatar?.file,
        })}
        style={{
          borderColor: avatarColor,
        }}
      >
        {avatar?.file ? (
          <img
            src={getImageUrl(avatar.file)}
            alt={userName}
            style={{ maxHeight: '100%', maxWidth: '100%' }}
          />
        ) : (
          <span style={{ color: avatarColor }}>{initials}</span>
        )}
      </div>
    </Tooltipped>
  );
}
