const SWRFetcher = async (url, params) => {
  try {
    const response = await fetch(
      `${
        import.meta.env.VITE_APP_API_URL || ''
      }/admin/${url}?${new URLSearchParams(params).toString()}`,
      {
        credentials: 'include',
      }
    );
    const { result, error } = await response.json();

    if (error) throw new Error(error.reason);

    return result;
  } catch (e) {
    return e;
  }
};

export default SWRFetcher;
