// @flow
import * as React from 'react';

import type { CustomActionProps } from 'src/types/components/pages/resourceRouter';

import { useGlobal, useResourceContext, useNotification } from 'src/hooks';
import API from 'src/helpers/api/API';

function EraseSubscriptionsAction({ render }: CustomActionProps): React.Node {
  const { user: admin } = useGlobal();
  const {
    resource: { _id: userId },
    apiPath,
  } = useResourceContext();
  const [submitting, setSubmitting] = React.useState(false);
  const { force: forceNotification } = useNotification();

  const resetSubscription = React.useCallback(async () => {
    setSubmitting(true);
    try {
      await API.del(`${apiPath}/${userId}/erase-subscriptions`);
      forceNotification({
        type: 'success',
        text: `Les abonnements ont été effacés`,
      });
    } finally {
      setSubmitting(false);
    }
  }, [userId, forceNotification]);

  if (!admin.isDeveloper || import.meta.env.VITE_APP_PUF_ENV === 'prod')
    return null;

  return render({
    onClick: resetSubscription,
    children: <span>Effacer les souscriptions</span>,
    disabled: submitting,
  });
}

export default EraseSubscriptionsAction;
