// @flow
import * as React from 'react';

import type { GenericProps } from './resourceSpecific/tabs';

import { useSchemaContext } from 'src/hooks';
import getItemCustomActions from './resourceSpecific/itemActions';

import { Helmet } from 'react-helmet';
import ResourceTable from 'src/pages/components/resource/ResourceTable';

type Props = {|
  ...GenericProps,
|};

export default function ListTab({ query }: Props): React.Node {
  const { schema } = useSchemaContext();

  return (
    <div className="list-tab">
      <Helmet>
        <title>{'Liste ' + (schema.label || schema._id)}</title>
      </Helmet>

      <ResourceTable
        openInANewTab={false}
        query={query}
        // $FlowIgnore
        getItemCustomActions={getItemCustomActions}
        canDelete
        canExport
      />
    </div>
  );
}
