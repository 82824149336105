import { create } from 'zustand';
import { merge, groupBy, keyBy, mapValues } from 'lodash';

const useDataStore = create((set, get) => ({
  data: {},
  get: (resourceType, resourceId) => {
    if (!resourceType || !resourceId || typeof resourceId !== 'string')
      return null;

    const resourceStore = get().data[resourceType] || {};

    return (
      resourceStore[resourceId] || {
        _cls: resourceType,
        _id: resourceId,
      }
    );
  },
  merge: (newData) =>
    set((state) => ({ data: merge({}, state.data, newData) })),
  mergeFromList: (resources) => {
    if (!Array.isArray(resources)) {
      console.warn(
        'Passed a non array to useDataStore.mergeFromList, cannot handle it'
      );
      return;
    }

    const formattedNewData = mapValues(
      groupBy(resources || [], '_cls'),
      (group) => keyBy(group, '_id')
    );

    set((state) => ({ data: merge({}, state.data, formattedNewData) }));
  },
}));

export default useDataStore;
