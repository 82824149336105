// @flow
import * as React from 'react';

import type { ResourceType, ResourceSchema } from 'src/types/models';
import type { CustomActionProps } from 'src/types/components/pages/resourceRouter';

import RESOURCE_TYPES from 'src/pages/constants/resources';

import userCustomActions from './user';
import emailTemplatesCustomActions from './emailTemplates';
import contentCustomActions from './contents';

export default function getResourceCustomActions(
  resourceType: ResourceType,
  schema: ResourceSchema
): Array<React.AbstractComponent<CustomActionProps>> {
  let customActions = [];

  if (schema.isContent) {
    customActions = [...customActions, ...contentCustomActions];
  }

  switch (resourceType) {
    case RESOURCE_TYPES.User.singular:
      customActions = [...customActions, ...userCustomActions];
      break;
    case RESOURCE_TYPES.EmailTemplate.singular:
      customActions = [...customActions, ...emailTemplatesCustomActions];
      break;
    default:
      return customActions;
  }

  return customActions;
}
