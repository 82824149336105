// @flow

import { useLocation } from 'react-router-dom';

import SECTIONS from 'src/pages/constants/sections';

export default function useCurrentSection() {
  const location = useLocation();
  const pathSectionPart = location.pathname.split('/')[2];
  const currentSection = Object.values(SECTIONS).find((section: Section) =>
    section.pathname.includes(pathSectionPart)
  );

  return currentSection;
}
