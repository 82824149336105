const BreadCrumb = {
  _id: 'Breadcrumb',
  frontComponent: 'Breadcrumb',
  label: 'Breadcrumb',
  icon: 'angle-double-right',
  forPage: true,
  forEntity: false,
  optionsSchema: [
    {
      key: 'homeLabel',
      label: 'Libellé du breadcrumb "home"',
      type: 'string',
      group: 'Affichage',
    },
  ],
};

export default BreadCrumb;
