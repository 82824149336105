const EXTERNAL_REFERENCES = {
  stripeCouponId: (stripeCouponId) =>
    stripeCouponId
      ? `https://dashboard.stripe.com/coupons/${stripeCouponId}`
      : '',
  stripeTestCouponId: (stripeTestCouponId) =>
    stripeTestCouponId
      ? `https://dashboard.stripe.com/test/coupons/${stripeTestCouponId}`
      : '',
  stripeProductId: (stripeProductId) =>
    stripeProductId
      ? `https://dashboard.stripe.com/products/${stripeProductId}`
      : '',
  stripeTestProductId: (stripeTestProductId) =>
    stripeTestProductId
      ? `https://dashboard.stripe.com/test/products/${stripeTestProductId}`
      : '',
};

export const getExternalReference = (propertySchema, value) => {
  const { key } = propertySchema;

  if (!EXTERNAL_REFERENCES[key]) return '';

  return EXTERNAL_REFERENCES[key](value);
};
