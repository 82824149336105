import { pickBy } from 'lodash';

export const getFieldAlerts = (options = {}, optionSchema = []) => {
  const alerts = {};

  for (const property of optionSchema) {
    const { key, required, label } = property;
    const value = options[key];
    alerts[key] = [];

    if (required && typeof value === 'undefined')
      alerts[key].push(`Champ "${label}" est requis`);
  }

  return pickBy(alerts, (alert) => alert.length > 0);
};
