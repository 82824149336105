// @flow
import * as React from 'react';

import type { ResourcePropertySchema, Resource } from 'src/types/models';
import type {
  OnPropertyChange,
  PropertiesEditorProps,
} from 'src/types/components/resource/propertyEditor/formEditor';

import { useSchemaContext } from 'src/hooks';

import SchemaEditor from './SchemaEditor';
import DefaultEditor from './DefaultEditor';

type Props = {|
  resource: Resource,
  onChange: ($Shape<Resource>) => any,
  disabled?: boolean,
|};

export default function ResourceFormEditor({
  onChange,
  resource,
  disabled,
}: Props): React.Node {
  const { schema } = useSchemaContext();

  const onPropertyChange: OnPropertyChange = React.useCallback(
    (propertySchema: ResourcePropertySchema, newPropertyValue: any) => {
      onChange({
        [propertySchema.key]: newPropertyValue,
      });
    },
    [onChange]
  );

  const PropertiesEditor =
    schema._id === 'schema' ? SchemaEditor : DefaultEditor;

  if (!resource) return null;

  return (
    <PropertiesEditor
      resource={resource}
      propertySchemas={schema.propertiesList}
      onPropertyChange={onPropertyChange}
      disabled={disabled}
    />
  );
}
