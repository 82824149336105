import React from 'react';

import { Builder } from '@react-awesome-query-builder/antd';

export default function RenderBuilder(props) {
  return (
    <div className="query-builder-container" style={{ padding: '10px' }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );
}
