import * as React from 'react';

import { useGlobal } from 'src/hooks';
import { NavLink } from 'react-router-dom';
import { getResourcePath } from 'src/helpers/url';
import { useDataStore } from 'src/zustand';

import LabelRenderer from '../../LabelRenderer';

export default function ResourceReferencesRenderer({
  value: list,
  propertySchema,
  resource,
  resourceSchema,
}) {
  const { globalSchema, siteId } = useGlobal();
  const { itemSchema } = propertySchema;
  const getResource = useDataStore((state) => state.get);

  const refType = itemSchema.type; // 'object_id' | 'object_ref'

  if (!['object_id', 'object_ref'].includes(refType)) return null;

  switch (refType) {
    case 'object_id': {
      const [objectType] = Array.isArray(itemSchema.objectType)
        ? itemSchema.objectType
        : [itemSchema.objectType];
      const refSchema = globalSchema[objectType];
      const references = list.map((itemId) => getResource(objectType, itemId));

      return references.map((reference) => {
        if (!reference) return null;

        return (
          <NavLink
            to={`${getResourcePath(siteId, refSchema)}/${reference._id}`}
            target="_blank"
            key={reference._id}
            style={{ display: 'flex' }}
          >
            <LabelRenderer resource={reference} schema={refSchema} />
          </NavLink>
        );
      });
    }
    case 'object_ref': {
      return list.map((objectRef) => {
        const refSchema = globalSchema[objectRef._cls];
        const reference = getResource(objectRef._cls, objectRef._id);

        if (!reference || !refSchema) return null;

        return (
          <NavLink
            to={`${getResourcePath(siteId, refSchema)}/${reference._id}`}
            target="_blank"
            key={reference._id}
            style={{ display: 'flex' }}
          >
            <LabelRenderer resource={reference} schema={refSchema} />
          </NavLink>
        );
      });
    }
    default:
      throw new Error('Cannot arrive');
  }
}
