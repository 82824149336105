// TODO: refacto this component, old version of the editor

// prop: value, error, onChange, schema, globalSchema, label
import _ from 'lodash';
import React from 'react';
import { PhotoshopPicker as ColorPicker } from 'react-color';

import localStore from 'store';
import ComponentBase from 'src/utils/ComponentBase';

import { Modal, Columns, Column } from 'src/components';

// TODO we should have created a ColorPickerBase but this pattern will probably change

export default class ColorPropertyEditor extends ComponentBase {
  constructor(props) {
    super(props);

    this.state = {
      pickerOpen: false,
      sampleText: 'Loren Ipsum',
    };
  }

  openPicker = () => {
    this.setState({
      pickerOpen: true,
      previousValue: this.props.value,
    });
  };

  onAccept = () => {
    this.setState({ pickerOpen: false });

    localStore.set(
      'preset_colors',
      _.uniq([
        this.props.value,
        ...(localStore.get('preset_colors') || []),
      ]).slice(0, 16)
    );
  };

  onCancel = () => {
    this.props.onChange(this.state.previousValue);
    this.setState({ pickerOpen: false });
  };

  onChangeValue = (color, event) => {
    color && this.props.onChange(color.hex);
  };

  render() {
    const { value, disabled } = this.props;
    let { previousValue } = this.state;

    // @see http://casesandberg.github.io/react-color/#api-color

    return (
      <div className="color-property-editor">
        <span
          className="color-value"
          style={{ background: value }}
          onClick={this.openPicker}
        />

        <Modal
          modal
          open={this.state.pickerOpen}
          onClose={(bt) => (bt === 'ok' ? this.onAccept() : this.onCancel())}
          contentClassName="InputColorField-modal"
        >
          <div className="ColorPicker-Wrapper" style={{ textAlign: 'center' }}>
            <ColorPicker
              disableAlpha
              color={value || '#0000'}
              onChangeComplete={this.onChangeValue}
              onAccept={this.onAccept}
              onCancel={this.onCancel}
              presetColors={localStore.get('preset_colors')}
              disabled={disabled}
            />
          </div>

          <Columns>
            <Column>
              <div
                style={{
                  border: '5px solid',
                  padding: '10vh 10vw',
                  textAlign: 'center',
                  color: previousValue,
                  fontSize: 42,
                }}
              >
                Lorem ipsum
              </div>
            </Column>
            <Column>
              <div
                style={{
                  border: '5px solid',
                  padding: '10vh 10vw',
                  textAlign: 'center',
                  color: value,
                  fontSize: 42,
                }}
              >
                Lorem ipsum
              </div>
            </Column>
          </Columns>
        </Modal>
      </div>
    );
  }
}
