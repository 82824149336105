const EntityReportButton = {
  _id: 'EntityReportButton',
  label: 'Bouton de signalement',
  icon: 'triangle-exclamation',
  frontComponent: 'EntityReportButton',
  optionsSchema: [
    {
      key: 'label',
      label: 'Contenu du bouton',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Affichage',
    },
  ],
  forEntity: true,
};

export default EntityReportButton;
