/* eslint-disable  no-new-func */
import * as React from 'react';

import COMPONENTS, { RENAME_COMPONENT_MAP } from '../definitions/components';
import SchemaContext from 'src/pages/contexts/SchemaContext';
import { useGlobal } from 'src/hooks';
import { ResourceConditionContext } from 'src/pages/contexts/ResourceContext';
import { ResourceFormContext } from 'src/pages/components/resource/ResourceEditor/FormEditor/contexts';
import extendComponent from '../definitions/components/extenders';

import { Modal, ErrorBoundary } from 'src/components';
import ResourceFormEditor from 'src/pages/components/resource/ResourceEditor/FormEditor';

export default function ComponentOptionsModal({
  component,
  onClose,
  open,
  onChange: onChangeProp,
}) {
  const resourceConditionContext = React.useContext(ResourceConditionContext);
  const componentSchema = React.useRef(
    component._cls === 'ComplexComponent'
      ? extendComponent({})
      : COMPONENTS.find(
          ({ _id }) =>
            _id === (RENAME_COMPONENT_MAP[component._id] || component._id)
        )
  );

  const { siteId, site, user } = useGlobal();
  const [submitting, setSubmitting] = React.useState(false);

  const [options, setOptions] = React.useState({ ...component.options });

  const onSave = React.useCallback(
    (options) => {
      setSubmitting(true);

      setTimeout(() => {
        onChangeProp(options);
        onClose();
      }, 0);
    },
    [onChangeProp, submitting]
  );

  const onChange = React.useCallback(
    (newOptions) =>
      setOptions((previousOptions) => ({
        ...previousOptions,
        ...newOptions,
      })),
    [setOptions]
  );

  if (!component) return null;
  if (!componentSchema.current?.optionsSchema) return null;

  const { optionsSchema, propertiesGroups } = componentSchema.current;

  const schema = {
    type: 'object',
    propertiesList: prepareComponentOptionsSchema(optionsSchema, options, {
      context: {
        ...resourceConditionContext,
        siteId,
        site: site.site,
        adminUser: user,
      },
    }),
    propertiesGroups,
  };

  return (
    <Modal
      modal
      open={open}
      freezeActions={submitting}
      onClose={(actionType) => {
        if (actionType === 'cancel') {
          onClose();
          return;
        }

        onSave(options);
      }}
      size="full"
    >
      <ErrorBoundary>
        <SchemaContext.Provider
          value={{
            schema,
          }}
        >
          <ResourceFormContext.Provider value={{ enableRouterTabs: false }}>
            <ResourceFormEditor
              resource={options}
              onChange={onChange}
              routerTabs={false}
            />
          </ResourceFormContext.Provider>
        </SchemaContext.Provider>
      </ErrorBoundary>
    </Modal>
  );
}

const prepareComponentOptionsSchema = (optionsSchema, compOptions, options) => {
  const { context = {} } = options || {};
  const schema = [];

  for (const _optionSchema of optionsSchema) {
    const optionSchema = { ..._optionSchema };
    const { condition } = optionSchema;

    if (condition) {
      const filterFunction = Function(
        '{ obj, context, site, adminUser, options }',
        'return (' + condition.f$ + ')'
      );

      if (
        !filterFunction({
          options: compOptions,
          obj: compOptions,
          site: context.site,
          context,
          adminUser: context.adminUser,
        })
      )
        continue;
    }

    schema.push(optionSchema);
  }

  return schema;
};
