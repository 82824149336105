const EntityClass = {
  _id: 'entityClass',
  label: "Classe de l'article",
  icon: 'tags',
  frontComponent: 'EntityClass',
  hasHTMLMarkup: true,
  optionsSchema: [
    {
      key: 'mapping',
      label: 'Mapping de la classe vers le texte',
      type: 'list',
      default: [],
      group: 'Général',
      itemSchema: {
        type: 'object',
        propertiesList: [
          {
            key: 'value',
            label: 'Valeur du type (en bdd)',
            type: 'enum',
            values: [
              { label: 'Article', value: 'TextContent' },
              { label: 'Vidéo', value: 'VideoContent' },
              { label: 'Auteur', value: 'Author' },
              { label: 'Article de la revue', value: 'MagazineArticle' },
              { label: 'Revue', value: 'MagazineIssue' },
              { label: 'Blog', value: 'Blog' },
              { label: 'Article de blog', value: 'BlogArticle' },
              { label: 'Auteur de blog', value: 'BlogAuthor' },
              { label: 'Dossier', value: 'Series' },
              { label: 'Survey', value: 'Sondage' },
            ],
          },
          {
            key: 'label',
            label: 'Valeur à afficher',
            type: 'string',
          },
        ],
      },
    },
  ],
  forEntity: true,
};

export default EntityClass;
