// @flow
import * as React from 'react';
import { useEffectOnce } from 'usehooks-ts';

import type { Resource, ResourceSchema } from 'src/types';
import ResourceAPIConnector from 'src/helpers/api/ResourceAPIConnector';

import { useGlobal } from 'src/hooks';
import SchemaContext from 'src/pages/contexts/SchemaContext';
import { ResourceFormContext } from 'src/pages/components/resource/ResourceEditor/FormEditor/contexts';
import { getResourcePath } from 'src/helpers/url';

import ResourceFormEditor from 'src/pages/components/resource/ResourceEditor/FormEditor';
import { Button, Notification } from 'src/components';

type Props = {|
  schema: ResourceSchema,
  onCancel: () => any,
  onAfterCreate: (Resource) => any,
|};

export default function CreateResourceForm({
  schema,
  onCancel,
  onAfterCreate,
}: Props): React.Node {
  const { siteId } = useGlobal();
  const [resource, setResource] = React.useState({});
  const [creating, setCreating] = React.useState(false);
  const [error, setError] = React.useState(null);

  const resourceAPIConnector = React.useRef(
    new ResourceAPIConnector(siteId, schema)
  );

  useEffectOnce(() => {
    const fetchResourceInitialValue = async () => {
      const fetchedResource = await resourceAPIConnector.current.load();

      setResource(fetchedResource);
    };

    fetchResourceInitialValue();
  });

  const createResource = React.useCallback(async () => {
    try {
      setCreating(true);
      const createdResource = await resourceAPIConnector.current.create(
        resource
      );
      onAfterCreate(createdResource);
    } catch (e) {
      setCreating(false);
      setError(e.message);
    }
  }, [resource]);

  return (
    <div className="create-resource-form">
      <SchemaContext.Provider
        value={{
          schema,
          path: getResourcePath(siteId, schema),
        }}
      >
        <ResourceFormContext.Provider value={{ enableRouterTabs: false }}>
          <ResourceFormEditor
            resource={resource}
            onChange={(newResource) =>
              setResource({ ...resource, ...newResource })
            }
          />
        </ResourceFormContext.Provider>
      </SchemaContext.Provider>

      {error && <Notification color="danger">{error}</Notification>}

      <div className="actions-wrapper" style={{ marginTop: '1rem ' }}>
        <Button onClick={onCancel}>Annuler</Button>

        <Button onClick={createResource} color="success" disabled={creating}>
          {creating ? 'Création...' : 'Créer et associer'}
        </Button>
      </div>
    </div>
  );
}
