// @flow
import * as React from 'react';
import ResourceAPIConnector from 'src/helpers/api/ResourceAPIConnector';

import type { ActionProps, CustomItemAction } from 'src/types/models/schema';

export type ResourceContextType<RT: Resource> = {|
  schema: ResourceSchema,
  resource: RT,
  connector: ResourceAPIConnector,
  path: string,
  dirty?: boolean,
  setDirty?: (boolean) => void,
  apiPath: string,
|};

export const ResourceContext =
  (React.createContext<ResourceContextType<Resource> | null>(
    null
  ): React.Context<ResourceContextType<Resource> | null>);

export default function withResourceContext<Config>(
  Component: React.AbstractComponent<{|
    ...Config,
    ...ResourceContextType<Resource>,
  |}>
): React.AbstractComponent<Config> {
  return function WithResourceContext(props: Config) {
    return (
      <ResourceContext.Consumer>
        {(context) => {
          if (!context) return null;
          return <Component {...context} {...props} />;
        }}
      </ResourceContext.Consumer>
    );
  };
}

export const ResourceConditionContext = (React.createContext<Object>(
  {}
): React.Context<Object>);

export const ResourceStableFieldsContext = (React.createContext<Object>({
  _cls: null,
}): React.Context<Object>);
