const EntityTitle = {
  _id: 'entityType',
  label: "Type de l'article",
  icon: 'tags',
  frontComponent: 'EntityType',
  hasHTMLMarkup: true,
  optionsSchema: [
    {
      key: 'mapping',
      label: 'Mapping du type faire le texte',
      type: 'list',
      default: [],
      group: 'Général',
      itemSchema: {
        type: 'object',
        propertiesList: [
          {
            key: 'value',
            label: 'Valeur du type (en bdd)',
            type: 'string',
          },
          {
            key: 'label',
            label: 'Valeur à afficher',
            type: 'string',
          },
        ],
      },
    },
  ],
  forEntity: true,
  siteIds: ['factuel'],
};

export default EntityTitle;
