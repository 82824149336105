import DisplayConditionRenderer from './DisplayConditionRenderer';
import NamespaceChainRenderer from './NamespaceChainRenderer';
import PublishStatusRenderer from './PublishStatusRenderer';
import NamespaceRenderer from './NamespaceRenderer';
import ColorRenderer from './ColorRenderer';

const CustomRenderers = {
  'Page.namespace': NamespaceChainRenderer,
  'Namespace.path': NamespaceChainRenderer,
};

export const getCustomPropertyRenderer = (schemaId, propertyKey) => {
  if (CustomRenderers[`${schemaId}.${propertyKey}`])
    return CustomRenderers[`${schemaId}.${propertyKey}`];

  switch (propertyKey) {
    case 'color':
      return ColorRenderer;
    case 'displayConditions':
      return DisplayConditionRenderer;
    case 'namespace':
      return NamespaceRenderer;
    case 'publishStatus':
    case 'status':
      return PublishStatusRenderer;

    default:
      return null;
  }
};
