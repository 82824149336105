const ToggleableRichText = {
  _id: 'ToggleableRichText',
  frontComponent: 'ToggleableRichText',
  label: 'Texte dépliable',
  icon: 'angle-double-down',
  forPage: true,
  forEntity: false,
  hasHTMLMarkup: true,
  optionsSchema: [
    {
      key: 'label',
      label: 'Texte "gâchette"',
      type: 'string',
      group: 'Affichage',
    },
    { key: 'content', label: 'Contenu', type: 'richtext', group: 'Affichage' },
    {
      key: 'withAnimation',
      label: 'Avec animation dépliante',
      type: 'bool',
      group: 'Affichage',
    },
  ],
};

export default ToggleableRichText;
