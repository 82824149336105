export default function ColorRenderer({ value: color }) {
  return (
    <div
      style={{
        border: `10px solid ${color}`,
        borderRadius: '5px',
        width: '4px',
        height: '4px',
      }}
    />
  );
}
