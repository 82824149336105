const ContentsGrid = {
  _id: 'gridList',
  label: 'Grille',
  icon: 'th-large',
  type: 'ContentContainer',
  isContentContainer: true,
  optionsSchema: [
    {
      key: 'colCount',
      label: 'Nombre de colonnes max.',
      description:
        "Il s'agit en fait de la taille d'un colonne en 1/12ème de page. 0 = automatique",
      type: 'int',
      default: 4,
      min: 0,
      max: 12,
      group: 'Affichage',
    },
    {
      key: 'seperatorBetweenRows',
      label: 'Ajouter un séparateur entre les rows',
      type: 'bool',
      group: 'Affichage',
    },
  ],
  frontComponent: 'ContentsGrid',
  forPage: true,
  hasEntities: true,
};

export default ContentsGrid;
