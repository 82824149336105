// @flow
import * as React from 'react';
import { groupBy, sortBy } from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';

import type { ResourcePropertySchema, Resource } from 'src/types/models/schema';
import type { OnPropertyChange } from 'src/types/components/resource/propertyEditor/formEditor';

import { ResourceFormContext } from '../contexts';
import { useGlobal, useSchemaContext } from 'src/hooks';
import { filterProperty, transformProperty } from './helpers';
import { ResourceConditionContext } from 'src/pages/contexts/ResourceContext';

import PropertyGroup from './PropertyGroup';
import { Tabs, EmptyState } from 'src/components';

type Props = {|
  resource: Resource,
  propertySchemas: Array<ResourcePropertySchema>,
  isSidebar?: boolean,
  onPropertyChange: OnPropertyChange,
  disabled?: boolean,
|};

export default function DefaultResourceEditor({
  resource,
  propertySchemas,
  isSidebar,
  onPropertyChange,
  disabled,
}: Props): React.Node {
  const { enableRouterTabs = true } = React.useContext(ResourceFormContext);
  const { user, globalSchema, site, siteContext } = useGlobal();
  const resourceConditionContext = React.useContext(ResourceConditionContext);
  const { schema } = useSchemaContext();
  const { propertyOrder = {} } = schema;
  const location = useLocation();
  const history = useHistory();

  const formattedPropertySchemas = propertySchemas
    .filter((propertySchema) =>
      filterProperty(propertySchema, {
        resource,
        user,
        site: site.site,
        siteContext,
        ...resourceConditionContext,
      })
    )
    .map((propertySchema) =>
      transformProperty(propertySchema, {
        resource,
        globalSchema,
      })
    );

  const propertyGroups = groupBy(
    formattedPropertySchemas,
    (property) => property.group || 'Autre'
  );

  const availableGroups = sortBy(Object.keys(propertyGroups), (groupLabel) => {
    const index = (schema.propertiesGroups || []).indexOf(groupLabel);
    return index === -1 ? 100 : index;
  });

  const formattedHash =
    location.hash && decodeURIComponent(location.hash.slice(1));
  const initialGroup =
    formattedHash && availableGroups.includes(formattedHash)
      ? formattedHash
      : availableGroups[0];

  const [currentGroup, _setCurrentGroup] = React.useState(initialGroup);

  const setCurrentGroup = React.useCallback(
    (newTab) => {
      if (enableRouterTabs) history.push(`#${newTab}`);
      _setCurrentGroup(newTab);
    },
    [_setCurrentGroup, enableRouterTabs]
  );

  if (propertySchemas.length === 0) {
    return (
      <EmptyState
        title="Aucune propriété à afficher"
        // icon="face-eyes-xmarks"
        icon="snowman"
        style={{ padding: '80px 20px' }}
      />
    );
  }

  return (
    <div className="resource-form-default-editor">
      {availableGroups.length > 1 && (
        <Tabs
          isBoxed
          fullWidth
          items={availableGroups.map((groupLabel) => ({
            label: groupLabel,
            onClick: () => setCurrentGroup(groupLabel),
            active: currentGroup === groupLabel,
          }))}
        />
      )}

      <PropertyGroup
        label={currentGroup}
        propertySchemas={propertyGroups[currentGroup] || []}
        resource={resource}
        onPropertyChange={onPropertyChange}
        disabled={disabled}
        hasManyGroups={availableGroups.length > 1}
        propertyOrder={propertyOrder[currentGroup]}
      />
    </div>
  );
}
