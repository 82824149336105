const ListPropertySchema = [
  {
    key: 'itemDefinitinitionType',
    label: "Définition du type d'objet",
    type: 'enum',
    values: [
      {
        value: 'insideDefinition',
        label: 'Définition interne',
      },
      {
        value: 'itemType',
        label: 'Type de ressource existante',
      },
    ],
    default: 'itemType',
    required: true,
    group: 'Général',
  },
  {
    key: 'itemType',
    label: "Type(s) d'item",
    description:
      "Attention, à utiliser à la place d'itemSchema ? avec ? préciser",
    type: 'enum',
    multiple: true,
    required: false, // ?
    group: 'Général',
    dataSource: {
      isGlobal: true,
      resource: 'schema',
      valuePath: '_id',
      labelPath: 'label',
    },
    condition: { f$: "obj.itemDefinitinitionType === 'itemType'" },
  },
  {
    key: 'itemSchema',
    label: "Schéma d'un item",
    type: 'object',
    group: 'Général',
    condition: { f$: "obj.itemDefinitinitionType === 'insideDefinition'" },
    propertiesList: [
      {
        key: '_cls',
        label: 'Classe',
        type: 'string',
        default: 'schema',
        readOnly: true,
      },
      {
        key: '_id',
        label: 'id',
        type: 'string',
        default: null,
      },
      {
        key: 'type',
        label: 'Type',
        type: 'string',
        default: 'object',
        readOnly: true,
      },
      {
        key: 'propertiesList',
        label: 'Properties List',
        type: 'list',
        unique: 'key',
        default: [],
        itemSchema: {
          type: 'ObjectPropertySchema',
        },
      },
    ],
  },
];

export default ListPropertySchema;
