// @flow
import _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';

import type { Handlers, Content } from '../../../types';

import { ComponentsContext } from '../../../contexts';
import { makeSpacingClass } from 'src/helpers/style';
import { useGlobal } from 'src/hooks';
import { getResourcePath } from 'src/helpers/url';

import {
  Draggable,
  Icon,
  Button,
  Message,
  MessageHeader,
  MessageBody,
  ErrorBoundary,
} from 'src/components';
import LayoutComponentThumbnail from './LayoutComponentThumbnail';
import Row from '../../Row';
import ComponentOptionsModal from '../../../components/ComponentOptionsModal';

type Props = {|
  content: Content,
  path: string,
  actualSize: number, // ColumnSize,
  handlers: Handlers,
  index: number,
  rowCount: number,
  onSelect?: (path: string) => void,
  isSelected?: boolean,
|};

export default function ColumnContent({
  content,
  path,
  actualSize,
  handlers,
  index,
  rowCount,
  onSelect,
  isSelected,
}: Props): React.Node {
  const { siteId, globalSchema } = useGlobal();
  const complexComponentSchema = globalSchema.ComplexComponent;
  const [optionsModalOpen, setOptionsModalOpen] = React.useState(false);

  return (
    <>
      <ComponentsContext.Consumer>
        {({ simple: simpleComponents, complex: complexComponents }) => {
          if (!content || !content.component) {
            console.log('No content or no component in content !', content);
          }

          const components = [...simpleComponents, ...complexComponents];
          const componentDefinition = _.find(
            components,
            ({ _id }) =>
              !!content && !!content.component && _id === content.component._id
          );
          const component = {
            ...content.component,
            ...componentDefinition,
          };

          return content && content.component ? (
            <Draggable type="tomove" data={{ component, path }}>
              <Message
                size="small"
                additionalClassName={classnames(
                  'column-content',
                  makeSpacingClass([['margin', 2, 'bottom']]),
                  'component-container',
                  {
                    selected: isSelected,
                  }
                )}
              >
                <MessageHeader style={{ justifyContent: 'space-between' }}>
                  <div>
                    <Button
                      onClick={() => setOptionsModalOpen(true)}
                      tooltip="Options et Données à afficher"
                      size="small"
                      style={{ marginRight: 5 }}
                    >
                      <Icon name="cog" size="small" />
                    </Button>

                    {component._cls === 'ComplexComponent' && (
                      <Button
                        size="small"
                        style={{ marginRight: 5 }}
                        target="_blank"
                        to={`${getResourcePath(
                          siteId,
                          complexComponentSchema,
                          component._id
                        )}/edit`}
                      >
                        <Icon name="external-link-alt" size="small" />
                      </Button>
                    )}
                  </div>

                  <div>
                    {onSelect && (
                      <Button
                        onClick={() => onSelect(path)}
                        tooltip="Sélectionner pour grouper"
                        size="small"
                        style={{ marginRight: 5 }}
                      >
                        <Icon
                          name={isSelected ? 'times' : 'mouse-pointer'}
                          size="small"
                        />
                      </Button>
                    )}

                    {index > 0 && (
                      <Button
                        onClick={() => handlers.onContentUp(path)}
                        tooltip="Monter d'un cran"
                        size="small"
                        style={{ marginRight: 5 }}
                      >
                        <Icon name="angle-up" size="small" />
                      </Button>
                    )}
                    {index < rowCount - 1 && (
                      <Button
                        onClick={() => handlers.onContentDown(path)}
                        tooltip="Descendre d'un cran"
                        size="small"
                        style={{ marginRight: 5 }}
                      >
                        <Icon name="angle-down" size="small" />
                      </Button>
                    )}
                    <Button
                      onClick={() => handlers.onRemoveContent(path)}
                      tooltip="Supprimer ce composant"
                      size="small"
                      color="danger"
                    >
                      <Icon name="times" size="small" />
                    </Button>
                  </div>
                </MessageHeader>

                <MessageBody style={{ padding: 5 }}>
                  <ErrorBoundary>
                    <LayoutComponentThumbnail component={component} />
                  </ErrorBoundary>
                </MessageBody>
              </Message>
            </Draggable>
          ) : (
            <Message
              size="small"
              additionalClassName={classnames(
                'column-content',
                makeSpacingClass([['margin', 2, 'bottom']]),
                {
                  selected: isSelected,
                }
              )}
              style={{ marginTop: index > 0 ? 20 : 0 }}
            >
              <MessageHeader style={{ justifyContent: 'flex-end' }}>
                {index > 0 && (
                  <Button
                    onClick={() => handlers.onContentUp(path)}
                    size="small"
                    style={{ marginRight: 5 }}
                  >
                    <Icon name="angle-up" />
                  </Button>
                )}
                {index < rowCount - 1 && (
                  <Button
                    onClick={() => handlers.onContentDown(path)}
                    size="small"
                    style={{ marginRight: 5 }}
                  >
                    <Icon name="angle-down" />
                  </Button>
                )}
                <Button
                  onClick={() => handlers.onRemoveContent(path)}
                  size="small"
                  color="danger"
                  isOutlined
                >
                  <Icon name="times" />
                </Button>
              </MessageHeader>

              <MessageBody style={{ padding: 0 }}>
                <Row
                  row={content}
                  path={path}
                  handlers={handlers}
                  rowCount={rowCount}
                  index={index}
                  actualSize={actualSize}
                  isDeep
                />
              </MessageBody>
            </Message>
          );
        }}
      </ComponentsContext.Consumer>

      {optionsModalOpen && (
        <ComponentOptionsModal
          open
          onClose={() => setOptionsModalOpen(false)}
          component={content.component}
          onChange={(newValue) => handlers.onChangeComponent(path, newValue)}
        />
      )}
    </>
  );
}
