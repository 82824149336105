// @flow
import * as React from 'react';
import classnames from 'classnames';
import { groupBy } from 'lodash';

import { useResizeDetector } from 'react-resize-detector';
import { useSetRecoilState } from 'recoil';

import { useGlobal } from 'src/hooks';
import { headerHeightState } from 'src/recoil/atoms';
import { canUserAccessSection } from 'src/helpers/models/users';
import SECTIONS from 'src/pages/constants/sections';
import { toPath } from 'src/helpers/string';
import { SectionsContext } from 'src/pages/contexts';

import { NavLink } from 'react-router-dom';
import { Icon, Tag } from 'src/components';
// import VersionTag from './VersionTag';
import InstanceSelect from './InstanceSelect';
import UserDropdown from './UserDropdown';
import UserActions from './UserActions';

export default function TopNavbar(): React.Node {
  const { user, siteId } = useGlobal();
  const sectionsContext = React.useContext(SectionsContext);
  const countByCollection = sectionsContext.countByCollection;

  // Header size watcher (to adjust app content available height)
  const setHeaderState = useSetRecoilState(headerHeightState);
  const onResize = React.useCallback(
    (_width, height) => setHeaderState(height),
    [setHeaderState]
  );

  const { ref } = useResizeDetector({
    handleHeight: true,
    refreshMode: 'debounce',
    refreshRate: 200,
    onResize,
  });

  // Dropdown active states
  const [mobileMenuDisplayed, setMobileMenuDisplayed] = React.useState(false);

  // Burger menu for mobile view
  const burgerMenu = (
    <a
      role="button"
      className="navbar-burger"
      aria-label="menu"
      aria-expanded="false"
      data-target="navbarBasicExample"
      onClick={() => setMobileMenuDisplayed(!mobileMenuDisplayed)}
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  );

  return (
    <nav className="navbar has-shadow" ref={ref}>
      <InstanceSelect />

      {burgerMenu}

      <div
        className={classnames('navbar-menu', {
          'is-active': mobileMenuDisplayed,
        })}
      >
        {/* Various sections navigation items */}
        <div className="navbar-start">
          {Object.keys(SECTIONS)
            .filter((sectionId) => {
              const section = SECTIONS[sectionId];
              return (
                canUserAccessSection(user, section) &&
                sectionId !== 'superadmin' &&
                !section.wrappingSection
              );
            })
            .map((sectionId) => {
              const section = SECTIONS[sectionId];
              const sectionRoutes = sectionsContext.routes[sectionId];

              if (sectionRoutes.length === 0)
                return (
                  <NavLink
                    key={sectionId}
                    to={toPath(`/${siteId}${section.pathname}`)}
                    activeClassName="is-active"
                    className={classnames('navbar-item', {
                      'has-dropdown': sectionRoutes.length > 0,
                      'is-hoverable': sectionRoutes.length > 0,
                    })}
                    onClick={() => setMobileMenuDisplayed(false)}
                  >
                    <Icon name={section.icon} />
                    <span>{section.label}</span>
                  </NavLink>
                );

              return (
                <div
                  className="navbar-item has-dropdown is-hoverable"
                  key={sectionId}
                >
                  <NavLink
                    to={toPath(`/${siteId}${section.pathname}`)}
                    activeClassName="is-active"
                    className="navbar-link"
                    onClick={() => setMobileMenuDisplayed(false)}
                  >
                    <Icon name={section.icon} />
                    <span>{section.label}</span>
                  </NavLink>

                  <div className="navbar-dropdown">
                    {sectionRoutes.map((route, i) => {
                      const count = countByCollection[route.schemaId];
                      const prevRoute = sectionRoutes[i - 1];
                      const prevRouteHasFilters =
                        prevRoute?.filters && prevRoute.filters.length > 0;
                      const hasFilters =
                        route.filters && route.filters.length > 0;

                      return (
                        <React.Fragment key={route.pathname}>
                          {hasFilters && !prevRouteHasFilters && (
                            <hr className="navbar-divider" />
                          )}
                          <NavLink
                            key={route.pathname}
                            to={toPath(
                              `/${siteId}${section.pathname}/${route.pathname}`
                            )}
                            activeClassName="is-active"
                            className="navbar-item"
                            onClick={() => setMobileMenuDisplayed(false)}
                          >
                            <span>
                              {route.icon && (
                                <Icon
                                  name={route.icon}
                                  style={{ marginRight: 5 }}
                                />
                              )}
                              <span>{route.label}</span>
                            </span>

                            {(!!count || count === 0) && (
                              <Tag style={{ marginLeft: 10 }} isRounded>
                                {count}
                              </Tag>
                            )}
                          </NavLink>

                          {hasFilters && (
                            <>
                              {route.filters.map((filter) => {
                                return (
                                  <NavLink
                                    key={filter.pathname}
                                    to={toPath(
                                      `/${siteId}${section.pathname}/${route.pathname}/${filter.pathname}/collection`
                                    )}
                                    activeClassName="is-active"
                                    className="navbar-item sub-item"
                                    onClick={() =>
                                      setMobileMenuDisplayed(false)
                                    }
                                  >
                                    <span>
                                      <Icon
                                        name="arrow-turn-down-right"
                                        style={{ marginRight: 5 }}
                                      />
                                      &nbsp;&nbsp;&nbsp;
                                      <span>{filter.label}</span>
                                    </span>
                                  </NavLink>
                                );
                              })}
                              <hr className="navbar-divider" />
                            </>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          {Object.keys(
            groupBy(
              Object.values(SECTIONS).filter(
                (section) => section.wrappingSection
              ),
              'wrappingSection'
            )
          ).map((wrappingSection) => {
            const sections = Object.values(SECTIONS).filter(
              (section) => section.wrappingSection === wrappingSection
            );

            return (
              <div
                className="navbar-item has-dropdown is-hoverable"
                key={wrappingSection}
              >
                <a className="navbar-link">{wrappingSection}</a>

                <div className="navbar-dropdown">
                  {sections.map((section) => {
                    return (
                      <NavLink
                        key={section.pathname}
                        to={toPath(`/${siteId}${section.pathname}`)}
                        activeClassName="is-active"
                        className="navbar-item"
                        onClick={() => setMobileMenuDisplayed(false)}
                      >
                        <span>
                          {section.icon && (
                            <Icon
                              name={section.icon}
                              style={{ marginRight: 5 }}
                            />
                          )}
                          <span>{section.label}</span>
                        </span>
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        <div className="navbar-end">
          {/* Super admin navigation item */}
          {user.superAdmin && (
            <NavLink
              className="navbar-item"
              to={toPath(`/${siteId}/super-admin`)}
              activeClassName="is-active"
              onClick={() => setMobileMenuDisplayed(false)}
            >
              <Icon name={SECTIONS.superadmin.icon} />
              <span>{SECTIONS.superadmin.label}</span>
            </NavLink>
          )}

          <UserActions />

          {/* User info dropdown */}
          <UserDropdown user={user} />
        </div>
      </div>

      {/* {user?.isDeveloper && <VersionTag />} */}
    </nav>
  );
}
