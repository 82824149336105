const Text = {
  _id: 'Text',
  label: 'Text',
  icon: 'text-height',
  frontComponent: 'Text',
  labelPath: ['contentNodes'],
  hasHTMLMarkup: true,
  optionsSchema: [
    {
      key: 'contentNodes',
      label: 'Contenu',
      required: true,
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Général',
    },
    {
      key: 'link',
      label: 'Lien',
      type: 'Link',
      group: 'Général',
    },
  ],
  forPage: true,
};

export default Text;
