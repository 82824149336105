import React from 'react';
import ReactDOM from 'react-dom';
import setLocale from 'src/utils/setLocale';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// import GitInfo from 'react-git-info/macro';
import truncate from 'truncate-html';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';

import 'src/styles/index.scss';

// Vendor CSS
import 'jsoneditor-react/es/editor.min.css';

import App from './App';

function initialize() {
  setLocale('fr');

  const {
    VITE_ENABLE_SENTRY,
    SENTRY_PUBLIC_ORG_NAME,
    SENTRY_PUBLIC_ORG_ID,
    SENTRY_PUBLIC_FRONT_ADMIN_PUBLIC_KEY,
    SENTRY_PUBLIC_FRONT_ADMIN_PROJECT_ID,
    DEPLOY_ENV,
    DEPLOY_RELEASE_NAME,
  } = import.meta.env;

  const USE_SENTRY =
    VITE_ENABLE_SENTRY === 'true' &&
    SENTRY_PUBLIC_ORG_NAME &&
    SENTRY_PUBLIC_ORG_ID &&
    SENTRY_PUBLIC_FRONT_ADMIN_PUBLIC_KEY &&
    SENTRY_PUBLIC_FRONT_ADMIN_PROJECT_ID &&
    DEPLOY_RELEASE_NAME &&
    DEPLOY_ENV;

  if (USE_SENTRY) {
    const prodErrorSessionReplayRate = Math.max(
      process.env.SENTRY_PUBLIC_ERROR_SESSION_RATE
        ? parseFloat(process.env.SENTRY_PUBLIC_ERROR_SESSION_RATE)
        : 0,
      0.001
    );

    Sentry.init({
      dsn: `https://${SENTRY_PUBLIC_FRONT_ADMIN_PUBLIC_KEY}@${SENTRY_PUBLIC_ORG_ID}.ingest.sentry.io/${SENTRY_PUBLIC_FRONT_ADMIN_PROJECT_ID}`,
      integrations: [
        new Integrations.BrowserTracing(),
        new Sentry.Replay(),
        new Sentry.Feedback({
          colorScheme: 'light',
        }),
      ],
      environment: DEPLOY_ENV,
      release: DEPLOY_RELEASE_NAME,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: prodErrorSessionReplayRate,
    });
  }

  truncate.setup({
    length: 100,
    byWords: true,
  });

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
  );
}

initialize();

ReactDOM.render(<App />, document.getElementById('root'));

// disabled to allow easier refresh when upgrading. See https://github.com/facebook/create-react-app/issues/3372
//registerServiceWorker();
