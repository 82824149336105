import * as React from 'react';

import API from 'src/helpers/api/API.js';
import { useGlobal, useNotification } from 'src/hooks';
import { SchemaActions } from 'src/stores/SchemaStore';
import { isDevelopment } from 'src/helpers/env';

import { Icon, Text, Button, Dropdown, DropdownMenuItem } from 'src/components';

export default function UserActions() {
  const { siteId, user } = useGlobal();
  const { force: forceNotification } = useNotification();

  const clearFrontendCache = React.useCallback(async () => {
    await API.post(`${siteId}/frontend/actions/clear-pages-cache`);

    forceNotification({
      type: 'success',
      text: 'Le cache des pages a été réinitialisé',
    });
  }, []);

  const items = [];

  if (user.admin) {
    items.push(
      <a
        onClick={() => clearFrontendCache()}
        title="Vider le cache des pages"
        key="cache_bust"
        className="navbar-item"
      >
        <Text>
          <Icon name="burst" type="regular" style={{ marginRight: 5 }} />
          <span>Vider le cache des pages</span>
        </Text>
      </a>
    );
  }

  if (isDevelopment()) {
    items.push(
      <a
        onClick={() => SchemaActions.load(siteId)}
        key="schema_update"
        className="navbar-item"
      >
        <Text>
          <Icon name="redo" style={{ marginRight: 5 }} />
          <span>Recharger les schémas</span>
        </Text>
      </a>
    );
  }

  if (items.length === 0) return null;

  return (
    <div className="navbar-item has-dropdown is-hoverable ">
      <a className="navbar-link">
        <Icon name="cog" />
      </a>

      <div className="navbar-dropdown is-right">{items}</div>
    </div>
  );
}
