// @flow
import * as React from 'react';

import type { Resource, ResourcePropertySchema } from 'src/types/models';
import type { Props } from '../';

import RESOURCE_TYPES from 'src/pages/constants/resources';

import getContentCustomProperty from './content';

export default function getResourceCustomPropertyEditor(
  resource: Resource,
  propertySchema: ResourcePropertySchema
): ?React.AbstractComponent<Props> {
  switch (resource._cls) {
    case RESOURCE_TYPES.TextContent.singular:
      return getContentCustomProperty(resource, propertySchema);
    default:
      return null;
  }
}
