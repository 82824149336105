import React from 'react';

import { Utils as QbUtils } from '@react-awesome-query-builder/antd';

export default function RenderResult({ tree: immutableTree, config }) {
  return (
    <div className="query-builder-result">
      <div>
        Query string:{' '}
        <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre>
      </div>
      <div>
        JsonLogic:{' '}
        <pre>
          {JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}
        </pre>
      </div>
    </div>
  );
}
