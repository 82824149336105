import React from 'react';
import request from 'superagent';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import { useInterval } from 'usehooks-ts';
import $ from 'jquery';
import classNames from 'classnames';

import { isMoreOrLess } from 'src/helpers/number';
import { APIContext } from 'src/pages/contexts';

import Button from '../Button';
import { Notification, InformationBubble } from '../information';

import Select from 'src/components/form/Select';
import Control from 'src/components/form/Control';
import Label from 'src/components/form/Label';
import Field from 'src/components/form/Field';

const ImageEditorTheme = {
  // 'downloadButton.display': 'none',
};

const LOCALE_FR = {
  // override default English locale to your custom language
  Apply: 'Appliquer',
  Cancel: 'Annuler',
  Crop: 'Recadrer',
  Delete: 'Supprimer',
  DeleteAll: 'Supprimer tout',
  Download: 'Télécharger',
  Draw: 'Dessiner',
  Filter: 'Filtre',
  Flip: 'Inverser',
  History: 'Historique',
  Icon: 'Icone',
  Load: 'Importer',
  Mask: 'Masque',
  Redo: 'Réappliquer',
  Reset: 'Restaurer',
  Resize: 'Redimensionner',
  Rotate: 'Rotation',
  Shape: 'Forme',
  Text: 'Texte',
  Undo: 'Annuler',
};

const DEFAULT_OUTPUT_FORMAT = 'jpeg';

export default function ImageFileUpload({
  value,
  onChange,
  onCancel,
  acceptedRatio,
  maxSize,
  outputFormats = ['jpeg', 'png'],
}) {
  const [outputFormat, setOutputFormat] = React.useState(
    Array.isArray(outputFormats) && outputFormats.length > 0
      ? outputFormats[0]
      : DEFAULT_OUTPUT_FORMAT
  );
  const { apiImageUploadUrl, getImageUrl } = React.useContext(APIContext);
  const editorRef = React.createRef();
  const [editorInstance, setEditorInstance] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [currentActiveMenu, setCurrentActiveMenu] = React.useState(null);

  let expectedRatio;

  if (acceptedRatio) {
    const [width, height] = acceptedRatio.split(':');
    expectedRatio = parseInt(width, 10) / parseInt(height, 10);
  }

  React.useEffect(() => {
    const editorInstance = editorRef.current.getInstance();
    setEditorInstance(editorInstance);
  }, []);

  // Complicated code to listen to crop menu selected and auto apply accepted ratio
  useInterval(
    () => {
      if (!expectedRatio) return;
      const [el] = $('.tui-image-editor-menu .tui-image-editor-item.active');

      if (!el) {
        setCurrentActiveMenu(null);
        return;
      }

      const newActiveMenu = el.className.split('tie-btn-')[1].split(' ')[0];
      if (newActiveMenu === currentActiveMenu) return;

      setCurrentActiveMenu(newActiveMenu);

      if (newActiveMenu !== 'crop') return;

      try {
        editorInstance.setCropzoneRect(expectedRatio);
      } catch (e) {
        console.error(e);
      }
    },
    // Delay in milliseconds or null to stop it
    300
  );

  const onSave = React.useCallback(
    async function (e) {
      // get original filename and replace file extension to .jpeg
      const imageFileName =
        editorInstance.getImageName().split('.').slice(0, -1).join('.') +
        `.${outputFormat || DEFAULT_OUTPUT_FORMAT}`;

      const imageDataUrl = editorInstance.toDataURL({
        format: outputFormat || DEFAULT_OUTPUT_FORMAT,
      });

      const image = new Image();
      image.src = imageDataUrl;
      await image.decode();

      const imageRatio = image.width / image.height;

      if (expectedRatio && !isMoreOrLess(imageRatio, expectedRatio, 0.1)) {
        setError(
          `Ratio de l'image non accepté (Ratio accepté : ${acceptedRatio})`
        );
        return;
      }

      const blob = await (await fetch(imageDataUrl)).blob();
      const file = new File([blob], imageFileName);

      if (maxSize && file.size > maxSize * 1000) {
        // maxSize in Ko, convert to bytes
        setError(
          `Taille maximale dépassée ( ${maxSize} Ko maximum ; actuel : ${
            file.size / 1000
          } Ko )`
        );
        return;
      }

      const response = await request
        .post(apiImageUploadUrl)
        .withCredentials()
        .attach('file', file);

      const storedImagePath = response.body.result;

      onChange(storedImagePath);
    },
    [onChange, editorInstance, outputFormat]
  );

  return (
    <div
      className={classNames('image-file-upload', {
        'has-preset-ratio': !!acceptedRatio,
      })}
    >
      <div className="puf-image-toolbar">
        <Field
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <Label>Format de l'image</Label>
          <Control style={{ width: 150 }}>
            <Select
              disabled={outputFormats.length === 0}
              value={
                outputFormat
                  ? {
                      value: outputFormat,
                      label: outputFormat,
                    }
                  : null
              }
              options={outputFormats.map((format) => ({
                value: format,
                label: format,
              }))}
              onChange={(option) => setOutputFormat(option.value)}
              placeholder="Format..."
              menuPortalTarget={document.getElementById('select-portal')}
            />
          </Control>
        </Field>
      </div>

      <ImageEditor
        ref={editorRef}
        includeUI={{
          loadImage: value
            ? {
                path: getImageUrl(value),
                name: value,
              }
            : '',
          theme: ImageEditorTheme,
          locale: LOCALE_FR,
          menu: ['crop', 'resize', 'text', 'filter'],
          initMenu: 'crop',
          uiSize: {
            height: '500px',
          },
          menuBarPosition: 'top',
        }}
        usageStatistics={false}
      />

      {(acceptedRatio || maxSize) && (
        <InformationBubble style={{ margin: '10px 0' }}>
          <p>Vous devez respecter les contraintes suivantes :</p>
          <ul className="bullet-list">
            {acceptedRatio && <li>Ratio accepté : {acceptedRatio}</li>}
            {maxSize && <li>Taille maximale : {maxSize}Ko</li>}
          </ul>
        </InformationBubble>
      )}

      {error && (
        <Notification color="danger" style={{ margin: '10px 0' }}>
          {error}
        </Notification>
      )}

      <div className="actions">
        <Button onClick={onCancel}>Annuler</Button>
        <Button onClick={onSave} color="success">
          Valider les changements
        </Button>
      </div>
    </div>
  );
}
