import BoolRenderer from './BoolRenderer';
import UserFileRenderer from './UserFileRenderer';
import ImageRenderer from './ImageRenderer';
import ListRenderer from './ListRenderer';
import EnumRenderer from './EnumRenderer';
import ObjectPropertySchemaRenderer from './ObjectPropertySchemaRenderer';
import RichTextRenderer from './RichTextRenderer';
import DateRenderer from './DateRenderer';
import TimestampRenderer from './TimestampRenderer';
import ObjectIdRenderer from './ObjectIdRenderer';
import ObjectRefRenderer from './ObjectRefRenderer';
import StringRenderer from './StringRenderer';
import ObjectPropertyReferenceRenderer from './ObjectPropertyReferenceRenderer';

function GenericRenderer({ value }) {
  return value ? JSON.stringify(value) : null;
}

function ISORenderer({ value }) {
  return value || null;
}

export const getGenericPropertyRenderer = (propertySchema) => {
  const { type } = propertySchema;

  switch (type) {
    case 'timestamp':
      return TimestampRenderer;
    case 'date':
      return DateRenderer;
    case 'image':
      return ImageRenderer;
    case 'file': {
      const { isUserFile } = propertySchema;

      // Currently, the file and image property type are the same
      if (isUserFile) return UserFileRenderer;
      return ImageRenderer;
    }
    case 'bool':
      return BoolRenderer;
    case 'richtext':
      return RichTextRenderer;
    case 'ObjectPropertySchema':
      return ObjectPropertySchemaRenderer;
    case 'list':
      return ListRenderer;
    case 'enum':
      return EnumRenderer;
    case 'object_id':
      return ObjectIdRenderer;
    case 'object_ref':
      return ObjectRefRenderer;

    case 'mongooseRef':
      return GenericRenderer;

    case 'string':
      return StringRenderer;
    case 'slug':
    case 'int':
    case 'float':
      return ISORenderer;

    case 'object':
      switch (propertySchema.objectType) {
        case 'ObjectPropertyReference':
          return ObjectPropertyReferenceRenderer;
        default:
          return null;
      }

    default:
      return null;
  }
};
