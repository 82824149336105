const Carousel = {
  _id: 'contentsSlideshow',
  label: 'Carousel',
  isContentContainer: true,
  canBeWrapped: true,
  icon: 'arrows-alt-h',
  description: 'Diaporama ("slideshow") de contenus défilant automatiquement',
  frontComponent: 'ContentsSlider',
  type: 'ContentContainer',
  optionsSchema: [
    {
      key: 'speed',
      label: 'Vitesse de transition',
      type: 'enum',
      default: 800,
      values: [
        // TODO slider
        { label: 'très lent', value: 2000 },
        { label: 'lent', value: 1200 },
        { label: 'moyen', value: 800 },
        { label: 'rapide', value: 500 },
        { label: 'très rapide', value: 300 },
      ],
      group: 'Affichage',
    },
    {
      key: 'delay',
      label: 'Délai entre chaque image, en secondes',
      unit: 's', // TODO
      type: 'float',
      default: 3,
      min: 0,
      group: 'Affichage',
    },
    {
      key: 'animType',
      label: 'Animation de transition',
      type: 'enum',
      values: [
        { label: 'défilement', value: 'scroll' },
        { label: 'fondu', value: 'fade' },
      ],
      default: 'scroll',
      group: 'Affichage',
    },
    {
      key: 'showDots',
      label: 'Afficher les puces pour accéder aux images',
      type: 'bool',
      default: false,
      group: 'Affichage',
    },
    {
      key: 'showArrows',
      label: 'Afficher les flèches',
      type: 'bool',
      default: false,
      group: 'Affichage',
    },
  ],
  forPage: true,
  hasEntities: true,
};

export default Carousel;
