const CurrentDate = {
  _id: 'CurrentDate',
  frontComponent: 'CurrentDate',
  label: 'Date du jour',
  icon: 'calendar',
  forPage: true,
  forEntity: false,
  optionsSchema: [
    {
      key: 'prefix',
      label: 'Préfix',
      type: 'string',
      preview: true,
      secondaryPreview: true,
      group: 'Affichage',
    },
    {
      key: 'format',
      label: 'Format',
      type: 'enum',
      default: 'DD/MM/YYYY',
      preview: true,
      values: [
        {
          value: 'DD/MM/YYYY',
          label: 'Compact DD/MM/YYYY (ex: "18/05/2022")',
        },
        {
          value: 'LL',
          label: 'Développé LL (ex: "18 Mai 2022")',
        },
        {
          value: 'dddd Do MMMM',
          label: 'ex: lundi 2 janvier',
        },
        {
          value: 'LLLL',
          label: 'Très développé LLLL (ex: "vendredi 22 avril 2022 17:30")',
        },
        {
          value: 'from-now',
          label: "Depuis aujourd'hui (ex: il y a une semaine)",
        },
      ],
      group: 'Affichage',
    },
    {
      key: 'customFormat',
      label: 'Format custom',
      description:
        "Vous pouvez spécifier n'importe quel format valie de la librairie momentjs https://momentjs.com/docs/#/displaying/",
      type: 'string',
      preview: true,
      group: 'Affichage',
    },
    {
      key: 'fromNow',
      label: 'Par rapport à maintenant (ex: il y a 2 heures)',
      description: 'Voir https://momentjs.com/docs/#/displaying/fromnow/',
      type: 'bool',
      secondaryPreview: true,
      group: 'Affichage',
    },
  ],
};

export default CurrentDate;
