// @flow
import * as React from 'react';
import { debounce } from 'lodash';

export type HistoryStack<T> = Array<T>;

type Returned<T> = {|
  historyStackLength: number,
  historyStackIndex: number,
  setHistoryStackIndex: (number) => void,
  setState: ($Shape<T>) => void,
|};

export default function useHistoryStack<T>(
  initialStack: HistoryStack<T>,
  onChange: (T) => any
): Returned<T> {
  const [historyStackIndex, setHistoryStackIndexState] = React.useState(0);
  const [, setHistoryDiffs] = React.useState([]);
  const [historyStack, setHistoryStackState] =
    React.useState<HistoryStack<T>>(initialStack);

  const setHistoryStack = React.useCallback(
    debounce(
      (setStack) => {
        setHistoryStackState((previousStack) => {
          const newStack = setStack(previousStack);
          setHistoryStackIndexState(newStack.length - 1);
          return newStack;
        });
      },
      500,
      { maxWait: 5000 }
    ),
    [setHistoryStackState, setHistoryStackIndexState]
  );

  const setHistoryStackIndex = React.useCallback(
    (newIndex) => {
      if (newIndex === -1 || newIndex === historyStack.length) return;
      setHistoryStackIndexState(newIndex);
      onChange(historyStack[newIndex]);
    },
    [historyStack, setHistoryStackIndexState]
  );

  const setValueState = React.useCallback(
    (newValueChunk: $Shape<T>) => {
      // $FlowIgnore
      onChange((previousValue) => {
        const newValue = {
          ...previousValue,
          ...newValueChunk,
        };

        // We are at the end of history
        if (historyStackIndex === historyStack.length - 1) {
          setHistoryStack((previousStack) => [...previousStack, newValue]);
          setHistoryDiffs((previousDiffs) => [...previousDiffs, newValueChunk]);
        } else {
          setHistoryStack((previousStack) => [
            ...historyStack.slice(0, historyStackIndex + 1),
            newValue,
          ]);
        }

        return newValue;
      });
    },
    [onChange, setHistoryStack, setHistoryDiffs]
  );

  return {
    historyStackLength: historyStack.length,
    historyStackIndex,
    setHistoryStackIndex,
    // $FlowIgnore
    setState: setValueState,
  };
}
