const EpisodeNumber = {
  _id: 'episodeNumber',
  frontComponent: 'EpisodeNumber',
  label: "Nombre d'épisodes",
  optionsSchema: [
    { key: 'prefix', label: 'Préfixe', type: 'string', group: 'Affichage' },
    { key: 'suffix', label: 'Suffixe', type: 'string', group: 'Affichage' },
  ],
  forEntity: true,
  hasEntities: false,
};

export default EpisodeNumber;
