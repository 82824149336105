const EntityTheme = {
  _id: 'EntityTheme',
  label: 'Thème du contenu',
  icon: 'tag',
  frontComponent: 'EntityTheme',
  forEntity: true,
  optionsSchema: [],
  hasHTMLMarkup: true,
};

export default EntityTheme;
