const MediaPropertySchema = [
  {
    key: 'objectType',
    label: 'Média',
    type: 'object',
    group: 'Général',
    propertiesList: [
      {
        _cls: 'ObjectPropertySchema',
        key: 'mediaType',
        label: 'Type du support',
        type: 'enum',
        values: [
          {
            value: 'image',
            label: 'Image',
          },
          {
            value: 'video',
            label: 'Vidéo',
          },
          // {
          //   value: 'audio',
          //   label: 'Audio',
          // },
          {
            value: 'pdf',
            label: 'PDF',
          },
        ],
        default: 'image',
      },
    ],
  },
];

export default MediaPropertySchema;
