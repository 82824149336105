const HARDCODED_SCHEMAS = {
  DiffusionList: {
    _id: 'DiffusionList',
    label: 'Contenus à afficher',
    type: 'list',
    itemType: ['ContentSchedule', 'ContentRequester'],
  },
};

export default HARDCODED_SCHEMAS;
