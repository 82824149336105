const EntityShareButton = {
  _id: 'EntityShareButton',
  label: 'Partager le contenu',
  icon: 'share',
  frontComponent: 'EntityShareButton',
  forEntity: true,
  hasBulmaButtonOption: true,
  hasColorOption: true,
  optionsSchema: [
    {
      key: 'iconOnly',
      label: "N'afficher que les icônes",
      type: 'bool',
      default: true,
      group: 'Affichage',
    },
    {
      key: 'label',
      label: 'Intitulé du bouton',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Affichage',
    },
    {
      key: 'twitter',
      label: 'Twitter',
      type: 'bool',
      default: true,
      group: 'Affichage',
    },
    {
      key: 'facebook',
      label: 'Facebook',
      type: 'bool',
      default: true,
      group: 'Affichage',
    },
    {
      key: 'linkedin',
      label: 'LinkedIn',
      type: 'bool',
      default: true,
      group: 'Affichage',
    },
    {
      key: 'whatsapp',
      label: 'Whatsapp',
      type: 'bool',
      default: true,
      group: 'Affichage',
    },
    {
      key: 'pinterest',
      label: 'Pinterest',
      type: 'bool',
      default: true,
      group: 'Affichage',
    },
    {
      key: 'email',
      label: 'Email',
      type: 'bool',
      default: true,
      group: 'Affichage',
    },
  ],
};

export default EntityShareButton;
