// @flow
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { ResourcePropertySchema } from 'src/types';

import { getPropertySchema, TYPE_OPTIONS } from 'src/schema/PropertySchema';
import invariant from 'src/helpers/invariant';
import { update, remove, swap } from 'src/helpers/array';

import { Button, Icon, Centered, Select, Control, Text } from 'src/components';
import { ObjectListPropertyEditorItem } from 'src/pages/components/resource/PropertyEditor/ListEditor/ObjectListEditor';

type Props = {|
  value: Array<ResourcePropertySchema>,
  propertySchema: any,
  onChange: (propertySchema: ResourcePropertySchema, propertyValue: any) => any,
  disabled?: boolean,
|};

export default function PropertiesListEditor({
  value: propertiesList = [],
  propertySchema: propertiesListSchema,
  onChange: onChangeProp,
  disabled,
}: Props): React.Node {
  const [expandedProperties, setExpandedProperties] = React.useState({});
  const [newPropertyType, setNewPropertyType] = React.useState(null);

  const togglePropertyExpansion = React.useCallback(
    (propertyKey) =>
      setExpandedProperties((previousState) => ({
        ...previousState,
        [propertyKey]: !previousState[propertyKey],
      })),
    []
  );

  const createNewProperty = React.useCallback(() => {
    const itemId = uuidv4();

    if (!newPropertyType) return;
    const propertySchema = getPropertySchema(newPropertyType.value);

    invariant(!!propertySchema);

    const newProperty = Object.fromEntries([
      ['_id', itemId],
      ['_cls', 'ObjectPropertySchema'],
      ...propertySchema.propertiesList.map((property) => [
        property.key,
        property.default,
      ]),
      ['type', newPropertyType.value],
    ]);
    onChangeProp(propertiesListSchema, [...propertiesList, newProperty]);
    togglePropertyExpansion(itemId);
    setNewPropertyType(null);
  }, [propertiesList, newPropertyType]);

  const updateProperty = React.useCallback(
    (propertyIndex, property) => {
      onChangeProp(
        propertiesListSchema,
        update(propertiesList, propertyIndex, property)
      );
    },
    [propertiesList]
  );

  const deleteProperty = React.useCallback(
    (propertyIndex) => {
      onChangeProp(propertiesListSchema, remove(propertiesList, propertyIndex));
    },
    [propertiesList]
  );

  const swapProperties = React.useCallback(
    (firstIndex, secondIndex) => {
      onChangeProp(
        propertiesListSchema,
        swap(propertiesList, firstIndex, secondIndex)
      );
    },
    [propertiesList]
  );

  return (
    <div className="object-list-editor" style={{ marginBottom: 350 }}>
      <Text isTitle element="h2" size={4}>
        Liste des propriétés
      </Text>

      {propertiesList.map((property, propertyIndex) => {
        const itemId = property._id || property.key;
        if (!itemId) return null;
        const itemExpanded = !!expandedProperties[itemId];
        const itemSchema = getPropertySchema(property.type);
        const itemKey = itemId;

        return (
          <ObjectListPropertyEditorItem
            key={itemKey}
            index={propertyIndex}
            item={property}
            itemSchema={itemSchema}
            expanded={itemExpanded}
            disabled={disabled}
            isFirstItem={propertyIndex === 0}
            isLastItem={propertyIndex === propertiesList.length - 1}
            toggleItemExpansion={togglePropertyExpansion}
            deleteItem={deleteProperty}
            swapItems={swapProperties}
            updateItem={updateProperty}
          />
        );
      })}

      <Centered additionalClassName="actions-wrapper">
        <Control>
          <Select
            styles={{ container: () => ({ width: 200 }) }}
            value={newPropertyType}
            options={TYPE_OPTIONS}
            onChange={setNewPropertyType}
          />
        </Control>

        <Button
          onClick={createNewProperty}
          additionalClassName="add-button"
          disabled={!newPropertyType}
          style={{ marginLeft: 10 }}
        >
          <Icon name="plus" style={{ marginRight: 3 }} /> Ajout d'une propriété
        </Button>
      </Centered>
    </div>
  );
}
