import Reflux from 'reflux';
import API from 'src/helpers/api/API';
import _ from 'lodash';

import HARDCODED_SCHEMAS from 'src/schema/hardcodedSchemas';

export var SchemaActions = Reflux.createActions([
  'load',
  'set',
  //"setSpecific",
]);

export default class SchemaStore extends Reflux.Store {
  constructor() {
    super();

    this.state = {
      schemaLoadError: null,
      globalSchema: {},
      schemaLoaded: false,
    };

    this.listenables = SchemaActions;
  }

  load(siteId) {
    API.get('global/data/schema', { siteId }).then(
      // TODO instance
      ({ data, propertySchemas }) => this.set(data, propertySchemas),
      (error) => this.setState({ globalSchema: {}, schemaLoadError: error })
    );
  }

  set(globalSchema, propertySchemas) {
    this.setState({
      globalSchema: {
        ...HARDCODED_SCHEMAS,
        ..._.keyBy(globalSchema, '_id'),
        ..._.keyBy(propertySchemas, '_id'),
      },
      propertySchemas: _.keyBy(propertySchemas, '_id'),
      schemaLoadError: null,
      schemaLoaded: true,
    });
  }

  static getGlobalSchema() {
    let schemaStore = Reflux.getGlobalState().schemaStore;
    this.instanceName = schemaStore && schemaStore.globalSchema;
  }
}

SchemaStore.id = 'SchemaStore'; // https://github.com/reflux/refluxjs/tree/master/docs/stores#global-state
