import moment from 'moment';
import React from 'react';

import InstanceStore from 'src/stores/InstanceStore';
import API from 'src/helpers/api/API';
import ComponentBase from 'src/utils/ComponentBase';

import {
  Button,
  ProgressBar,
  Icon,
  Text,
  Flex,
  Tag,
  Select,
  Field,
  Label,
  Control,
} from 'src/components';

const ENCODING_STATUS_TO_COLOR = {
  encoding: 'warning',
  pending: 'info',
  done: 'success',
  error: 'danger',
};

export default class VideoEncodingPage extends ComponentBase {
  constructor(props) {
    super(props);
    this.stores = [InstanceStore];
  }

  state = {
    sortBy: 'createTs',
  };

  load() {
    const { query: propQuery } = this.props;
    const { sortBy } = this.state;

    let query = {
      adminFilter: propQuery?.filterBy,
      sortBy: { [sortBy]: -1 },
      limit: 100,
    };

    API.get(
      this.state.instanceName + '/data/Video',
      query,
      'video_encoding_list'
    ).then(
      ({ data }) => this.setState({ data }),
      (error) => this.setState({ data: [], error: error.message })
    );

    this.removeRefreshTimer && this.removeRefreshTimer();
  }

  didMount() {
    this.observeProps(['query', 'location.search'], () => {
      this.load();
    });
  }

  didUpdate(prevProps, prevState) {
    if (prevState.instanceName !== this.state.instanceName) {
      this.load();
    }
  }

  swap(vid1, vid2) {
    API.put(
      this.state.instanceName + '/data/Video/' + encodeURIComponent(vid1._id),
      {
        ...vid1,
        encodingPriority: vid2.encodingPriority,
      }
    ).then(() =>
      API.put(
        this.state.instanceName + '/data/Video/' + encodeURIComponent(vid2._id),
        {
          ...vid2,
          encodingPriority: vid1.encodingPriority,
        }
      ).then(() => this.load())
    );
  }

  render() {
    const { label } = this.props;
    let { data, sortBy } = this.state;

    const makeOption = (value) => ({
      value,
      label: {
        createTs: 'Date de création',
        encodingPriority: "Priorité d'encodage",
      }[value],
    });

    return (
      <div>
        <Flex style={{ justifyContent: 'space-between', marginBottom: 10 }}>
          <Text element="h1" isTitle size={4} style={{ marginBottom: 20 }}>
            {label}
          </Text>

          <Field style={{ display: 'flex', alignItems: 'center' }}>
            <Label style={{ marginRight: 10 }}>Trier par</Label>
            <Control>
              <Select
                styles={{ container: () => ({ width: 200 }) }}
                value={makeOption(sortBy)}
                options={['createTs', 'encodingPriority'].map(makeOption)}
                onChange={(newOption) => {
                  this.setState({ sortBy: newOption.value }, () => {
                    this.load();
                  });
                }}
              />
            </Control>
          </Field>
        </Flex>

        {data && data.length > 0 ? (
          data.map((video, i) => (
            <div key={video._id} className="VideoEncodingPage-row">
              <Flex
                style={{ justifyContent: 'space-between', marginBottom: 10 }}
              >
                <div>
                  <Button
                    disabled={i === 0}
                    onClick={() => this.swap(data[0], video)}
                    title="Priorité max"
                    style={{ marginRight: 10 }}
                    size="small"
                  >
                    <Icon name="angle-double-up" size="sm" />
                  </Button>

                  <Button
                    disabled={i === 0}
                    onClick={() => this.swap(data[i - 1], video)}
                    title="Augmenter la priorité"
                    style={{ marginRight: 10 }}
                    size="small"
                  >
                    <Icon name="angle-up" size="sm" />
                  </Button>

                  <Button
                    disabled={i === data.length - 1}
                    onClick={() => this.swap(data[i + 1], video)}
                    title="Diminuer la priorité"
                    style={{ marginRight: 10 }}
                    size="small"
                  >
                    <Icon name="angle-down" size="sm" />
                  </Button>

                  <Button
                    disabled={i === data.length - 1}
                    onClick={() => this.swap(data[data.length - 1], video)}
                    title="Priorité min"
                    style={{ marginRight: 10 }}
                    size="small"
                  >
                    <Icon name="angle-double-down" size="sm" />
                  </Button>
                </div>
                <div>
                  {video.encodingProgress && video.encodingProgress < 1 && (
                    <ProgressBar
                      value={video.encodingProgress * 100}
                      style={{ marginRight: 10 }}
                    >
                      {video.encodingProgressText}
                    </ProgressBar>
                  )}

                  <Text weight="bold" style={{ marginRight: 10 }}>
                    {moment
                      .unix(
                        video.createTs ||
                          (video.srcInfo && video.srcInfo.modified)
                      )
                      .format('L hh:mm')}
                  </Text>

                  <Tag color={ENCODING_STATUS_TO_COLOR[video.encodingStatus]}>
                    {video.encodingStatus}
                  </Tag>
                </div>
              </Flex>

              <div>
                <Text isTitle size={6} element="h4">
                  {video.sourceFile || video._id}
                </Text>

                {(video.trailer || video.full) && (
                  <>
                    <Text weight="bold" element="p">
                      Flux:
                    </Text>

                    <ul className="bullet-list">
                      {video.trailer && (
                        <li>
                          trailer {video.trailer.res.join(', ')} -{' '}
                          {video.trailer.mp4 ? ' progressive ' : ''}{' '}
                          {video.trailer.smil ? ' HLS' : ''}
                        </li>
                      )}
                      {video.full && (
                        <li>
                          full {video.full.res.join(', ')} -{' '}
                          {video.full.mp4 ? ' progressive ' : ''}
                          {video.full.smil ? ' HLS' : ''}
                        </li>
                      )}
                    </ul>
                  </>
                )}
              </div>
            </div>
          ))
        ) : (
          <Text>Pas d'encodage pour ce filtre</Text>
        )}
      </div>
    );
  }
}
