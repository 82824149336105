const Button = {
  _id: 'ActionButton',
  frontComponent: 'ActionButton',
  label: 'Bouton',
  icon: 'siren',
  hasBulmaButtonOption: true,
  hasColorOption: true,
  hasBulmaSizeOption: true,
  optionsSchema: [
    {
      key: 'label',
      label: 'Libellé',
      required: true,
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Général',
    },
    {
      key: 'action',
      label: 'Action',
      type: 'object',
      required: true,
      propertiesList: [
        {
          label: "Nom de l'action",
          key: 'name',
          type: 'enum',
          values: [
            { label: 'Ouvrir une popup', value: 'open_popup' },
            { label: 'Ouvrir le side menu', value: 'toggle_side_menu' },
          ],
        },
        {
          key: 'popupId',
          type: 'object_id',
          objectType: ['PopUp'],
          label: 'ID de la popup ciblée',
          condition: { f$: 'obj.name === "open_popup"' },
        },
      ],
      group: 'Général',
    },
  ],
  forPage: true,
};

export default Button;
