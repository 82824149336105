// @flow
import * as React from 'react';

import type { DragType } from './Draggable';
import type { Style } from '../types';

import utils from './utils';

type GetTransferDataReturn = ?{ [key: DragType]: string };

type Props = {|
  wrapperComponent?: any,
  className?: string,
  children: React.Node,
  enabled: boolean,
  draggableId?: string,
  types?: Array<DragType>,
  onDrop?: (GetTransferDataReturn, SyntheticDragEvent<*>) => any,
  canDrop?: (GetTransferDataReturn) => boolean,
  onDragLeave?: (SyntheticDragEvent<*>, boolean) => any,
  onDragEnter?: (SyntheticDragEvent<*>, boolean) => any,
  onDragOver?: (SyntheticDragEvent<*>, boolean) => any,
  style?: Style,
|};

type State = {|
  over: boolean,
|};

type DefaultProps = {|
  enabled: boolean,
|};

function pickTypes(e: SyntheticDragEvent<*>): Array<string> {
  return e.dataTransfer ? e.dataTransfer.types : [];
}

function filterProps(props: $Shape<Props>) {
  let forbidden = [
    'types',
    'className',
    'enabled',
    'wrapperComponent',
    'canDrop',
  ];
  return Object.keys(props).reduce((p, c) => {
    if (!forbidden.includes(c)) {
      p[c] = props[c];
    }
    return p;
  }, {});
}

export default class Droppable extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    enabled: true,
  };

  state: State = {
    over: false,
  };

  render(): React.Node {
    let Tag = 'div';
    let props = Object.assign({}, this.props);
    const { style } = props;

    if (this.props.wrapperComponent) {
      Tag = this.props.wrapperComponent.type;
      props = Object.assign(props, this.props.wrapperComponent.props);
    }

    let classes = 'Droppable';
    if (props.className) classes += ` ${props.className}`;
    if (this.state.over) classes += ' over';

    return (
      <Tag
        className={classes}
        {...filterProps(props)}
        onDrop={this.onDrop.bind(this)}
        onDragOver={this.onDragOver.bind(this)}
        onDragEnter={this.onDragEnter.bind(this)}
        onDragLeave={this.onDragLeave.bind(this)}
        onDragExit={this.onDragLeave.bind(this)}
        style={style}
      >
        {props.children}
      </Tag>
    );
  }

  onDragOver(e: SyntheticDragEvent<*>) {
    if (!this.allowed(pickTypes(e))) return;
    e.stopPropagation();

    let droppable = true;
    const { canDrop, onDragOver } = this.props;

    if (typeof canDrop === 'function')
      droppable = canDrop(this.getTransferData(e));

    if (droppable) {
      e.preventDefault();
    }

    if (typeof onDragOver === 'function') onDragOver(e, droppable);
  }

  onDragEnter(e: SyntheticDragEvent<*>) {
    if (this.state.over) return;
    if (!this.allowed(pickTypes(e))) return;
    e.stopPropagation();

    let droppable = true;
    const { canDrop, onDragEnter } = this.props;

    if (typeof canDrop === 'function')
      droppable = canDrop(this.getTransferData(e));

    if (droppable) {
      e.preventDefault(); // = allow drop
      this.setState({ over: true });
    }

    if (typeof onDragEnter === 'function') onDragEnter(e, droppable);
  }

  onDragLeave(e: SyntheticDragEvent<*>) {
    e.preventDefault();
    if (!this.allowed(pickTypes(e))) return;
    e.stopPropagation();

    let over = true;
    if (this.refs.droppable) {
      let { left, top, right, bottom } =
        this.refs.droppable.getBoundingClientRect();
      // Why +5 ? +6 ? not explained. From CSS ?
      if (e.clientX <= left + 6 || e.clientX >= right - 6) over = false;
      if (e.clientY <= top + 6 || e.clientY >= bottom - 6) over = false;
      //console.debug("onDragLeave outside: pointer", e.clientX, e.clientY, "element: ", ~~left, ~~top, ~~right, ~~bottom, over);
      if (over) return;
    }

    this.setState({ over: false });
    const { canDrop, onDragLeave } = this.props;

    let droppable = true;
    if (typeof canDrop === 'function')
      droppable = canDrop(this.getTransferData(e));
    if (typeof onDragLeave === 'function') onDragLeave(e, droppable);
  }

  getTransferData(e: SyntheticDragEvent<*>): GetTransferDataReturn {
    let props = Object.assign({}, this.props);

    if (this.props.wrapperComponent)
      props = Object.assign(props, this.props.wrapperComponent.props);

    return !!props.types
      ? [].concat(props.types).reduce((d, type) => {
          let strData = e.dataTransfer.getData(type);
          d[type] =
            (strData && JSON.parse(e.dataTransfer.getData(type))) ||
            utils.dataTransferInProtectedMode[type];
          return d;
        }, {})
      : null;
  }

  onDrop(e: SyntheticDragEvent<*>) {
    e.preventDefault();

    if (!this.allowed(pickTypes(e))) return;

    e.stopPropagation();
    let droppable = true;
    const { canDrop, onDrop } = this.props;

    if (typeof canDrop === 'function')
      droppable = canDrop(this.getTransferData(e));

    if (droppable) {
      this.setState({ over: false });
      if (typeof onDrop === 'function') onDrop(this.getTransferData(e), e);
    }

    utils.dataTransferInProtectedMode = {}; // bug fix drag end not called
  }

  allowed(attemptingTypes: Array<string>): boolean {
    let props = Object.assign({}, this.props);

    if (this.props.wrapperComponent)
      props = Object.assign(props, this.props.wrapperComponent.props);
    if (!props.enabled) return false;

    let _attemptingTypes = utils.toArray(attemptingTypes);
    if (!props.types) return true;

    return [].concat(props.types).reduce((sum, type) => {
      if (_attemptingTypes.indexOf(type) >= 0) return true;
      return sum;
    }, false);
  }
}
