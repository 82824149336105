import * as React from 'react';
import { shallow } from 'zustand/shallow';

import useUIStore from 'src/zustand/UIStore';

import JsonEditor from './JsonEditor';
import { Modal } from 'src/components';

export default function JSONCodeModal() {
  const [state, closeModal] = useUIStore(
    (state) => [state.codeModalState, state.closeCodeModal],
    shallow
  );

  if (!state?.resource) return null;

  const { resource, onChange } = state;

  return (
    <Modal
      open
      onClose={closeModal}
      title="Code JSON de la ressource"
      modalClassName="is-small"
      size="full"
    >
      <JsonEditor
        resource={resource}
        onChange={onChange}
        disabled={!onChange}
      />
    </Modal>
  );
}
