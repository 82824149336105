const EntityDescription = {
  _id: 'EntityDescription',
  label: 'Description',
  icon: 'book-reader',
  frontComponent: 'EntityDescription',
  optionsSchema: [],
  forEntity: true,
  hasHTMLMarkup: true,
};

export default EntityDescription;
