// @flow
import type { Tab } from 'src/types/components/pages/resourceRouter';

import VideoStreamsTab from './VideoStreamsTab';

const videoSteamsCustomTabs: Array<Tab> = [
  {
    to: 'streams',
    icon: 'file-video',
    label: 'URLs & fichiers',
    content: VideoStreamsTab,
  },
];

export default videoSteamsCustomTabs;
