import { HTML_ELEMENT_OPTION_SCHEMAS } from '../../extenders/markupExtender';

const EntityLinkedContents = {
  _id: 'EntityLinkedContents',
  label: 'Contenus liés',
  icon: 'link',
  frontComponent: 'EntityLinkedContents',
  forEntity: true,

  optionsSchema: [
    { ...HTML_ELEMENT_OPTION_SCHEMAS, key: 'LabelElement' },
    {
      key: 'label',
      label: 'Titre de la liste',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Affichage',
    },
    {
      key: 'mode',
      label: "Mode d'affichage",
      type: 'enum',
      dataSource: {
        resource: 'ComplexComponent',
        valuePath: 'mode',
        labelPath: 'label',
        query: {
          limit: 0,
          adminFilter: { objectType: { $exists: true, $not: { $size: 0 } } },
        },
      },
      group: 'Affichage',
      _id: '341547b2-e840-407f-91f4-d622f0ddc740',
      required: true,
      default: 'list',
    },
    {
      key: 'colCount',
      label: 'Nombre de colonnes max.',
      description:
        "Il s'agit en fait de la taille d'un colonne en 1/12ème de page. 0 = automatique",
      type: 'int',
      default: 4,
      min: 0,
      max: 12,
      group: 'Affichage',
    },
  ],
};

export default EntityLinkedContents;
