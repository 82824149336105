export const generateEntitiesConfig = (schemas, globalSchema) => {
  const config = {};

  let fields = {};

  for (const schema of schemas) {
    const entityFields = generateFieldsConfigFromSchema(schema, globalSchema);
    fields = { ...fields, ...entityFields };
  }

  config.fields = fields;

  return config;
};

export const generateUserConfig = (userSchema, globalSchema) => {
  return {
    fields: {
      ...generateFieldsConfigFromSchema(userSchema, globalSchema),
      ...USER_FIELDS_CONFIG,
    },
  };
};

// Config documentation reference :
// https://github.com/ukrbublik/react-awesome-query-builder/blob/master/CONFIG.adoc
const generateFieldsConfigFromSchema = (schema, globalSchema, options) => {
  const { depth = 0 } = options || {};
  const { propertiesList } = schema;

  const fields = {};

  for (const property of propertiesList) {
    const { key, label, type } = property;

    const field = {
      label,
      // valueSources: ['value'],
    };

    let fieldType, mode;
    const fieldSettings = {};
    let subfields = {};

    switch (type) {
      case 'string':
        fieldType = 'text';
        break;
      case 'richtext':
        fieldType = '!struct';

        subfields.html = {
          type: 'text',
          label: 'html',
        };

        subfields.editorStateJson = {
          type: 'text',
          label: 'editorStateJson',
        };

        break;
      case 'enum': {
        fieldType = 'select';

        if (property.dataSource) {
          fieldSettings.listValues = [];
          break;
        }

        fieldSettings.listValues = property.values.map((value) => ({
          value: value.value,
          title: value.label,
        }));
        break;
      }
      case 'float':
      case 'int':
        fieldType = 'number';
        break;
      case 'bool':
        fieldType = 'boolean';
        break;
      case 'timestamp':
        fieldType = 'number';
        field.label = `[UNIX timestamp] - ${field.label}`;
        break;
      case 'date':
        fieldType = 'date';
        break;
      case 'duration':
        fieldType = 'text';
        break;
      // case 'object': {
      //   fieldType = '!struct';
      //   subFields.name = {
      //     type: 'text',
      //     label: 'Name',
      //     label2: 'User name', //optional, see below
      //   };
      //  break;
      // }
      // case 'json':
      //   fieldType = '!struct';
      //   subFields.name = {
      //     type: 'text',
      //     label: 'Name',
      //     label2: 'User name', //optional, see below
      //   };
      //   break;
      case 'list': {
        fieldType = '!group';
        mode = 'array';

        if (
          depth < 2 &&
          property.itemSchema &&
          ['object_id', 'object_ref'].includes(property.itemSchema?.type) &&
          property.itemSchema?.objectType
        ) {
          const { objectType: schemaObjectType } = property.itemSchema;
          const objectType = Array.isArray(schemaObjectType)
            ? schemaObjectType[0]
            : schemaObjectType;
          const subSchema = globalSchema[objectType];

          if (!subSchema) break;

          subfields = generateEntityFieldsConfig(subSchema, globalSchema, {
            depth: depth + 1,
          });
        }

        break;
      }
      case 'object_ref': {
        fieldType = '!struct';

        subfields._id = {
          type: 'text',
          label: '_id',
        };

        subfields._cls = {
          type: 'text',
          label: '_cls',
        };

        subfields.label = {
          type: 'text',
          label: 'label',
        };

        break;
      }
      case 'object_id':
        fieldType = 'text';
        field.label = `[Identifiant mongoose] - ${field.label}`;
        break;
      case 'file':
        fieldType = 'text';
        field.label = `[Path] - ${field.label}`;
        break;
      // case 'layout':
      //   fieldType = '!group';
      //   subFields.name = {
      //     type: 'text',
      //     label: 'Name',
      //     label2: 'User name', //optional, see below
      //   };
      //   break;
      case 'slug':
        fieldType = 'text';
        field.label = `[Slug] - ${field.label}`;
        break;
      // case 'mongooseRef':
      //   fieldType = 'text';
      //   break;
      // case 'image':
      //   fieldType = '!struct';
      //   subFields.name = {
      //     type: 'text',
      //     label: 'Name',
      //     label2: 'User name', //optional, see below
      //   };
      //   break;
      case 'rawImage':
        fieldType = 'text';
        field.label = `[Path] - ${field.label}`;
        break;
      // case 'FrontIcon':
      // case 'FrontColor':
      // case 'Link':
      //   fieldType = '!struct';
      //   subFields.name = {
      //     type: 'text',
      //     label: 'Name',
      //     label2: 'User name', //optional, see below
      //   };
      //   break;
      case 'color':
        fieldType = 'text';
        field.label = `[Couleur] - ${field.label}`;
        break;
      default:
        continue;
    }

    field.type = fieldType;
    field.mode = mode;
    field.fieldSettings = fieldSettings;
    field.subfields = subfields;

    fields[key] = field;
  }

  return fields;
};

export const USER_FIELDS_CONFIG = {
  subscribed: {
    type: 'boolean',
    label: 'Est abonné',
  },
  isPremium: {
    type: 'boolean',
    label: 'Est premium',
  },
  login: {
    type: 'text',
    label: "Email de l'utilisateur",
  },
  connected: {
    type: 'boolean',
    label: 'Utilisateur connecté',
  },
};

export const USER_SESSION_FIELDS_CONFIG = {
  fields: {
    cookies: {
      label: 'Cookies',
      type: '!group',
      mode: 'array',
      subfields: {
        name: {
          label: 'name',
          label2: 'Nom',
          type: 'text',
        },
        value: {
          label: 'value',
          label2: 'Valeur',
          type: 'text',
        },
      },
    },
  },
};

// Schema based on next-useragent
// https://github.com/tokuda109/next-useragent#parsed-objects
export const DEVICE_FIELDS_CONFIG = {
  fields: {
    isMobile: {
      type: 'boolean',
      label: 'Appareil : mobile',
    },
    isTablet: {
      type: 'boolean',
      label: 'Appareil : tablette',
    },
    isDesktop: {
      type: 'boolean',
      label: 'Appareil : desktop',
    },
    isBot: {
      type: 'boolean',
      label: 'Est un bot',
    },
    isIphone: {
      type: 'boolean',
      label: 'Est un iPhone',
    },
    isIpad: {
      type: 'boolean',
      label: 'Est un iPad',
    },
    isChrome: {
      type: 'boolean',
      label: 'Navigateur : Chrome',
    },
    isFirefox: {
      type: 'boolean',
      label: 'Navigateur : Firefox',
    },
    isSafari: {
      type: 'boolean',
      label: 'Navigateur : Safari',
    },
    isIE: {
      type: 'boolean',
      label: 'Navigateur : Internet Explorer',
    },
    isMac: {
      type: 'boolean',
      label: 'OS : Mac',
    },
    isChromeOS: {
      type: 'boolean',
      label: 'OS : ChromeOS',
    },
    isWindows: {
      type: 'boolean',
      label: 'OS : Windows',
    },
    isIos: {
      type: 'boolean',
      label: 'OS : iOS',
    },
    isAndroid: {
      type: 'boolean',
      label: 'OS : Android',
    },
  },
};

export const CONTEXT_FIELDS_CONFIG = {
  fields: {
    time: {
      type: 'datetime',
      label: 'Date',
    },
  },
};
