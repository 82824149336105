// @flow
import * as React from 'react';
import useDebouncedEffect from 'use-debounced-effect';
import { v4 as uuidv4 } from 'uuid';

import type { ResourcePropertySchema } from 'src/types';
import type { OnPropertyValueChange } from 'src/types/components/resource/propertyEditor';

import { useGlobal } from 'src/hooks';
import SchemaContext from 'src/pages/contexts/SchemaContext';
import { ResourceFormContext } from 'src/pages/components/resource/ResourceEditor/FormEditor/contexts';

import ResourceFormEditor from 'src/pages/components/resource/ResourceEditor/FormEditor';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: Object,
  subSchema?: Object,
  noTitle?: boolean,
  actions?: React.Node,
  disabled?: boolean,
|};

function ObjectEditor({
  onChange: onChangeProp,
  value,
  propertySchema,
  noTitle,
  subSchema,
  disabled: disabledProp,
}: Props): React.Node {
  const [internalValue, setInternalValue] = React.useState(value || {});
  const { globalSchema } = useGlobal();
  const { objectType, schema } = propertySchema;
  const subResourceType = Array.isArray(objectType)
    ? objectType[0]
    : objectType;

  // isDefinedServerSide serves to declare a schema property as not editable in schema edition
  // TODO improve this by serializing server side to "disabled" and handle more generally "disabled" in property editor
  const disabled = disabledProp || internalValue.isDefinedServerSide;

  // Get linked resource schema
  const subResourceSchema =
    schema ||
    (subResourceType ? globalSchema[subResourceType] : propertySchema);
  const schemaContextValue = {
    schema: subResourceSchema,
    path: '',
  };

  React.useEffect(() => {
    if (value) return;
    if (!subResourceSchema.propertiesList) return;

    onChange(
      Object.fromEntries([
        ['_id', uuidv4()],
        ['_cls', subResourceSchema._id],
        ...(subResourceSchema.propertiesList || []).map((property) => [
          property.key,
          property.default,
        ]),
      ])
    );
  }, []);

  const onChange = React.useCallback(
    (valueShape) => {
      setInternalValue((previousValue) => ({
        ...previousValue,
        ...valueShape,
      }));
    },
    [setInternalValue]
  );

  useDebouncedEffect(() => onChangeProp(internalValue), 500, [internalValue]);

  if (!subResourceSchema.propertiesList) return null;

  return (
    <div className="object-property-editor">
      {/* TODO: Display object and link to edition */}
      {/* $FlowIgnore */}
      <SchemaContext.Provider value={schemaContextValue}>
        <ResourceFormContext.Provider value={{ enableRouterTabs: false }}>
          <ResourceFormEditor
            resource={internalValue}
            onChange={onChange}
            disabled={disabled}
          />
        </ResourceFormContext.Provider>
      </SchemaContext.Provider>
    </div>
  );
}

export default (React.memo(ObjectEditor): React.ComponentType<Props>);
