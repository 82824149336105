import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import InstanceStore from 'src/stores/InstanceStore';
import API from 'src/helpers/api/API';
import ComponentBase from 'src/utils/ComponentBase';

import { OldMultiFormElements, Switch, StatsGraph, Icon } from 'src/components';

export default class VideoServersMonitoringTab extends ComponentBase {
  store = InstanceStore;

  didMount() {
    API.get('global/data/VideoServer').then((servers) =>
      this.setState({ servers })
    );

    this.setTimer(
      () => {
        API.get('videoServersStatus').then((res) => {
          this._status = _.keyBy(res, '_id');
        });
      },
      1000,
      true
    );

    this.setTimer(
      () => {
        API.get(
          'videoServersHistory', // only for online servers
          { limit: this.state.shortTime && 120 }
        ).then((history) =>
          this.setState({
            history,
            status: this._status,
          })
        );
      },
      1000,
      true
    );
  }

  render() {
    let { servers, history, status, shortTime, stacked, realTimeTarget } =
      this.state;

    const getStatus = (server, field, def) =>
      _.get(status, field ? [server._id, field] : [server._id], def);

    let isNormal = _.every(
      servers,
      (s) => getStatus(s, 'alertLevel') === 0 && getStatus(s, 'online')
    );

    let totalWeight = _.sumBy(
      _.filter(servers, (s) => getStatus(s, 'alertLevel') === 0),
      (s) => _.get(s, 'weight', 1)
    );

    let totalConnByWeight;
    if (!realTimeTarget) {
      const lastEntry = _.last(history);
      totalConnByWeight =
        lastEntry &&
        _.sumBy(_.values(lastEntry.servers), '_s.connections') /
          _.sumBy(servers, (s) => _.get(s, 'weight', 1));
    }

    return (
      <div>
        <OldMultiFormElements>
          <Switch
            preLabel="5 min"
            label="2 min"
            value={shortTime}
            onChange={(shortTime) => this.setState({ shortTime })}
          />
          <Switch
            label="Empiler"
            value={stacked}
            onChange={(stacked) => this.setState({ stacked })}
          />
          <Switch
            preLabel="Répartition : temps réel"
            label="historique"
            value={realTimeTarget}
            onChange={(realTimeTarget) => this.setState({ realTimeTarget })}
          />
        </OldMultiFormElements>

        {history &&
          _.map(servers, (s) => (
            <div key={s._id}>
              <br />
              <h3>
                {s.name || s.baseUrl}
                {status &&
                  status[s._id] &&
                  !_.isNil(status[s._id].alertLevel) && (
                    <>
                      <span> - Niveau d'alerte : </span>
                      <span
                        className={'alert-level--' + status[s._id].alertLevel}
                      >
                        {status[s._id].alertLevel === 0 && (
                          <Icon name="thermometer-empty" />
                        )}
                        {status[s._id].alertLevel === 1 && (
                          <Icon name="thermometer-quarter" />
                        )}
                        {status[s._id].alertLevel === 2 && (
                          <Icon name="thermometer-half" />
                        )}
                        {status[s._id].alertLevel === 3 && (
                          <Icon name="thermometer-three-quarters" />
                        )}
                        {status[s._id].alertLevel >= 4 && (
                          <Icon name="thermometer-full" />
                        )}
                        {' ' + status[s._id].alertLevel}
                      </span>
                    </>
                  )}
              </h3>
              <br />

              <StatsGraph
                chartType={'Gauge'}
                columns={[
                  {
                    type: 'string',
                    label: 'label',
                  },
                  {
                    type: 'number',
                    label: 'value',
                  },
                ]}
                rows={[
                  ['CPU', (getStatus(s, 'cpuLoad', 0) * 100).toPrecision(3)],
                  ['RAM', (getStatus(s, 'memoryLoad', 0) * 100).toPrecision(3)],
                  ['Swap', (getStatus(s, 'swapLoad', 0) * 100).toPrecision(3)],
                  ...(s.bandwidth
                    ? [
                        [
                          'BP',
                          (
                            (getStatus(s, 'outRate', 0) * 100) /
                            (s.bandwidth * 1024 * 1024)
                          ).toPrecision(3),
                        ],
                      ]
                    : []),
                ]}
                height="120px"
                options={{
                  yellowFrom: 80,
                  yellowTo: 95,
                  redFrom: 95,
                  redTo: 100,
                  minorTicks: 5,
                }}
              />

              <StatsGraph
                title={s.name + ' - connexions'}
                chartType={'Line'}
                columns={[
                  {
                    type: 'string',
                    label: '',
                  },
                  {
                    type: 'number',
                    label: 'répartition idéale',
                  },
                  {
                    type: 'number',
                    label: stacked
                      ? 'connexions actuelles + prévisionnelles'
                      : 'connexions prévisionnelles',
                  },
                  {
                    type: 'number',
                    label: 'connexions réelles',
                  },
                ]}
                rows={_.map(history, (h) => [
                  moment.unix(h.ts).format('H:m:s'),
                  isNormal
                    ? realTimeTarget
                      ? (_.sumBy(_.values(h.servers), '_s.connections') *
                          (s.weight || 1)) /
                        totalWeight
                      : totalConnByWeight * (s.weight || 1)
                    : null,

                  stacked
                    ? _.get(h.servers, [s._id, '_s', 'connections'], 0) +
                      _.get(h.servers, [s._id, '_s', 'newConnections'], 0)
                    : _.get(h.servers, [s._id, '_s', 'newConnections'], 0),

                  _.get(h.servers, [s._id, '_s', 'connections'], 0),
                ])}
                height="300px"
                options={{
                  animation: null,
                  series: {
                    0: {
                      color: '#a8a8a8',
                      lineDashStyle: [3, 3],
                      lineWidth: 3,
                    },
                  },
                }}
              />
            </div>
          ))}
      </div>
    );
  }
}
