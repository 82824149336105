export const BULMA_SIZE_OPTION_SCHEMA = {
  key: 'bulmaSize',
  label: 'Taille',
  type: 'enum',
  default: 'normal',
  values: [
    {
      value: 'tiny',
      label: 'minuscule',
    },
    {
      value: 'small',
      label: 'petit',
    },
    {
      value: 'normal',
      label: 'normal',
    },
    {
      value: 'large',
      label: 'large',
    },
  ],
  group: 'Style',
};

export const BULMA_BUTTON_STYLE_OPTION_SCHEMA = [
  {
    key: 'isButton',
    label: 'Est un bouton',
    type: 'bool',
    default: false,
    group: 'Style',
  },
  {
    key: 'bulmaStyle',
    label: 'Style Bulma',
    type: 'enum',
    multiple: true,
    description:
      'Voir sur https://bulma.io/documentation/elements/button/#styles',
    values: [
      { value: null, label: 'Normal' },
      { value: 'isOutlined', label: 'Outlined' },
      { value: 'isInverted', label: 'Inversé' },
      {
        value: 'isRounded',
        label: 'Arrondi',
      },
      {
        value: 'isText',
        label: 'Comme un texte',
      },
      {
        value: 'isGhost',
        label: 'Comme un lien',
      },
    ],
    required: false,
    group: 'Style',
    condition: { f$: 'obj?.isButton' },
  },
  {
    key: 'isMultiline',
    label: 'Sur plusieurs lignes',
    type: 'bool',
    default: false,
    group: 'Style',
    condition: { f$: 'obj?.isButton' },
  },
];

const extendComponent = (component, options = {}) => {
  const { propertyListKey = 'optionsSchema' } = options;

  let extendedOptionsSchema = [];

  if (component.hasBulmaSizeOption)
    extendedOptionsSchema.push(BULMA_SIZE_OPTION_SCHEMA);

  if (component.hasBulmaButtonOption) {
    extendedOptionsSchema = [
      ...extendedOptionsSchema,
      ...BULMA_BUTTON_STYLE_OPTION_SCHEMA,
    ];
  }

  return {
    ...component,
    [propertyListKey]: [
      ...(component[propertyListKey] || []),
      ...extendedOptionsSchema,
    ],
  };
};

export default extendComponent;
