// @flow
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { useGlobal, usePagination } from 'src/hooks';
import { getResourcePath } from 'src/helpers/url';

import {
  Table,
  THead,
  TBody,
  Th,
  Tr,
  Td,
  Text,
  Icon,
  EmptyState,
  Notification,
  Select,
  Field,
  Control,
} from 'src/components';
import Paginator from 'src/pages/components/Paginator';

type RowInfoType = {|
  warnings: Array<any>,
  errors: Array<any>,
|};

type Props = {|
  result: {
    count: number,
    issues: Array<RowInfoType>,
  },
|};

const lignColumnStyle = { minWidth: 50, maxWidth: 50, width: 50 };

export default function RowInfos({ result }: Props): React.Node {
  const { issues: allRowInfos } = result;

  const {
    page,
    setPage,
    countPerPage,
    setCountPerPage,
    reset: resetPagination,
  } = usePagination({
    countPerPage: 50,
    page: 0,
  });
  const [selectedProblemType, setSelectedProblemTypeState] =
    React.useState(null);

  const setSelectedProblemType = React.useCallback(
    (newTypeOption) => {
      setSelectedProblemTypeState(newTypeOption);
      resetPagination();
    },
    [setSelectedProblemTypeState, resetPagination]
  );

  const {
    rowInfos: allErroredRowInfos,
    errorCount,
    errorLines,
    warningCount,
    warningLines,
    errorsByTypes,
    errorTypeLabels,
    warningsByTypes,
    warningTypeLabels,
  } = React.useMemo(() => {
    const filteredRowInfos = [];
    const errorsByTypes = {};
    const warningsByTypes = {};
    const errorTypeLabels = {};
    const warningTypeLabels = {};
    let errorCount = 0,
      errorLines = 0,
      warningCount = 0,
      warningLines = 0;

    allRowInfos.forEach((row, index) => {
      if (row.errors.length === 0 && row.warnings.length === 0) return;

      filteredRowInfos.push({
        ...row,
        lignNumber: index + 2,
      });
      errorCount += row.errors.length;
      errorLines += row.errors.length > 0 ? 1 : 0;
      warningCount += row.warnings.length;
      warningLines += row.warnings.length > 0 ? 1 : 0;

      for (const error of row.errors) {
        errorsByTypes[error.code] = errorsByTypes[error.code] || [];
        errorsByTypes[error.code].push({
          ...row,
          lignNumber: index + 2,
        });
        errorTypeLabels[error.code] =
          errorTypeLabels[error.code] || error.message;
      }

      for (const warning of row.warnings) {
        warningsByTypes[warning.code] = warningsByTypes[warning.code] || [];
        warningsByTypes[warning.code].push({
          ...row,
          lignNumber: index + 2,
        });
        warningTypeLabels[warning.code] =
          warningTypeLabels[warning.code] || warning.message;
      }
    });

    return {
      rowInfos: filteredRowInfos,
      errorCount,
      errorLines,
      warningCount,
      warningLines,
      errorsByTypes,
      warningsByTypes,
      errorTypeLabels,
      warningTypeLabels,
    };
  }, [allRowInfos]);

  if (allErroredRowInfos.length === 0)
    return (
      <div className="row-infos">
        <Text isTitle size={4} element="h4">
          Rapport ({allRowInfos.length} lignes)
        </Text>
        <EmptyState
          title="Aucun problème n'a été détecté"
          icon="check"
          style={{ padding: '80px 20px' }}
        />
      </div>
    );

  const rowInfosToDisplay = selectedProblemType
    ? errorsByTypes[selectedProblemType.value] ||
      warningsByTypes[selectedProblemType.value]
    : allErroredRowInfos;

  const displayedRowInfos = rowInfosToDisplay.slice(
    page * countPerPage,
    (page + 1) * countPerPage
  );

  return (
    <div className="row-infos">
      <Text isTitle size={4} element="h4">
        Rapport ({allRowInfos.length} lignes)
      </Text>

      <Notification color="warning">
        <p style={{ marginBottom: 10 }}>
          Des problèmes ont été trouvés dans votre export. Vous trouverez
          ci-dessous un compte-rendu ligne par ligne
        </p>

        <ul className="bullet-list">
          <li>
            {errorCount} erreurs ont été trouvées ({errorLines} lignes
            concernées)
          </li>
          <li>
            {warningCount} avertissements ont été trouvés ({warningLines} lignes
            concernées)
          </li>
        </ul>

        {Object.keys(errorsByTypes).length > 0 && (
          <div className="error-types" style={{ marginTop: 20 }}>
            <Text isTitle size={6} element="h4">
              Détail des erreurs, par type
            </Text>

            <ul className="bullet-list">
              {Object.keys(errorsByTypes).map((errorType) => (
                <li>
                  <b>{errorTypeLabels[errorType]}</b> {'=> '}
                  {errorsByTypes[errorType].length} trouvées
                </li>
              ))}
            </ul>
          </div>
        )}

        {Object.keys(warningsByTypes).length > 0 && (
          <div className="error-types" style={{ marginTop: 20 }}>
            <Text isTitle size={6} element="h4">
              Détail des avertissements, par type
            </Text>

            <ul className="bullet-list">
              {Object.keys(warningsByTypes).map((warningType) => (
                <li>
                  <b>{warningTypeLabels[warningType]}</b> {'=> '}
                  {warningsByTypes[warningType].length} trouvées
                </li>
              ))}
            </ul>
          </div>
        )}
      </Notification>

      <Paginator
        page={page}
        setPage={setPage}
        countPerPage={countPerPage}
        setCountPerPage={setCountPerPage}
        loading={false}
        count={rowInfosToDisplay.length}
        error={null}
      >
        <Table scrollable>
          <THead>
            <Tr>
              <Th style={lignColumnStyle}>
                <div style={{ width: 100 }}>Numéro de ligne</div>
              </Th>
              <Th>
                <Text color="danger">
                  <Icon
                    name="exclamation-triangle"
                    style={{ marginRight: 5 }}
                  />
                </Text>
                {errorCount} Erreurs
                {Object.keys(errorsByTypes).length > 0 && (
                  <Field style={{ marginTop: 5 }}>
                    <Control>
                      <Select
                        isClearable
                        value={
                          selectedProblemType &&
                          errorsByTypes[selectedProblemType.value]
                            ? selectedProblemType
                            : null
                        }
                        options={Object.keys(errorsByTypes).map(
                          (errorType) => ({
                            value: errorType,
                            label: `${errorTypeLabels[errorType]} (${errorsByTypes[errorType].length})`,
                          })
                        )}
                        onChange={setSelectedProblemType}
                        placeholder="Filter par type d'erreur"
                      />
                    </Control>
                  </Field>
                )}
              </Th>
              <Th>
                <Text color="warning">
                  <Icon
                    name="exclamation-triangle"
                    style={{ marginRight: 5 }}
                  />
                </Text>
                {warningCount} Avertissements
                {Object.keys(warningsByTypes).length > 0 && (
                  <Field style={{ marginTop: 5 }}>
                    <Control>
                      <Select
                        isClearable
                        value={
                          selectedProblemType &&
                          warningsByTypes[selectedProblemType.value]
                            ? selectedProblemType
                            : null
                        }
                        options={Object.keys(warningsByTypes).map(
                          (warningType) => ({
                            value: warningType,
                            label: `${warningTypeLabels[warningType]} (${warningsByTypes[warningType].length})`,
                          })
                        )}
                        onChange={setSelectedProblemType}
                        placeholder="Filter par type d'avertissement"
                      />
                    </Control>
                  </Field>
                )}
              </Th>
            </Tr>
          </THead>

          <TBody>
            {displayedRowInfos.map((rowInfo, index) => (
              <RowInfo
                key={page * countPerPage + index}
                lignNumber={rowInfo.lignNumber}
                info={rowInfo}
              />
            ))}
          </TBody>
        </Table>
      </Paginator>
    </div>
  );
}

function RowInfo({ info, lignNumber }) {
  const { siteId, globalSchema } = useGlobal();

  return (
    <Tr>
      <Td style={lignColumnStyle}>
        <div style={{ width: 100 }}>{lignNumber}</div>
      </Td>
      <Td>
        {info.errors.length > 0 ? (
          <ul className="bullet-list">
            {info.errors.map((error, i) => {
              const { message, resource } = error;
              const resourceSchema = resource
                ? globalSchema[resource._cls]
                : null;

              return (
                <li key={i}>
                  {resource && resourceSchema && (
                    <NavLink
                      to={getResourcePath(siteId, resourceSchema, resource._id)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${resource._cls} - ${resource._id}`}
                      <Icon
                        name="external-link-alt"
                        style={{ marginLeft: 5 }}
                        size="small"
                      />
                    </NavLink>
                  )}
                  <div>
                    {resource && resourceSchema ? ' => ' : ''}
                    {message}
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <Text color="grey-light" transformation="italic" size={7}>
            Pas d'erreur
          </Text>
        )}
      </Td>
      <Td>
        {info.warnings.length > 0 ? (
          <ul className="bullet-list">
            {info.warnings.map((warning, i) => {
              const { message, resource } = warning;
              const resourceSchema = resource
                ? globalSchema[resource._cls]
                : null;

              return (
                <li key={i}>
                  {resource && resourceSchema && (
                    <NavLink
                      to={getResourcePath(siteId, resourceSchema, resource._id)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${resource._cls} - ${resource._id}`}
                      <Icon
                        name="external-link-alt"
                        style={{ marginLeft: 5 }}
                        size="small"
                      />
                    </NavLink>
                  )}
                  <p>
                    {resource && resourceSchema ? ' => ' : ''}
                    {message}
                  </p>
                </li>
              );
            })}
          </ul>
        ) : (
          <Text color="grey-light" transformation="italic" size={7}>
            Pas d'avertissement
          </Text>
        )}
      </Td>
    </Tr>
  );
}
