const ImagePropertySchema = [
  {
    key: 'acceptedRatio',
    label: 'Ratio accepté',
    type: 'enum',
    group: 'Général',
    values: [
      {
        value: '2:3',
        label: '2:3',
      },
      {
        value: '1:1',
        label: '1:1',
      },
      {
        value: '16:9',
        label: '16:9',
      },
    ],
    description: "Ratio accepté pour l'image",
  },
  {
    key: 'maxSize',
    label: 'Taille maximale du fichier (en Ko)',
    type: 'int',
    group: 'Général',
  },
  {
    key: 'outputFormats',
    label: 'Format de sortie',
    type: 'enum',
    group: 'Général',
    default: 'jpeg',
    multiple: true,
    values: [
      {
        value: 'jpeg',
        label: 'jpeg',
      },
      {
        value: 'png',
        label: 'png',
      },
    ],
  },
];

export default ImagePropertySchema;
