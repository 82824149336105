// @flow
import * as React from 'react';
import { isEqual, isObject } from 'lodash';
import { useSetRecoilState } from 'recoil';

import type { Resource } from 'src/types/models/schema';

import { getSimpleObjectDiff } from 'src/helpers/debug';
import { notificationCenterState, forceNotification } from 'src/recoil/atoms';
import { isGarbageFromDraftEditor } from 'src/helpers/misc';
import { useResourceContext, useHistoryStack } from 'src/hooks';
import {
  ResourceConditionContext,
  ResourceStableFieldsContext,
} from 'src/pages/contexts/ResourceContext';
import { ResourceFormContext } from 'src/pages/components/resource/ResourceEditor/FormEditor/contexts';

import { Notification } from 'src/components';
import JsonEditor from 'src/pages/components/JsonEditor';
import FormEditor from './FormEditor';
import Footer from './Footer';

type Props = {|
  onDuplicate?: (Resource) => any,
  onCancel?: () => any,
  onClose?: () => any, // Duplicate with onCancel ?
  isNew?: boolean,
  disabled?: boolean,
|};

export default function ResourceEditor({
  onCancel,
  onClose,
  onDuplicate,
  isNew,
  disabled,
}: Props): React.Node {
  const { resource, connector, schema, dirty, setDirty } = useResourceContext();
  const originalResource = React.useRef(resource);
  const lastSavedResource = React.useRef(resource);
  const [editJson, setEditJson] = React.useState(false);
  const [editedResource, setEditedResourceState] = React.useState(resource);
  const resourceStableFieldsContextValue = React.useRef({
    _cls: resource._cls,
  });
  const {
    historyStackLength,
    historyStackIndex,
    setHistoryStackIndex,
    setState: setEditedResource,
  } = useHistoryStack([editedResource], setEditedResourceState);
  const setNotificationCenter = useSetRecoilState(notificationCenterState);
  const [saving, setSaving] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const canRestoreDaft = !isEqual(resource, originalResource.current);
  const isResourcePersisted = !isNew;
  const resourceHasChanged = !isEqual(editedResource, resource);

  React.useEffect(() => {
    setEditedResourceState(resource);
  }, [resource]);

  React.useEffect(() => {
    setDirty && setDirty(resourceHasChanged);
  }, [resourceHasChanged]);

  const saveResource = React.useCallback(
    async (newResource: Resource) => {
      if (!dirty) return editedResource;

      try {
        setSaving(true);
        const objectToSave = isNew
          ? newResource
          : getSimpleObjectDiff(lastSavedResource.current, newResource);
        // For diff debug
        // console.log(diff, originalResource.current, newResource);

        const savedResource = await connector.save(objectToSave);
        lastSavedResource.current = savedResource;
        return savedResource;
      } catch (e) {
        if (e.errors) {
          setNotificationCenter(
            forceNotification({
              id: `${e.code}-${e.message}`,
              type: 'danger',
              text: (
                <div>
                  <p style={{ marginBottom: 10 }}>
                    Le document ne peut être sauvegardé car il est invalide.
                  </p>
                  <ul className="bullet-list">
                    {Object.keys(e.errors).map((fieldName) => {
                      const fieldError = e.errors[fieldName];
                      return (
                        <li>
                          {fieldName} {'=>'} {fieldError.message}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ),
              time: 3000,
            })
          );
        } else {
          setNotificationCenter(
            forceNotification({
              id: `${e.code}-${e.message}`,
              type: 'danger',
              text: e.message,
              time: 3000,
            })
          );
        }

        throw e;
      } finally {
        setSaving(false);
      }
    },
    [connector.save, dirty, setSaving, lastSavedResource.current]
  );

  const canDelete = isResourcePersisted;

  const deleteResource = React.useCallback(async () => {
    if (!canDelete || deleting) return;

    if (window.confirm('Êtes-vous sûr de vouloir supprimer ?')) {
      try {
        setDeleting(true);
        return connector.destroy();
      } catch (e) {
        setNotificationCenter(
          forceNotification({
            id: `${e.code}-${e.message}`,
            type: 'danger',
            text: e.message,
            time: 3000,
          })
        );
        throw e;
      } finally {
        setDeleting(false);
      }
    }
  }, [connector.destroy, canDelete, setDeleting]);

  const description =
    !!schema.description &&
    !isGarbageFromDraftEditor(schema.description) &&
    (isObject(schema.description)
      ? // $FlowIgnore
        schema.description.html
      : schema.description);
  const resourceNotFoundInInstance = false;
  const hasDraftVersion = !!resource; // Probably not the right condition
  const canSave = true;
  const canDuplicate = isResourcePersisted && !!onDuplicate;

  return (
    <div className="resource-edit">
      {resourceNotFoundInInstance && (
        <Notification color="warning">
          Vous avez changé de site. Cet objet n'existe pas dans ce site et va
          donc être créé
        </Notification>
      )}

      {/* Description du schema */}
      {!!description && (
        <Notification color="info" light>
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Notification>
      )}

      <ResourceConditionContext.Provider
        value={{
          hasEntity:
            !!editedResource.objectType && editedResource.objectType.length > 0,
        }}
      >
        <ResourceStableFieldsContext.Provider
          value={resourceStableFieldsContextValue.current}
        >
          {editJson ? (
            <JsonEditor
              resource={editedResource}
              onChange={setEditedResource}
              disabled={disabled}
            />
          ) : (
            <ResourceFormContext.Provider value={{ enableRouterTabs: true }}>
              <FormEditor
                resource={editedResource}
                onChange={setEditedResource}
                disabled={disabled}
              />
            </ResourceFormContext.Provider>
          )}
        </ResourceStableFieldsContext.Provider>
      </ResourceConditionContext.Provider>

      <Footer
        resource={resource}
        jsonEditorActive={editJson}
        toggleJSON={() => setEditJson(!editJson)}
        canRestoreDaft={canRestoreDaft}
        onRestoreDraft={
          hasDraftVersion
            ? () => saveResource(originalResource.current)
            : undefined
        }
        onSave={canSave ? () => saveResource(editedResource) : undefined}
        onDuplicate={
          canDuplicate && onDuplicate ? () => onDuplicate(resource) : undefined
        }
        onDelete={canDelete ? deleteResource : undefined}
        canDelete={canDelete}
        onCancel={onCancel}
        onClose={onClose || (() => {})}
        historyStackLength={historyStackLength}
        historyStackIndex={historyStackIndex}
        setHistoryStackIndex={setHistoryStackIndex}
        isResourcePersisted={isResourcePersisted}
        dirty={!!dirty}
        saving={saving}
        schema={schema}
      />
    </div>
  );
}
