// @flow

import * as React from 'react';
import moment from 'moment';

import { FieldContext } from './contexts';

import { DatePicker } from 'src/components';

export default function DateEditor({
  propertySchema,
  onChange,
  disabled,
  value,
  withPortal,
}: Props): React.Node {
  const { min, max } = propertySchema;
  const { onFocus, onBlur } = React.useContext(FieldContext);
  const minDate = min
    ? min === 'now'
      ? moment().toDate()
      : moment(min).toDate()
    : null;
  const maxDate = max ? moment(max).toDate() : null;

  return (
    <DatePicker
      withPortal={withPortal}
      showTimeSelect
      showFullFormat
      value={value && new Date(value)}
      onChange={(newDate) => onChange(newDate)}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}
