const ProductCard = {
  _id: 'ProductCard',
  label: "Carte d'offre abonnement",
  description: "Offre d'abonnement sous forme de carte",
  icon: 'cards-blank',
  frontComponent: 'ProductCard',
  labelPath: ['title', 'price', 'duration'],
  forPage: true,
  propertiesGroups: ['Général', 'Affichage'],
  optionsSchema: [
    {
      key: 'title',
      label: 'Titre de la carte',
      type: 'string',
      required: true,
      group: 'Affichage',
    },
    {
      key: 'titleSup',
      label: 'Titre de la carte : astérisque',
      type: 'string',
      required: false,
      group: 'Affichage',
    },
    {
      key: 'subtitle',
      label: 'Sous-titre de la carte',
      type: 'string',
      required: false,
      group: 'Affichage',
    },
    {
      key: 'hasCustomPaymentOptions',
      label: 'Afficher la sélection de méthode de paiement particulière',
      type: 'bool',
      required: false,
      group: 'Affichage',
    },
    {
      key: 'defaultPaymentMethodLabel',
      condition: { f$: 'obj.hasCustomPaymentOptions' },
      label: 'Titre de la méthode de paiement par défaut',
      type: 'string',
      required: false,
      group: 'Affichage',
    },
    {
      key: 'paymentMethodOptions',
      label: 'Méthodes de paiement à afficher en plus du paiement par défaut',
      type: 'list',
      required: false,
      group: 'Affichage',
      condition: { f$: 'obj.hasCustomPaymentOptions' },
      itemSchema: {
        type: 'object',
        propertiesList: [
          {
            key: 'label',
            label: 'Titre de la méthode de paiement à afficher',
            type: 'string',
          },
          {
            key: 'value',
            label: 'Méthode de paiement',
            type: 'enum',
            values: [
              { value: 'sepa_debit', label: 'SEPA' },
              { value: 'card', label: 'CB' },
            ],
          },
        ],
      },
    },
    {
      key: 'discountMessage',
      label: 'Message de réduction',
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'amountLabelOverride',
      label:
        'Prix à afficher à la place du prix "réel" de l\'offre (en cas de coupon intégré à l\'offre',
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'additionalPerks',
      label: 'avantages supplémentaires',
      type: 'richtext',
      group: 'Affichage',
    },
    {
      key: 'footerText',
      label: 'Texte en dessous du bouton (ex: modalité de paiement)',
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'product',
      label: 'Produit',
      type: 'object_ref',
      objectType: ['SubscriptionFormula'],
      group: 'Affichage',
    },
    {
      key: 'preselectionMessage',
      label: 'Texte affiché comme si la card était pré-sélectionnée',
      type: 'string',
      group: 'Affichage',
    },
  ],
};

export default ProductCard;
