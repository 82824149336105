const EntityVideo = {
  _id: 'EntityVideo',
  label: 'Video du contenu',
  icon: 'play-circle',
  frontComponent: 'EntityVideo',
  optionsSchema: [
    {
      key: 'ratioWH',
      label: 'Ratio',
      type: 'enum',
      values: [
        { label: '16/9', value: '16-9' },
        { label: '4/3', value: '4-3' },
        { label: 'carré', value: '1-1' },
        { label: 'portrait', value: '3-4' },
        { label: 'cinemascope', value: '2_35-1' },
        { label: 'bandeau', value: '5-1' },
      ],
      group: 'Général',
    },
    {
      key: 'autoplay',
      label: 'Démarrer la lecture automatiquement (si possible)',
      type: 'bool',
      default: false,
      group: 'Général',
    },
  ],
  forEntity: true,
};

export default EntityVideo;
