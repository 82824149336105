// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { SwitchColor, SwitchSize } from 'src/components/types';
import type { Style } from '../types';

import { bulmaColorClassName, bulmaSizeClassName } from 'src/helpers/style';

import Field from './Field';
import Label from './Label';

export type Props = {|
  value: boolean,
  onChange: (boolean) => any,
  disabled?: boolean,
  id?: string,
  label?: string | React.Node,
  required?: boolean,
  tooltip?: string,
  preLabel?: string,

  isThin?: boolean,
  isRounded?: boolean,
  isOutlined?: boolean,

  color?: SwitchColor,
  size?: SwitchSize,

  additionalClassName?: string,
  additionalFieldClassName?: string,
  style?: Style,
|};

function Switch({
  value,
  onChange,
  label,
  disabled,
  tooltip,
  preLabel,
  id,
  size,
  color,
  required,
  additionalFieldClassName,
  additionalClassName,
  style,
  isThin,
  isRounded,
  isOutlined,
}: Props): React.Node {
  const className = classnames(
    'switch',
    bulmaColorClassName(color),
    bulmaSizeClassName(size),
    additionalClassName,
    {
      'is-thin': isThin,
      'is-rounded': isRounded,
      'is-outlined': isOutlined,
    }
  );

  return (
    <Field
      style={style}
      additionalClassName={classnames(
        'switch-container',
        additionalFieldClassName,
        {
          'is-undefined': value === undefined,
        }
      )}
      onClick={() => !disabled && onChange(!value)}
    >
      {!!preLabel && (
        <>
          <Label
            required={false}
            tooltip={tooltip}
            additionalClassName="pre-label"
          >
            {preLabel}
          </Label>{' '}
        </>
      )}

      <input
        type="checkbox"
        className={className}
        value={!!value}
        checked={!!value}
        id={id}
        disabled={disabled}
        readOnly
      />

      {label && (
        <Label required={required} tooltip={tooltip}>
          {label}
        </Label>
      )}
    </Field>
  );
}

Switch.defaultProps = {
  isRounded: true,
  size: 'normal',
};

export default Switch;
