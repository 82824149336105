// @flow

import * as React from 'react';
import classnames from 'classnames';

import { useGlobal } from 'src/hooks';
import { FieldContext } from './contexts';
import { getExternalReference } from 'src/pages/constants/schemas';

import {
  Label,
  Field,
  Tag,
  ErrorBoundary,
  Button,
  Control,
  QuestionMarkTooltip,
  RichTextContent,
  InformationBubble,
  Icon,
} from 'src/components';

export default function PropertyField({
  propertySchema,
  disabled,
  children,
  value,
  options = {},
}): React.Node {
  const { user } = useGlobal();
  const { key, required, label, description, toggleable, type, hint, warning } =
    propertySchema;
  const { noLabel } = options;
  const externalReference = getExternalReference(propertySchema, value);
  const { depth = 0 } = React.useContext(FieldContext);

  const [toggled, toggle] = React.useState(false);
  const editorDisplayed = !toggleable || toggled;
  const [fieldState, _setFieldState] = React.useState({
    focused: false,
    displayHint: false,
  });
  const { focused, displayHint } = fieldState;
  const setFieldState = React.useCallback(
    (shape) =>
      _setFieldState((previousState) => ({
        ...previousState,
        ...shape,
      })),
    [_setFieldState]
  );

  return (
    <FieldContext.Provider
      value={{
        ...fieldState,
        setFieldState,
        onFocus: () => setFieldState({ focused: true }),
        onBlur: () => setFieldState({ focused: false }),
        depth: depth + 1,
      }}
    >
      <Field
        additionalClassName={classnames(
          'resource-property',
          `type-${type}`,
          `${key}-property-key`,
          `depth-${depth}`,
          {
            focused,
          }
        )}
        key={key}
      >
        {!noLabel && label && (
          <Label style={{ justifyContent: 'space-between' }}>
            <div className="label-content">
              {label}
              {required ? ' *' : ''}

              {externalReference && (
                <a
                  href={externalReference}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="label-external-reference"
                  style={{ marginLeft: 5 }}
                >
                  <Icon name="external-link" />
                </a>
              )}

              {user.isDeveloper && key && (
                <Tag color="info" light style={{ marginLeft: 5 }}>
                  {key}
                </Tag>
              )}
              {disabled && (
                <Tag color="warning" light style={{ marginLeft: 5 }}>
                  Non éditable
                </Tag>
              )}

              {description && <QuestionMarkTooltip content={description} />}

              {!displayHint && !focused && hint?.html && (
                <Button
                  isText
                  additionalClassName="hint-button"
                  size="small"
                  onClick={() => setFieldState({ displayHint: true })}
                  style={{ marginLeft: 5 }}
                >
                  <Icon name="lightbulb" />
                </Button>
              )}
            </div>

            {toggleable && (
              <Button isText size="small" onClick={() => toggle(!toggled)}>
                {toggled ? 'Replier' : 'Déplier'}
              </Button>
            )}
          </Label>
        )}

        <Control>
          {editorDisplayed && <ErrorBoundary>{children}</ErrorBoundary>}
        </Control>

        {(focused || displayHint) && hint?.html && (
          <InformationBubble
            size="small"
            style={{ marginTop: 5 }}
            onHide={() => setFieldState({ displayHint: false })}
          >
            <RichTextContent richtext={hint} />
          </InformationBubble>
        )}

        {warning && (
          <InformationBubble
            size="small"
            style={{ marginTop: 5 }}
            icon="warning"
          >
            <p>{warning}</p>
          </InformationBubble>
        )}
      </Field>
    </FieldContext.Provider>
  );
}
