export const RATIOS = {
  '1:1': 1 / 1,
  '2:3': 2 / 3,
  '3:2': 3 / 2,
  '4:3': 4 / 3,
  '5:4': 5 / 4,
  '7:5': 7 / 5,
  '16:9': 16 / 9,
};

export default RATIOS;
