import contentContainerExtender from './contentContainerExtender';
import styleExtender from './styleExtender';
import markupExtender from './markupExtender';
import bulmaExtender from './bulmaExtender';
import conditionsExtender from './conditionsExtender';
import imageExtender from './imageExtender';

const extenders = [
  contentContainerExtender,
  bulmaExtender,
  markupExtender,
  styleExtender,
  conditionsExtender,
  imageExtender,
];

const NAME_OPTION_SCHEMA = {
  key: 'name',
  label: "Nom interne de l'emplacement",
  description:
    "Pour identifier cette liste dans l'admin lors de la planification des contenus. Non affiché sur le site.",
  type: 'string',
  group: 'Affichage',
  _id: '341547b2-e840-407f-91f4-d622f0ddc741',
};

const extendComponent = (component, options = {}) => {
  const { propertyListKey = 'optionsSchema' } = options;

  let extendedComponent = {
    ...component,
    [propertyListKey]: [
      NAME_OPTION_SCHEMA,
      ...(component[propertyListKey] || []),
    ],
  };

  for (const extend of extenders) {
    extendedComponent = extend(extendedComponent, options);
  }

  return extendedComponent;
};

export default extendComponent;
