// @flow
import * as React from 'react';
import { pickBy, omit, isEmpty, merge } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { shallow } from 'zustand/shallow';

import type { Resource } from 'src/types/models';
import type { Query } from 'src/types/routing';
import type { Returned as UsePaginationReturned } from 'src/hooks/states/usePagination';

import {
  useGlobal,
  useSchemaContext,
  usePagination,
  useDelayedUpdate,
} from 'src/hooks';
import { getSchemaFetchPath, getSchemaExportPath } from 'src/helpers/url';
import { buildQueryString } from 'src/helpers/misc';
import { useDataStore } from 'src/zustand';

import API from 'src/helpers/api/API';

export type SortByValue = -1 | void | 1;
export type PropertyFilterBy = { [propertyKey: string]: any };
export type PropertySortBy = { [propertyKey: string]: SortByValue };
export type PropertySetFilterBy = (
  propertyKey: string,
  activate: boolean,
  value: any
) => void;
export type PropertySetSortBy = (
  propertyKey: string,
  value: SortByValue
) => void;

type Options = {|
  query?: Query,
  countPerPage?: number,
  archived: boolean,
|};

type Returned = {|
  items: Array<Resource>,
  count: number,
  sortBy: PropertySortBy,
  filterBy: PropertyFilterBy,
  setSortBy: PropertySetSortBy,
  setFilterBy: PropertySetFilterBy,
  loading: boolean,
  error: null | string,
  refetch: (params: Object) => Promise<any>,
  exportUrl: string,
  ...$Diff<UsePaginationReturned, { fetchSearch: string }>,
|};

const DEFAULT_QUERY = {
  filterBy: {},
  sortBy: {},
  search: '',
  countPerPage: 20,
  page: 0,
};

export default function useResourcePaginatedData(options: ?Options): Returned {
  const { schema } = useSchemaContext();
  const { siteId } = useGlobal();

  const { query: providedQuery = {}, archived } = options || {};

  const [sortBy, _setSortBy] = React.useState<PropertySortBy>(
    DEFAULT_QUERY.sortBy
  );
  const [filterBy, _setFilterBy] = React.useState<PropertyFilterBy>(
    DEFAULT_QUERY.filterBy
  );

  const [debouncedFilterBy, setDebouncedFilterBy] = useDelayedUpdate(
    filterBy,
    _setFilterBy
  );

  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [count, setCount] = React.useState(0);
  const [exportUrl, setExportUrl] = React.useState('');
  const paginatorId = React.useMemo(uuidv4);
  const [mergeReferences, mergeReferencesFromList] = useDataStore(
    (state) => [state.merge, state.mergeFromList],
    shallow
  );

  const defaultSortBy = React.useMemo(() => {
    return { createdAt: -1, _id: -1 };
  }, [schema]);

  const additionalFetchParams = React.useMemo(() => {
    const usedSortBy = !isEmpty(pickBy(sortBy)) ? sortBy : defaultSortBy;
    const usedFilterBy = pickBy(filterBy);
    return {
      sortBy: usedSortBy,
      adminFilter: merge({}, providedQuery?.filterBy || {}, usedFilterBy),
    };
  }, [
    JSON.stringify(pickBy(sortBy)),
    JSON.stringify(pickBy(filterBy)),
    JSON.stringify(providedQuery),
  ]);

  const {
    search,
    setSearch,
    fetchSearch,
    page,
    setPage,
    countPerPage,
    setCountPerPage,
    reset,
  } = usePagination({
    countPerPage: DEFAULT_QUERY.countPerPage,
    search: DEFAULT_QUERY.search,
    page: DEFAULT_QUERY.page,
  });

  const setSortBy = React.useCallback(
    (propertyKey: string, value: SortByValue) => {
      _setSortBy((previousSortBy) => ({
        ...previousSortBy,
        [propertyKey]: value,
      }));
    },
    []
  );

  const setFilterBy = React.useCallback(
    (propertyKey: string, activate: boolean, value: any) => {
      if (!activate) {
        setDebouncedFilterBy((previousFilterBy) =>
          omit(previousFilterBy, propertyKey)
        );
        return;
      }

      setDebouncedFilterBy((previousFilterBy) => ({
        ...previousFilterBy,
        [propertyKey]: value,
      }));
    },
    []
  );

  const fetch = React.useCallback(
    async (params = {}) => {
      const fetchUrl = getSchemaFetchPath(siteId, schema);

      const queryParams = {
        ...additionalFetchParams,
        adminTable: true,
        withCount: true,
        offset: (page || 0) * countPerPage,
        limit: countPerPage,
        adminSearch: fetchSearch,
        archived,
        serialize: true,
        ...params,
      };

      try {
        setLoading(true);

        const result = await API.get(fetchUrl, queryParams, paginatorId);

        setExportUrl(
          `${API.ADMIN_API_URL}/${getSchemaExportPath(
            siteId,
            schema
          )}?${buildQueryString({ ...queryParams, format: 'csv' })}`
        );
        mergeReferences(result.references || {});
        mergeReferencesFromList(result.data);
        setData(result.data || []);
        setCount(result.count || 0);
        setError(null);
        return ((result.data: any): Array<Resource>);
      } catch (error) {
        setData([]);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [schema, fetchSearch, page, countPerPage, additionalFetchParams, archived]
  );

  React.useEffect(() => {
    setPage(0);
  }, [archived, fetchSearch, countPerPage]);

  React.useEffect(() => {
    fetch();
  }, [
    schema,
    fetchSearch,
    page,
    countPerPage,
    additionalFetchParams,
    archived,
  ]);

  return {
    items: data,
    count,
    sortBy,
    setSortBy,
    filterBy: debouncedFilterBy,
    setFilterBy,
    error,
    loading,
    search,
    setSearch,
    page,
    setPage,
    countPerPage,
    setCountPerPage,
    refetch: fetch,
    reset,
    exportUrl,
  };
}
