import { HTML_ELEMENT_OPTION_SCHEMAS } from './markupExtender';

const MODE_PROPERTY = {
  key: 'mode',
  label: "Mode d'affichage",
  type: 'enum',
  dataSource: {
    resource: 'ComplexComponent',
    valuePath: 'mode',
    labelPath: 'label',
    query: {
      limit: 0,
      adminFilter: { objectType: { $exists: true, $not: { $size: 0 } } },
    },
  },
  group: 'Contenus',
  _id: '341547b2-e840-407f-91f4-d622f0ddc740',
};

const CONTAINER_SHARED_OPTIONS_SCHEMA = [
  {
    key: 'label',
    label: 'Titre de la liste',
    description: 'Sera affiché sur le site',
    type: 'string',
    group: 'Affichage',
    _id: '341547b2-e840-407f-91f4-d622f0ddc742',
  },
  {
    key: 'labelIfMultiple',
    label: 'Titre de la liste si plusieurs',
    type: 'string',
    group: 'Affichage',
    _id: '341547b2-e840-407f-91f4-d622f0ddc743',
  },

  {
    key: 'labelClassName',
    label: 'Classe HTML à ajouter au titre',
    type: 'string',
    group: 'Style',
  },
  { ...HTML_ELEMENT_OPTION_SCHEMAS, key: 'LabelElement' },

  {
    key: 'pageId',
    label: 'Lien vers une page sur le titre de la liste',
    type: 'object_id',
    objectType: 'Page',
    condition: { f$: 'obj.label' },
    _id: '341547b2-e840-407f-91f4-d622f0ddc744',
    group: 'Affichage',
  },
  MODE_PROPERTY,
  {
    ...MODE_PROPERTY,
    key: 'alternateMode',
    label: "Mode d'affichage alternatif (1 contenu sur 2)",
    _id: '341547b2-e840-407f-91f4-d622f0ddc7400',
  },
  {
    key: 'itemCount',
    label: 'Nombre de contenus max au total',
    type: 'int',
    _id: '341547b2-e840-407f-91f4-d622f0ddc747',
    group: 'Contenus',
  },
  {
    key: 'list',
    type: 'DiffusionList',
    label: 'Contenus à afficher',
    // TODO max: 1
    required: true,
    preview: true,
    group: 'Contenus',
    _id: '341547b2-e840-407f-91f4-d622f0ddc748',
  },
  {
    key: 'allowDuplicates',
    label:
      'Autoriser la liste à afficher des contenus déjà affichés précédemment',
    type: 'bool',
    default: false,
    group: 'Contenus',
    _cls: 'ObjectPropertySchema',
    _id: '341547b2-e840-407f-91f4-d622f0ddc749',
  },
  {
    key: 'enableLoadMore',
    label:
      'Autoriser le chargement additionnel de contenus (rajoute un bouton "Charger plus")',
    type: 'bool',
    default: false,
    _id: '341547b2-e840-407f-91f4-d622f0ddc750',
    group: 'Contenus',
  },
  {
    key: 'loadMoreLabel',
    label: 'Texte du bouton permettant de charger plus de contenus',
    type: 'string',
    group: 'Affichage',
  },
  {
    _cls: 'ObjectPropertySchema',
    key: 'contextualCategories',
    label:
      'Les contenus affichés ne doivent pas afficher les catégories suivantes, qui sont sous-entendues',
    default: false,
    type: 'enum',
    group: 'Avancé',
    multiple: true,
    dataSource: {
      resource: 'Category',
      valuePath: '_id',
      labelPath: 'label',
      query: { limit: 0 },
    },
    _id: '341547b2-e840-407f-91f4-d622f0ddc745',
  },
  {
    label:
      'Les contenus affichés ne doivent pas afficher les tags suivantes, qui sont sous-entendues',
    _cls: 'ObjectPropertySchema',
    key: 'contextualTags',
    default: false,
    type: 'enum',
    group: 'Avancé',
    multiple: true,
    dataSource: {
      resource: 'Tag',
      valuePath: '_id',
      labelPath: 'label',
      query: { limit: 0 },
    },
    _id: '341547b2-e840-407f-91f4-d622f0ddc746',
  },
];

const propertiesGroups = ['Contenus', 'Affichage', 'Avancé', 'Style'];

const extendComponent = (component, options = {}) => {
  const { propertyListKey = 'optionsSchema' } = options;

  if (!component.isContentContainer) return component;

  if (component.hasSingleContent) {
    return {
      ...component,
      propertiesGroups,
      [propertyListKey]: [
        ...(component[propertyListKey] || []),
        ...CONTAINER_SHARED_OPTIONS_SCHEMA.filter((property) =>
          [
            'label',
            'contextualCategories',
            'contextualTags',
            'mode',
            'list',
          ].includes(property.key)
        ),
      ],
    };
  }

  return {
    ...component,
    propertiesGroups,
    optionsSchema: [
      ...CONTAINER_SHARED_OPTIONS_SCHEMA,
      ...component.optionsSchema,
    ],
    forPage: true,
    hasEntities: true,
  };
};

export default extendComponent;
