export const HTML_ELEMENT_OPTION_SCHEMAS = {
  _cls: 'ObjectPropertySchema',
  required: false,
  readOnly: false,
  createOnly: false,
  unique: false,
  sortable: false,
  filterable: false,
  searchable: false,
  key: 'Element',
  label: 'Élément HTML',
  type: 'enum',
  values: [
    {
      value: 'h1',
      label: 'h1',
    },
    {
      value: 'h2',
      label: 'h2',
    },
    {
      value: 'h3',
      label: 'h3',
    },
    {
      value: 'h4',
      label: 'h4',
    },
    {
      value: 'h5',
      label: 'h5',
    },
    {
      value: 'h6',
      label: 'h6',
    },
    {
      value: 'p',
      label: 'p',
    },
    {
      value: 'div',
      label: 'div',
    },
    {
      value: 'span',
      label: 'span',
    },
    {
      value: 'section',
      label: 'section',
    },
    {
      value: 'article',
      label: 'article',
    },
    {
      value: 'default',
      label: 'Par défaut',
    },
  ],
  group: 'Markup',
};

const extendComponent = (component, options = {}) => {
  const { propertyListKey = 'optionsSchema' } = options;

  if (!component.hasHTMLMarkup) return component;

  return {
    ...component,
    [propertyListKey]: [
      ...(component[propertyListKey] || []),
      HTML_ELEMENT_OPTION_SCHEMAS,
    ],
  };
};

export default extendComponent;
