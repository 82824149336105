// @flow
import * as React from 'react';

import type { Resource, ResourceSchema } from 'src/types/models';
import type {
  ActionProps,
  CustomAction,
} from 'src/types/components/pages/resourceSpecific';

import { Icon, Button } from 'src/components';
import ModerateActions from './ModerateActions';

export default function getItemActions(
  schema: ResourceSchema,
  resource: Resource
): Array<CustomAction> {
  let customActions = [];

  if (schema.isContent && schema.hasViewRecording) {
    customActions = [...customActions, ViewStatisticsAction];
  }

  if (resource.frontendUrl)
    customActions = [...customActions, LinkToFrontendAction];

  switch (schema._id) {
    case 'UserComment':
      customActions.push(ModerateActions);
      break;
    default:
      break;
  }

  return customActions;
}

function ViewStatisticsAction({ basePath, resource }: ActionProps) {
  return (
    <Button
      to={resource.views ? `${basePath}/${resource._id}/statistics` : ''}
      style={{ marginRight: 5 }}
      size="small"
      isLight
      color="info"
      disabled={!resource.views}
    >
      <Icon name="chart-line" />
    </Button>
  );
}

function LinkToFrontendAction({ basePath, resource }: ActionProps) {
  if (!resource.frontendUrl) return null;

  return (
    <Button
      href={resource.frontendUrl}
      size="small"
      isGhost
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="arrow-up-right-from-square" />
    </Button>
  );
}
