import * as React from 'react';
import { useCopyToClipboard } from 'usehooks-ts';

import Button from './Button';
import Icon from './Icon';
import Tooltip from './Tooltip';

export default function CopyButton({ textToCopy, style }) {
  const [copied, setCopied] = React.useState(false);
  const [, copyToClipboard] = useCopyToClipboard();

  const copyText = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setCopied(true);
      copyToClipboard(textToCopy);

      setTimeout(() => {
        setCopied(false);
      }, 3000);
    },
    [copyToClipboard, setCopied, textToCopy]
  );

  return (
    <Tooltip visible={copied} overlay="Lien copié !" trigger={[]}>
      <Button onClick={copyText} size="small" style={style}>
        <Icon name="copy" type={copied ? 'solid' : 'regular'}></Icon>
      </Button>
    </Tooltip>
  );
}
