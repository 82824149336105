const EntityProperty = {
  _id: 'field',
  label: 'Champ du contenu',
  icon: 'file-image',
  frontComponent: 'EntityProperty',
  forEntity: true,
  hasHTMLMarkup: true,
  labelPath: ['propertyRef'],
  optionsSchema: [
    {
      key: 'propertyRef',
      label: 'Afficher :',
      type: 'object',
      objectType: 'ObjectPropertyReference',
      default: true,
      group: 'Général',
    },
    {
      key: 'prefix',
      label: 'Libellé après le temps de lecture',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Général',
    },
    {
      key: 'suffix',
      label: 'Libellé après le temps de lecture',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Général',
    },
    {
      key: 'linkToEntity',
      label: "Créer un lien vers la page de l'objet",
      type: 'bool',
      default: true,
      group: 'Général',
    },
    {
      key: 'ratioWH',
      label: 'Ratio',
      type: 'enum',
      required: false,
      values: [
        { label: '16/9', value: 'ratio-16-9' },
        { label: '4/3', value: 'ratio-4-3' },
        { label: 'carré', value: 'ratio-1-1' },
        { label: 'portrait', value: 'ratio-3-4' },
        { label: 'cinemascope', value: 'ratio-2_35-1' },
        { label: 'bandeau', value: 'ratio-5-1' },
      ],
      group: 'Général',
      condition: { f$: 'obj?.propertyRef?.type === "image"' },
    },
  ],
};

export default EntityProperty;
