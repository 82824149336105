import React from 'react';

const Video = {
  _id: 'video',
  label: 'Vidéo seule',
  description: (
    <span>
      <h3>Vidéo sans contenu associé</h3>
      <div>
        Affiche un player vidéo jouant une vidéo choisie soit dans la liste des
        Video du site, soit externe (flux ou Youtube).
      </div>
      <div>Usage typique : vidéo d'intro."</div>
    </span>
  ),
  icon: 'video',
  frontComponent: 'Video',
  forPage: true,
  optionsSchema: [
    {
      key: 'video',
      label: 'Vidéo',
      type: 'object_ref',
      objectType: 'Video',
      required: true,
      group: 'Général',
    },
    {
      key: 'image',
      label: 'Poster vidéo',
      type: 'image',
      required: false,
      acceptedRatio: '16:9',
      maxSize: 1500,
      outputFormats: ['jpeg'],
      group: 'Général',
    },
    {
      key: 'title',
      label: 'Titre à afficher',
      type: 'string',
      group: 'Général',
    },
    {
      key: 'bypassPaywall',
      label: 'Ne pas appliquer de paywall',
      type: 'bool',
      group: 'Général',
    },
  ],
};

export default Video;
