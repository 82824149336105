// @flow
import * as React from 'react';

import type { Resource } from 'src/types';
import type { Style } from 'src/components/types';

import { Card, CardHeader } from 'src/components';

type Props = {|
  resource: Resource,
  onAdd?: () => *,
  onRemove?: () => *,
  style?: Style,
|};

function ListPropertyEditorItem({
  resource,
  onAdd,
  onRemove,
  style,
}: Props): React.Node {
  let action = undefined;

  if (onAdd) {
    action = {
      icon: 'plus',
      onClick: onAdd,
    };
  } else if (onRemove) {
    action = {
      icon: 'times',
      onClick: onRemove,
    };
  }

  return (
    <Card style={style}>
      <CardHeader title={resource.label || resource._id} action={action} />
    </Card>
  );
}

export default (React.memo(ListPropertyEditorItem): React.ComponentType<Props>);
