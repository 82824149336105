import * as React from 'react';
import useDebouncedEffect from 'use-debounced-effect';

import SchemaContext from 'src/pages/contexts/SchemaContext';
import { useGlobal } from 'src/hooks';

import ResourceFormEditor from 'src/pages/components/resource/ResourceEditor/FormEditor';

const DEFAULT_VALUE = {
  label: '',
  file: null,
  caption: '',
};

export const ImageEditorContext = React.createContext({
  acceptedRatio: undefined,
  maxSize: undefined,
  outputFormats: undefined,
});

export default function ImageEditor({
  value,
  onChange: onChangeProp,
  disabled,
  propertySchema,
}) {
  const { propertySchemas } = useGlobal();
  const [internalValue, setInternalValue] = React.useState(
    value || DEFAULT_VALUE
  );
  const { image: ImagePropertySchema } = propertySchemas;
  const { acceptedRatio, maxSize, outputFormats } = propertySchema;

  const onChange = React.useCallback(
    (valueShape) => {
      setInternalValue((previousValue) => ({
        ...previousValue,
        ...valueShape,
      }));
    },
    [setInternalValue]
  );

  useDebouncedEffect(() => onChangeProp(internalValue), 500, [internalValue]);

  React.useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <SchemaContext.Provider value={{ schema: ImagePropertySchema, path: '' }}>
      <ImageEditorContext.Provider
        value={{
          maxSize,
          acceptedRatio,
          outputFormats,
        }}
      >
        <ResourceFormEditor
          resource={internalValue || DEFAULT_VALUE}
          onChange={onChange}
          disabled={disabled}
        />
      </ImageEditorContext.Provider>
    </SchemaContext.Provider>
  );
}
