import * as React from 'react';

import { useGlobal } from 'src/hooks';
import { NavLink } from 'react-router-dom';
import { getResourcePath } from 'src/helpers/url';
import { useDataStore } from 'src/zustand';

import LabelRenderer from '../LabelRenderer';
import { Tag, Icon } from 'src/components';

export default function ObjectRefRenderer({
  value: ref,
  propertySchema,
  resource,
  resourceSchema,
}) {
  const { globalSchema, siteId } = useGlobal();
  const getResource = useDataStore((state) => state.get);

  if (!ref) return null;

  const { _cls: objectType } = ref;
  const refSchema = objectType ? globalSchema[objectType] : null;
  const reference = getResource(objectType, ref._id);

  if (!reference || !refSchema)
    return (
      <Tag>
        {objectType} - {ref._id}
      </Tag>
    );

  return (
    <NavLink
      to={`${getResourcePath(siteId, refSchema)}/${reference._id}`}
      target="_blank"
      style={{ display: 'flex' }}
    >
      <LabelRenderer resource={reference} schema={refSchema} />
      <Icon
        size="sm"
        name="external-link-alt"
        style={{ verticalAlign: 'text-bottom', marginLeft: 5 }}
      />
    </NavLink>
  );
}
