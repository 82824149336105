const Link = {
  _id: 'link',
  frontComponent: 'Link',
  label: 'Lien',
  icon: 'link',
  hasBulmaButtonOption: true,
  hasColorOption: true,
  hasBulmaSizeOption: true,
  labelPath: ['labelNode'],
  optionsSchema: [
    {
      key: 'labelNode',
      label: 'Texte (nouveau)',
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Général',
    },
    {
      key: 'to',
      label: 'Lien',
      type: 'Link',
      group: 'Général',
    },
  ],
  forPage: true,
  forEntity: false,
  hasEntities: false,
};

export default Link;
