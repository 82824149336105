import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import API from 'src/helpers/api/API';
import { useGlobal, useResourceContext, useNotification } from 'src/hooks';
import SchemaContext from 'src/pages/contexts/SchemaContext';

import { Modal, Notification } from 'src/components';
import ResourceFormEditor from 'src/pages/components/resource/ResourceEditor/FormEditor';

export default function CreateSnapshotModal({ open, onClose }) {
  const { resource, path: resourcePath } = useResourceContext();
  const { siteId, user, globalSchema } = useGlobal();
  const { force: forceNotification } = useNotification();
  const history = useHistory();
  const [snapshot, setSnapshot] = React.useState({
    label: moment().format('DD-MM-YYYY HH:mm'),
    description: '',
    image: null,
    recordType: resource._cls,
    recordId: resource._id,
    record: resource,
    adminUser: user._id,
    snapshotAt: moment(),
  });
  const schemaContextValue = {
    schema: globalSchema.Snapshot,
    path: resourcePath,
  };

  const createSnapshot = React.useCallback(async () => {
    try {
      const createdSnapshot = await API.post(
        `${siteId}/data/Snapshot`,
        snapshot
      );

      forceNotification({
        type: 'success',
        text: 'Le snapshot a été créé',
      });

      onClose();

      history.push(
        `${resourcePath}/${resource._id}/snapshots/${createdSnapshot._id}`
      );
    } catch (e) {
      if (e.errors) {
        forceNotification({
          id: `${e.code}-${e.message}`,
          type: 'danger',
          text: (
            <div>
              <p style={{ marginBottom: 10 }}>
                Le document ne peut être sauvegardé car il est invalide.
              </p>
              <ul className="bullet-list">
                {Object.keys(e.errors).map((fieldName) => {
                  const fieldError = e.errors[fieldName];
                  return (
                    <li>
                      {fieldName} {'=>'} {fieldError.message}
                    </li>
                  );
                })}
              </ul>
            </div>
          ),
          time: 3000,
        });
      } else {
        forceNotification({
          id: `${e.code}-${e.message}`,
          type: 'danger',
          text: e.message,
          time: 3000,
        });
      }
    }
  }, [snapshot, onClose, history, resourcePath]);

  return (
    <Modal
      modal={false}
      open={open}
      onClose={(actionType) => {
        if (actionType === 'cancel') {
          onClose();
          return;
        }

        createSnapshot();
      }}
      title="Faire une capture de la ressource"
    >
      <Notification color="info" style={{ margin: '10px 0' }}>
        Les informations entrées ci-dessous ne pourront plus être modifiées une
        fois sauvegardées
      </Notification>

      <SchemaContext.Provider value={schemaContextValue}>
        <ResourceFormEditor
          resource={snapshot}
          onChange={(newValue) => setSnapshot({ ...snapshot, ...newValue })}
        />
      </SchemaContext.Provider>
    </Modal>
  );
}
