import CategoryRenderer from './CategoryRenderer';
import TagRenderer from './TagRenderer';
import AdminUserRenderer from './AdminUserRenderer';

const getResourceCustomRenderer = (resource) => {
  if (!resource?._cls) return null;

  switch (resource._cls) {
    case 'AdminUser':
      return AdminUserRenderer;
    case 'Category':
      return CategoryRenderer;
    case 'Tag':
    case 'WorkLabel':
    case 'Rights':
      return TagRenderer;
    default:
      return null;
  }
};

export default getResourceCustomRenderer;
