import { merge, keyBy } from 'lodash';

import TypePropertySchema from './specificProperties/Type';
import {
  BooleanPropertySchema as BooleanSpecificPropertySchema,
  ColorPropertySchema as ColorSpecificPropertySchema,
  CommonPropertySchema,
  EnumPropertySchema as EnumSpecificPropertySchema,
  FilePropertySchema as FileSpecificPropertySchema,
  FloatPropertySchema as FloatSpecificPropertySchema,
  FontPropertySchema as FontSpecificPropertySchema,
  ImagePropertySchema as ImageSpecificPropertySchema,
  IntegerPropertySchema as IntegerSpecificPropertySchema,
  LayoutPropertySchema as LayoutSpecificPropertySchema,
  LinkPropertySchema as LinkSpecificPropertySchema,
  ListPropertySchema as ListSpecificPropertySchema,
  MediaPropertySchema as MediaSpecificPropertySchema,
  MongooseRefPropertySchema as MongooseRefSpecificPropertySchema,
  ObjectIdPropertySchema as ObjectIdSpecificPropertySchema,
  ObjectRefPropertySchema as ObjectRefSpecificPropertySchema,
  ObjectPropertySchema as ObjectSpecificPropertySchema,
  RichTextPropertySchema as RichTextSpecificPropertySchema,
  StringPropertySchema as StringSpecificPropertySchema,
  TimestampPropertySchema as TimestampSpecificPropertySchema,
} from './types';

const preparePropertySchema = (propertyType, specificTypePropertiesSchema) => {
  const mergedList = [];

  const commonByKey = keyBy(CommonPropertySchema, 'key');
  const specificByKey = keyBy(specificTypePropertiesSchema, 'key');

  const allKeys = [...Object.keys(commonByKey), ...Object.keys(specificByKey)];

  for (const propertyKey of allKeys) {
    mergedList.push(
      merge(commonByKey[propertyKey] || {}, specificByKey[propertyKey] || {})
    );
  }

  return {
    _id: 'ObjectPropertySchema',
    description: "An object's property in the data model",
    label: 'Propriété',
    propertiesList: mergedList,
    type: 'object',
    propertyType,
    propertiesGroups: ['Général', 'Affichage', 'Avancé'],
  };
};

export const BooleanPropertySchema = preparePropertySchema(
  'bool',
  BooleanSpecificPropertySchema
);
export const ColorPropertySchema = preparePropertySchema(
  'color',
  ColorSpecificPropertySchema
);
export const EnumPropertySchema = preparePropertySchema(
  'enum',
  EnumSpecificPropertySchema
);
export const FilePropertySchema = preparePropertySchema(
  'file',
  FileSpecificPropertySchema
);
export const FloatPropertySchema = preparePropertySchema(
  'float',
  FloatSpecificPropertySchema
);
export const FontPropertySchema = preparePropertySchema(
  'font',
  FontSpecificPropertySchema
);
export const ImagePropertySchema = preparePropertySchema(
  'image',
  ImageSpecificPropertySchema
);
export const IntegerPropertySchema = preparePropertySchema(
  'int',
  IntegerSpecificPropertySchema
);
export const LayoutPropertySchema = preparePropertySchema(
  'layout',
  LayoutSpecificPropertySchema
);
export const LinkPropertySchema = preparePropertySchema(
  'Link',
  LinkSpecificPropertySchema
);
export const ListPropertySchema = preparePropertySchema(
  'list',
  ListSpecificPropertySchema
);
export const MediaPropertySchema = preparePropertySchema(
  'media',
  MediaSpecificPropertySchema
);
export const MongooseRefPropertySchema = preparePropertySchema(
  'mongooseRef',
  MongooseRefSpecificPropertySchema
);
export const ObjectIdPropertySchema = preparePropertySchema(
  'object_id',
  ObjectIdSpecificPropertySchema
);
export const ObjectRefPropertySchema = preparePropertySchema(
  'object_ref',
  ObjectRefSpecificPropertySchema
);
export const RichTextPropertySchema = preparePropertySchema(
  'richtext',
  RichTextSpecificPropertySchema
);
export const StringPropertySchema = preparePropertySchema(
  'string',
  StringSpecificPropertySchema
);
export const TimestampPropertySchema = preparePropertySchema(
  'timestamp',
  TimestampSpecificPropertySchema
);
export const ObjectPropertySchema = preparePropertySchema(
  'object',
  ObjectSpecificPropertySchema
);

export const getPropertySchema = (type) => {
  switch (type) {
    case 'bool':
      return BooleanPropertySchema;
    case 'Color':
      return ColorPropertySchema;
    case 'enum':
      return EnumPropertySchema;
    case 'file':
      return FilePropertySchema;
    case 'float':
      return FloatPropertySchema;
    case 'Font':
      return FontPropertySchema;
    case 'image':
      return ImagePropertySchema;
    case 'int':
      return IntegerPropertySchema;
    case 'layout':
      return LayoutPropertySchema;
    case 'Link':
      return LinkPropertySchema;
    case 'list':
      return ListPropertySchema;
    case 'media':
      return MediaPropertySchema;
    case 'mongooseRef':
      return MongooseRefPropertySchema;
    case 'object_id':
      return ObjectIdPropertySchema;
    case 'objectRef':
      return ObjectRefPropertySchema;
    case 'richtext':
      return RichTextPropertySchema;
    case 'string':
      return StringPropertySchema;
    case 'timestamp':
      return TimestampPropertySchema;
    case 'object':
      return ObjectPropertySchema;
    default: {
      console.error(`Unrecognized property type ${type}`);
      return [];
    }
  }
};

export const TYPES = TypePropertySchema.values.map((option) => option.value);
export const TYPE_OPTIONS = TypePropertySchema.values;

export const getAllPropertySchemas = () => TYPES.map(getPropertySchema);
