const EntityLink = {
  _id: 'EntityLink',
  label: "Lien vers l'entité",
  icon: 'link',
  frontComponent: 'EntityLink',
  optionsSchema: [
    {
      key: 'label',
      label: 'Texte',
      type: 'string',
      group: 'Affichage',
    },
    {
      key: 'isButton',
      label: 'Est un bouton',
      type: 'bool',
      default: false,
      group: 'Affichage',
    },
    {
      key: 'color',
      label: 'Couleur',
      type: 'object',
      objectType: ['FrontColor'],
      default: null,
      toggleable: true,
      group: 'Affichage',
    },
    {
      key: 'icon',
      label: 'Icône',
      type: 'object',
      objectType: ['FrontIcon'],
      default: null,
      toggleable: true,
      group: 'Affichage',
    },
    {
      key: 'size',
      label: 'Taille',
      type: 'enum',
      default: 'normal',
      values: [
        {
          value: 'tiny',
          label: 'minuscule',
        },
        {
          value: 'small',
          label: 'petit',
        },
        {
          value: 'normal',
          label: 'normal',
        },
        {
          value: 'large',
          label: 'large',
        },
      ],
      group: 'Affichage',
    },
  ],

  forEntity: true,
};

export default EntityLink;
