export const HTML_ID_OPTION_SCHEMAS = {
  _cls: 'ObjectPropertySchema',
  key: 'htmlID',
  label: 'ID HTML',
  type: 'string',
  required: false,
  group: 'Style',
};

export const ICON_OPTION_SCHEMA = {
  key: 'icon',
  label: 'Icône',
  type: 'object',
  objectType: ['FrontIcon'],
  default: null,
  toggleable: true,
  group: 'Style',
};

export const COLOR_OPTION_SCHEMA = {
  key: 'color',
  label: 'Couleur',
  type: 'object',
  objectType: ['FrontColor'],
  default: null,
  toggleable: true,
  group: 'Style',
};

export const HTML_CLASSNAME_OPTION_SCHEMA = {
  _cls: 'ObjectPropertySchema',
  key: 'className',
  label: 'Classe CSS',
  type: 'string',
  required: false,
  group: 'Style',
};

export const HTML_WRAPPER_CLASSNAME_OPTION_SCHEMA = {
  _cls: 'ObjectPropertySchema',
  key: 'wrapperClassName',
  label: 'Classe CSS du wrapper (créer un wrapper div)',
  type: 'string',
  required: false,
  group: 'Style',
};

export const MARGINS_OPTION_SCHEMA = {
  _cls: 'ObjectPropertySchema',
  key: 'hasMargins',
  label: 'Mêmes marges qu\'une "row"',
  type: 'bool',
  required: false,
  group: 'Style',
};

export const STYLE_PROPERTIES_OPTION_SCHEMA = {
  _cls: 'ObjectPropertySchema',
  required: false,
  readOnly: false,
  createOnly: false,
  unique: false,
  sortable: false,
  filterable: false,
  searchable: false,
  key: 'styleProperties',
  label: 'Propriétés CSS',
  type: 'list',
  itemType: ['FrontStyleProperty'],
  group: 'Style',
};

const extendComponent = (component, options = {}) => {
  const { propertyListKey = 'optionsSchema' } = options;

  let extendedOptionsSchema = [];

  if (component.hasColorOption) extendedOptionsSchema.push(COLOR_OPTION_SCHEMA);

  if (component.hasIconOption) extendedOptionsSchema.push(ICON_OPTION_SCHEMA);
  if (component.hasMarginsOption || !component.isNotComponent)
    extendedOptionsSchema.push(MARGINS_OPTION_SCHEMA);
  if (component.canBeWrapped)
    extendedOptionsSchema.push(HTML_WRAPPER_CLASSNAME_OPTION_SCHEMA);

  extendedOptionsSchema.push(HTML_CLASSNAME_OPTION_SCHEMA);
  extendedOptionsSchema.push(HTML_ID_OPTION_SCHEMAS);
  extendedOptionsSchema.push(STYLE_PROPERTIES_OPTION_SCHEMA);

  return {
    ...component,
    [propertyListKey]: [
      ...(component[propertyListKey] || []),
      ...extendedOptionsSchema,
    ],
  };
};

export default extendComponent;
