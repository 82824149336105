import * as React from 'react';
import $ from 'jquery';
import { round } from 'lodash';

import { APIContext } from 'src/pages/contexts';
import RATIOS from 'src/constants/imageRatio';
import { getClosestNumber, isMoreOrLess } from 'src/helpers/number';

const getHumanReadableRatio = (ratio) => {
  const closestRatio = getClosestNumber(ratio, Object.values(RATIOS));
  const isVeryClose = isMoreOrLess(ratio, closestRatio);

  if (!isVeryClose) return ratio;

  return Object.keys(RATIOS).find(
    (ratioLabel) => round(RATIOS[ratioLabel], 4) === round(closestRatio, 4)
  );
};

export default function useImageSize(src) {
  const { getImageUrl } = React.useContext(APIContext);
  const [image, setImage] = React.useState(null);
  const [imageSize, setImageSize] = React.useState(null);

  React.useEffect(() => {
    const effect = async () => {
      if (!src) {
        setImage(null);
        return;
      }

      const newImage = new Image();
      newImage.src = getImageUrl(src);
      await newImage.decode();
      $.get(newImage.src, function (res) {
        newImage.fileSize = res.length / 1000;
        setImageSize(res.length);
        setImage(newImage);
      });
    };

    effect();
  }, [src, getImageUrl]);

  if (!image) return null;

  return {
    width: image.width,
    height: image.height,
    ratio: getHumanReadableRatio(image.width / image.height),
    fileSize: imageSize,
  };
}
