export function listComponentsInSite(
  site,
  pages,
  pageComponentDict,
  ccComponentDict,
  { complexComponents }
) {
  pages
    .filter((page) => !!page)
    .forEach((page) => {
      const rows = page.templateLayout;
      if (!rows) return;

      rows
        .filter((row) => !!row)
        .forEach((row) => {
          listComponentsInRow(site, 'page', page, row, pageComponentDict, {
            complexComponents,
          });
        });
    });

  Object.values(complexComponents)
    .filter((comp) => !!comp)
    .forEach((component) => {
      const rows = component.layout;
      if (!rows) return;

      rows
        .filter((row) => !!row)
        .forEach((row) => {
          listComponentsInRow(
            site,
            'complexComponent',
            component,
            row,
            ccComponentDict,
            {
              complexComponents,
            }
          );
        });
    });

  return { pageComponentDict, ccComponentDict };
}

export function listComponentsInRow(
  site,
  type, // 'page' | 'complexComponent'
  page,
  row,
  componentDict,
  { complexComponents }
) {
  if (row.component) {
    addComponent(site, type, page, row.component, componentDict, {
      complexComponents,
    });
    return componentDict;
  }

  if (!row.cols) {
    console.warn(
      `Row without col in site ${site._id} - page ${page._id}. Row:`,
      row
    );
    return componentDict;
  }

  row.cols
    .filter((col) => !!col)
    .forEach((column) => {
      listComponentsInColumn(site, type, page, column, componentDict, {
        complexComponents,
      });
    });

  return componentDict;
}

export function listComponentsInColumn(
  site,
  type,
  page,
  column,
  componentDict,
  { complexComponents }
) {
  if (!column.contents) {
    console.warn(
      `Column without contents in site ${site._id} - page ${page._id}. Column:`,
      column
    );
    return componentDict;
  }

  column.contents
    .filter((content) => !!content)
    .forEach((content) => {
      listComponentsInContent(site, type, page, content, componentDict, {
        complexComponents,
      });
    });

  return componentDict;
}

export function listComponentsInContent(
  site,
  type,
  page,
  content,
  componentDict,
  { complexComponents }
) {
  if (!content) {
    return componentDict;
  }

  if (content.cols) {
    content.cols
      .filter((col) => !!col)
      .forEach((column) => {
        listComponentsInColumn(site, type, page, column, componentDict, {
          complexComponents,
        });
      });

    return componentDict;
  }

  if (!content || !content.component) {
    console.warn(
      `Content without content/component in site ${site._id} - page ${page._id}. Column:`,
      content
    );
  }

  const { component, options } = content;

  if (component._cls === 'ComplexComponent') {
    listComponentsInComplexComponent(
      site,
      type,
      page,
      component,
      componentDict,
      {
        complexComponents,
      }
    );
    return componentDict;
  }

  addComponent(site, type, page, component, componentDict, {
    complexComponents,
    options,
  });
}

export function listComponentsInComplexComponent(
  site,
  type,
  page,
  component,
  componentDict,
  { complexComponents }
) {
  const complexComponent = complexComponents[component._id];

  if (!complexComponent?.layout) return;

  const rows = complexComponent.layout;

  rows
    .filter((row) => !!row)
    .forEach((row) => {
      listComponentsInRow(site, type, page, row, componentDict, {
        complexComponents,
      });
    });
}

export function addComponent(
  site,
  type,
  page,
  component,
  componentDict,
  { complexComponents, options }
) {
  const mode = component.options?.mode || options?.mode || 'default';

  componentDict[component._id] = componentDict[component._id] || {
    backofficeId: component._id,
    frontendId: component.frontComponent,
    count: 0,
    sites: {},
    modes: {},
    sitePages: {},
  };
  componentDict[component._id].count += 1;
  componentDict[component._id].modes[mode] =
    componentDict[component._id].modes[mode] + 1 || 1;
  componentDict[component._id].sites[site._id] =
    componentDict[component._id].sites[site._id] + 1 || 1;
  componentDict[component._id].sitePages[site._id] =
    componentDict[component._id].sitePages[site._id] || [];
  componentDict[component._id].sitePages[site._id].push(
    `${page._id} (${type})`
  );

  return componentDict;
}
