const EntityEffectsCount = {
  _id: 'EntityEffectsCount',
  label: "Nombre d'effets",
  icon: 'book-reader',
  frontComponent: 'EntityEffectsCount',
  forEntity: true,
  siteIds: ['factuel'],
  optionsSchema: [],
};

export default EntityEffectsCount;
