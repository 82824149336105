const Countdown = {
  _id: 'Countdown',
  frontComponent: 'Countdown',
  label: 'Countdown',
  icon: 'clock',
  optionsSchema: [
    {
      key: 'prefix',
      label: 'Libellé',
      required: false,
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Affichage',
    },
    {
      key: 'deadline',
      label: 'Deadline',
      required: true,
      type: 'date',
      default: null,
      group: 'Général',
    },
    {
      key: 'suffix',
      label: 'Libellé',
      required: false,
      type: 'list',
      itemType: ['react-node'],
      default: [],
      group: 'Affichage',
    },
  ],
  forPage: true,
};

export default Countdown;
